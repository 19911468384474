import { BooleanProperty, IntegerProperty, NameProperty, UnformattedTextProperty } from '@edgebox/data-definition-kit';
import { ContentCol, FormField, FormRow, formWithValidation, HeaderCol, InfoIcon, Right } from '@edgebox/react-components';
import {
  ClientContractEntity,
  ClientContractRevisionEntity,
  ClientCustomerEntity,
  ClientSiteEntity,
  FeatureSummaryAll,
} from '@edgebox/sync-core-rest-client';
import { ContractConfiguration } from '@edgebox/sync-core-rest-client/dist/services/api';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import React from 'react';
import { Alert, Badge, Button, Col, Modal, Row } from 'react-bootstrap';
import {
  FEATURE_AUTHENTICATION_ADDITIONAL_BASIC_AUTH,
  FEATURE_CUSTOM_REQUEST_TIMEOUT,
  FEATURE_CUSTOM_REQUEST_TIMEOUT_AVAILABLE,
  FEATURE_DYNAMIC_POOL_ASSIGNMENT,
  FEATURE_DYNAMIC_POOL_ASSIGNMENT_AVAILABLE,
  FEATURE_PREFER_2XX_STATUS_CODE,
  FEATURE_PREFER_2XX_STATUS_CODE_AVAILABLE,
  FEATURE_REQUEST_PER_TRANSLATION,
  FEATURE_REQUEST_PER_TRANSLATION_AVAILABLE,
  FEATURE_REQUEST_POLLING,
  FEATURE_REQUEST_POLLING_AVAILABLE,
  FEATURE_REQUEST_POLLING_TIMEOUT,
  FEATURE_SELF_SERVICE_FEATURE_FLAGS,
  FEATURE_SKIP_UNCHANGED_ENTITIES_AVAILABLE,
  FEATURE_SKIP_UNCHANGED_ENTITIES_LARGE_ENTITY_COUNT,
  FEATURE_SKIP_UNCHANGED_ENTITIES_MODE,
  FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE,
  FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF,
  FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON,
  FEATURE_SKIP_UNCHANGED_ENTITIES_SLOW_REQUEST_DURATION,
  FEATURE_SKIP_UNCHANGED_TRANSLATIONS,
  FEATURE_SKIP_UNCHANGED_TRANSLATIONS_AVAILABLE,
  FEATURE_THROTTLING,
} from '../../../features';
import { sendToParent } from '../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ContractWarnings } from '../../ContractWarnings';
import { EmbeddedModal } from '../../EmbeddedModal';
import { SiteFeatureFlagGate } from '../../SiteFeatureFlagGate';
import { SwitchButton } from '../../SwitchButton';
import { SyncCoreFeatureFlagGate } from '../../SyncCoreFeatureFlagGate';
import { ParamsComponent } from '../ParamsComponent';
import { ThrottleSettings } from './ThrottleSettings';

class MigratedParams {
  @BooleanProperty(false)
  migrated?: boolean;
}

interface IProps {
  embedded?: boolean;
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
  contract?: ClientContractEntity;
  contractConfiguration?: ContractConfiguration;
  contractRevision?: ClientContractRevisionEntity | null;
  customer?: ClientCustomerEntity;
  loadedSite?: boolean;
  refreshingContract?: boolean;
  dynamicPoolAssignment?: boolean;
  savingDynamicPoolAssignment?: boolean;
  requestPerTranslation?: boolean;
  savingRequestPerTranslation?: boolean;
  skipUnchagedTranslations?: boolean;
  savingSkipUnchagedTranslations?: boolean;
  skipUnchagedEntitiesMode?:
    | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF
    | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON
    | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE;
  skipUnchagedEntitiesSlowRequestDuration?: number;
  skipUnchagedEntitiesLargeEntityCount?: number;
  savingSkipUnchagedEntities?: boolean;
  editSkipUnchagedEntities?: boolean;
  requestPolling?: boolean;
  savingRequestPolling?: boolean;
  basicAuthUsername?: string;
  editBasicAuth?: boolean;
  savingBasicAuth?: boolean;
  editRequestTimeout?: boolean;
  savingRequestTimeout?: boolean;
  editPollingRequestTimeout?: boolean;
  savingPollingRequestTimeout?: boolean;
  prefer2xxStatusCode?: boolean;
  savingPrefer2xxStatusCode?: boolean;
  allFlags?: FeatureSummaryAll;
}

class RequestTimeoutDto {
  constructor(props: RequestTimeoutDto) {
    Object.assign(this, props);
  }

  @IntegerProperty(false, { min: 5_000, max: 120_000 })
  siteTimeout?: number;

  @IntegerProperty(false, { min: 5_000, max: 120_000 })
  projectTimeout?: number;
}
const RequestTimeoutForm = formWithValidation<RequestTimeoutDto, RequestTimeoutDto>(
  (values) => plainToInstance(RequestTimeoutDto, values),
  (entity) => instanceToPlain<RequestTimeoutDto>(entity)
);

class PollingRequestTimeoutDto {
  constructor(props: PollingRequestTimeoutDto) {
    Object.assign(this, props);
  }

  @IntegerProperty(false, { min: 1, max: 60 * 24 })
  siteTimeoutHours?: number;

  @IntegerProperty(false, { min: 1, max: 60 * 24 })
  projectTimeoutHours?: number;
}
const PollingRequestTimeoutForm = formWithValidation<PollingRequestTimeoutDto, PollingRequestTimeoutDto>(
  (values) => plainToInstance(PollingRequestTimeoutDto, values),
  (entity) => instanceToPlain<PollingRequestTimeoutDto>(entity)
);

class BasicAuthDto {
  constructor(props: BasicAuthDto) {
    Object.assign(this, props);
  }

  @NameProperty(true, 100)
  basicAuthUsername!: string;

  @UnformattedTextProperty(true, 100)
  basicAuthPassword!: string;
}
const BasicAuthForm = formWithValidation<BasicAuthDto, BasicAuthDto>(
  (values) => plainToInstance(BasicAuthDto, values),
  (entity) => instanceToPlain<BasicAuthDto>(entity as any) as any
);

class SkipUnchangedEntitiesDto {
  constructor(props: SkipUnchangedEntitiesDto) {
    Object.assign(this, props);
  }

  @IntegerProperty(false, { min: 0, max: 2 })
  mode?: 0 | 1 | 2;

  @IntegerProperty(false, { min: 5_000, max: 120_000 })
  slowRequestDuration?: number;

  @IntegerProperty(false, { min: 10, max: 1_000 })
  largeEntityCount?: number;
}
const SkipUnchangedEntitiesForm = formWithValidation<SkipUnchangedEntitiesDto, SkipUnchangedEntitiesDto>(
  (values) => plainToInstance(SkipUnchangedEntitiesDto, values),
  (entity) => instanceToPlain<SkipUnchangedEntitiesDto>(entity)
);

export class SiteSettings extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    let site: ClientSiteEntity | undefined = undefined;
    try {
      site = await this.getCurrentSite();

      this.setState({
        site,
        loadedSite: true,
      });

      if (!site) {
        return {};
      }
    } catch (e) {
      return {
        loadedSite: true,
      };
    }

    const flags = await this.api.utility.features.summary();
    const dynamicPoolAssignment = !!flags[FEATURE_DYNAMIC_POOL_ASSIGNMENT];
    const prefer2xxStatusCode = !!flags[FEATURE_PREFER_2XX_STATUS_CODE];
    const requestPerTranslation = !!flags[FEATURE_REQUEST_PER_TRANSLATION];
    const skipUnchagedTranslations = !!flags[FEATURE_SKIP_UNCHANGED_TRANSLATIONS];
    const requestPolling = !!flags[FEATURE_REQUEST_POLLING];
    const skipUnchagedEntitiesMode = flags[FEATURE_SKIP_UNCHANGED_ENTITIES_MODE] as
      | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF
      | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON
      | typeof FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE
      | undefined;
    const skipUnchagedEntitiesSlowRequestDuration = flags[FEATURE_SKIP_UNCHANGED_ENTITIES_SLOW_REQUEST_DURATION];
    const skipUnchagedEntitiesLargeEntityCount = flags[FEATURE_SKIP_UNCHANGED_ENTITIES_LARGE_ENTITY_COUNT];

    const allFlags = await this.api.utility.features.summaryAll();

    const basicAuthUsername = flags[FEATURE_AUTHENTICATION_ADDITIONAL_BASIC_AUTH]
      ? (await this.api.syndication.authentication.getBasicAuth())?.basicAuthUsername || ''
      : '';

    return {
      dynamicPoolAssignment,
      prefer2xxStatusCode,
      requestPerTranslation,
      skipUnchagedTranslations,
      requestPolling,
      skipUnchagedEntitiesMode,
      skipUnchagedEntitiesSlowRequestDuration,
      skipUnchagedEntitiesLargeEntityCount,
      basicAuthUsername,
      allFlags,
    };
  }

  render() {
    const {
      site,
      loadedSite,
      dynamicPoolAssignment,
      savingDynamicPoolAssignment,
      prefer2xxStatusCode,
      savingPrefer2xxStatusCode,
      requestPerTranslation,
      savingRequestPerTranslation,
      skipUnchagedTranslations,
      savingSkipUnchagedTranslations,
      requestPolling,
      savingRequestPolling,
      allFlags,
      editRequestTimeout,
      savingRequestTimeout,
      editPollingRequestTimeout,
      savingPollingRequestTimeout,
      basicAuthUsername,
      savingBasicAuth,
      editBasicAuth,
      editSkipUnchagedEntities,
      savingSkipUnchagedEntities,
      skipUnchagedEntitiesMode,
      skipUnchagedEntitiesSlowRequestDuration,
      skipUnchagedEntitiesLargeEntityCount,
    } = this.state;

    const { embedded } = this.props;

    const registerAgain = (light?: boolean) => (
      <Button
        variant={light ? 'light' : 'primary'}
        onClick={() =>
          sendToParent({
            type: 'register-site',
          })
        }
      >
        Re-register
      </Button>
    );

    if (!site) {
      if (loadedSite) {
        return (
          <Alert variant={'danger'}>
            Failed to load site information. Please reload the page. If this issue persists please contact our support.
            <br />
            <br />
            {registerAgain()}
          </Alert>
        );
      }
      return this.renderRequest();
    }

    return (
      <div>
        {embedded ? undefined : <ContractWarnings />}

        <Row>
          <HeaderCol xs={2}>Throttling</HeaderCol>
          <ContentCol>
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_THROTTLING}
              ifEnabled={() => <ThrottleSettings />}
              ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow throttling requests.</Alert>}
            />
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Performance</HeaderCol>
          <ContentCol>
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_SELF_SERVICE_FEATURE_FLAGS}
              ifEnabled={() => (
                <>
                  <SiteFeatureFlagGate featureName="request-per-translation">
                    {() => (
                      <>
                        <label className="pb-0 mb-0 fw-bold">
                          Individual requests per translation{' '}
                          <InfoIcon size="large">
                            This feature is applied across all sites in the same project.
                            <br />
                            When enabled, the Sync Core sends one request per translation instead of one request with all translations. This
                            works for both push and pull.
                          </InfoIcon>
                        </label>
                        <Row>
                          <ContentCol xs={12} sm={10} md={8} lg={6}>
                            <SyncCoreFeatureFlagGate
                              featureName={FEATURE_REQUEST_PER_TRANSLATION_AVAILABLE}
                              ifEnabled={() => (
                                <>
                                  <SwitchButton
                                    disabled={
                                      savingRequestPerTranslation || savingSkipUnchagedTranslations || requestPerTranslation === undefined
                                    }
                                    selected={requestPerTranslation ? '1' : '0'}
                                    options={{ '1': 'Yes', '0': 'No' }}
                                    onSelect={async (newValue) => {
                                      this.setState({
                                        savingRequestPerTranslation: true,
                                      });
                                      const enabled = newValue === '1';
                                      const flags = await this.api.utility.features.update(
                                        FEATURE_REQUEST_PER_TRANSLATION,
                                        'project',
                                        enabled ? 1 : undefined
                                      );
                                      if (!enabled && skipUnchagedTranslations) {
                                        await this.api.utility.features.update(FEATURE_SKIP_UNCHANGED_TRANSLATIONS, 'project', undefined);
                                      }
                                      this.setState({
                                        requestPerTranslation: !!flags[FEATURE_REQUEST_PER_TRANSLATION],
                                        skipUnchagedTranslations: enabled ? skipUnchagedTranslations : false,
                                        savingRequestPerTranslation: undefined,
                                      });
                                    }}
                                  />
                                </>
                              )}
                              ifDisabled={() => (
                                <Alert variant="light">Please update your Sync Core to allow per-translation requests.</Alert>
                              )}
                            />
                          </ContentCol>
                        </Row>

                        {requestPerTranslation && (
                          <>
                            <label className="pb-0 mb-0 fw-bold">
                              Skip unchanged translations{' '}
                              <InfoIcon size="large">
                                This feature is applied across all sites in the same project.
                                <br />
                                When enabled, the Sync Core will only send translations to this site that have changed since the last
                                update. Sites will also start sending only translations that are pushed explicitly rather than sending every
                                translation in it's current state.
                              </InfoIcon>
                            </label>
                            <Row>
                              <ContentCol xs={12} sm={10} md={8} lg={6}>
                                <SyncCoreFeatureFlagGate
                                  featureName={FEATURE_SKIP_UNCHANGED_TRANSLATIONS_AVAILABLE}
                                  ifEnabled={() => (
                                    <>
                                      <SwitchButton
                                        disabled={
                                          savingRequestPerTranslation ||
                                          savingSkipUnchagedTranslations ||
                                          skipUnchagedTranslations === undefined
                                        }
                                        selected={skipUnchagedTranslations ? '1' : '0'}
                                        options={{ '1': 'Yes', '0': 'No' }}
                                        onSelect={async (newValue) => {
                                          this.setState({
                                            savingSkipUnchagedTranslations: true,
                                          });
                                          const enabled = newValue === '1';
                                          const flags = await this.api.utility.features.update(
                                            FEATURE_SKIP_UNCHANGED_TRANSLATIONS,
                                            'project',
                                            enabled ? 1 : undefined
                                          );
                                          this.setState({
                                            skipUnchagedTranslations: !!flags[FEATURE_SKIP_UNCHANGED_TRANSLATIONS],
                                            savingSkipUnchagedTranslations: undefined,
                                          });
                                        }}
                                      />
                                    </>
                                  )}
                                  ifDisabled={() => (
                                    <Alert variant="light">
                                      Please update your Sync Core to allow skipping requests for unchanged translations.
                                    </Alert>
                                  )}
                                />
                              </ContentCol>
                            </Row>
                          </>
                        )}
                      </>
                    )}
                  </SiteFeatureFlagGate>

                  <label className="pb-0 mb-0 fw-bold">Custom request timeout</label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_CUSTOM_REQUEST_TIMEOUT_AVAILABLE}
                        ifEnabled={() => (
                          <>
                            <span className="me-2">
                              {allFlags?.site?.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT] ? (
                                `${allFlags.site.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT]} ms`
                              ) : allFlags?.project?.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT] ? (
                                `${allFlags.project.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT]} ms`
                              ) : (
                                <em>default</em>
                              )}
                            </span>
                            <Button className="px-0" variant="light" onClick={() => this.setState({ editRequestTimeout: true })}>
                              Edit
                            </Button>

                            <EmbeddedModal
                              show={editRequestTimeout}
                              onHide={() => this.setState({ editRequestTimeout: undefined })}
                              size="xl"
                              scrollable
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Custom request timeout</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <RequestTimeoutForm
                                  initialValues={Object.assign(
                                    new RequestTimeoutDto({
                                      projectTimeout: allFlags?.project?.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT],
                                      siteTimeout: allFlags?.site?.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT],
                                    })
                                  )}
                                  onSubmit={async (values: RequestTimeoutDto) => {
                                    this.setState({
                                      savingRequestTimeout: true,
                                    });

                                    await this.api.utility.features.update(
                                      FEATURE_CUSTOM_REQUEST_TIMEOUT,
                                      'project',
                                      values.projectTimeout ? values.projectTimeout : undefined
                                    );
                                    const updatedFlags = allFlags || {};
                                    if (!updatedFlags.project) {
                                      updatedFlags.project = {
                                        flags: {},
                                      };
                                    }
                                    if (values.projectTimeout) {
                                      updatedFlags.project!.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT] = values.projectTimeout;
                                    } else {
                                      delete updatedFlags.project!.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT];
                                    }

                                    await this.api.utility.features.update(
                                      FEATURE_CUSTOM_REQUEST_TIMEOUT,
                                      'site',
                                      values.siteTimeout ? values.siteTimeout : undefined
                                    );
                                    if (!updatedFlags.site) {
                                      updatedFlags.site = {
                                        flags: {},
                                      };
                                    }
                                    if (values.siteTimeout) {
                                      updatedFlags.site!.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT] = values.siteTimeout;
                                    } else {
                                      delete updatedFlags.site!.flags[FEATURE_CUSTOM_REQUEST_TIMEOUT];
                                    }

                                    this.setState({
                                      allFlags: updatedFlags,
                                      savingRequestTimeout: undefined,
                                      editRequestTimeout: undefined,
                                    });
                                  }}
                                >
                                  {() => (
                                    <>
                                      <Row>
                                        <Col xs={12} lg={4}>
                                          <h4>This site</h4>
                                          <FormRow name={'siteTimeout'} label={'Timeout'} labelPlacement={'above'}>
                                            <FormField name={'siteTimeout'} label={'(use default)'} type={'number'} noRow suffix="ms" />
                                          </FormRow>
                                        </Col>

                                        <Col xs={12} lg={4}>
                                          <h4>Default for all sites</h4>
                                          <FormRow name={'projectTimeout'} label={'Default timeout'} labelPlacement={'above'}>
                                            <FormField
                                              name={'projectTimeout'}
                                              label={`(use Sync Core default)`}
                                              type={'number'}
                                              noRow
                                              suffix="ms"
                                            />
                                          </FormRow>
                                        </Col>
                                      </Row>

                                      <Right className="pe-3">
                                        <Button variant="primary" type="submit" disabled={savingRequestTimeout}>
                                          Save
                                        </Button>
                                      </Right>
                                    </>
                                  )}
                                </RequestTimeoutForm>
                              </Modal.Body>
                            </EmbeddedModal>
                          </>
                        )}
                        ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow for custom request timeouts.</Alert>}
                      />
                    </ContentCol>
                  </Row>

                  <label className="pb-0 mb-0 fw-bold">Skip unchanged entities</label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_SKIP_UNCHANGED_ENTITIES_AVAILABLE}
                        ifEnabled={() => (
                          <>
                            <span className="me-2">
                              {skipUnchagedEntitiesMode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON ? (
                                <strong>Always</strong>
                              ) : skipUnchagedEntitiesMode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE ? (
                                <strong>Adaptive</strong>
                              ) : (
                                <em>Off</em>
                              )}
                              {skipUnchagedEntitiesMode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE
                                ? ` for entities containing more than ${skipUnchagedEntitiesLargeEntityCount} sub-entities and requests taking longer than ${skipUnchagedEntitiesSlowRequestDuration}ms.`
                                : null}
                            </span>
                            <Button className="px-0" variant="light" onClick={() => this.setState({ editSkipUnchagedEntities: true })}>
                              Edit
                            </Button>

                            <EmbeddedModal
                              show={editSkipUnchagedEntities}
                              onHide={() => this.setState({ editSkipUnchagedEntities: undefined })}
                              size="xl"
                              scrollable
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Skip unchanged entities</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <SkipUnchangedEntitiesForm
                                  initialValues={Object.assign(
                                    new SkipUnchangedEntitiesDto({
                                      mode: skipUnchagedEntitiesMode,
                                      largeEntityCount: skipUnchagedEntitiesLargeEntityCount,
                                      slowRequestDuration: skipUnchagedEntitiesSlowRequestDuration,
                                    })
                                  )}
                                  onSubmit={async (values: SkipUnchangedEntitiesDto) => {
                                    this.setState({
                                      savingSkipUnchagedEntities: true,
                                    });

                                    await this.api.utility.features.update(FEATURE_SKIP_UNCHANGED_ENTITIES_MODE, 'project', values.mode);
                                    await this.api.utility.features.update(
                                      FEATURE_SKIP_UNCHANGED_ENTITIES_LARGE_ENTITY_COUNT,
                                      'project',
                                      values.mode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE ? values.largeEntityCount : undefined
                                    );
                                    await this.api.utility.features.update(
                                      FEATURE_SKIP_UNCHANGED_ENTITIES_SLOW_REQUEST_DURATION,
                                      'project',
                                      values.mode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE ? values.slowRequestDuration : undefined
                                    );

                                    this.setState({
                                      skipUnchagedEntitiesMode: values.mode,
                                      skipUnchagedEntitiesLargeEntityCount: values.largeEntityCount,
                                      skipUnchagedEntitiesSlowRequestDuration: values.slowRequestDuration,
                                      savingSkipUnchagedEntities: undefined,
                                      editSkipUnchagedEntities: undefined,
                                    });
                                  }}
                                >
                                  {({ values, setValue }) => (
                                    <>
                                      <FormRow name={'mode'} label={'Mode'} labelPlacement={'above'}>
                                        <SwitchButton
                                          size={'m'}
                                          selected={
                                            typeof values.mode === 'number'
                                              ? values.mode.toString()
                                              : FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF.toString()
                                          }
                                          options={{
                                            //[FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ON.toString()]: 'Always',
                                            [FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE.toString()]: 'Adaptive',
                                            [FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_OFF.toString()]: 'Off',
                                          }}
                                          onSelect={async (newValue) => {
                                            setValue('mode', parseInt(newValue));
                                          }}
                                        />
                                      </FormRow>

                                      {values.mode === FEATURE_SKIP_UNCHANGED_ENTITIES_MODE_ADAPTVE && (
                                        <>
                                          <FormRow
                                            name={'largeEntityCount'}
                                            label={'Large entity count'}
                                            labelPlacement={'above'}
                                            className="mt-2"
                                          >
                                            <FormField name={'largeEntityCount'} label={'(use default)'} type={'number'} noRow />
                                          </FormRow>

                                          <FormRow
                                            name={'slowRequestDuration'}
                                            label={'Slow request duration'}
                                            labelPlacement={'above'}
                                            className="mt-2"
                                          >
                                            <FormField
                                              name={'slowRequestDuration'}
                                              label={'(use default)'}
                                              type={'number'}
                                              noRow
                                              suffix="ms"
                                            />
                                          </FormRow>
                                        </>
                                      )}

                                      <Right className="pe-3 pt-5">
                                        <Button variant="primary" type="submit" disabled={savingSkipUnchagedEntities}>
                                          Save
                                        </Button>
                                      </Right>
                                    </>
                                  )}
                                </SkipUnchangedEntitiesForm>
                              </Modal.Body>
                            </EmbeddedModal>
                          </>
                        )}
                        ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow for custom request timeouts.</Alert>}
                      />
                    </ContentCol>
                  </Row>
                </>
              )}
              ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow managing performance settings.</Alert>}
            />
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Dynamic pool assignment</HeaderCol>
          <ContentCol>
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_SELF_SERVICE_FEATURE_FLAGS}
              ifEnabled={() => (
                <>
                  <label className="pb-0 mb-0 fw-bold">
                    Delete content when changing pools
                    <InfoIcon size="large">
                      This feature is applied across all sites in the same project.
                      <br />
                      You have to enable dynamic pool assignments per site in the Settings tab to use this feature.
                      <br />
                      If you are assigning different pools to content depending on their environment (e.g. push content from "dev" to
                      "stage" and then to "prod") you should not enable this as it will delete content from upstream sites whenever lower
                      sites push an update.
                    </InfoIcon>
                  </label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_DYNAMIC_POOL_ASSIGNMENT_AVAILABLE}
                        ifEnabled={() => (
                          <>
                            <SwitchButton
                              disabled={savingDynamicPoolAssignment || dynamicPoolAssignment === undefined}
                              selected={dynamicPoolAssignment ? '1' : '0'}
                              options={{ '1': 'Yes', '0': 'No' }}
                              onSelect={async (newValue) => {
                                this.setState({
                                  savingDynamicPoolAssignment: true,
                                });
                                const enabled = newValue === '1';
                                const flags = await this.api.utility.features.update(
                                  FEATURE_DYNAMIC_POOL_ASSIGNMENT,
                                  'project',
                                  enabled ? 1 : undefined
                                );
                                this.setState({
                                  dynamicPoolAssignment: !!flags[FEATURE_DYNAMIC_POOL_ASSIGNMENT],
                                  savingDynamicPoolAssignment: undefined,
                                });
                              }}
                            />
                            <Alert variant="warning">
                              If you have already pushed content, please re-push all content items before enabling this or content may be
                              deleted unintentionally!
                            </Alert>
                          </>
                        )}
                        ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow dynamic pool assignments.</Alert>}
                      />
                    </ContentCol>
                  </Row>
                </>
              )}
              ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow managing feature flags.</Alert>}
            />
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Private sites</HeaderCol>
          <ContentCol>
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_SELF_SERVICE_FEATURE_FLAGS}
              ifEnabled={() => (
                <>
                  <label className="pb-0 mb-0 fw-bold">
                    Basic Auth protection{' '}
                    <InfoIcon size="large">
                      Only needed if you are using Basic Auth to protect your pre-production sites against public access.
                    </InfoIcon>
                  </label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_AUTHENTICATION_ADDITIONAL_BASIC_AUTH}
                        ifEnabled={() => (
                          <>
                            <span className="me-2">{basicAuthUsername ? basicAuthUsername : <em>none</em>}</span>
                            <Button
                              className="px-0"
                              variant="light"
                              disabled={basicAuthUsername === undefined}
                              onClick={() => this.setState({ editBasicAuth: true })}
                            >
                              Edit
                            </Button>
                            <Alert variant="warning">
                              This only works if you are using Cookie authentication for Content Sync and if your webserver filters out
                              Basic Auth.
                              <br />
                              After switching to Cookie auth, please re-register the site to propagate the changes to the Sync Core.
                            </Alert>

                            <EmbeddedModal
                              show={editBasicAuth}
                              onHide={() => this.setState({ editBasicAuth: undefined })}
                              size="xl"
                              scrollable
                            >
                              <Modal.Header closeButton>
                                <Modal.Title>Basic Auth protection</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <BasicAuthForm
                                  initialValues={Object.assign(
                                    new BasicAuthDto({
                                      basicAuthUsername: basicAuthUsername || '',
                                      basicAuthPassword: '',
                                    })
                                  )}
                                  validate={(values: BasicAuthDto) => {
                                    if (!values.basicAuthUsername) {
                                      return {
                                        basicAuthUsername: 'Please enter a username',
                                      };
                                    }
                                    if (!values.basicAuthPassword) {
                                      return {
                                        basicAuthPassword: 'Please enter a password',
                                      };
                                    }
                                  }}
                                  onSubmit={async (values: BasicAuthDto) => {
                                    this.setState({
                                      savingBasicAuth: true,
                                    });

                                    await this.api.syndication.authentication.setBasicAuth(
                                      values.basicAuthUsername && values.basicAuthPassword
                                        ? {
                                            username: values.basicAuthUsername,
                                            password: values.basicAuthPassword,
                                          }
                                        : {}
                                    );

                                    this.setState({
                                      savingBasicAuth: undefined,
                                      editBasicAuth: undefined,
                                      basicAuthUsername: values.basicAuthUsername || '',
                                    });
                                  }}
                                >
                                  {({ values }) => (
                                    <>
                                      <Row>
                                        <Col xs={6} lg={4}>
                                          <FormRow name={'basicAuthUsername'} label={'Username'} labelPlacement={'above'}>
                                            <FormField name={'basicAuthUsername'} label={''} type={'text'} noRow />
                                          </FormRow>
                                        </Col>

                                        <Col xs={6} lg={4}>
                                          <FormRow name={'basicAuthPassword'} label={'Password'} labelPlacement={'above'}>
                                            <FormField name={'basicAuthPassword'} label={''} type={'password'} noRow />
                                          </FormRow>
                                        </Col>
                                      </Row>

                                      <Right className="pe-3">
                                        <Button
                                          variant="danger"
                                          type="button"
                                          disabled={savingBasicAuth}
                                          onClick={async () => {
                                            this.setState({
                                              savingBasicAuth: true,
                                            });

                                            await this.api.syndication.authentication.setBasicAuth({});

                                            this.setState({
                                              savingBasicAuth: undefined,
                                              editBasicAuth: undefined,
                                              basicAuthUsername: '',
                                            });
                                          }}
                                        >
                                          Remove
                                        </Button>

                                        <Button
                                          variant="primary"
                                          type="submit"
                                          disabled={savingBasicAuth || !values.basicAuthUsername || !values.basicAuthPassword}
                                        >
                                          Save
                                        </Button>
                                      </Right>
                                    </>
                                  )}
                                </BasicAuthForm>
                              </Modal.Body>
                            </EmbeddedModal>
                          </>
                        )}
                        ifDisabled={() => (
                          <Alert variant="light">Please update your Sync Core to allow additional Basic Auth protection.</Alert>
                        )}
                      />
                    </ContentCol>
                  </Row>
                </>
              )}
              ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow managing feature flags.</Alert>}
            />

            <SiteFeatureFlagGate featureName="request-per-translation">
              {() => (
                <>
                  <label className="pb-0 mb-0 fw-bold">
                    Request polling{' '}
                    <InfoIcon size="large">
                      When enabled, the Sync Core does not send requests directly to the site. Instead, the Sync Core waits for the site to
                      poll and answer to requests asynchronously. This allows you to connect sites that are not accessble from the public
                      internet at all like local development sites.
                    </InfoIcon>
                  </label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_REQUEST_POLLING_AVAILABLE}
                        ifEnabled={() => (
                          <>
                            <SiteFeatureFlagGate
                              featureName={'request-polling'}
                              ifDisabled={() => (
                                <Alert variant="light">
                                  Please update our module to the latest version and enable the "private environment" sub module to make use
                                  of request polling.
                                </Alert>
                              )}
                            >
                              {() => (
                                <>
                                  <SwitchButton
                                    disabled={savingRequestPolling || requestPolling === undefined}
                                    selected={requestPolling ? '1' : '0'}
                                    options={{ '1': 'Yes', '0': 'No' }}
                                    onSelect={async (newValue) => {
                                      this.setState({
                                        savingRequestPolling: true,
                                      });
                                      const enabled = newValue === '1';
                                      const flags = await this.api.utility.features.update(
                                        FEATURE_REQUEST_POLLING,
                                        'site',
                                        enabled ? 1 : undefined
                                      );
                                      this.setState({
                                        requestPolling: !!flags[FEATURE_REQUEST_POLLING],
                                        savingRequestPolling: undefined,
                                      });
                                    }}
                                  />
                                  <Alert variant="warning">
                                    <strong>Only enable this if your site can't be accessed from the public internet at all</strong>;
                                    otherwise disabling this will have a much better overall performance.
                                    <br />
                                    This feature only works if you have no Basic Auth protection like the shield module enabled on this site
                                    and are using Basic Auth as the authentication mechanism for this site (see <em>Authentication</em> in
                                    the <em>Settings</em> tab of the module).
                                    <br />
                                    You must have the "private environment" sub module enabled and then run <em>drush cspep</em> to answer
                                    all pending requests.
                                  </Alert>
                                </>
                              )}
                            </SiteFeatureFlagGate>
                          </>
                        )}
                        ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow request polling.</Alert>}
                      />
                    </ContentCol>
                  </Row>

                  {requestPolling && (
                    <>
                      <label className="pb-0 mb-0 fw-bold">Request TTL</label>
                      <Row>
                        <ContentCol xs={12} sm={10} md={8} lg={6}>
                          <SyncCoreFeatureFlagGate
                            featureName={FEATURE_REQUEST_POLLING_AVAILABLE}
                            ifEnabled={() => (
                              <>
                                <span className="me-2">
                                  {allFlags?.site?.flags[FEATURE_REQUEST_POLLING_TIMEOUT] ? (
                                    `${Math.round(allFlags.site.flags[FEATURE_REQUEST_POLLING_TIMEOUT] / 60 / 60)} hours`
                                  ) : allFlags?.project?.flags[FEATURE_REQUEST_POLLING_TIMEOUT] ? (
                                    `${Math.round(allFlags.project.flags[FEATURE_REQUEST_POLLING_TIMEOUT] / 60 / 60)} hours`
                                  ) : (
                                    <em>default (1 day)</em>
                                  )}
                                </span>
                                <Button className="px-0" variant="light" onClick={() => this.setState({ editPollingRequestTimeout: true })}>
                                  Edit
                                </Button>

                                <EmbeddedModal
                                  show={editPollingRequestTimeout}
                                  onHide={() => this.setState({ editPollingRequestTimeout: undefined })}
                                  size="xl"
                                  scrollable
                                >
                                  <Modal.Header closeButton>
                                    <Modal.Title>Custom request TTL for request polling</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <PollingRequestTimeoutForm
                                      initialValues={Object.assign(
                                        new PollingRequestTimeoutDto({
                                          projectTimeoutHours: allFlags?.project?.flags[FEATURE_REQUEST_POLLING_TIMEOUT]
                                            ? Math.round(allFlags?.project?.flags[FEATURE_REQUEST_POLLING_TIMEOUT] / 60 / 60)
                                            : undefined,
                                          siteTimeoutHours: allFlags?.site?.flags[FEATURE_REQUEST_POLLING_TIMEOUT]
                                            ? Math.round(allFlags?.site?.flags[FEATURE_REQUEST_POLLING_TIMEOUT] / 60 / 60)
                                            : undefined,
                                        })
                                      )}
                                      onSubmit={async (values: PollingRequestTimeoutDto) => {
                                        this.setState({
                                          savingPollingRequestTimeout: true,
                                        });

                                        await this.api.utility.features.update(
                                          FEATURE_REQUEST_POLLING_TIMEOUT,
                                          'project',
                                          values.projectTimeoutHours ? values.projectTimeoutHours * 60 * 60 : undefined
                                        );
                                        const updatedFlags = allFlags || {};
                                        if (!updatedFlags.project) {
                                          updatedFlags.project = {
                                            flags: {},
                                          };
                                        }
                                        if (values.projectTimeoutHours) {
                                          updatedFlags.project!.flags[FEATURE_REQUEST_POLLING_TIMEOUT] =
                                            values.projectTimeoutHours * 60 * 60;
                                        } else {
                                          delete updatedFlags.project!.flags[FEATURE_REQUEST_POLLING_TIMEOUT];
                                        }

                                        await this.api.utility.features.update(
                                          FEATURE_REQUEST_POLLING_TIMEOUT,
                                          'site',
                                          values.siteTimeoutHours ? values.siteTimeoutHours * 60 * 60 : undefined
                                        );
                                        if (!updatedFlags.site) {
                                          updatedFlags.site = {
                                            flags: {},
                                          };
                                        }
                                        if (values.siteTimeoutHours) {
                                          updatedFlags.site!.flags[FEATURE_REQUEST_POLLING_TIMEOUT] = values.siteTimeoutHours * 60 * 60;
                                        } else {
                                          delete updatedFlags.site!.flags[FEATURE_REQUEST_POLLING_TIMEOUT];
                                        }

                                        this.setState({
                                          allFlags: updatedFlags,
                                          savingPollingRequestTimeout: undefined,
                                          editPollingRequestTimeout: undefined,
                                        });
                                      }}
                                    >
                                      {() => (
                                        <>
                                          <Row>
                                            <Col xs={12} lg={4}>
                                              <h4>This site</h4>
                                              <FormRow name={'siteTimeoutHours'} label={'Timeout'} labelPlacement={'above'}>
                                                <FormField
                                                  name={'siteTimeoutHours'}
                                                  label={'(use default)'}
                                                  type={'number'}
                                                  noRow
                                                  suffix="hours"
                                                />
                                              </FormRow>
                                            </Col>

                                            <Col xs={12} lg={4}>
                                              <h4>Default for all sites</h4>
                                              <FormRow name={'projectTimeoutHours'} label={'Default timeout'} labelPlacement={'above'}>
                                                <FormField
                                                  name={'projectTimeoutHours'}
                                                  label={`(use Sync Core default)`}
                                                  type={'number'}
                                                  noRow
                                                  suffix="hours"
                                                />
                                              </FormRow>
                                            </Col>
                                          </Row>

                                          <Right className="pe-3">
                                            <Button variant="primary" type="submit" disabled={savingPollingRequestTimeout}>
                                              Save
                                            </Button>
                                          </Right>
                                        </>
                                      )}
                                    </PollingRequestTimeoutForm>
                                  </Modal.Body>
                                </EmbeddedModal>
                              </>
                            )}
                            ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow for custom request TTLs.</Alert>}
                          />
                        </ContentCol>
                      </Row>
                    </>
                  )}
                </>
              )}
            </SiteFeatureFlagGate>
          </ContentCol>
        </Row>

        <Row>
          <HeaderCol xs={2}>CDN</HeaderCol>
          <ContentCol>
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_SELF_SERVICE_FEATURE_FLAGS}
              ifEnabled={() => (
                <>
                  <label className="pb-0 mb-0 fw-bold">
                    Prefer 2xx status code
                    <InfoIcon size="large">
                      This feature is applied across all sites in the same project.
                      <br />
                      When enabled, status codes of 5xx and 4xx will instead be returned as 2xx so that they are not filtered by the CDN.
                      This only affects responses that come directly from our module and are already sanitized so don't require being
                      filtered by your CDN.
                      <br />
                      If your CDN doesn't filter 5xx responses, don't enable this.
                    </InfoIcon>
                  </label>
                  <Row>
                    <ContentCol xs={12} sm={10} md={8} lg={6}>
                      <SyncCoreFeatureFlagGate
                        featureName={FEATURE_PREFER_2XX_STATUS_CODE_AVAILABLE}
                        ifEnabled={() => (
                          <>
                            <SwitchButton
                              disabled={savingPrefer2xxStatusCode || prefer2xxStatusCode === undefined}
                              selected={prefer2xxStatusCode ? '1' : '0'}
                              options={{ '1': 'Yes', '0': 'No' }}
                              onSelect={async (newValue) => {
                                this.setState({
                                  savingPrefer2xxStatusCode: true,
                                });
                                const enabled = newValue === '1';
                                const flags = await this.api.utility.features.update(
                                  FEATURE_PREFER_2XX_STATUS_CODE,
                                  'project',
                                  enabled ? 1 : undefined
                                );
                                this.setState({
                                  prefer2xxStatusCode: !!flags[FEATURE_PREFER_2XX_STATUS_CODE],
                                  savingPrefer2xxStatusCode: undefined,
                                });
                              }}
                            />
                          </>
                        )}
                        ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow 2xx status code preference.</Alert>}
                      />
                    </ContentCol>
                  </Row>
                </>
              )}
              ifDisabled={() => <Alert variant="light">Please update your Sync Core to allow managing feature flags.</Alert>}
            />
          </ContentCol>
        </Row>
      </div>
    );
  }
}
