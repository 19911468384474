import { ClientError, ClientErrorType } from '@edgebox/data-definition-kit';
import { LoadingBar, Throbber } from '@edgebox/react-components';
import { mapClassValidatorToFormikErrors } from '@edgebox/react-components/dist/components/Form/FormikErrorHandler';
import { FormikErrors } from 'formik';
import React, { CSSProperties } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { EmbeddedModal } from '../components/EmbeddedModal';

export type RequestLoadingAnimation = 'none' | 'bar' | 'throbber' | (() => React.ReactNode);

interface IProps {
  loadingAnimation?: RequestLoadingAnimation;
  error?: ClientError;
  errorStyle?: CSSProperties;
  errorClassName?: string;
  showUnauthorizedModal?: boolean;
  showLoadingAnimationImmediately?: boolean;
}

interface IState {
  hideUnauthorizedModal?: boolean;
  showLoadingAnimation?: boolean;
}

const SHOW_LOADING_ANIMATION_DELAY = 750;

export class Request extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showLoadingAnimation: props.showLoadingAnimationImmediately,
    };
  }

  __isMounted = false;

  componentDidMount() {
    this.__isMounted = true;

    if (this.state.showLoadingAnimation) {
      return;
    }

    setTimeout(() => (this.__isMounted ? this.setState({ showLoadingAnimation: true }) : undefined), SHOW_LOADING_ANIMATION_DELAY);
  }

  componentWillUnmount() {
    this.__isMounted = false;
  }

  render() {
    const { loadingAnimation, error, showUnauthorizedModal, errorClassName, errorStyle } = this.props;
    const { hideUnauthorizedModal, showLoadingAnimation } = this.state;

    let loadingAnimationElement: React.ReactNode = undefined;

    if (!loadingAnimation || loadingAnimation === 'bar') {
      loadingAnimationElement = (
        <div className="mt-2">
          <LoadingBar />
        </div>
      );
    } else if (loadingAnimation === 'throbber') {
      loadingAnimationElement = <Throbber />;
    } else if (loadingAnimation && loadingAnimation !== 'none') {
      loadingAnimationElement = loadingAnimation();
    }

    if (loadingAnimationElement) {
      return showLoadingAnimation ? loadingAnimationElement : null;
    }

    if (!error) {
      return null;
    }

    if (error.type === ClientErrorType.Validation) {
      if (error.details) {
        const details = error.details || error.message;

        if (Array.isArray(details)) {
          const errors = mapClassValidatorToFormikErrors(details) as FormikErrors<any>;
          const prettyName = (name: string) => {
            return (
              name
                // Add spacing
                .replace(/([a-z])([A-Z])/g, (match: string, start: string, letter: string) => `${start} ${letter.toLowerCase()}`)
                .replace(/^([a-z])/, (match: string, letter: string) => letter.toUpperCase())
            );
          };

          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>
                  One of the values you entered failed further validation. Please fix the issues listed below:
                  <ul>
                    {Object.keys(errors).map((field, i) => {
                      const errorMessages: string[] = errors[field as any] as string[];
                      return (
                        <li key={i}>
                          <strong>{prettyName(field)}</strong>
                          {errorMessages.length === 1 ? (
                            `: ${errorMessages[0]}`
                          ) : (
                            <ul>
                              {errorMessages.map((errorMessage, n) => {
                                return <li key={n}>{errorMessage}</li>;
                              })}
                            </ul>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                </Alert>
              </Col>
            </Form.Group>
          );
        } else if (typeof details === 'string') {
          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>{details}</Alert>
              </Col>
            </Form.Group>
          );
        } else {
          return (
            <Form.Group as={Row} controlId="formError">
              <Col>
                <Alert variant={'danger'}>
                  An unexpected error occurred. You can try again, try reloading the page or contact us if this issue persists.
                </Alert>
              </Col>
            </Form.Group>
          );
        }
      }
    }

    if (error.type === ClientErrorType.Unauthorized && showUnauthorizedModal) {
      return (
        <EmbeddedModal show={!hideUnauthorizedModal} onHide={() => this.setState({ hideUnauthorizedModal: true })}>
          <Modal.Header closeButton>
            <Modal.Title>Session expired</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <p>Please login again.</p>
            <p>This will reload the page. If you have any unsaved data, you can close this modal and copy it first.</p>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="light" onClick={() => this.setState({ hideUnauthorizedModal: true })}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                (window as any).parentIFrame.sendMessage({
                  type: 'reload',
                });
              }}
            >
              Login
            </Button>
          </Modal.Footer>
        </EmbeddedModal>
      );
    }

    const messages = {
      [ClientErrorType.Validation]: `Validation failed. Error message is: ${error.message}`,
      [ClientErrorType.NotFound]: "The requested item doesn't exist. If you think this is an error, please contact us.",
      [ClientErrorType.Unauthorized]: 'Your login expired. Please copy any unsaved work and refresh the page.',
      [ClientErrorType.Forbidden]: "You don't have the necessary permissions for this. Maybe you have to login with a different user.",
      [ClientErrorType.Timeout]: "Couldn't reach the Content Sync cloud. Please check your internet connection and try again.",
      [ClientErrorType.UnexpectedResponse]: "The Content Sync cloud didn't provide a valid response. Please try again later.",
      [ClientErrorType.Other]: `An unexpected error happened: ${error.message}.`,
    };

    if (error.type === ClientErrorType.Other) {
      console.log('Unexpected error', error);
    }

    const message = messages[error.type];

    return (
      <Alert style={errorStyle} className={errorClassName} variant={'danger'}>
        {message}
      </Alert>
    );
  }
}
