import { IconButton, LeftRightContainer, LeftRightH1 } from '@edgebox/react-components';
import { FlowSyndicationMode, MigrationType, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntityWithSummary, ClientMigrationSummary } from '@edgebox/sync-core-rest-client';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { IMigrationContextValue, IMigrationItem, MigrationContext } from '../../../contexts/MigrationContext';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { StepCircle } from '../../StepCircle';
import { ParamsComponent } from '../ParamsComponent';
import { MigrateConnect } from './Migrate/MigrateConnect';
import { MigrateParams } from './Migrate/MigrateParams';
import { MigratePull } from './Migrate/MigratePull';
import { MigratePush } from './Migrate/MigratePush';
import { MigrateSwitch } from './Migrate/MigrateSwitch';
import { MigrateTest } from './Migrate/MigrateTest';

enum MigrationStepGroup {
  Connect,
  Test,
  Push,
  Map,
  Switch,
}
const ALL_STEP_GROUPS = [
  MigrationStepGroup.Connect,
  MigrationStepGroup.Test,
  MigrationStepGroup.Push,
  MigrationStepGroup.Map,
  MigrationStepGroup.Switch,
];

const STEP_GROUP_NAMES = ['Connect', 'Test', 'Push', 'Map', 'Switch'];

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  finishedSteps?: MigrationStepGroup[];
  warningSteps?: MigrationStepGroup[];
  activeStepGroup?: MigrationStepGroup;
  maxStepGroup?: MigrationStepGroup;
  showWholeExplanation?: boolean;
  migrationContext?: IMigrationContextValue;
}

export class MigrateWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { params } = this.props;
    let maxStepGroup = MigrationStepGroup.Connect;

    const finishedSteps: MigrationStepGroup[] = [];
    const warningSteps: MigrationStepGroup[] = [];

    let allMigrations: ClientMigrationEntityWithSummary[] = [];
    if (this.currentSiteUuid) {
      let numberOfPages = 0;
      let page = 0;
      do {
        const response = await this.api.syndication.migrations.listWithSummary(
          {
            groupByEntityTypeAndFlowAndSite: true,
            initialSetup: true,
            siteUuid: this.currentSiteUuid,
          },
          { page, itemsPerPage: 25 }
        );

        allMigrations = allMigrations.concat(response.items);

        numberOfPages = response.numberOfPages;
        page++;
      } while (page < numberOfPages);
    }

    const flowsPushing = params.flows.filter((c) => !!c.types.find((c) => !!c.pushMode));
    const entityTypesPushing = flowsPushing
      .map((flow) =>
        flow.types.map((type) => ({
          flow,
          type,
        }))
      )
      .flat();
    const flowsPulling = params.flows.filter((c) => !!c.types.find((c) => !!c.pullMode));
    const entityTypesPulling = flowsPulling
      .map((flow) =>
        flow.types.map((type) => ({
          flow,
          type,
        }))
      )
      .flat();

    const isDone = (item: IMigrationItem, push: boolean): boolean => {
      if (push ? item.flow.skipPush : item.flow.skipPull) {
        return true;
      }
      if (!item.totalSummary.length) {
        return false;
      }
      const byStatus = item.totalSummary.filter((c) => !!c.count);
      return byStatus.length === 1 && byStatus[0].status === SyndicationStatus.Finished;
    };
    const getTotalSummary = (items: ClientMigrationEntityWithSummary[]): ClientMigrationSummary[] => {
      let result: ClientMigrationSummary[] = [];

      const lastFullIndex = items.findIndex(
        (c) => (c.type === MigrationType.MapExistingById || c.type === MigrationType.PushAll) && !c.changedAfter
      );
      if (lastFullIndex < 0) {
        return [];
      }

      result = [...items[0].summary];
      /*for (let i = lastFullIndex-1; i>0; i--) {
        const item = items[i];
        if (item.type === MigrationType.RetrieveFailed) {
          const fixed = item.summary.find(c => c.status === SyndicationStatus.Initializing);
          const fixed = item.summary.find(c => c.status === SyndicationStatus.Finished);
          const fixed = item.summary.find(c => c.status===SyndicationStatus.Finished);
        }
      }*/

      return result;
    };
    const getMigrations = async (push: boolean): Promise<IMigrationItem[]> => {
      return await Promise.all(
        (push ? entityTypesPushing : entityTypesPulling)
          .filter((c) => (push ? c.type.pushMode !== FlowSyndicationMode.Dependent : c.type.pullMode !== FlowSyndicationMode.Dependent))
          .map(async (typeAndFlow) => {
            const flow = typeAndFlow.flow;
            const type = typeAndFlow.type;
            const latest = allMigrations.find(
              (c) =>
                c.flowMachineName === flow.machineName &&
                c.entityTypeReference?.namespaceMachineName === type.namespaceMachineName &&
                c.entityTypeReference?.machineName === type.machineName
            );
            const getWithPrevious = async (migration: ClientMigrationEntityWithSummary): Promise<ClientMigrationEntityWithSummary[]> => {
              const previous = migration.previousMigration?.getId()
                ? await this.api.syndication.migrations.itemWithSummary(migration.previousMigration!.getId()!)
                : undefined;
              if (!previous) {
                return [migration];
              }
              return [migration, ...(await getWithPrevious(previous))];
            };
            const all = latest ? await getWithPrevious(latest) : [];
            const totalSummary = getTotalSummary(all);
            const result = {
              flow,
              type,
              latest,
              totalSummary,
              all,
              isDone: false,
            };
            result.isDone = isDone(result, push);
            return result;
          })
      );
    };
    const pushMigrations = await getMigrations(true);
    const pullMigrations = await getMigrations(false);

    const migrationContext: IMigrationContextValue = {
      pullDone: !pullMigrations.find((c) => !c.isDone),
      pushDone: !pushMigrations.find((c) => !c.isDone),
      pushMigrations,
      pullMigrations,
      update: (migration) => {
        const push = migration.type !== MigrationType.MapExistingById;
        const source = push ? pushMigrations : pullMigrations;
        const item = source.find(
          (c) =>
            c.flow.machineName === migration.flowMachineName &&
            c.type.namespaceMachineName === migration.entityTypeReference?.namespaceMachineName &&
            c.type.machineName === migration.entityTypeReference?.machineName
        );
        if (!item) {
          return;
        }
        const existing = item.all.findIndex((c) => c.id === migration.id);
        if (existing >= 0) {
          if (item.all[existing] === item.latest) {
            item.latest = migration;
          }
          item.all[existing] = migration;
        } else {
          item.latest = migration;
          item.all = [migration, ...item.all];
        }
        item.totalSummary = getTotalSummary(item.all);
        item.isDone = isDone(item, push);

        const finishedSteps = this.state.finishedSteps;
        if (push) {
          migrationContext.pushDone = !migrationContext.pushMigrations.find((c) => !c.isDone);
          if (migrationContext.pushDone && !this.state.finishedSteps?.includes(MigrationStepGroup.Push)) {
            finishedSteps?.push(MigrationStepGroup.Push);
            this.setState({
              finishedSteps,
              activeStepGroup: MigrationStepGroup.Map,
              maxStepGroup: MigrationStepGroup.Map,
            });
          }
        }
        // Must recalculate even if type is PUSH as there may not be any Flows
        // in which case we continue straight to the switch.
        migrationContext.pullDone = !migrationContext.pullMigrations.find((c) => !c.isDone);
        if (migrationContext.pushDone && migrationContext.pullDone && !this.state.finishedSteps?.includes(MigrationStepGroup.Map)) {
          finishedSteps?.push(MigrationStepGroup.Map);
          this.setState({
            finishedSteps,
            activeStepGroup: MigrationStepGroup.Switch,
            maxStepGroup: MigrationStepGroup.Switch,
          });
        }

        this.setState({ migrationContext });
      },
    };

    if (params.site.uuid && params.pools.find((c) => c.exported) && params.flows.find((c) => c.exported)) {
      const site = await this.getCurrentSite();
      if (this.context.baseUrl !== site?.baseUrl || params.pools.find((c) => !c.exported) || params.flows.find((c) => !c.exported)) {
        warningSteps.push(MigrationStepGroup.Connect);
      }
      finishedSteps.push(MigrationStepGroup.Connect);
      maxStepGroup = MigrationStepGroup.Test;
    }
    if (maxStepGroup === MigrationStepGroup.Test && params.flows.filter((flow) => flow.skipTest).length === params.flows.length) {
      finishedSteps.push(MigrationStepGroup.Test);
      maxStepGroup = MigrationStepGroup.Push;
    }
    if (maxStepGroup === MigrationStepGroup.Push && migrationContext.pushDone) {
      finishedSteps.push(MigrationStepGroup.Push);
      maxStepGroup = MigrationStepGroup.Map;
    }
    if (maxStepGroup === MigrationStepGroup.Map && migrationContext.pullDone) {
      finishedSteps.push(MigrationStepGroup.Map);
      maxStepGroup = MigrationStepGroup.Switch;
    }

    const activeStepGroup = maxStepGroup;

    return {
      finishedSteps,
      warningSteps,
      activeStepGroup,
      maxStepGroup,
      migrationContext,
      showWholeExplanation: activeStepGroup === MigrationStepGroup.Connect,
    };
  }

  render() {
    const { params } = this.props;
    const { activeStepGroup, showWholeExplanation, maxStepGroup, warningSteps, migrationContext } = this.state;

    if (activeStepGroup === undefined || maxStepGroup === undefined || !migrationContext) {
      return this.renderRequest();
    }

    return (
      <>
        <LeftRightH1
          left={
            <>
              Migrate Content Sync v1 <FontAwesomeIcon icon={faLongArrowRight} /> v2: {STEP_GROUP_NAMES[activeStepGroup]}
            </>
          }
          right={
            <>
              {ALL_STEP_GROUPS.map((group) => (
                <StepCircle
                  key={group}
                  index={group + 1}
                  active={activeStepGroup === group}
                  warning={!!warningSteps?.includes(group)}
                  enabled={maxStepGroup >= group}
                  onSelect={() => this.setState({ activeStepGroup: group })}
                />
              ))}
            </>
          }
        />

        <Alert variant="light" className="mt-4">
          <p>
            The migration process is divided into five steps.
            {showWholeExplanation ? (
              <strong>
                {' '}
                Content Sync can still be used normally to syndicate content with your previous Sync Core until the final step (Switch).
              </strong>
            ) : (
              <>
                ..{' '}
                <Button variant="link" onClick={() => this.setState({ showWholeExplanation: true })}>
                  Read more
                </Button>
              </>
            )}
          </p>

          {showWholeExplanation && (
            <p>
              <ol>
                <li>
                  <strong>Connect</strong>: Register the site with our backend and then export your existing configuration to the new Sync
                  Core.
                </li>
                <li>
                  <strong>Test</strong>: Run the push and pull operation on individual entities to verify that everything works as expected.
                </li>
                <li>
                  <strong>Push</strong>: Let the new Sync Core pull all existing content from your <strong>publishing</strong> sites.
                </li>
                <li>
                  <strong>Map</strong>: Let the new Sync Core pull all existing content from your <strong>subscribing</strong> sites and map
                  it to what it pulled from your <em>publishing</em> sites.
                </li>
                <li>
                  <strong>Switch</strong>: Switch to the new Sync Core so that all new updates run through it.
                </li>
              </ol>
            </p>
          )}
        </Alert>

        <MigrationContext.Provider value={migrationContext}>
          {activeStepGroup === MigrationStepGroup.Connect && <MigrateConnect params={params} />}
          {activeStepGroup === MigrationStepGroup.Test && <MigrateTest params={params} />}
          {activeStepGroup === MigrationStepGroup.Push && <MigratePush params={params} />}
          {activeStepGroup === MigrationStepGroup.Map && <MigratePull params={params} />}
          {activeStepGroup === MigrationStepGroup.Switch && <MigrateSwitch params={params} />}
        </MigrationContext.Provider>

        <LeftRightContainer
          className="mt-4"
          left={
            <IconButton
              onClick={() => this.setState({ activeStepGroup: activeStepGroup - 1 })}
              disabled={activeStepGroup === 0}
              icon={faChevronLeft}
            >
              Back
            </IconButton>
          }
          right={
            <IconButton
              iconPosition="right"
              onClick={() => this.setState({ activeStepGroup: activeStepGroup + 1 })}
              disabled={activeStepGroup === MigrationStepGroup.Switch || activeStepGroup === maxStepGroup}
              icon={faChevronRight}
            >
              Next
            </IconButton>
          }
        />
      </>
    );
  }
}

export const Migrate = (props: Omit<IProps, 'params'>) => (
  <ParamsComponent<MigrateParams> Params={MigrateParams}>
    {(params: MigrateParams) => {
      return <MigrateWithParams params={params} {...props} />;
    }}
  </ParamsComponent>
);
