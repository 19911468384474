import React from 'react';

export function Breadcrumb({ items }: { items: { name: React.ReactNode; onClick?: () => void }[] }) {
  return (
    <div>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <span
            className={`${!item.onClick ? 'text-dark' : 'text-primary cursor-pointer text-decoration-underline'}`}
            onClick={item.onClick}
          >
            {item.name}
          </span>
          {index === items.length - 1 ? null : <span className="text-light mx-2">/</span>}
        </React.Fragment>
      ))}
    </div>
  );
}
