import { InternalId } from '@edgebox/data-definition-kit';
import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  entityId?: InternalId | 'current';
  children: (entity?: ClientSiteEntity) => React.ReactNode;
}

interface IState extends ISyncCoreApiComponentState {
  entity?: ClientSiteEntity;
  loaded?: boolean;
}

export class WithSite extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    try {
      if (this.props.entityId) {
        const entity =
          this.props.entityId === 'current' ? await this.getCurrentSite() : await this.api.billing.sites.item(this.props.entityId);
        return {
          loaded: true,
          entity,
        };
      }
    } catch (e) {}
    return {
      loaded: true,
    };
  }

  render() {
    const { children } = this.props;
    const { loaded, entity } = this.state;

    if (!loaded) {
      return this.renderRequest();
    }

    return children(entity);
  }
}
