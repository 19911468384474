import { MigrationType, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntityWithSummary, IMigrationListFilters } from '@edgebox/sync-core-rest-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { doneStatus, SyndicationStatusIcon } from './Customer/SyndicationStatusIcon';
import { EntityTypeVersionName } from './EntityTypeVersionName';
import { FlowName } from './FlowName';
import { PagedList } from './PagedList';
import { getDateName } from './FormatDate';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { SiteName } from './SiteName';
import { ICON_PULL_MANY, ICON_PUSH_MANY } from '../Helpers';

interface IProps extends IMigrationListFilters {
  backend?: boolean;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  includeViewLink?: boolean;
  groupBySite?: boolean;
  selected?: ClientMigrationEntityWithSummary;
  onSelectItem?: (item: ClientMigrationEntityWithSummary) => void;
  style?: React.CSSProperties;
  className?: string;
  excludePushManually?: boolean;
  groupByDate?: boolean;
  showSiteName?: boolean;
}

interface IState extends ISyncCoreApiComponentState, IMigrationListFilters {}

const REFRESH_INTERVAL_RUNNING = 5_000;

interface IItemProps {
  entity: ClientMigrationEntityWithSummary;
  className?: string;
  selected?: ClientMigrationEntityWithSummary;
  onSelectItem?: (item: ClientMigrationEntityWithSummary) => void;
  timeOnly?: boolean;
  showSiteName?: boolean;
}
interface IItemState extends ISyncCoreApiComponentState {
  entity?: ClientMigrationEntityWithSummary;
}
export class MigrationListItem extends SyncCoreApiComponent<IItemProps, IItemState> {
  async load() {
    let entity = this.state.entity || this.props.entity;

    // Called a second time, so we want to refresh now.
    if (this.state.entity) {
      entity = await this.api.syndication.migrations.itemWithSummary(entity.id, true);
      // Update parent component, too.
      if (this.props.onSelectItem && this.props.selected?.id === entity.id) {
        this.props.onSelectItem(entity);
      }
    }

    if (entity.summary.find((c) => !doneStatus.includes(c.status))) {
      setTimeout(() => this.__isMounted && this.load(), REFRESH_INTERVAL_RUNNING);
    }

    return {
      entity,
    };
  }

  render() {
    const entity = this.state.entity || this.props.entity;
    const { className, onSelectItem, timeOnly, showSiteName } = this.props;

    const isPush = [
      MigrationType.PushAll,
      MigrationType.PushAllLatest,
      MigrationType.PushFailed,
      MigrationType.PushManually,
      MigrationType.RetrieveFailed,
    ].includes(entity.type);

    return (
      <>
        <Row
          className={`pt-2 pb-2 ${className || ''} ${
            onSelectItem ? 'cursor-pointer bg-white-selectable rounded hover-shadow mt-2 mb-2' : undefined
          }`}
          onClick={onSelectItem ? () => onSelectItem(entity) : undefined}
        >
          <Col xs={2} className="text-start">
            <OverlayTrigger
              placement="right"
              overlay={(props) =>
                entity.flow ? (
                  <Tooltip id={`tooltip-type-${entity.id}`} {...props} className="tooltip-wide">
                    <span>
                      Flow: <FlowName id={entity.flow.getId()} inline />
                    </span>
                  </Tooltip>
                ) : null
              }
            >
              <span className="me-2 align-middle d-inline-block">
                <FontAwesomeIcon icon={isPush ? ICON_PUSH_MANY : ICON_PULL_MANY} className="align-baseline" />
              </span>
            </OverlayTrigger>
            {entity.summary.length ? (
              entity.summary.map((summary, index) => (
                <div key={index} className="d-inline-block me-1">
                  <span>
                    <SyndicationStatusIcon status={summary.status as any} size="sm" />
                  </span>
                  <span>{summary.count}x</span>
                </div>
              ))
            ) : (
              <SyndicationStatusIcon status={entity.status} />
            )}
          </Col>
          <Col xs={timeOnly ? 1 : 2} className="border-start-2 border-light-lighter mb-1 ps-2">
            <span className="d-inline-block align-middle">{timeOnly ? entity.createdAt.format('LT') : entity.createdAt.format('LLL')}</span>
          </Col>
          <Col xs={timeOnly ? 8 : 7} className="border-start-2 border-light-lighter mb-1 ps-2">
            <strong className="d-inline-block align-middle me-1">
              {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', entity.type)}
            </strong>{' '}
            {entity.entityTypeVersion ? (
              <EntityTypeVersionName entityId={entity.entityTypeVersion.getId()!} withIcon />
            ) : (
              <em>Per entity</em>
            )}
            {showSiteName ? (
              <span className="d-inline-block align-middle text-muted">
                {' '}
                {isPush ? 'to' : 'from'} <SiteName id={entity.site.getId()} inline />
              </span>
            ) : null}
          </Col>
          {onSelectItem && (
            <Col xs={1} className="text-end pe-2 text-dark">
              <FontAwesomeIcon icon={faChevronRight} />
            </Col>
          )}
        </Row>
      </>
    );
  }
}

export class PagedMigrationList extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { emptyMessage, emptyMessageWithNoFilters, onSelectItem, selected, style, className, groupByDate, showSiteName } = this.props;

    return (
      <PagedList<ClientMigrationEntityWithSummary, IMigrationListFilters>
        key={JSON.stringify(this.props)}
        style={style}
        className={className}
        groupBy={groupByDate ? (item) => getDateName(item.createdAt) : undefined}
        noPagerIfNotNeeded
        request={(page, filter) => {
          return this.api.syndication.migrations.listWithSummary(
            {
              ...filter,
              ...this.props,
              ...(this.props.excludePushManually
                ? {
                    types: Object.values(MigrationType).filter((c) => c !== MigrationType.PushManually),
                  }
                : {}),
            },
            { page }
          );
        }}
        emptyMessageWithNoFilters={emptyMessageWithNoFilters}
        emptyMessage={emptyMessage || <Alert variant={'light'}>No operations match your filter.</Alert>}
        renderItem={(entity) => (
          <MigrationListItem
            entity={entity}
            key={entity.id}
            onSelectItem={onSelectItem}
            selected={selected}
            timeOnly
            showSiteName={showSiteName}
          />
        )}
        /*renderListHeader={() => (
          <Row>
            <HeaderCol xs={1}>Status</HeaderCol>
            <HeaderCol xs={1} className="text-center">
              Operations
            </HeaderCol>
            <HeaderCol xs={2}>Started</HeaderCol>
            <HeaderCol xs={2}>Type</HeaderCol>
            <HeaderCol xs={2}>Entity type</HeaderCol>
            <HeaderCol xs={4}>Details</HeaderCol>
          </Row>
        )}*/
      />
    );
  }
}
