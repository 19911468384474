import { PageLoading } from '@edgebox/react-components';
import { FeatureSummary, SyncCoreApi } from '@edgebox/sync-core-rest-client';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useLocation } from 'react-router';
import { siteRequests } from '../frame-messages';
import { getUserFromJwt } from '../services/SyncCoreApiComponent';

export interface IAppParams {
  siteOptions: {
    jwt: string;
    syncCoreDomain: string;
    baseUrl: string;
    featureFlags: FeatureSummary;
  };
  pageOptions: any;
}

export const AppParamsContext: any = React.createContext<IAppParams | null>(null);

interface IState extends Partial<IAppParams> {
  exchangeTokenError?: any;
}

interface IProps extends PropsWithChildren {}

export function AppParams(props: IProps) {
  let [state, _setState] = useState<IState>({});
  const setState = useCallback(
    (diff: Partial<IState>) => {
      const changed = { ...state, ...diff };
      _setState(changed);
      // eslint-disable-next-line
      state = changed;
    },
    [state, _setState]
  );

  const location = useLocation();

  const onMessage = useCallback(
    async (message: any) => {
      if (message.type === 'options' || message.type === 'update-options') {
        setState({
          pageOptions: Array.isArray(message.options) ? {} : message.options,
        });
      } else if (message.type === 'config') {
        const siteOptions = Array.isArray(message.config) ? {} : message.config;
        if (!siteOptions.jwt && siteOptions.backendJwt) {
          const api = new SyncCoreApi({
            jwt: siteOptions.backendJwt,
            services: {
              syncCore: {
                baseDomain: siteOptions.syncCoreDomain,
              },
            },
          });
          try {
            const { jwt } = await api.utility.backend.exchangeToken(siteOptions.siteUuid);
            siteOptions.jwt = jwt;
          } catch (e) {
            console.error('failed to exchange token', e);
            setState({
              exchangeTokenError: e,
            });
            return;
          }
        }
        (window as any).LATEST_USER = getUserFromJwt(siteOptions.jwt) ?? getUserFromJwt(siteOptions.backendJwt);
        setState({
          siteOptions,
        });
      } else if (message.type === 'response') {
        if (siteRequests[message.callbackId]) {
          siteRequests[message.callbackId](message.response);
          delete siteRequests[message.callbackId];
        } else {
          console.error(`Entity list response for unknown callback id ${message.callbackId}.`, message);
        }
      }
    },
    [setState]
  );

  useEffect(() => {
    const broker = (window as any).IFRAME_MESSAGE_BROKER;

    broker.onMessage = onMessage;
  }, [onMessage]);

  useEffect(() => {
    const broker = (window as any).IFRAME_MESSAGE_BROKER;

    if (broker.messages.length) {
      broker.messages.forEach(broker.onMessage);
    }

    // Apply classes to the <body> that allow styling based on the iframe
    // size for a box, a line a page.
    const isLine = location.pathname.substring(0, 18) === '/box/update-status';
    const isBox = location.pathname.substring(0, 5) === '/box/';
    if (isLine) {
      document.body.classList.add('size-line');
    } else if (isBox) {
      document.body.classList.add('size-box');
    } else {
      document.body.classList.add('size-page');
    }

    for (const path of location.pathname.substring(1).split('/')) {
      document.body.classList.add('page-' + path.replace(/[^a-z0-9-]/g, '-'));
    }
  }, [location.pathname]);

  const small = location.pathname.substring(0, 5) === '/box/';
  const loading = () =>
    small ? (
      <div className="text-center">
        <FontAwesomeIcon
          icon={faSpinner}
          spin={true}
          //size={"4x"}
          className={'text-secondary'}
          style={{ margin: '1rem 0 0 0' }}
        />
      </div>
    ) : (
      <PageLoading>Loading...</PageLoading>
    );
  return (
    <AppParamsContext.Provider value={state}>
      {state?.siteOptions && state?.pageOptions ? (
        props.children
      ) : state?.exchangeTokenError ? (
        small ? (
          <FontAwesomeIcon icon={faExclamationTriangle} className="text-warning" title={'Failed to exchange auth token with Sync Core.'} />
        ) : (
          <Alert variant="warning">Failed to exchange auth token with Sync Core.</Alert>
        )
      ) : (
        loading()
      )}
    </AppParamsContext.Provider>
  );
}
