import { ClientError, ClientErrorType, Uuid } from '@edgebox/data-definition-kit';
import { IAppConfiguration } from '@edgebox/react-components';
import { ClientCustomerEntity, ClientSiteEntity, ISyncCoreApi, SyncCoreApi } from '@edgebox/sync-core-rest-client';
import { ValidationError } from 'class-validator';
import jwt_decode from 'jwt-decode';
import React from 'react';
import { Optional } from 'utility-types';
import { ISyncCoreApiContextValue, SyncCoreApiContext } from '../contexts/SyncCoreApiContext';
import { Request, RequestLoadingAnimation } from './Request';

export type IDataComponentConfiguration = IAppConfiguration<{
  syncCoreDomain: string;
  jwt: string;
  baseUrl: string;
}>;

export enum RequestReason {
  LoadComponent = 'load',
  SubmitForm = 'submit',
  ExecuteAction = 'action',
  RequestDetails = 'details',
  Save = 'save',
}

type ErrorCollection = {
  [type in RequestReason]?: ClientError;
};

type RequestCollection = {
  [type in RequestReason]?: Promise<any>;
};

export interface ISyncCoreApiComponentState {
  apiErrors: ErrorCollection;
  openRequests: RequestCollection;
  hideUnauthorizedModal?: boolean;
}

export function getUserFromJwt(jwt: string | undefined): { email?: string; name?: string } | undefined {
  if (!jwt) {
    return undefined;
  }
  const payload = jwt_decode(jwt) as any | undefined;
  return payload?.user;
}

export function getCurrentSiteUuid(jwt: string | undefined): Uuid | undefined {
  if (!jwt) {
    return undefined;
  }
  const payload = jwt_decode(jwt) as any | undefined;
  return payload?.uuid;
}

export abstract class SyncCoreApiComponent<
  Props = {},
  State extends ISyncCoreApiComponentState = ISyncCoreApiComponentState
> extends React.Component<Props, State> {
  constructor(props: Props, state?: Optional<State, 'apiErrors' | 'openRequests'>) {
    super(props);

    const defaults = {
      apiErrors: {},
      openRequests: {},
    };

    // React provides us the context sometimes.
    this.state = !state || (state as any) instanceof SyncCoreApi ? (defaults as State) : (Object.assign(state, defaults) as State);

    this.load = this.wrapApiCallFunction(this.load.bind(this), RequestReason.LoadComponent, true) as any;
  }

  context!: ISyncCoreApiContextValue;
  static contextType = SyncCoreApiContext;
  get api(): ISyncCoreApi {
    return this.context.api;
  }

  protected __isMounted = false;

  get currentSiteUuid(): Uuid | undefined {
    return getCurrentSiteUuid(this.context.jwt);
  }

  async getCurrentSite(expectToExist: true): Promise<ClientSiteEntity>;
  async getCurrentSite(expectToExist?: false): Promise<ClientSiteEntity | undefined>;
  async getCurrentSite(expectToExist?: boolean): Promise<ClientSiteEntity | undefined> {
    try {
      const uuid = this.currentSiteUuid;
      if (!uuid) {
        return;
      }

      return await this.api.billing.sites.itemByUuid(uuid);
    } catch (e) {
      if (!(e instanceof ClientError) || e.type !== ClientErrorType.NotFound) {
        throw e;
      }
    }
  }

  get formValidationError(): ValidationError[] | undefined {
    if (
      this.state.apiErrors[RequestReason.SubmitForm]?.type === ClientErrorType.Validation &&
      Array.isArray(this.state.apiErrors[RequestReason.SubmitForm]!.details)
    ) {
      return this.state.apiErrors[RequestReason.SubmitForm]!.details;
    }

    return undefined;
  }

  componentDidMount(): void {
    this.__isMounted = true;
    this.load();
  }

  componentWillUnmount(): void {
    this.__isMounted = false;
  }

  wrapApiCallFunction<ResultType>(
    fn: (...args: any) => Promise<ResultType>,
    requestReason: RequestReason = RequestReason.RequestDetails,
    updateState?: boolean
  ): () => Promise<ResultType | undefined> {
    if (updateState) {
      return async (...args: any) => {
        const state = await this.wrapApiCall(fn(...args), requestReason);
        if (this.__isMounted && state) {
          this.setState(state as any);
        }

        return state;
      };
    }

    return async (...args: any) => {
      return await this.wrapApiCall(fn(...args), requestReason);
    };
  }

  async wrapApiCall<ResultType>(
    call: Promise<ResultType>,
    requestReason: RequestReason = RequestReason.RequestDetails
  ): Promise<ResultType | undefined> {
    const { apiErrors, openRequests } = this.state;

    apiErrors[requestReason] = undefined;

    openRequests[requestReason] = call;

    this.setState({
      apiErrors,
      openRequests,
    });

    try {
      const result = await call;

      const { openRequests: updatedOpenRequests } = this.state;
      updatedOpenRequests[requestReason] = undefined;
      if (this.__isMounted) {
        this.setState({
          openRequests: updatedOpenRequests,
        });
      }

      return result;
    } catch (e) {
      if (!(e instanceof ClientError)) {
        console.error('Unexpected request error in component', this, e);
      }
      const error = e instanceof ClientError ? e : new ClientError(ClientErrorType.Other, undefined as any, (e as Error).message);

      const { apiErrors: updatedApiErrors, openRequests: updatedOpenRequests } = this.state;
      updatedApiErrors[requestReason] = error;
      updatedOpenRequests[requestReason] = undefined;

      if (this.__isMounted) {
        this.setState({
          apiErrors: updatedApiErrors,
          openRequests: updatedOpenRequests,
        });
      }
    }
  }

  renderRequest(
    requestReason = RequestReason.LoadComponent,
    loadingAnimation?: RequestLoadingAnimation,
    showLoadingAnimationImmediately?: boolean
  ): React.ReactNode {
    const hideUnauthorizedModal = this.state.hideUnauthorizedModal;
    const error = this.state.apiErrors[requestReason];
    const request = this.state.openRequests[requestReason];

    return (
      <Request
        loadingAnimation={request ? loadingAnimation : 'none'}
        error={error}
        showUnauthorizedModal={!hideUnauthorizedModal}
        showLoadingAnimationImmediately={showLoadingAnimationImmediately}
      />
    );
  }

  abstract load(): Promise<Partial<State>>;
  abstract render(): React.ReactNode;
}
