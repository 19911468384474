import { SyndicationType } from '@edgebox/sync-core-data-definitions';
import { ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { EntityTypeVersionName } from './EntityTypeVersionName';
import { SiteName } from './SiteName';

interface IProps {
  entity: ClientSyndicationEntity;
  showEntityDetails?: boolean;
  showSiteName?: boolean;
  showType?: boolean;
  showDirection?: boolean;
}

const TYPE_NAMES: { [key in SyndicationType]: string } = {
  [SyndicationType.DeleteEmbeddedEntity]: 'Delete embedded',
  [SyndicationType.DeleteEntity]: 'Delete',
  [SyndicationType.PushEntity]: 'Update',
  [SyndicationType.RetrieveAndDeleteEmbeddedEntity]: 'Deleted embedded',
  [SyndicationType.RetrieveAndDeleteEntity]: 'Deleted',
  [SyndicationType.RetrieveAndPushEntity]: 'Updated',
  [SyndicationType.RetrieveEntity]: 'Retrieve',
  [SyndicationType.RetrieveEntityList]: 'List',
  [SyndicationType.RetrieveConfig]: 'Retrieve config',
};
export function getSyndicationTypeName(type: SyndicationType, name?: string) {
  if (name) {
    return `${TYPE_NAMES[type]} ${name}`;
  }
  return TYPE_NAMES[type];
}

export class SyndicationName extends React.Component<IProps, {}> {
  render() {
    const { entity, showEntityDetails, showSiteName, showType, showDirection } = this.props;

    if (entity.type === SyndicationType.RetrieveAndPushEntity) {
      return (
        <>
          <strong>
            {showDirection ? 'Push' : null}
            {showType ? (showDirection ? ' update' : 'Updated') : null}
          </strong>
          {showSiteName ? (
            <>
              {' '}
              {showDirection ? 'from' : 'at'} <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.RetrieveAndDeleteEntity || entity.type === SyndicationType.RetrieveAndDeleteEmbeddedEntity) {
      return (
        <>
          <strong>
            {showDirection ? 'Push' : null}
            {showType ? (showDirection ? ' deletion' : 'Deleted') : null}
          </strong>{' '}
          {showEntityDetails ? (
            <>
              {' '}
              of {entity.rootEntityTypeVersion && <EntityTypeVersionName entityId={entity.rootEntityTypeVersion.getId()!} />}{' '}
              <em>{entity.rootEntityReference?.name}</em>
            </>
          ) : null}
          {showSiteName ? (
            <>
              {' '}
              at <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.PushEntity) {
      return (
        <>
          <strong>
            {showDirection ? 'Pull' : null}
            {showType ? (showDirection ? ' update' : 'Update') : null}
          </strong>
          {showSiteName ? (
            <>
              {showDirection ? ' into' : ''} <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.DeleteEntity || entity.type === SyndicationType.DeleteEmbeddedEntity) {
      return (
        <>
          <strong>
            {showDirection ? 'Pull' : null}
            {showType ? (showDirection ? ' deletion' : 'Delete') : null}
          </strong>{' '}
          {showEntityDetails ? (
            <>
              {' '}
              of {entity.rootEntityTypeVersion && <EntityTypeVersionName entityId={entity.rootEntityTypeVersion.getId()!} />}{' '}
              <em>{entity.rootEntityReference?.name}</em>
            </>
          ) : null}
          {showSiteName ? (
            <>
              {' '}
              {showDirection ? 'into' : 'at'} <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.RetrieveEntity) {
      return (
        <>
          <strong>Request</strong>
          {showEntityDetails ? ' entity details' : null}
          {showSiteName ? (
            <>
              {' '}
              from <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.RetrieveEntityList) {
      return (
        <>
          <strong>List</strong> entities
          {showSiteName ? (
            <>
              {' '}
              at <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    if (entity.type === SyndicationType.RetrieveConfig) {
      return (
        <>
          <strong>Sync</strong> config
          {showSiteName ? (
            <>
              {' '}
              at <SiteName id={entity.targetSite.getId()} inline />
            </>
          ) : null}
        </>
      );
    }

    return null;
  }
}
