import { StaticReference } from '@edgebox/data-definition-kit';
import { Right } from '@edgebox/react-components';
import { FlowSyndicationMode, MigrationType, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntity, ClientMigrationSummary, ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { Alert, Badge, Button, Modal } from 'react-bootstrap';
import { IMigrationContextValue, IMigrationItem, MigrationContext } from '../../../../contexts/MigrationContext';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { CollapsibleContainer } from '../../../CollapsibleContainer';
import { EmbeddedModal } from '../../../EmbeddedModal';
import { PagedSyndicationList } from '../../../PagedSyndicationList';
import { doneStatus, issueStatus, SyndicationStatusIcon } from '../../SyndicationStatusIcon';
import { FlowMigrationStatus, MigrateParams } from './MigrateParams';

const STATUS_UPDATE_INTERVAL = 20_000;

const hasMigrationErrors = (summary?: ClientMigrationSummary[]) => {
  return !!summary?.find((c) => c.count && issueStatus.includes(c.status as any));
};
export const isMigrationFinished = (summary?: ClientMigrationSummary[]) => {
  if (!summary?.length) {
    return false;
  }
  const byStatus = summary.filter((c) => !!c.count);
  return byStatus.length === 1 && (byStatus[0].status as any) === SyndicationStatus.Finished;
};
const sumAll = (numbers: number[]): number => {
  return numbers.length === 0 ? 0 : numbers.length === 1 ? numbers[0] : numbers.reduce((a, b) => a + b);
};
const getMigrationProgress = (summary?: ClientMigrationSummary[]) => {
  if (!summary?.length) {
    return 0;
  }
  const allCount = sumAll(summary.map((c) => c.count));
  const doneCount = sumAll(summary.filter((c) => doneStatus.includes(c.status as any)).map((c) => c.count));
  if (allCount === 0) {
    return 0;
  }
  return Math.floor((doneCount / allCount) * 100);
};
const getMigrationProgressSum = (summaries: (undefined | ClientMigrationSummary[])[]) => {
  if (summaries.length === 0) {
    return 100;
  }
  const missing = summaries.filter((c) => !c);
  // If some are still missing, we divide the sum of the progress by the total
  // number of potential summaries so the user doesn't see "100%" and think
  // everything's done.
  if (missing.length > 0) {
    const progresses = sumAll(summaries.filter((c) => !!c).map((c) => getMigrationProgress(c) / 100));
    return Math.floor((progresses / summaries.length) * 100);
  }
  // Otherwise we can provide more reliable numbers that take into account that
  // different entity types will hold a different number of entities, thus
  // taking more or less time.
  const allCount = sumAll(
    summaries
      .filter((c) => !!c)
      .flat()
      .map((c) => c!.count)
  );
  const doneCount = sumAll(
    summaries
      .filter((c) => !!c)
      .flat()
      .filter((c) => doneStatus.includes(c!.status as any))
      .map((c) => c!.count)
  );
  if (allCount === 0) {
    return 0;
  }
  return Math.floor((doneCount / allCount) * 100);
};

interface IFlowProps {
  migrationContext: IMigrationContextValue;
  params: MigrateParams;
  flow: FlowMigrationStatus;
  push: boolean;
}
interface IFlowState extends ISyncCoreApiComponentState {
  wantsToSkip?: boolean;
  submitting?: boolean;
  typeStatus?: IMigrationItem[];
  site?: ClientSiteEntity;
  showSyndicationList?: boolean;
  refresh?: number;
}
export class MigrateFlowComponent extends SyncCoreApiComponent<IFlowProps, IFlowState> {
  async load() {
    const { flow, push, migrationContext } = this.props;
    if (!this.state.site) {
      const site = await this.getCurrentSite();
      this.setState({ site });
    }
    let typeStatus = this.state.typeStatus;
    if (typeStatus) {
      typeStatus = await Promise.all(
        typeStatus.map(async (status) => {
          if (status.latest && !doneStatus.includes(status.latest.status)) {
            const updated = await this.api.syndication.migrations.itemWithSummary(status.latest.id, true);
            migrationContext.update(updated);
          }
          return status;
        })
      );
    } else if (!this.state.submitting) {
      if (push) {
        typeStatus = migrationContext.pushMigrations.filter((c) => c.flow.machineName === flow.machineName);
      } else {
        typeStatus = migrationContext.pullMigrations.filter((c) => c.flow.machineName === flow.machineName);
      }
    }

    setTimeout(() => {
      if (!this.__isMounted) {
        return;
      }

      this.load();
    }, STATUS_UPDATE_INTERVAL);

    return {
      typeStatus,
    };
  }

  render() {
    const { flow, push, migrationContext } = this.props;
    const { submitting, wantsToSkip, typeStatus, site, showSyndicationList } = this.state;

    if (!typeStatus || !site) {
      return this.renderRequest();
    }

    const startFor = async (status: IMigrationItem, previous?: ClientMigrationEntity, type?: MigrationType) => {
      const index = typeStatus.indexOf(status);
      if (!type) {
        type = push ? MigrationType.PushAll : MigrationType.MapExistingById;
      }
      const migration = await this.api.syndication.migrations.create({
        type,
        entityTypeReference: {
          machineName: status.type.machineName,
          namespaceMachineName: status.type.namespaceMachineName,
          versionId: status.type.versionId,
        },
        flowMachineName: flow.machineName,
        initialSetup: true,
        skipSyndication: true,
        previousMigration: previous ? new StaticReference(previous) : undefined,
        changedAfter: previous ? previous.createdAt.clone() : undefined,
      });
      const migrationWithSummary = await this.api.syndication.migrations.withSummary(migration);
      migrationContext.update(migrationWithSummary);
      const updatedTypeStatus = this.state.typeStatus!;
      updatedTypeStatus[index].latest = migrationWithSummary;
      this.setState({
        typeStatus: updatedTypeStatus,
      });
    };
    const startForMultiple = async (
      typeStatus: IMigrationItem[],
      previous?: ClientMigrationEntity,
      type?: MigrationType,
      force?: boolean
    ) => {
      try {
        this.setState({
          submitting: true,
        });
        await Promise.all(
          typeStatus.map(async (status) => {
            if (!force && status.latest && !previous) {
              // Ignore those that are still running or finished successfully.
              if (![SyndicationStatus.Aborted, SyndicationStatus.Failed, SyndicationStatus.LimitExceeded].includes(status.latest.status)) {
                return;
              }
            }
            await startFor(status, previous, type);
          })
        );
      } finally {
        this.setState({
          submitting: false,
        });
      }
    };

    const skipped = push ? flow.skipPush : flow.skipPull;
    const done = typeStatus.find((c) => c.totalSummary && isMigrationFinished(c.totalSummary));
    const hasTypesWithNoMigration = !!typeStatus.find((c) => !c.latest);

    return (
      <CollapsibleContainer
        name={
          <>
            {push ? 'Push' : 'Map'} <em>{flow.name}</em>
          </>
        }
        status={
          done ? (
            'check'
          ) : skipped ? (
            <Badge bg="warning">Skipped</Badge>
          ) : (
            <>
              <Badge bg="light" className="text-dark">
                {getMigrationProgressSum(typeStatus.map((c) => c.totalSummary))}%
              </Badge>
            </>
          )
        }
        defaultExpanded={true}
      >
        {typeStatus.map((type, index) => {
          const mode = push ? type.type.pushMode : type.type.pullMode;
          if (!mode || mode === FlowSyndicationMode.Dependent) {
            return null;
          }

          const hasErrors = hasMigrationErrors(type.totalSummary);

          return (
            <CollapsibleContainer
              name={
                <>
                  {type.type.namespaceMachineName}.{type.type.machineName}
                </>
              }
              level={1}
              status={
                type.latest ? (
                  <>
                    <SyndicationStatusIcon status={type.latest.status} size="sm" />
                    <Badge bg="light">{getMigrationProgress(type.totalSummary)}%</Badge>
                  </>
                ) : undefined
              }
              defaultExpanded={!type.latest || !doneStatus.includes(type.latest.status)}
            >
              {type.totalSummary.length ? (
                <>
                  <h4>Summary</h4>
                  {type.totalSummary.map((summary, index) => (
                    <div key={index}>
                      <span>
                        <SyndicationStatusIcon status={summary.status as any} size="sm" />
                      </span>
                      <span>{summary.count}x</span>
                    </div>
                  ))}
                  {hasErrors && !showSyndicationList && (
                    <Button variant="light" onClick={() => this.setState({ showSyndicationList: true })}>
                      Show issues
                    </Button>
                  )}
                </>
              ) : (
                <Alert variant="light">Not running yet.</Alert>
              )}
              {type.latest && showSyndicationList && (
                <>
                  <h4 className="mt-4">Issues</h4>
                  <PagedSyndicationList
                    migrationId={type.latest.id}
                    key={JSON.stringify(type.latest)}
                    statuses={[SyndicationStatus.Retrying, SyndicationStatus.Failed, SyndicationStatus.LimitExceeded]}
                  />
                </>
              )}
              <Right className="me-0">
                {type.latest?.status === SyndicationStatus.Failed && (
                  <Button
                    disabled={submitting}
                    variant="light"
                    className="fw-bold"
                    onClick={async () => {
                      await startForMultiple([type], type.latest, MigrationType.RetrieveFailed);
                    }}
                  >
                    Retry failed
                  </Button>
                )}
                {type.latest &&
                  [SyndicationStatus.Finished, SyndicationStatus.Failed, SyndicationStatus.Aborted].includes(type.latest.status) && (
                    <>
                      <Button
                        disabled={submitting}
                        variant="danger"
                        className="fw-bold"
                        onClick={async () => {
                          await startForMultiple([type], undefined, undefined, true);
                        }}
                      >
                        Re-run completely
                      </Button>
                      <Button
                        disabled={submitting}
                        variant="light"
                        className="fw-bold"
                        onClick={async () => {
                          await startForMultiple([type], type.latest, undefined, true);
                        }}
                      >
                        Update
                      </Button>
                    </>
                  )}
                {!type.latest && (
                  <Button
                    disabled={submitting}
                    variant="primary"
                    className="fw-bold"
                    onClick={async () => {
                      await startForMultiple([type]);
                    }}
                  >
                    Start
                  </Button>
                )}
              </Right>
            </CollapsibleContainer>
          );
        })}

        <Right className="me-0 mt-4 mb-2">
          {wantsToSkip && (
            <EmbeddedModal show={true} onHide={() => this.setState({ wantsToSkip: undefined })} size="xl" scrollable>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This will mark all unfinished entity types as <em>skipped</em> so you can continue to the next migration step.
                <br />
                <br />
                You can still come back later and continue.
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger"
                  className="fw-bold"
                  disabled={submitting}
                  onClick={() => {
                    this.setState({
                      submitting: true,
                    });

                    sendToParent({
                      type: push ? 'migration-skip-flows-push' : 'migration-skip-flows-pull',
                      machineNames: [flow.machineName],
                    });
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </EmbeddedModal>
          )}
          {!done && (
            <Button
              disabled={submitting}
              variant="danger"
              className="fw-bold"
              onClick={() => {
                this.setState({
                  wantsToSkip: true,
                });
              }}
            >
              Skip Flow
            </Button>
          )}
          {hasTypesWithNoMigration && (
            <Button
              disabled={submitting}
              variant="primary"
              className="fw-bold"
              onClick={async () => {
                await startForMultiple(typeStatus);
              }}
            >
              Start all
            </Button>
          )}
        </Right>
      </CollapsibleContainer>
    );
  }
}

export function MigrateFlow(props: Omit<IFlowProps, 'migrationContext'>) {
  return (
    <MigrationContext.Consumer>
      {(migrationContext) => (migrationContext ? <MigrateFlowComponent {...props} migrationContext={migrationContext} /> : null)}
    </MigrationContext.Consumer>
  );
}
