import {
  BooleanProperty,
  ExternalServiceId,
  ExternalServiceIdProperty,
  NestedProperty,
  Uuid,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import { IconButton, LeftRightContainer } from '@edgebox/react-components';
import { SyndicationStatus, SyndicationType } from '@edgebox/sync-core-data-definitions';
import { ClientSiteEntity, ClientSyndicationEntity, ClientSyndicationUsageSummary } from '@edgebox/sync-core-rest-client';
import { faDrupal } from '@fortawesome/free-brands-svg-icons/faDrupal';
import { faChartNetwork } from '@fortawesome/pro-duotone-svg-icons/faChartNetwork';
import { faArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltRight';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faSyncAlt } from '@fortawesome/pro-light-svg-icons/faSyncAlt';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Alert, Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import Select, { components } from 'react-select';
import { getStyleColors } from '../../../Helpers';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ContractWarnings } from '../../ContractWarnings';
import { PagedSyndicationList, SyndicationListItem, getLatestError } from '../../PagedSyndicationList';
import { SerializedEntityRevision } from '../../SerializedEntityRevision';
import { SiteName } from '../../SiteName';
import { SyndicationStatusBadge } from '../../SyndicationStatusBadge';
import { UsageSummary } from '../../UsageSummary';
import { ParamsComponent } from '../ParamsComponent';
import { dangerStatus, doneStatus, neutralStatus, SyndicationStatusIcon, warningStatus } from '../SyndicationStatusIcon';
import { SwitchButton } from '../../SwitchButton';
import { HeadlineBackButton } from '../HeadlineBackButton';
import { SyndicationDetails } from '../Syndication/SyndicationDetails';
import { OperationErrorDetails } from '../Syndication/OperationErrorDetails';
import { EnvironmentIconForSite } from '../../Icons';
import { Breadcrumb } from '../Breadcrumb';
import { getAppLinkForTask } from '../AppLink';
import { ExternalLinkWithIcon } from '../../ExternalLinkWithIcon';
import { DisplayEntityRevision } from '../../DisplayEntityRevision';
import { EntityTypeVersionName } from '../../EntityTypeVersionName';

interface IProps {
  params: Params;
  recent?: ClientSyndicationUsageSummary[];
  embedded?: boolean;
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
  recent?: ClientSyndicationUsageSummary[];
  selected?: number;
  selectedComparison?: number;
  isRunning?: boolean;
  isRunningOrRetrying?: boolean;
  isLoading?: boolean;
  updatedAt?: number;
  show?: 'details' | 'serialized';
  serializeMode?: 'table' | 'yaml-pretty' | 'yaml-raw';
  updateCount?: number;

  selectedSyndication?: ClientSyndicationEntity;
  errorDetails?: { operationIndex: number; errorIndex: number };

  fullscreenRef?: React.RefObject<any>;
  inFullscreen?: boolean;
}

export const CustomOption = (props: any) => {
  const option = props.options.find((c: any) => c.thisSite.id === props.value);

  return (
    <div>
      <OverlayTrigger
        placement="left"
        delay={{ show: 300, hide: 0 }}
        overlay={(props) => (
          <Tooltip id={`tooltip-option-${option.thisSite.id}`} {...props}>
            {option.startedAt.format('LLL')}
          </Tooltip>
        )}
      >
        <div>
          <components.Option {...props} />
        </div>
      </OverlayTrigger>
    </div>
  );
};

class EntityReference {
  // Entity type
  @ExternalServiceIdProperty(false)
  namespaceMachineName?: ExternalServiceId;
  @ExternalServiceIdProperty(false)
  machineName?: ExternalServiceId;

  // Unique entity ID
  @UuidProperty(false)
  remoteUuid?: Uuid;
  @ExternalServiceIdProperty(false)
  remoteUniqueId?: ExternalServiceId;
}

class Params extends EntityReference {
  // Permissions
  @BooleanProperty(false)
  configurationAccess?: boolean;

  @NestedProperty(false, () => EntityReference)
  rootEntities?: EntityReference[];
}

const REFRESH_INTERVAL_RUNNING = [1_000, 2_000, 4_000, 7_000, 10_000];
const REFRESH_INTERVAL_STILL = 30_000;

interface IListProps {
  params: Params;
  recent?: ClientSyndicationUsageSummary[];
  embedded?: boolean;
}
interface IListState extends ISyncCoreApiComponentState {
  isLoading?: boolean;
  statuses?: { reference: EntityReference; recent: ClientSyndicationUsageSummary[] }[];
  selectedStatusIndex?: number;
}

export class EntityStatusWithParams extends SyncCoreApiComponent<IListProps, IListState> {
  async load() {
    const { params } = this.props;

    if (!params.rootEntities || this.isOwnAndSoleRootEntity) {
      return {
        isLoading: false,
      };
    }

    this.setState({
      isLoading: true,
    });

    const site = await this.getCurrentSite();
    if (!site) {
      return {
        isLoading: false,
      };
    }

    const statuses = await Promise.all(
      params.rootEntities.map(async (reference) => ({
        reference,
        recent: (
          await this.api.syndication.syndications.usageSummaryForSite(
            site.uuid,
            reference.namespaceMachineName && reference.machineName
              ? { namespaceMachineName: reference.namespaceMachineName, machineName: reference.machineName }
              : undefined,
            reference.remoteUniqueId ? { remoteUniqueId: reference.remoteUniqueId } : { remoteUuid: reference.remoteUuid! },
            { includingMigrations: true, isRegularSyndication: true, separateUntil: 3 }
          )
        ).items,
      }))
    );

    statuses.sort((a, b) => {
      if (!a.recent.length) {
        return b.recent.length ? -1 : 0;
      }
      if (!b.recent.length) {
        return 1;
      }
      return b.recent[0].thisSite.createdAt.valueOf() - a.recent[0].thisSite.createdAt.valueOf();
    });

    return {
      statuses,
      isLoading: false,
    };
  }

  get isOwnAndSoleRootEntity(): boolean {
    const { params } = this.props;
    return (
      !params.rootEntities?.length ||
      (params.rootEntities.length === 1 &&
        (params.rootEntities[0].remoteUniqueId ?? params.rootEntities[0].remoteUuid) === (params.remoteUniqueId ?? params.remoteUuid))
    );
  }

  render() {
    const { params, recent, embedded } = this.props;
    const { isLoading, statuses, selectedStatusIndex } = this.state;

    if (isLoading) {
      return this.renderRequest();
    }

    if (!params.rootEntities || this.isOwnAndSoleRootEntity) {
      return <IndividualEntityStatusWithParams params={params} recent={recent} embedded={embedded} />;
    }

    if (params.rootEntities.length === 1) {
      const rootEntity = params.rootEntities[0];
      const recent = statuses?.[0].recent;
      const syndication = recent?.[0]?.thisSite;
      return (
        <>
          <Alert variant="light">
            This content has been shared as part of another entry <em>{syndication?.rootEntityReference?.name ?? null}</em> that is shown
            below.
          </Alert>

          <IndividualEntityStatusWithParams
            embedded={embedded}
            params={{
              ...params,
              namespaceMachineName: rootEntity.namespaceMachineName,
              machineName: rootEntity.machineName,
              remoteUniqueId: rootEntity.remoteUniqueId,
              remoteUuid: rootEntity.remoteUuid,
            }}
            recent={recent}
          />
        </>
      );
    }

    if (!statuses) {
      return <Alert variant="danger">Failed to load site information.</Alert>;
    }

    if (selectedStatusIndex !== undefined) {
      const selected = statuses[selectedStatusIndex];
      return (
        <>
          <Alert variant="light" className="ms-3 me-3">
            This content has been shared as part of {statuses.length} other entries.{' '}
            <Button variant="link" onClick={() => this.setState({ selectedStatusIndex: undefined })}>
              Show all
            </Button>
          </Alert>

          <IndividualEntityStatusWithParams
            embedded={embedded}
            params={{
              ...params,
              namespaceMachineName: selected.reference.namespaceMachineName,
              machineName: selected.reference.machineName,
              remoteUniqueId: selected.reference.remoteUniqueId,
              remoteUuid: selected.reference.remoteUuid,
            }}
            recent={selected.recent}
          />
        </>
      );
    }

    return (
      <div className="ms-3 me-3">
        <Alert variant="light" className="mb-5">
          This content has been shared as part of {statuses.length} other entries that are shown below with their latest update.
        </Alert>

        {statuses.map((c, index) => {
          const entity = c.recent[0]?.thisSite;
          return (
            <div key={c.reference.remoteUniqueId ?? c.reference.remoteUuid} className="mb-5">
              <h2>
                {entity && entity.rootEntityReference?.name ? (
                  <span className="ms-2">
                    {' '}
                    {entity.rootEntityTypeVersion?.getId() && (
                      <EntityTypeVersionName entityId={entity.rootEntityTypeVersion.getId()} asIcon isLeft />
                    )}{' '}
                    {entity.rootEntityReference.name}
                  </span>
                ) : (
                  `Unnamed ${c.reference.namespaceMachineName}`
                )}
              </h2>
              {entity ? (
                <SyndicationListItem entity={entity} onClick={() => this.setState({ selectedStatusIndex: index })} />
              ) : (
                <Alert variant="light">This item has not been shared since the initial roll-out.</Alert>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}

export class IndividualEntityStatusWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    this.setState({
      isLoading: true,
    });

    let site: ClientSiteEntity | undefined = this.state.site;

    if (!site) {
      site = await this.getCurrentSite();

      if (!site) {
        return {};
      }
    }

    const { remoteUniqueId, remoteUuid, namespaceMachineName, machineName } = this.props.params;

    const recent =
      this.props.recent ||
      (
        await this.api.syndication.syndications.usageSummaryForSite(
          site.uuid,
          namespaceMachineName && machineName ? { namespaceMachineName, machineName } : undefined,
          remoteUniqueId ? { remoteUniqueId } : { remoteUuid: remoteUuid! },
          { includingMigrations: true, isRegularSyndication: true, separateUntil: 3 }
        )
      ).items;

    // Retrying is not considered running as the interval is longer. But we do show a manual refresh
    // button in this case.
    const isSyndicationRunning = (status?: SyndicationStatus, includeRetrying?: boolean) => {
      return (
        status === SyndicationStatus.Initializing ||
        status === SyndicationStatus.Running ||
        (includeRetrying && status === SyndicationStatus.Retrying)
      );
    };

    const isRunning = !!recent.find(
      (summary) =>
        isSyndicationRunning(summary.thisSite?.status) ||
        isSyndicationRunning(summary.sourceSite?.status) ||
        isSyndicationRunning(summary.targetSite?.status) ||
        !!summary.targetSites?.find((c) => isSyndicationRunning(c.status)) ||
        !!summary.targetSummary?.find((c) => isSyndicationRunning(c.status))
    );

    const isRunningOrRetrying = !!recent.find(
      (summary) =>
        isSyndicationRunning(summary.thisSite?.status, true) ||
        isSyndicationRunning(summary.sourceSite?.status, true) ||
        isSyndicationRunning(summary.targetSite?.status, true) ||
        !!summary.targetSites?.find((c) => isSyndicationRunning(c.status, true)) ||
        !!summary.targetSummary?.find((c) => isSyndicationRunning(c.status, true))
    );

    let updateCount = this.state.updateCount ? this.state.updateCount + 1 : 0;

    if (isRunning) {
      if (this.__refreshInterval) {
        clearInterval(this.__refreshInterval);
        this.__refreshInterval = null;
      }

      setTimeout(
        this.load,
        updateCount >= REFRESH_INTERVAL_RUNNING.length
          ? REFRESH_INTERVAL_RUNNING[REFRESH_INTERVAL_RUNNING.length - 1]
          : REFRESH_INTERVAL_RUNNING[updateCount]
      );
    } else {
      if (!this.__refreshInterval) {
        this.__refreshInterval = setTimeout(() => {
          if (!this.__isMounted) {
            clearInterval(this.__refreshInterval);
            this.__refreshInterval = null;
            return;
          }

          this.setState({ updatedAt: moment().valueOf(), updateCount: 1 });
        }, REFRESH_INTERVAL_STILL);
      }
    }

    return {
      site,
      recent,
      isRunning,
      isRunningOrRetrying,
      isLoading: false,
      updateCount,
      updatedAt: moment().valueOf(),
      fullscreenRef: this.state.fullscreenRef ?? React.createRef(),
    };
  }

  protected __refreshInterval: any = null;

  render() {
    const {
      site,
      recent,
      isRunning,
      isRunningOrRetrying,
      isLoading,
      updatedAt,
      show,
      selectedComparison,
      serializeMode,
      selectedSyndication,
      errorDetails,
      fullscreenRef,
      inFullscreen,
    } = this.state;
    const selected = this.state.selected || 0;

    const { embedded } = this.props;

    if (!site || !recent) {
      return this.renderRequest();
    }

    const summary = recent[selected];

    if (!summary) {
      return (
        <Alert variant="light">
          This content has not been pushed from or pulled into this site yet. If you think this is an error, try refreshing the page in a
          couple of seconds.
        </Alert>
      );
    }

    const setSyndicationAndErrorDetails = (
      selectedSyndication?: ClientSyndicationEntity,
      errorDetails?: { operationIndex: number; errorIndex: number }
    ) => this.setState({ selectedSyndication, errorDetails, show: show === 'details' && !selectedSyndication ? undefined : show });
    const setErrorDetails = (errorDetails?: { operationIndex: number; errorIndex: number }) => this.setState({ errorDetails });
    const setSyndication = (syndication?: ClientSyndicationEntity) => {
      syndication = syndication ? syndication : selectedSyndication?.id === summary.thisSite?.id ? undefined : summary.thisSite;
      this.setState({
        selectedSyndication: syndication,
        errorDetails: undefined,
        show: show === 'details' && !syndication ? undefined : show,
      });
    };
    const showSyndicationDetails = (syndication: ClientSyndicationEntity, defaultToErrorDisplay?: boolean) => {
      const errorDetails = defaultToErrorDisplay ? getLatestError(syndication) : undefined;
      this.setState({
        show: 'details',
        selectedSyndication: syndication,
        errorDetails,
      });
    };

    const isSourceSite =
      summary.thisSite?.type === SyndicationType.RetrieveEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndPushEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndDeleteEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndDeleteEmbeddedEntity;

    const sourceSite = isSourceSite ? summary.thisSite : summary.sourceSite;
    const targetSites = isSourceSite
      ? summary.targetSites?.length
        ? summary.targetSites
        : summary.targetSite
        ? [summary.targetSite]
        : []
      : [summary.thisSite];
    /*const targetSite = undefined;
    summary.targetSummary = [
      {
        count: 1,
        progress: 0.8,
        status: SyndicationStatus.Running,
      },
      {
        count: 1,
        progress: 0.4,
        status: SyndicationStatus.Retrying,
      },
      {
        count: 72,
        progress: 1,
        status: SyndicationStatus.Finished,
      },
      {
        count: 1,
        progress: 1,
        status: SyndicationStatus.Failed,
      },
    ];*/

    // TODO: Add "Show usage" that is also available for CONTENT users.
    const { primary, danger } = getStyleColors();

    const selectRecent = (
      <Select
        value={recent[selected]}
        getOptionValue={(option) => option.thisSite.id}
        getOptionLabel={(option) => option.startedAt.fromNow()}
        options={recent}
        onChange={(option) => option && this.setState({ selected: recent.indexOf(option) })}
        components={{ Option: CustomOption }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary,
            danger,
          },
        })}
      />
    );

    const background = show === 'details' ? 'pe-3 ps-3 pt-1 pb-3 page-background' : 'pe-3 ps-3 pb-3';

    if (show === 'details' && selectedSyndication) {
      const navigation =
        selectedSyndication?.id === summary.thisSite?.id ? null : (
          <Breadcrumb
            items={[
              { name: 'This site', onClick: () => this.setState({ selectedSyndication: summary.thisSite }) },
              { name: <SiteName id={selectedSyndication.targetSite.getId()} inline /> },
            ]}
          />
        );

      if (errorDetails) {
        return (
          <div style={{ minHeight: '400px' }} className={`${background}`}>
            {navigation}
            <OperationErrorDetails
              syndication={selectedSyndication}
              noSyndication={setSyndication}
              setErrorDetails={setErrorDetails}
              errorIndex={errorDetails.errorIndex}
              operationIndex={errorDetails.operationIndex}
            />
          </div>
        );
      }

      return (
        <div style={{ minHeight: '400px' }} className={`${background}`}>
          {navigation}
          <SyndicationDetails
            showSiteName={selectedSyndication?.id !== summary.thisSite?.id}
            key={selectedSyndication.id}
            syndication={selectedSyndication}
            setSyndication={setSyndication}
            setSyndicationAndErrorDetails={setSyndicationAndErrorDetails}
          />
        </div>
      );
    }
    if (show === 'serialized') {
      const selectOther = (
        <Select
          isClearable
          value={selectedComparison === undefined ? undefined : recent[selectedComparison]}
          getOptionValue={(option) => option.thisSite.id}
          getOptionLabel={(option) => option.startedAt.fromNow()}
          isOptionDisabled={(option) => selected === recent.indexOf(option)}
          placeholder="Compare to ..."
          options={recent}
          onChange={(option) => this.setState({ selectedComparison: !option ? undefined : recent.indexOf(option) })}
          components={{ Option: CustomOption }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary,
              danger,
            },
          })}
        />
      );

      return (
        <div style={{ minHeight: '400px' }} className={`${background} bg-white ${inFullscreen ? 'overflow-auto' : ''}`} ref={fullscreenRef}>
          <Row className="mt-1 mb-2">
            <Col xs={3}>
              <h1>
                <HeadlineBackButton onClick={() => this.setState({ selectedComparison: undefined, show: undefined })} />{' '}
                <span className="d-inline-block align-middle">Serialize</span>
              </h1>
            </Col>
            <Col xs={3} className="mt-3">
              <SwitchButton
                selected={serializeMode ?? 'table'}
                options={{ table: 'Table', 'yaml-pretty': 'Technical', 'yaml-raw': 'Raw' }}
                size="m"
                onSelect={async (newValue) => {
                  this.setState({ serializeMode: newValue });
                }}
              />
            </Col>
            <Col xs={3} className="mt-3">
              {recent.length > 1 && selectOther}
            </Col>
            <Col xs={3} className="mt-3">
              <div className="me-1">{selectRecent}</div>
            </Col>
            {false && (
              <Col>
                <IconButton
                  icon={faExpand}
                  onClick={() => {
                    try {
                      if (document.fullscreenElement) {
                        fullscreenRef?.current?.exitFullscreen();
                        this.setState({ inFullscreen: false });
                      } else {
                        fullscreenRef?.current?.requestFullscreen();
                        this.setState({ inFullscreen: true });
                      }
                    } catch (e) {}
                  }}
                />
              </Col>
            )}
          </Row>

          {!serializeMode || serializeMode === 'table' ? (
            <DisplayEntityRevision
              id={summary.thisSite.rootEntity.getId()}
              comparisonId={
                selectedComparison && recent[selectedComparison] ? recent[selectedComparison].thisSite.rootEntity.getId() : undefined
              }
              key={
                summary.thisSite.rootEntity.getId() +
                '-' +
                (selectedComparison && recent[selectedComparison] ? recent[selectedComparison].thisSite.rootEntity.getId() : undefined)
              }
            />
          ) : (
            <SerializedEntityRevision
              id={summary.thisSite.rootEntity.getId()}
              comparisonId={
                selectedComparison && recent[selectedComparison] ? recent[selectedComparison].thisSite.rootEntity.getId() : undefined
              }
              structure={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
              values={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
            />
          )}
        </div>
      );
    }

    const renderProgressCircle = (syndication?: ClientSyndicationEntity) => {
      const progressVariant = syndication
        ? dangerStatus.includes(syndication.status)
          ? 'danger'
          : warningStatus.includes(syndication.status)
          ? 'warning'
          : neutralStatus.includes(syndication.status)
          ? 'dark'
          : 'success'
        : 'none';
      const done = syndication && doneStatus.includes(syndication.status);
      const progress = done
        ? 1
        : syndication?.operations
        ? syndication.operations.length > 0
          ? syndication.operations.filter((c) => doneStatus.includes(c.status)).length / syndication.operations.length
          : 1
        : 0;

      const isLink = this.props.params.configurationAccess && !!syndication;

      const element = (
        <div className="mb-4">
          <div
            style={{ width: '200px', height: '200px', maxWidth: '20vw', maxHeight: '20vw' }}
            className={`${isLink ? 'cursor-pointer' : ''}`}
            onClick={isLink ? () => showSyndicationDetails(syndication) : undefined}
          >
            <CircularProgressbarWithChildren value={progress * 100} className={`variant-${progressVariant} shadow rounded-circle`}>
              <div>
                {syndication ? (
                  <EnvironmentIconForSite
                    siteId={syndication.targetSite.getId()!}
                    fallbackIcon={faDrupal}
                    className="text-muted"
                    size={'6x'}
                  />
                ) : (
                  <FontAwesomeIcon icon={faQuestion} className="text-muted" size={'6x'} />
                )}
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ position: 'absolute', right: '35px', left: '0', bottom: '40px', height: '30px' }}
              >
                {syndication ? (
                  <SyndicationStatusIcon
                    size="2x"
                    status={syndication.status}
                    errorType={syndication?.operations?.[0]?.errors?.[0]?.type}
                  />
                ) : undefined}
              </div>
              {syndication?.usage ? (
                <div
                  style={{ position: 'absolute', top: '0', right: '0', bottom: '0', left: '0', overflow: 'hidden', borderRadius: '200px' }}
                >
                  <div
                    className="text-center bg-white align-middle"
                    style={{ position: 'absolute', right: '25%', left: '25%', bottom: '0', height: '30px' }}
                  >
                    <UsageSummary entity={syndication.usage} />
                  </div>
                </div>
              ) : undefined}
            </CircularProgressbarWithChildren>
          </div>
        </div>
      );

      if (syndication) {
        return (
          <OverlayTrigger
            key={syndication.id}
            placement="bottom"
            overlay={(props) => (
              <Tooltip id={`tooltip-syndication-${syndication.targetSite.getId()}`} {...props}>
                {(isSourceSite ? syndication === sourceSite : targetSites.includes(syndication)) ? (
                  'This site'
                ) : (
                  <SiteName light id={syndication.targetSite.getId()} middle />
                )}
                &nbsp;
                <SyndicationStatusBadge status={syndication.status} errorType={syndication.operations?.[0]?.errors?.[0]?.type} />{' '}
                {!done && `${Math.floor(progress * 100)}%`}
              </Tooltip>
            )}
          >
            {element}
          </OverlayTrigger>
        );
      }

      return element;
    };

    const renderBigIcon = (icon: any, showDetailsLink: boolean, title?: string) => {
      const isLink = showDetailsLink && this.props.params.configurationAccess && !!summary.thisSite;

      const element = (
        <div
          style={{ width: '200px', height: '200px', maxWidth: '20vw', maxHeight: '20vw' }}
          className={`${isLink ? 'cursor-pointer' : ''}`}
          onClick={isLink ? () => showSyndicationDetails(summary.thisSite) : undefined}
        >
          <CircularProgressbarWithChildren value={100} className={`variant-none shadow rounded-circle`}>
            <div>
              <FontAwesomeIcon
                icon={icon}
                className={icon === faBan ? 'text-light' : 'text-muted'}
                size={'6x'}
                style={{ '--fa-primary-color': 'var(--primary)' } as any}
              />
            </div>
          </CircularProgressbarWithChildren>
        </div>
      );

      if (title) {
        return (
          <OverlayTrigger
            placement="bottom"
            overlay={(props) => (
              <Tooltip id="tooltip-big-icon" {...props}>
                {title}
              </Tooltip>
            )}
          >
            {element}
          </OverlayTrigger>
        );
      }

      return element;
    };

    let refreshButton = !isRunning ? (
      <IconButton
        disabled={isLoading}
        /*TODO: iconProps={spin:isLoading}}*/ variant={'dark'}
        icon={faSyncAlt}
        onClick={() => this.load()}
      />
    ) : undefined;
    if (refreshButton && updatedAt) {
      refreshButton = (
        <div>
          <OverlayTrigger
            placement="left"
            overlay={(props) => (
              <Tooltip id="tooltip-refreshed-at" {...props}>
                Updated {moment(updatedAt).fromNow()}
              </Tooltip>
            )}
          >
            <div>{refreshButton}</div>
          </OverlayTrigger>
        </div>
      );
    }

    return (
      <div style={{ minHeight: '400px' }} className={`${background}`}>
        <Row className="justify-content-end">
          <Col className="m-1" xs={3}>
            {selectRecent}
          </Col>
          <Col className="flex-grow-0 pt-1 ps-0">{refreshButton}</Col>
        </Row>

        <ContractWarnings />

        <Row className="justify-content-between pb-4">
          <Col xs={3} className={'d-flex justify-content-center align-items-center p-4'}>
            {renderProgressCircle(sourceSite)}
          </Col>
          <Col xs={1} className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faArrowAltRight} className="text-light" size={'2x'} />
          </Col>
          <Col xs={3} className={'d-flex justify-content-center align-items-center p-4'}>
            {renderBigIcon(faChartNetwork, false, 'Sync Core')}
          </Col>
          <Col xs={1} className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon icon={faArrowAltRight} className="text-light" size={'2x'} />
          </Col>
          <Col xs={3} className={'d-flex justify-content-center align-items-center p-4'}>
            <div className="d-flex flex-column">
              {targetSites?.length
                ? targetSites.map((targetSite) => renderProgressCircle(targetSite))
                : summary.targetSummary
                ? summary.targetSummary.length
                  ? summary.targetSummary.map((summary) => {
                      const progressVariant = dangerStatus.includes(summary.status)
                        ? 'danger'
                        : warningStatus.includes(summary.status)
                        ? neutralStatus.includes(summary.status)
                          ? 'dark'
                          : 'warning'
                        : 'success';
                      const done = doneStatus.includes(summary.status);
                      const progress = done ? 1 : summary.progress;

                      return (
                        <OverlayTrigger
                          key={summary.status}
                          placement="bottom"
                          overlay={(props) => (
                            <Tooltip id="targets-status" {...props}>
                              <SyndicationStatusBadge status={summary.status} /> {!done && `${Math.floor(progress * 100)}%`}
                            </Tooltip>
                          )}
                        >
                          <div style={{ width: '200px', height: '200px', maxWidth: '20vw', maxHeight: '20vw', margin: '10px 0' }}>
                            <CircularProgressbarWithChildren
                              value={progress * 100}
                              className={`variant-${progressVariant} shadow rounded-circle`}
                            >
                              <div
                                style={{ marginBottom: '-45px', marginTop: '-20px', height: '128px' }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <div style={{ fontSize: '450%' }} className="text-muted">
                                  <strong>{summary.count}</strong>x
                                </div>
                              </div>
                              <div className="d-flex justify-content-end" style={{ marginRight: '65px', width: '100%', height: '30px' }}>
                                <SyndicationStatusIcon size="2x" status={summary.status} />
                              </div>
                            </CircularProgressbarWithChildren>
                          </div>
                        </OverlayTrigger>
                      );
                    })
                  : renderBigIcon(faTimes, true)
                : isRunningOrRetrying
                ? renderBigIcon(faQuestion, false)
                : renderBigIcon(faBan, false)}
            </div>
          </Col>
        </Row>

        <LeftRightContainer
          className="align-items-center"
          left={
            <em className="text-muted">
              Started {summary.thisSite.createdAt.fromNow()}
              {summary.finishedAt ? `, finished after ${summary.finishedAt.from(summary.thisSite.createdAt, true)}` : undefined}.
            </em>
          }
          right={
            this.props.params.configurationAccess ? (
              embedded ? (
                summary.thisSite && (
                  <ExternalLinkWithIcon to={getAppLinkForTask(summary.thisSite.id, site.uuid)} className={''}>
                    Open in App
                  </ExternalLinkWithIcon>
                )
              ) : (
                <>
                  <Button variant="light" onClick={() => this.setState({ show: 'serialized' })} className="shadow-sm">
                    Serialize
                  </Button>
                  <Button variant="light" onClick={() => showSyndicationDetails(summary.thisSite)} className="shadow-sm">
                    Details
                  </Button>
                </>
              )
            ) : undefined
          }
        />
      </div>
    );
  }
}

export const EntityStatus = () => (
  <ParamsComponent<Params> Params={Params}>
    {(params: Params) => {
      return <EntityStatusWithParams params={params} />;
    }}
  </ParamsComponent>
);
