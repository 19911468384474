import { ContractConfiguration } from '@edgebox/sync-core-rest-client/dist/services/api';
import React from 'react';
import { Alert } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {}

interface IState extends ISyncCoreApiComponentState {
  contractConfiguration?: ContractConfiguration;
}

export class ContractWarnings extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const contractConfiguration = await this.api.utility.configuration.contract();

    return {
      contractConfiguration,
    };
  }

  render() {
    const { contractConfiguration } = this.state;

    if (!contractConfiguration) {
      return this.renderRequest();
    }

    if (contractConfiguration.contractExpired) {
      return <Alert variant="danger">Your contract has expired. Please renew your contract to use Content Sync again.</Alert>;
    }

    if (contractConfiguration.remainingUpdates !== undefined && contractConfiguration.remainingUpdates !== null) {
      if (contractConfiguration.remainingUpdates <= 0) {
        return (
          <Alert variant="warning">
            You have reached your update limit for the current contract period. Updates will be paused until your contract is upgraded or
            renewed.
          </Alert>
        );
      }

      if (contractConfiguration.remainingUpdates <= 20) {
        return (
          <Alert variant="warning">
            You only have {contractConfiguration.remainingUpdates} updates remaining for your current contract period. When you hit the
            limit, updates will be paused until your contract is upgraded or renewed.
          </Alert>
        );
      }
    }

    return null;
  }
}
