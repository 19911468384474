import { FeatureSummary } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  featureName: string;
  ifEnabled?: () => React.ReactNode;
  ifDisabled?: () => React.ReactNode;
}

interface IState extends ISyncCoreApiComponentState {
  flags?: FeatureSummary;
}

export class SyncCoreFeatureFlagGate extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const flags = await this.api.utility.features.summary();
    return {
      flags,
    };
  }

  render() {
    const { featureName, ifEnabled, ifDisabled } = this.props;
    const { flags } = this.state;
    if (!flags) {
      return this.renderRequest();
    }

    if (flags[featureName]) {
      return ifEnabled ? ifEnabled() : null;
    }

    return ifDisabled ? ifDisabled() : null;
  }
}
