import {
  BooleanProperty,
  IntegerProperty,
  NestedProperty,
  UnformattedTextProperty,
  ExternalServiceId,
  InternalId,
} from '@edgebox/data-definition-kit';
import { InfoBox } from '@edgebox/react-components';
import { MigrationType, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntityWithSummary, ClientSiteEntity, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ContractWarnings } from '../../ContractWarnings';
import { PagedSyndicationList } from '../../PagedSyndicationList';
import { ParamsComponent, transformBool, transformInteger } from '../ParamsComponent';
import Select from 'react-select';
import { getStyleColors } from '../../../Helpers';
import { SwitchButton } from '../../SwitchButton';
import { STATUS_FILTERS, StatusFilter, StatusFilterOrMassUpdates } from '../Syndication/SyndicationHelper';
import { OperationErrorDetails } from '../Syndication/OperationErrorDetails';
import { SyndicationDetails } from '../Syndication/SyndicationDetails';
import { MigrationDetails } from '../Syndication/MigrationDetails';
import { HeadlineBackButton } from '../HeadlineBackButton';
import { Breadcrumb } from '../Breadcrumb';
import { MigrationList } from '../Syndication/MigrationList';
import { IsMongoId, IsOptional, IsString, Matches } from 'class-validator';
import { Transform, instanceToPlain } from 'class-transformer';
import { sendToParent } from '../../../frame-messages';

type DisplaySyndicationType = 'config' | 'content';

class Query {
  @IsString()
  @Matches(/^(all|running|finished|aborted|failed|mass-updates)$/)
  @IsOptional()
  statusFilter?: StatusFilterOrMassUpdates;

  @IsString()
  @Matches(/^(all|running|finished|aborted|failed)$/)
  @IsOptional()
  migrationStatusFilter?: StatusFilter;

  @IsString()
  @IsMongoId()
  @IsOptional()
  migrationId?: InternalId;

  @IsString()
  @IsMongoId()
  @IsOptional()
  syndicationId?: InternalId;

  @IntegerProperty(false)
  @Transform(transformInteger)
  operationIndex?: number;

  @IntegerProperty(false)
  @Transform(transformInteger)
  errorIndex?: number;

  @BooleanProperty(false)
  @Transform(transformBool)
  allSites?: boolean;

  @IsString()
  @Matches(/^(config|content)$/)
  @IsOptional()
  type?: DisplaySyndicationType;
}

class Params {
  @UnformattedTextProperty(false, 200)
  selectedFlowMachineName?: ExternalServiceId;

  @BooleanProperty(false)
  startNew?: boolean;

  @BooleanProperty(false)
  forbidStartNew?: boolean;

  @BooleanProperty(false)
  viewOnly?: boolean;

  @NestedProperty(false, () => Query)
  query?: Query;
}

interface IProps {
  params: Params;
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
  showForAllSites?: boolean;
  syndicationType?: DisplaySyndicationType;
  statusFilter?: StatusFilterOrMassUpdates;
  startNew?: boolean;
  selectedMigration?: ClientMigrationEntityWithSummary;
  selectedSyndication?: ClientSyndicationEntity;
  errorDetails?: { operationIndex: number; errorIndex: number };
  query?: Query;
  migrationStatusFilter?: StatusFilter;
  watch?: boolean;
}

export class SyndicationDashboardWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const site = await this.getCurrentSite(true);

    const statusFilter: StatusFilterOrMassUpdates = this.props.params.startNew ? 'mass-updates' : 'all';

    const query = this.props.params.query || {};
    let newState: Partial<IState> = {
      site,
      statusFilter,
      startNew: this.props.params.startNew,
      query,
    };
    if (Object.keys(query).length) {
      const { state } = await this.getStateFromQuery(query, newState);
      newState = { ...newState, ...state };
    }

    return newState;
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const paramsQuery = this.props.params.query;
    const stateQuery = this.state.query;
    if (this.state) {
      if (paramsQuery && Object.keys(paramsQuery).length) {
        if (!prevProps.params.query || JSON.stringify(paramsQuery) !== JSON.stringify(prevProps.params.query)) {
          this.updateStateFromQuery(paramsQuery, this.state);
        }
      }
      if (stateQuery) {
        const { changed, query } = this.getQueryFromState(stateQuery, this.state);
        if (changed) {
          sendToParent({
            type: 'update-query',
            query: instanceToPlain(query),
          });
        }
      }
    }
  }

  async getStateFromQuery(query: Query, useState: Partial<IState>): Promise<Partial<{ changed: boolean; state: Partial<IState> }>> {
    let changed = false;

    const state = { ...useState };
    if (query.allSites !== state.showForAllSites) {
      changed = true;
      state.showForAllSites = query.allSites;
    }
    if (query.type !== state.syndicationType) {
      changed = true;
      state.syndicationType = query.type;
    }
    if (query.statusFilter !== state.statusFilter) {
      if (query.statusFilter !== undefined || !useState.startNew) {
        changed = true;
        state.statusFilter = query.statusFilter;
      }
    }
    if (query.migrationStatusFilter !== state.migrationStatusFilter) {
      changed = true;
      state.migrationStatusFilter = query.migrationStatusFilter;
    }
    if (query.errorIndex !== state.errorDetails?.errorIndex || query.operationIndex !== state.errorDetails?.operationIndex) {
      changed = true;
      if (typeof query.errorIndex === 'number' && typeof query.operationIndex === 'number') {
        state.errorDetails = {
          errorIndex: query.errorIndex,
          operationIndex: query.operationIndex,
        };
      } else {
        state.errorDetails = undefined;
      }
    }
    if (query.migrationId !== state.selectedMigration?.id) {
      changed = true;
      const selectedMigration = query.migrationId ? await this.api.syndication.migrations.itemWithSummary(query.migrationId) : undefined;
      state.selectedMigration = selectedMigration;
    }
    if (query.syndicationId !== state.selectedSyndication?.id) {
      changed = true;
      const selectedSyndication = query.syndicationId ? await this.api.syndication.syndications.item(query.syndicationId) : undefined;
      state.selectedSyndication = selectedSyndication;
    }

    return { state, changed };
  }

  async updateStateFromQuery(query: Query, useState: Partial<IState>) {
    const { state, changed } = await this.getStateFromQuery(query, useState);

    if (changed) {
      this.setState({ ...state, query } as IState);
    }
  }

  getQueryFromState(previousQuery: Query, state: Partial<IState>) {
    let changed = false;
    const query = { ...previousQuery };

    if (query.allSites !== state.showForAllSites) {
      changed = true;
      query.allSites = state.showForAllSites;
    }
    if (query.type !== state.syndicationType) {
      changed = true;
      query.type = state.syndicationType;
    }
    if (query.statusFilter !== state.statusFilter) {
      changed = true;
      query.statusFilter = state.statusFilter;
    }
    if (query.migrationStatusFilter !== state.migrationStatusFilter) {
      changed = true;
      query.migrationStatusFilter = state.migrationStatusFilter;
    }
    if (query.errorIndex !== state.errorDetails?.errorIndex || query.operationIndex !== state.errorDetails?.operationIndex) {
      changed = true;
      if (state.errorDetails) {
        query.errorIndex = state.errorDetails.errorIndex;
        query.operationIndex = state.errorDetails.operationIndex;
      } else {
        delete query.errorIndex;
        delete query.operationIndex;
      }
    }
    if (query.migrationId !== state.selectedMigration?.id) {
      changed = true;
      query.migrationId = state.selectedMigration?.id;
    }
    if (query.syndicationId !== state.selectedSyndication?.id) {
      changed = true;
      query.syndicationId = state.selectedSyndication?.id;
    }

    return {
      query: Object.assign(new Query(), query),
      changed,
    };
  }

  render() {
    let statusFilter = this.state.statusFilter;
    const { site, showForAllSites, syndicationType, startNew, selectedSyndication, selectedMigration, errorDetails, watch } = this.state;
    const { forbidStartNew, viewOnly } = this.props.params;

    if (!site) {
      return this.renderRequest();
    }

    if (!statusFilter) {
      statusFilter = 'all';
    }

    const { primary, danger } = getStyleColors();

    const setSyndicationAndErrorDetails = (
      selectedSyndication?: ClientSyndicationEntity,
      errorDetails?: { operationIndex: number; errorIndex: number }
    ) => {
      setSyndication(selectedSyndication);
      setTimeout(() => setErrorDetails(errorDetails), 1);
    };
    const setErrorDetails = (errorDetails?: { operationIndex: number; errorIndex: number }) => this.setState({ errorDetails });
    const setSyndication = (selectedSyndication?: ClientSyndicationEntity) => {
      let setSelectedMigration = selectedMigration;

      if (selectedSyndication && (selectedSyndication.migration?.getId() || null) !== (selectedMigration?.id || null)) {
        setSelectedMigration = undefined;
        const migrationId = selectedSyndication.migration?.getId();
        if (migrationId) {
          this.api.syndication.migrations
            .itemWithSummary(migrationId)
            .then((migration) => {
              if (this.state.selectedSyndication?.id === selectedSyndication?.id) {
                // If it's less than two, then only the LIST and ONE entity request will have spawned, so it's a single-entity push and displaying the migration is not relevant.
                if (
                  migration.type === MigrationType.PushManually &&
                  migration.summary.map((c) => c.count).reduce((a, b) => a + b, 0) <= 2
                ) {
                  return;
                }
                this.setState({ selectedMigration: migration });
              }
            })
            .catch((e) => undefined);
        }
      }

      this.setState({ selectedMigration: setSelectedMigration, selectedSyndication, errorDetails: undefined });
    };
    const noMigration = () =>
      this.setState({
        statusFilter: 'mass-updates',
        selectedMigration: undefined,
        selectedSyndication: undefined,
        errorDetails: undefined,
      });
    const setMigration = (selectedMigration?: ClientMigrationEntityWithSummary) =>
      this.setState({ selectedMigration, selectedSyndication: undefined, errorDetails: undefined });
    const setFilter = (statusFilter: StatusFilterOrMassUpdates) =>
      this.setState({ statusFilter, selectedMigration: undefined, selectedSyndication: undefined, errorDetails: undefined });

    return (
      <div className={viewOnly ? 'p-1' : 'p-3'} style={{ minHeight: '500px' }}>
        <ContractWarnings />

        {errorDetails && selectedSyndication ? (
          <OperationErrorDetails
            syndication={selectedSyndication}
            noSyndication={() => setSyndication()}
            showSiteName={!!showForAllSites}
            noMassUpdatesName={'All updates'}
            noMassUpdates={() => setFilter('all')}
            noMigrationName={selectedMigration ? 'Mass updates' : undefined}
            noMigration={noMigration}
            migrationName={
              selectedMigration
                ? SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', selectedMigration.type)
                : undefined
            }
            setErrorDetails={setErrorDetails}
            {...errorDetails}
          />
        ) : selectedSyndication ? (
          <SyndicationDetails
            key={selectedSyndication.id}
            syndication={selectedSyndication}
            setSyndication={setSyndication}
            showSiteName={!!showForAllSites}
            noMassUpdatesName={'All updates'}
            noMassUpdates={() => setFilter('all')}
            noMigrationName={selectedMigration ? 'Mass updates' : undefined}
            noMigration={noMigration}
            migrationName={
              selectedMigration
                ? SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', selectedMigration.type)
                : undefined
            }
            migration={selectedMigration}
            setSyndicationAndErrorDetails={setSyndicationAndErrorDetails}
          />
        ) : selectedMigration ? (
          <MigrationDetails
            migration={selectedMigration}
            setMigration={(migration) => (migration ? setMigration(migration) : noMigration())}
            showSiteName={!!showForAllSites}
            onSelectSyndication={setSyndication}
            noMassUpdatesName="All updates"
            noMigrationName={'Mass updates'}
            noMassUpdates={() => setFilter('all')}
          />
        ) : (
          <>
            {statusFilter === 'mass-updates' ? (
              <Breadcrumb items={[{ name: 'All updates', onClick: () => setFilter('all') }, { name: 'Mass updates' }]} />
            ) : null}
            <div className={statusFilter === 'mass-updates' ? 'mt-2' : ''}>
              {statusFilter === 'mass-updates' && <HeadlineBackButton onClick={() => setFilter('all')} />}
              <Select
                placeholder="Status"
                value={{ statusFilter }}
                getOptionValue={(option) => option.statusFilter || ''}
                getOptionLabel={(option) => option.statusFilter?.replace(/-/g, ' ') || ''}
                formatOptionLabel={(label) => (
                  <div className={`text-capitalize ${label?.statusFilter === 'mass-updates' ? '' : ''}`}>
                    {label?.statusFilter?.replace(/-/g, ' ') || ''}
                  </div>
                )}
                options={([...Object.keys(STATUS_FILTERS), 'mass-updates'] as StatusFilter[]).map((statusFilter: StatusFilter) => ({
                  statusFilter,
                }))}
                onChange={(option) => option && setFilter(option.statusFilter)}
                styles={{
                  container: (base) => ({
                    ...base,
                    width: '200px',
                    display: 'inline-block',
                  }),
                  option: (base, props) => ({
                    ...base,
                    ...(props.data.statusFilter === 'mass-updates'
                      ? {
                          borderWidth: '1px 0 0 0',
                          borderStyle: 'solid',
                          borderColor: '#ddd',
                          paddingTop: '0.5em',
                          marginTop: '0.5em',
                        }
                      : {}),
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary,
                    danger,
                  },
                })}
              />
              <SwitchButton
                className="ms-2 align-baseline"
                selected={showForAllSites ? '1' : '0'}
                options={{ '1': 'all sites', '0': 'this site' }}
                onSelect={async (newValue) => {
                  this.setState({ showForAllSites: newValue === '1' });
                }}
                width="100px"
              />
              <SwitchButton
                className="ms-2 align-baseline"
                selected={syndicationType === 'config' ? 'config' : 'content'}
                options={{ content: 'Content', config: 'Config' }}
                onSelect={async (newValue) => {
                  this.setState({ syndicationType: newValue });
                }}
                width="100px"
              />
              <Form.Check
                inline
                className="ms-2"
                id="watch"
                type="checkbox"
                checked={!!watch}
                onChange={() => this.setState({ watch: !watch })}
                label="Watch"
              />
            </div>
            {statusFilter === 'aborted' && (
              <Alert variant="light" className="mt-3">
                Aborted updates are usually nothing to worry about. Updates are aborted if one of the following happens:
                <ul>
                  <li>A user manually aborts an update.</li>
                  <li>
                    The entity was changed again before the update ran, so that only the newer update is run and the previous update is
                    aborted.
                  </li>
                  <li>
                    The site responds with 404, indicating that a specific entity is not supposed to be pushed or pulled. Check your Flow
                    configuration in that case to understand why that happens.
                  </li>
                </ul>
              </Alert>
            )}
            {statusFilter === 'all' && (
              <InfoBox className="mt-3 mb-3 pt-3 pb-3">
                <div className="">
                  To synchronize individual content items edit the content and hit <em>Save and Push content</em>.
                  <br />
                  <br />
                  <Button
                    onClick={() => this.setState({ statusFilter: 'mass-updates' })}
                    className="rounded bg-lighter bg-hover-light fw-bold text-dark"
                  >
                    Mass Updates
                  </Button>
                </div>
              </InfoBox>
            )}
            {statusFilter === 'mass-updates' ? (
              <MigrationList
                startNew={startNew}
                forbidStartNew={forbidStartNew}
                viewOnly={viewOnly}
                siteId={showForAllSites ? undefined : site.id}
                showSiteName={!!showForAllSites}
                onSelectItem={setMigration}
                selectedFlowMachineName={this.props.params.selectedFlowMachineName}
              />
            ) : (
              <PagedSyndicationList
                watch={watch}
                includeViewLink
                groupByDate
                isOnPageBackground
                type={syndicationType}
                showSiteName={!!showForAllSites}
                siteIds={showForAllSites ? undefined : [site.id]}
                statuses={statusFilter && statusFilter !== 'all' ? STATUS_FILTERS[statusFilter] : undefined}
                onSelect={setSyndication}
                onErrorDetails={setSyndicationAndErrorDetails}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export const SyndicationDashboard = () => (
  <ParamsComponent<Params> Params={Params}>
    {(params: Params) => {
      return <SyndicationDashboardWithParams params={params} />;
    }}
  </ParamsComponent>
);
