import { ExternalLink } from '@edgebox/react-components';
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  url: string;
  className?: string;
}
interface IState {}

export class HelpLink extends React.Component<IProps, IState> {
  render() {
    const { url, children, className } = this.props;

    return (
      <ExternalLink to={url} className={`text-muted ${className || ''}`}>
        <FontAwesomeIcon icon={faQuestionCircle} /> <u>{children}</u>
      </ExternalLink>
    );
  }
}
