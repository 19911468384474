import React, { ReactNode } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

interface IProps<Key extends string, Value = string> {
  options: { [key in Key]: Value };
  className?: string;
  selected?: Key;
  disabled?: boolean;
  size?: 'sm' | 'ms' | 'm' | 'lg';
  width?: string;
  onSelect: (key: Key) => void;
}

interface IState {}

export class SwitchButton<Key extends string, Value extends ReactNode = string> extends React.Component<IProps<Key, Value>, IState> {
  render() {
    const { options, selected, onSelect, size, disabled, className } = this.props;
    const width = this.props.width || (size === 'lg' ? '240px' : size === 'm' ? '160px' : size === 'ms' ? '120px' : '80px');

    return (
      <ButtonGroup className={`shadow-border mb-1 ${className || ''}`}>
        {Object.keys(options).map((anyKey: string) => {
          const key = anyKey as Key;
          return (
            <Button
              key={key}
              style={{ width }}
              variant={key === selected ? 'primary' : 'light'}
              className={key === selected ? '' : 'bg-white'}
              onClick={disabled ? undefined : () => onSelect(key)}
            >
              {disabled && key !== selected ? <span className="text-light">{options[key]}</span> : options[key]}
            </Button>
          );
        })}
      </ButtonGroup>
    );
  }
}
