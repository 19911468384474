import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft';

export function HeadlineBackButton({ onClick }: { onClick: () => void }) {
  return (
    <Button variant="link" onClick={onClick} className="mt-0 mb-0 me-1 px-2 py-1 fs-5 bg-white hover-shadow shadow-sm">
      <FontAwesomeIcon icon={faLongArrowLeft} />
    </Button>
  );
}
