import { EnumProperty, NameProperty, UnformattedTextProperty, UrlProperty, Uuid, UuidProperty } from '@edgebox/data-definition-kit';
import { ContentCol, HeaderCol } from '@edgebox/react-components';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import { Alert, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { AppLink, AppPage } from '../AppLink';
import { ParamsComponent } from '../ParamsComponent';

interface IProps {
  params: RegisterSiteParams;
  embedded?: boolean;
}

export class RegisterSiteParams {
  @NameProperty()
  name!: string;

  @EnumProperty(true, SiteApplicationType, 'SiteApplicationType')
  appType!: SiteApplicationType;

  @UnformattedTextProperty(true, 32)
  appVersion!: string;

  @UnformattedTextProperty(true, 32)
  appModuleVersion!: string;

  @UrlProperty(true, false, { allowLocalhost: true })
  baseUrl!: string;

  @UrlProperty(true, false, { allowLocalhost: true })
  redirectUrl!: string;

  @UuidProperty(false)
  uuid?: Uuid;

  @UnformattedTextProperty(false, 10_000)
  domains?: string;
}

function getDomains(baseUrl: string, domains?: string): string[] {
  let result = [baseUrl.replace(/^https?:\/\/([^/]+).*$/, '$1'), ...(domains ? domains.split(',') : [])];
  result = result.filter((c, i) => c && result.indexOf(c) === i);
  return result;
}

export class RegisterSiteWithParams extends React.Component<IProps, {}> {
  render() {
    const { params, embedded } = this.props;

    const domains = getDomains(params.baseUrl, params.domains);

    return (
      <>
        {!embedded && (
          <Alert variant={'light'}>
            <p>Welcome to Content Sync!</p>
            <p>Content Sync allows you to share content between multiple Drupal sites. To get started, please register your site below.</p>
            <p>If you don't have an account yet, just sign up and start your free trial- no credit card required.</p>
          </Alert>
        )}
        <Row>
          <HeaderCol xs={2}>Site name</HeaderCol>
          <ContentCol>{params.name}</ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Site base URL</HeaderCol>
          <ContentCol>
            <span>{params.baseUrl}</span>
            {domains.length > 1 && (
              <div className="text-warning">
                You will be invoiced for{' '}
                <OverlayTrigger
                  placement="left"
                  overlay={(props) => (
                    <Tooltip id={`tooltip-domains`} {...props} className="tooltip-wide">
                      <ul>
                        {domains.map((domain, index) => (
                          <li key={index}>{domain}</li>
                        ))}
                      </ul>
                    </Tooltip>
                  )}
                >
                  <span className="border-warning" style={{ cursor: 'help', borderBottom: '1px dashed #000' }}>
                    {domains.length} domains
                  </span>
                </OverlayTrigger>{' '}
                on this site.
              </div>
            )}
          </ContentCol>
        </Row>

        <AppLink page={AppPage.RegisterSite} queryParams={params as unknown as Record<string, string | undefined>}>
          <span id="register-site">Register</span>
        </AppLink>
      </>
    );
  }
}

export const RegisterSite = (props: Omit<IProps, 'params'>) => (
  <ParamsComponent<RegisterSiteParams> Params={RegisterSiteParams}>
    {(params: RegisterSiteParams) => {
      return <RegisterSiteWithParams params={params} {...props} />;
    }}
  </ParamsComponent>
);
