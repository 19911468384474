import { ExternalServiceId } from '@edgebox/data-definition-kit';
import { Right } from '@edgebox/react-components';
import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { CollapsibleContainer } from '../../../CollapsibleContainer';
import { RegisteredSite } from '../RegisteredSite';
import { RegisterSiteWithParams } from '../RegisterSite';
import { MigrateParams } from './MigrateParams';

enum MigrationConnectStep {
  RegisterSite,
  ExportPools,
  ExportFlows,
  Done,
}

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  activeStep?: MigrationConnectStep;
  site?: ClientSiteEntity;
  completed: MigrationConnectStep[];

  selectedPools?: ExternalServiceId[];
  selectedFlows?: ExternalServiceId[];

  submitted?: boolean;
}

export class MigrateConnect extends SyncCoreApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      completed: [],
    });
  }

  async load() {
    let activeStep: MigrationConnectStep = MigrationConnectStep.RegisterSite;
    const completed: MigrationConnectStep[] = [];
    let site: ClientSiteEntity | undefined = undefined;
    if (this.props.params.site.uuid) {
      try {
        site = await this.getCurrentSite();
        if (this.context.baseUrl === site?.baseUrl) {
          completed.push(MigrationConnectStep.RegisterSite);
          activeStep = MigrationConnectStep.ExportPools;
        }
      } catch (e) {}
    }

    const selectedPools: ExternalServiceId[] = this.props.params.pools.filter((c) => !c.exported).map((c) => c.machineName);
    if (!selectedPools.length) {
      completed.push(MigrationConnectStep.ExportPools);
      activeStep = MigrationConnectStep.ExportFlows;
    }

    const selectedFlows: ExternalServiceId[] = this.props.params.flows.filter((c) => !c.exported).map((c) => c.machineName);
    if (!selectedFlows.length) {
      completed.push(MigrationConnectStep.ExportFlows);
      activeStep = MigrationConnectStep.Done;
    }

    return {
      completed,
      activeStep,
      site,
      selectedPools,
      selectedFlows,
    };
  }

  render() {
    const { params } = this.props;
    const { activeStep, completed, site, selectedPools, selectedFlows, submitted } = this.state;

    if (activeStep === undefined) {
      return this.renderRequest();
    }

    // TODO: Add step to automatically split Flows. Keep the existing machine name for the PULL part as it's
    //  used by the old Sync Core to identify what "modes" match. Push can get a new suffix like "_push".

    return (
      <>
        <CollapsibleContainer
          name="Register site"
          status={completed.includes(MigrationConnectStep.RegisterSite) ? 'check' : undefined}
          defaultExpanded={activeStep === MigrationConnectStep.RegisterSite}
        >
          {site ? <RegisteredSite embedded /> : <RegisterSiteWithParams embedded params={params.site} />}
        </CollapsibleContainer>

        <CollapsibleContainer
          name="Export Pools"
          status={completed.includes(MigrationConnectStep.ExportPools) ? 'check' : undefined}
          defaultExpanded={activeStep === MigrationConnectStep.ExportPools}
          disabled={activeStep < MigrationConnectStep.ExportPools}
        >
          {params.pools.map((pool) => {
            const selected = !!selectedPools?.includes(pool.machineName);
            return (
              <div key={pool.machineName}>
                <Form.Check
                  type="checkbox"
                  inline
                  checked={selected}
                  label={pool.name}
                  id={`export-pool-${pool.machineName}`}
                  onChange={() =>
                    this.setState({
                      selectedPools: selected
                        ? selectedPools!.filter((c) => c !== pool.machineName)
                        : [...(selectedPools || []), pool.machineName],
                    })
                  }
                />
                {pool.exported && <FontAwesomeIcon icon={faCheckCircle} className={`text-success`} />}
              </div>
            );
          })}
          <Right className="me-0">
            <Button
              variant="primary"
              disabled={!selectedPools?.length || submitted || activeStep < MigrationConnectStep.ExportPools}
              onClick={() => {
                sendToParent({
                  type: 'migration-export-pools',
                  machineNames: selectedPools!,
                });
                this.setState({ submitted: true });
              }}
            >
              Continue
              {submitted && (
                <>
                  {' '}
                  <FontAwesomeIcon icon={faSpinner} spin className={`text-light`} />
                </>
              )}
            </Button>
          </Right>
        </CollapsibleContainer>

        <CollapsibleContainer
          name="Export Flows"
          status={completed.includes(MigrationConnectStep.ExportFlows) ? 'check' : undefined}
          defaultExpanded={activeStep === MigrationConnectStep.ExportFlows}
          disabled={activeStep < MigrationConnectStep.ExportFlows}
        >
          {params.flows.map((flow) => {
            const selected = !!selectedFlows?.includes(flow.machineName);
            return (
              <div key={flow.machineName}>
                <Form.Check
                  type="checkbox"
                  inline
                  checked={selected}
                  label={flow.name}
                  id={`export-flow-${flow.machineName}`}
                  onChange={() =>
                    this.setState({
                      selectedFlows: selected
                        ? selectedFlows!.filter((c) => c !== flow.machineName)
                        : [...(selectedFlows || []), flow.machineName],
                    })
                  }
                />
                {flow.exported && <FontAwesomeIcon icon={faCheckCircle} className={`text-success`} />}
              </div>
            );
          })}
          <Right className="me-0">
            <Button
              variant="primary"
              disabled={!selectedFlows?.length || submitted || activeStep < MigrationConnectStep.ExportFlows}
              onClick={() => {
                sendToParent({
                  type: 'migration-export-flows',
                  machineNames: selectedFlows!,
                });
                this.setState({ submitted: true });
              }}
            >
              Continue
              {submitted && (
                <>
                  {' '}
                  <FontAwesomeIcon icon={faSpinner} spin className={`text-light`} />
                </>
              )}
            </Button>
          </Right>
        </CollapsibleContainer>
      </>
    );
  }
}
