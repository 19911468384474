import { Right } from '@edgebox/react-components';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { CollapsibleContainer } from '../../../CollapsibleContainer';
import { FlowMigrationStatus, MigrateParams } from './MigrateParams';

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  completedFlows: string[];

  flows?: FlowMigrationStatus[];
  submitted?: boolean;
}

export class MigrateSwitch extends SyncCoreApiComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props, {
      completedFlows: [],
    });
  }

  async load() {
    const params = this.props.params;
    const flows = params.flows.filter((c) => !!c.types.find((c) => !!c.pushMode));
    flows.forEach((c) =>
      c.types.sort((a, b) => {
        return a.namespaceMachineName > b.namespaceMachineName
          ? 1
          : a.namespaceMachineName < b.namespaceMachineName
          ? -1
          : a.machineName > b.machineName
          ? 1
          : -1;
      })
    );

    return {
      flows,
    };
  }

  render() {
    const { params } = this.props;
    const { flows, submitted } = this.state;

    if (!flows) {
      return this.renderRequest();
    }

    return (
      <>
        <CollapsibleContainer defaultExpanded={true} name="Switch">
          {params.settings.switched ? (
            <Alert variant="success">
              You have successfuly switched to the new Sync Core. If you have pushed or pulled content with the old Sync Core while the
              migration was running, please go back to steps 3 and/or 4 and use the "Update" button at the Flows to push/map any changes
              that were processed by the old Sync Core in the meantime.
            </Alert>
          ) : (
            <>
              <Alert variant="warning">
                All new updates will be processed by the new Sync Core. <strong>There is no turning back</strong> so only use this after all
                previous migration steps have been finished successfully. If you were still using the previous Sync Core in the meantime,
                you can go back to steps 3 and 4 afterwards to update the new Sync Core with all entities that were changed in the meantime.
              </Alert>
              <Right className="m-0">
                <Button
                  variant="warning"
                  disabled={submitted}
                  onClick={() => {
                    sendToParent({ type: 'migration-switch' });
                    this.setState({ submitted: true });
                  }}
                >
                  Switch
                  {submitted && (
                    <>
                      {' '}
                      <FontAwesomeIcon icon={faSpinner} spin className={`text-light`} />
                    </>
                  )}
                </Button>
              </Right>
            </>
          )}
        </CollapsibleContainer>
      </>
    );
  }
}
