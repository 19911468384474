import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons/faAsterisk';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';
import { faListTree } from '@fortawesome/pro-light-svg-icons/faListTree';
import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faBlog } from '@fortawesome/pro-light-svg-icons/faBlog';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faCrop } from '@fortawesome/pro-light-svg-icons/faCrop';
import { faDirections } from '@fortawesome/pro-light-svg-icons/faDirections';
import { faEnvelopeOpenText } from '@fortawesome/pro-light-svg-icons/faEnvelopeOpenText';
import { faFileAlt } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faPaperclip } from '@fortawesome/pro-light-svg-icons/faPaperclip';
import { faParagraph } from '@fortawesome/pro-light-svg-icons/faParagraph';
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons/faPhotoVideo';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { faTh } from '@fortawesome/pro-light-svg-icons/faTh';
import { faUser } from '@fortawesome/pro-light-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { faBook } from '@fortawesome/pro-light-svg-icons/faBook';
import { faUserTag } from '@fortawesome/pro-light-svg-icons/faUserTag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { machineNameToName } from '../../Helpers';

export const ICON_PER_TYPE: { [key in SiteApplicationType]: { [key: string]: { [key: string]: IconDefinition } } } = {
  [SiteApplicationType.Drupal]: {
    entity_queue: {
      '*': faList,
    },
    entity_subqueue: {
      '*': faListTree,
    },
    node: {
      '*': faFileAlt,
    },
    group: {
      '*': faUsers,
    },
    group_content: {
      '*': faLink,
    },
    paragraph: {
      '*': faParagraph,
    },
    paragraphs_library_item: {
      '*': faParagraph,
    },
    bibcite_contributor: {
      '*': faUserTag,
    },
    bibcite_keyword: {
      '*': faTag,
    },
    bibcite_reference: {
      '*': faBook,
    },
    block_content: {
      '*': faSquare,
    },
    block: {
      '*': faCog,
    },
    brick: {
      '*': faTh,
    },
    field_collection_item: {
      '*': faLayerGroup,
    },
    taxonomy_term: {
      '*': faTag,
    },
    media: {
      '*': faPhotoVideo,
    },
    crop: {
      '*': faCrop,
    },
    file: {
      '*': faPaperclip,
    },
    menu_link_content: {
      '*': faBars,
    },
    webform: {
      '*': faEnvelopeOpenText,
    },
    redirect: {
      '*': faDirections,
    },
    config: {
      '*': faCog,
    },
    user: {
      '*': faUser,
    },
    path_alias: {
      '*': faAsterisk,
    },
  },
  [SiteApplicationType.Wordpress]: {
    post: {
      post: faBlog,
      page: faFileAlt,
    },
  },
};
export const DEFAULT_TYPE_ICON = faQuestion;

export function getEntityTypeIcon(
  appType: SiteApplicationType,
  namespaceMachineName: string,
  machineName: string,
  isConfiguration?: boolean
) {
  let icon = ICON_PER_TYPE[appType][namespaceMachineName]
    ? ICON_PER_TYPE[appType][namespaceMachineName][machineName] || ICON_PER_TYPE[appType][namespaceMachineName]['*']
    : undefined;
  let title: string | undefined = undefined;
  if (icon) {
    if (namespaceMachineName === 'user') {
      title =
        "For security reasons we don't push or pull Drupal user accounts. References to users will still be resolved by mapping them where possible.";
    } else if (namespaceMachineName === 'path_alias') {
      title = 'Path aliases will be pushed and pulled automatically when the content they reference is pushed or pulled.';
    }
  } else if (isConfiguration) {
    icon = ICON_PER_TYPE[appType].config['*'];
    title = "Content Sync doesn't support updating configuration.";
  } else {
    icon = DEFAULT_TYPE_ICON;
  }
  return { icon, title };
}

export function EntityTypeIcon({
  appType,
  namespaceMachineName,
  machineName,
  isConfiguration,
  useTitle,
  className,
}: {
  appType: SiteApplicationType;
  namespaceMachineName: string;
  machineName: string;
  isConfiguration?: boolean;
  useTitle?: string;
  className?: string;
}) {
  const { icon, title } = getEntityTypeIcon(appType, namespaceMachineName, machineName, isConfiguration);
  return (
    <FontAwesomeIcon
      title={typeof useTitle === 'string' ? (useTitle ? useTitle : undefined) : title || machineNameToName(namespaceMachineName)}
      icon={icon}
      className={className}
    />
  );
}
