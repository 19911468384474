import { ExternalServiceId } from '@edgebox/data-definition-kit';
import React from 'react';
import { countEntities, ListRequestMode } from '../frame-messages';

interface IProps {
  flowMachineName?: ExternalServiceId;
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  mode: ListRequestMode;
}
interface IState {
  count?: number;
}

const numberFormat = new Intl.NumberFormat();

export class CountSiteEntities extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { namespaceMachineName, machineName, flowMachineName, mode } = this.props;

    countEntities({
      flowMachineName,
      namespaceMachineName,
      machineName,
      mode,
    }).then((count) => this.setState({ count }));
  }

  render() {
    const { count } = this.state;

    if (count === undefined) {
      return '...';
    }

    return numberFormat.format(count);
  }
}
