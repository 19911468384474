import { InternalId } from '@edgebox/data-definition-kit';
import { ClientFlowEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  id: InternalId;
  light?: boolean;
  inline?: boolean;
}

interface IState extends ISyncCoreApiComponentState {
  flow?: ClientFlowEntity;
}

export class FlowName extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { id } = this.props;

    const flow = await this.api.syndication.flows.item(id);

    return {
      flow,
    };
  }

  render() {
    const { light, inline } = this.props;

    const { flow } = this.state;
    if (flow) {
      if (inline) {
        return flow.name || '';
      }
      return <span className="text-truncate d-inline-block mw-100">{flow.name}</span>;
    }

    return <FontAwesomeIcon icon={faSpinner} spin className={`text-${light ? 'light' : 'dark'}`} style={{ fontSize: '1.4em' }} />;
  }
}
