import { SyndicationStatus } from '@edgebox/sync-core-data-definitions';

export const REFRESH_INTERVAL_RUNNING = 5_000;

export const STATUS_FILTERS = {
  all: undefined,
  running: [SyndicationStatus.Initializing, SyndicationStatus.Running, SyndicationStatus.Retrying],
  finished: [SyndicationStatus.Finished],
  aborted: [SyndicationStatus.Aborted],
  failed: [SyndicationStatus.Failed, SyndicationStatus.LimitExceeded],
};
export type StatusFilter = 'all' | 'running' | 'finished' | 'aborted' | 'failed';
export type StatusFilterOrMassUpdates = StatusFilter | 'mass-updates';

// A bug in the Sync Core lead to these numbers.
export const INVALID_FULL_DURATION = 25 * 60 * 60 * 1_000;
