import {
  BooleanProperty,
  EnumProperty,
  ExternalServiceId,
  NestedProperty,
  UnformattedTextProperty,
  UrlProperty,
  Uuid,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import { FlowSyndicationMode } from '@edgebox/sync-core-data-definitions';
import { RegisterSiteParams } from '../RegisterSite';

export class PoolMigrationStatus {
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;

  @UnformattedTextProperty(true, 200)
  name!: string;

  @BooleanProperty(true)
  exported!: boolean;
}

export class FlowEntityTypeEntityMigrationStatus {
  @UuidProperty(false)
  remoteUuid?: Uuid;

  @UnformattedTextProperty(false, 200)
  remoteUniqueId?: ExternalServiceId;

  @BooleanProperty(true)
  verified!: boolean;
}

export class FlowEntityTypeMigrationStatus {
  @UnformattedTextProperty(true, 200)
  namespaceMachineName!: ExternalServiceId;

  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;

  @UnformattedTextProperty(true, 200)
  versionId!: string;

  @EnumProperty(false, FlowSyndicationMode, 'FlowSyndicationMode')
  pushMode?: FlowSyndicationMode;

  @EnumProperty(false, FlowSyndicationMode, 'FlowSyndicationMode')
  pullMode?: FlowSyndicationMode;

  @NestedProperty(false, () => FlowEntityTypeEntityMigrationStatus)
  pushedEntity?: FlowEntityTypeEntityMigrationStatus;

  @NestedProperty(false, () => FlowEntityTypeEntityMigrationStatus)
  pulledEntity?: FlowEntityTypeEntityMigrationStatus;

  @BooleanProperty(true)
  skipTest!: boolean;
}

export class FlowMigrationStatus {
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;

  @UnformattedTextProperty(true, 200)
  name!: string;

  @BooleanProperty(true)
  exported!: boolean;

  @BooleanProperty(true)
  active!: boolean;

  @BooleanProperty(true)
  skipTest!: boolean;

  @BooleanProperty(true)
  skipPush!: boolean;

  @BooleanProperty(true)
  skipPull!: boolean;

  @NestedProperty(true, () => FlowEntityTypeMigrationStatus, { each: true })
  types!: FlowEntityTypeMigrationStatus[];
}

export class MigrateParamsSettings {
  @BooleanProperty(true)
  mustEnableHealthSubmodule!: boolean;

  @BooleanProperty(true)
  switched!: boolean;

  @UrlProperty(true, false, { allowLocalhost: true })
  pushToV2Url!: string;

  @UrlProperty(false, false, { allowLocalhost: true })
  pullManuallyFromV2Url?: string;
}

export class MigrateParams {
  @NestedProperty(true, () => RegisterSiteParams)
  site!: RegisterSiteParams;

  @NestedProperty(true, () => PoolMigrationStatus, { each: true })
  pools!: PoolMigrationStatus[];

  @NestedProperty(true, () => FlowMigrationStatus, { each: true })
  flows!: FlowMigrationStatus[];

  @NestedProperty(true, () => MigrateParamsSettings)
  settings!: MigrateParamsSettings;
}
