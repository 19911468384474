import { InternalId, Uuid } from '@edgebox/data-definition-kit';
import { ButtonLink } from '@edgebox/react-components';
import React, { Children } from 'react';

export enum AppPage {
  RegisterSite = 'sites/register',
}

interface IProps {
  page: AppPage;
  id?: string;
  children?: React.ReactNode;
  queryParams?: any;
}

function cleanUndefined(props: Record<string, string | undefined>): Record<string, string> {
  return Object.entries(props)
    .filter(([name, value]) => value !== undefined && value !== null)
    .reduce((a, [name, value]) => ({ ...a, [name]: value }), {});
}

interface IRegisterSiteProps {
  page: AppPage;
  queryParams: Record<string, string | undefined>;
  children?: React.ReactNode;
}

type IAnyProps = IRegisterSiteProps;

export function getAppLinkForTask(syndicationId: InternalId, siteUuid: Uuid) {
  return `https://${process.env.REACT_APP_APP_DOMAIN}/redirect/task/${syndicationId}/site/${siteUuid}`;
}
export function getAppLinkForContent(contentUuid: Uuid, siteUuid: Uuid) {
  return `https://${process.env.REACT_APP_APP_DOMAIN}/redirect/content/${contentUuid}/site/${siteUuid}`;
}

export class AppLink extends React.Component<IAnyProps, {}> {
  render() {
    const { children, page, id, queryParams } = this.props as IProps;

    let path: string = page;
    if (id) {
      path = path.replace(/:id/, id);
    }

    if (queryParams) {
      const cleanParams = cleanUndefined(queryParams);
      const keys = Object.keys(cleanParams);
      path += '?' + keys.map((k) => `${k}=${encodeURIComponent(cleanParams[k])}`).join('&');
    }

    return (
      <ButtonLink variant={'primary'} to={`//${process.env.REACT_APP_APP_DOMAIN}/${path}`} target={'_top'}>
        {children}
      </ButtonLink>
    );
  }
}

const CREATE_SUPPORT_TICKET_URL = process.env.REACT_APP_CREATE_SUPPORT_TICKET_URL;
export function WithCreateSupportTicketUrl({
  taskUrl,
  subject,
  children,
}: {
  taskUrl?: string;
  subject?: string;
  children: (createSupportTicketUrl: string) => React.ReactElement;
}): React.ReactElement | null {
  if (!CREATE_SUPPORT_TICKET_URL) {
    return null;
  }

  return children(
    CREATE_SUPPORT_TICKET_URL.replace(/\[task_url\]/g, taskUrl ? encodeURIComponent(taskUrl) : '').replace(
      /\[subject\]/g,
      subject ? encodeURIComponent(subject) : ''
    )
  );
}
