import { ExternalLink } from '@edgebox/react-components';
import { EntityRemoteStatus } from '@edgebox/sync-core-data-definitions';
import { ClientRemoteEntityUsageEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';

interface IProps {
  entity: ClientRemoteEntityUsageEntity;
  viewLabel?: React.ReactNode;
  noIcon?: boolean;
}

export class UsageSummary extends React.Component<IProps, {}> {
  render() {
    const { entity, viewLabel, noIcon } = this.props;

    if (entity.status !== EntityRemoteStatus.Exists) {
      return <Badge bg="danger">Deleted</Badge>;
    }

    if (noIcon) {
      return <ExternalLink to={entity.viewUrl}>{viewLabel || 'View'}</ExternalLink>;
    }

    return <ExternalLinkWithIcon to={entity.viewUrl}>{viewLabel || 'View'}</ExternalLinkWithIcon>;
  }
}
