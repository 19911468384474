import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

const CustomToggle = React.forwardRef(({ children, onClick }: { children: React.ReactNode; onClick: (e: any) => void }, ref: any) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className={'btn btn-light bg-none color-dark'}
  >
    {children}
  </a>
));

export function MenuButton({ children, onClick }: { onClick: () => void; children: React.ReactNode }) {
  return (
    <Button variant="link" className="text-muted px-3 py-2 d-block w-100 text-start text-decoration-none fs-5" onClick={onClick}>
      {children}
    </Button>
  );
}

export function KebabMenu({ children }: { children: React.ReactNode }) {
  return (
    <Dropdown className="">
      <Dropdown.Toggle as={CustomToggle} variant="light" id="dropdown-actions" className="border-none bg-none color-dark">
        <FontAwesomeIcon icon={faEllipsisV} size="2x" />
      </Dropdown.Toggle>

      {children}
    </Dropdown>
  );
}
