import { Right } from '@edgebox/react-components';
import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { EmbeddedModal } from '../../../EmbeddedModal';
import { MigrateFlow } from './MigrateFlow';
import { FlowMigrationStatus, MigrateParams } from './MigrateParams';

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  flows?: FlowMigrationStatus[];
  wantsToSkip?: boolean;
  submitting?: boolean;
}

export class MigratePush extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const params = this.props.params;
    const flows = params.flows.filter((c) => !!c.types.find((c) => !!c.pushMode));
    flows.forEach((c) =>
      c.types.sort((a, b) => {
        return a.namespaceMachineName > b.namespaceMachineName
          ? 1
          : a.namespaceMachineName < b.namespaceMachineName
          ? -1
          : a.machineName > b.machineName
          ? 1
          : -1;
      })
    );

    return {
      flows,
    };
  }

  render() {
    const { params } = this.props;
    const { flows, submitting, wantsToSkip } = this.state;

    if (!flows) {
      return this.renderRequest();
    }

    if (!flows.length) {
      return <Alert variant="dark">You have not configured any Flow to push content.</Alert>;
    }

    const allSkipped = !flows.find((c) => !c.skipPush);

    return (
      <>
        <Alert variant="info">
          The entities pushed here will <strong>not</strong> trigger updates on other sites. This is only used for indexing all your
          existing content that you had already pushed to the v1 Sync Core.
        </Alert>
        {flows.map((flow) => (
          <MigrateFlow key={flow.machineName} flow={flow} params={params} push />
        ))}
        {!allSkipped && (
          <Right className="m-0 mt-3">
            <Button variant="danger" onClick={() => this.setState({ wantsToSkip: true })} disabled={submitting || wantsToSkip}>
              Skip all Flows
            </Button>
          </Right>
        )}
        {wantsToSkip && (
          <EmbeddedModal show={true} onHide={() => this.setState({ wantsToSkip: undefined })} size="xl" scrollable>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This will mark all Flows with all their entity types as <em>skipped</em> so you can continue to the next migration step.
              <br />
              <br />
              You can still come back later and continue pushing.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                className="fw-bold"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    submitting: true,
                  });

                  sendToParent({
                    type: 'migration-skip-flows-push',
                    machineNames: flows.map((c) => c.machineName),
                  });
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </EmbeddedModal>
        )}
      </>
    );
  }
}
