import { ClientMigrationEntityWithSummary, ClientMigrationSummary } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { FlowEntityTypeMigrationStatus, FlowMigrationStatus } from '../components/Customer/Embed/Migrate/MigrateParams';

export interface IMigrationItem {
  flow: FlowMigrationStatus;
  type: FlowEntityTypeMigrationStatus;
  latest?: ClientMigrationEntityWithSummary;
  totalSummary: ClientMigrationSummary[];
  all: ClientMigrationEntityWithSummary[];
  isDone: boolean;
}
export interface IMigrationContextValue {
  pushMigrations: IMigrationItem[];
  pushDone: boolean;
  pullMigrations: IMigrationItem[];
  pullDone: boolean;
  update: (latest: ClientMigrationEntityWithSummary) => void;
}
export const MigrationContext: React.Context<IMigrationContextValue | undefined> = React.createContext<IMigrationContextValue | undefined>(
  undefined
);
