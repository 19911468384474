import React, { ReactNode } from 'react';
import { Form } from 'react-bootstrap';

interface IProps<Key extends string, Value = string> {
  options: { [key in Key]: Value };
  className?: string;
  selected?: Key;
  disabled?: boolean;
  onSelect: (key: Key) => void;
}

interface IState {}

export class Radios<Key extends string, Value extends ReactNode = string> extends React.Component<IProps<Key, Value>, IState> {
  render() {
    const { options, selected, onSelect, disabled, className } = this.props;

    return (
      <div className={className}>
        {Object.keys(options).map((anyKey: string) => {
          const key = anyKey as Key;
          return (
            <Form.Check
              key={key}
              style={{ height: '1.5rem' }}
              className={`align-self-center mx-0 my-1 cursor-pointer ${key === selected ? 'fw-bold' : ''}`}
              id={`option-${key}`}
              checked={key === selected}
              type={'radio'}
              label={<div className="cursor-pointer">{options[key]}</div>}
              onClick={disabled ? undefined : () => onSelect(key)}
              disabled={disabled}
            />
          );
        })}
      </div>
    );
  }
}
