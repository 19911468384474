import { LeftRightH2, LeftRightH3 } from '@edgebox/react-components';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Collapse } from 'react-bootstrap';

interface IProps {
  name: React.ReactNode;
  status?: 'check' | React.ReactNode;
  defaultExpanded?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  level?: number;
}
interface IState {
  expanded: boolean;
}

export class CollapsibleContainer extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      expanded: !!props.defaultExpanded,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (prevProps.defaultExpanded !== this.props.defaultExpanded && this.state.expanded !== this.props.defaultExpanded) {
      this.setState({
        expanded: !!this.props.defaultExpanded,
      });
    }
  }

  render() {
    let { status } = this.props;
    const { name, children, disabled, level } = this.props;
    const { expanded } = this.state;

    if (status === 'check') {
      status = <FontAwesomeIcon icon={faCheckCircle} className={`text-success`} style={{ fontSize: '1em' }} />;
    }

    const Component = !level ? LeftRightH2 : LeftRightH3;

    return (
      <div className="rounded border border-1 border-light p-3 mt-4">
        <div className="cursor-pointer" onClick={() => this.setState({ expanded: !expanded })}>
          <Component
            left={
              <>
                <span className={disabled ? 'text-light' : ''}>{name}</span> <span>{status}</span>
              </>
            }
            right={<FontAwesomeIcon icon={expanded ? faMinus : faPlus} className={`text-light`} style={{ fontSize: '1em' }} />}
          />
        </div>

        <Collapse in={expanded}>
          <div className="pt-3">{children}</div>
        </Collapse>
      </div>
    );
  }
}
