import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { getStyleColors } from '../Helpers';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  isClearable?: boolean;
  selectedSite?: ClientSiteEntity;
  onSelect: (selectedSite?: ClientSiteEntity) => void;
  width?: string;
}
interface IState extends ISyncCoreApiComponentState {}

export class SelectSite extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    return {};
  }

  render() {
    const { selectedSite, onSelect, isClearable, width } = this.props;

    const { primary, danger } = getStyleColors();

    return (
      <AsyncPaginate
        isClearable={isClearable}
        loadOptions={async (search, lastOptions, additional: any) => {
          const sites = await this.api.billing.sites.list({ search }, { page: additional ? additional.page - 1 : 0 });

          return {
            options: sites.items,
            hasMore: sites.page < sites.numberOfPages - 1,
            additional: { page: sites.page },
          };
        }}
        placeholder="Filter by site..."
        value={selectedSite}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        onChange={(option) => onSelect(option || undefined)}
        styles={{
          container: (base) => ({
            ...base,
            width,
            display: 'inline-block',
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary,
            danger,
          },
        })}
      />
    );
  }
}
