import DOMPurify from 'dompurify';
import React from 'react';

interface IProps {
  dangerousHtml: string;
  renderer?: {
    [selector: string]: (element: Element, index: number, numberOfItems: number) => void;
  };
}

export class UserHtml extends React.Component<IProps, {}> {
  constructor(props: IProps) {
    super(props);

    this.ref = React.createRef<HTMLDivElement>();
  }

  protected ref: React.RefObject<HTMLDivElement>;

  componentDidUpdate() {
    this.updateDom();
  }

  componentDidMount() {
    this.updateDom();
  }

  updateDom() {
    if (!this.ref.current) {
      return;
    }

    const links = this.ref.current.querySelectorAll('a');
    for (const link of links) {
      if (link.target !== '_top' && link.target !== '_blank') {
        link.target = '_top';
      }
    }

    if (this.props.renderer) {
      Object.entries(this.props.renderer).forEach(([selector, clb]) => {
        const items = Array.from(this.ref.current!.querySelectorAll(selector));
        items.forEach((item, index) => clb(item, index, items.length));
      });
    }
  }

  render() {
    const { dangerousHtml } = this.props;

    const clean = DOMPurify.sanitize(dangerousHtml);

    return <div className="user-html" ref={this.ref} dangerouslySetInnerHTML={{ __html: clean }} />;
  }
}
