import { InternalId } from '@edgebox/data-definition-kit';
import { faTrafficLight } from '@fortawesome/pro-light-svg-icons/faTrafficLight';
import { faFlaskVial } from '@fortawesome/pro-light-svg-icons/faFlaskVial';
import { faComputer } from '@fortawesome/pro-light-svg-icons/faComputer';
import { faIndustryWindows } from '@fortawesome/pro-light-svg-icons/faIndustryWindows';
import { SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { SyncCoreApiContext } from '../contexts/SyncCoreApiContext';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Nugget } from './Nugget';
import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';

export const SITE_ENVIRONMENT_TYPE_ICONS = {
  [SiteEnvironmentType.Local]: faComputer,
  [SiteEnvironmentType.Testing]: faFlaskVial,
  [SiteEnvironmentType.Staging]: faTrafficLight,
  [SiteEnvironmentType.Production]: faIndustryWindows,
};
export function SiteEnvironmentIcon({ type, ...rest }: Omit<FontAwesomeIconProps, 'icon'> & { type: SiteEnvironmentType }) {
  return <FontAwesomeIcon {...rest} icon={SITE_ENVIRONMENT_TYPE_ICONS[type]} />;
}
export function EnvironmentIconForSite({
  siteId,
  fallbackIcon,
  ...rest
}: Omit<FontAwesomeIconProps, 'icon'> & { siteId: InternalId; fallbackIcon: IconProp }) {
  const api = useContext(SyncCoreApiContext);
  const [siteEnvironmentType, setSiteEnvironmentType] = useState<SiteEnvironmentType | undefined>();

  useEffect(() => {
    api?.api.billing.sites.item(siteId).then((site) => setSiteEnvironmentType(site.environmentType));
  }, [api, siteId]);

  if (!siteEnvironmentType) {
    return <FontAwesomeIcon {...rest} icon={fallbackIcon} />;
  }

  return <SiteEnvironmentIcon {...rest} type={siteEnvironmentType} />;
}
export function EnvironmentIconForSiteAsNugget({
  siteId,
  fallbackIcon,
  children,
  dark,
}: { siteId: InternalId; fallbackIcon: IconProp; dark?: boolean } & PropsWithChildren) {
  const api = useContext(SyncCoreApiContext);
  const [site, setSite] = useState<ClientSiteEntity | undefined>();

  useEffect(() => {
    setSite(undefined);
    api?.api.billing.sites.item(siteId).then((site) => setSite(site));
  }, [api, siteId]);

  if (!site) {
    return (
      <Nugget dark={dark} icon={fallbackIcon}>
        {children}
      </Nugget>
    );
  }

  return (
    <Nugget dark={dark} icon={SITE_ENVIRONMENT_TYPE_ICONS[site.environmentType]}>
      {children ?? site.name}
    </Nugget>
  );
}
