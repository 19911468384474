import { SyncCoreDataDefinitionsEnumTranslator, SyndicationErrorType, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import React from 'react';
import Badge from 'react-bootstrap/Badge';

interface IProps {
  status: SyndicationStatus;
  errorType?: SyndicationErrorType;
  size?: 'small';
  className?: string;
}

export class SyndicationStatusBadge extends React.Component<IProps, {}> {
  render() {
    const { status, size, errorType, className } = this.props;

    const classes = `${size ? 'small' : ''} ${className || ''}`;

    const variants = {
      [SyndicationStatus.Initializing]: 'light',
      [SyndicationStatus.Running]: 'light',
      [SyndicationStatus.Retrying]: 'warning',
      [SyndicationStatus.Finished]: 'success',
      [SyndicationStatus.Failed]: 'danger',
      [SyndicationStatus.Aborted]: 'dark',
      [SyndicationStatus.LimitExceeded]: 'danger',
    };

    let title = SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SyndicationStatus', status);

    const mustPoll = status === SyndicationStatus.Retrying && errorType === SyndicationErrorType.SiteMustPoll;
    if (mustPoll) {
      title = 'Site must poll';
    }

    return (
      <Badge bg={mustPoll ? 'light' : variants[status]} className={classes}>
        {title}
      </Badge>
    );
  }
}
