import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

export function StepCircle({
  index,
  active,
  warning,
  enabled,
  onSelect,
}: {
  index: number;
  active: boolean;
  warning?: boolean;
  enabled: boolean;
  onSelect: () => void;
}) {
  const showIcon = warning;

  return (
    <span
      className={`${
        active ? 'bg-primary text-white' : enabled ? 'text-primary cursor-pointer border border-2 border-primary' : 'bg-light text-dark'
      } text-center ms-2 ${showIcon ? 'px-3' : ''}`}
      style={{ minWidth: '40px', height: '40px', lineHeight: '40px', fontSize: '24px', display: 'inline-block', borderRadius: '20px' }}
      onClick={enabled ? onSelect : undefined}
    >
      {index}
      {showIcon && (
        <>
          {' '}
          <FontAwesomeIcon icon={faExclamationTriangle} className={active ? 'text-light' : 'text-warning'} />
        </>
      )}
    </span>
  );
}
