import { InternalId } from '@edgebox/data-definition-kit';
import React from 'react';
import ReactDiffViewer from 'react-diff-viewer';
import SyntaxHighlighter from 'react-syntax-highlighter/dist/esm/default-highlight';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';

interface IProps {
  id: InternalId;
  comparisonId?: InternalId;
  structure?: 'plain' | 'pretty';
  values?: 'plain' | 'pretty';
}

interface IState extends ISyncCoreApiComponentState {
  serialized?: string;
  comparisonSerialized?: string;
}

export class SerializedEntityRevision extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { id, comparisonId, structure, values } = this.props;
    const options = { structure, values };
    const serialized = await this.api.syndication.remoteEntityRevisions.serialize(id, options);
    const comparisonSerialized = comparisonId
      ? await this.api.syndication.remoteEntityRevisions.serialize(comparisonId, options)
      : undefined;

    return {
      serialized,
      comparisonSerialized,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (
      this.props.id !== prevProps.id ||
      this.props.comparisonId !== prevProps.comparisonId ||
      this.props.structure !== prevProps.structure ||
      this.props.values !== prevProps.values
    ) {
      this.setState({
        serialized: undefined,
        comparisonSerialized: undefined,
      });
      this.load();
    }
  }

  render() {
    const { serialized, comparisonSerialized } = this.state;

    if (!serialized) {
      return this.renderRequest();
    }

    if (comparisonSerialized) {
      return <ReactDiffViewer oldValue={comparisonSerialized} newValue={serialized} />;
    }

    return <SyntaxHighlighter language="yaml">{serialized}</SyntaxHighlighter>;
  }
}
