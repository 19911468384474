import {
  AnyProperty,
  BooleanProperty,
  ExternalServiceId,
  NestedProperty,
  UnformattedTextProperty,
  Uuid,
} from '@edgebox/data-definition-kit';
import { ContentCol, EditButton, HeaderCol, InfoBox, InfoIcon, LeftRightContainer, LeftRightH1, Right } from '@edgebox/react-components';
import { Package, Product, SiteApplicationType, SiteEnvironmentType } from '@edgebox/sync-core-data-definitions';
import { ClientContractRevisionEntity, ClientPoolEntity, ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';
import { faDownload } from '@fortawesome/pro-light-svg-icons/faDownload';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faGlobe } from '@fortawesome/pro-light-svg-icons/faGlobe';
import { faKey } from '@fortawesome/pro-light-svg-icons/faKey';
import { faObjectGroup } from '@fortawesome/pro-light-svg-icons/faObjectGroup';
import { faObjectUngroup } from '@fortawesome/pro-light-svg-icons/faObjectUngroup';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faUpload } from '@fortawesome/pro-light-svg-icons/faUpload';
import { faArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { DragDropContext, Draggable, DraggableProvided, Droppable } from 'react-beautiful-dnd';
import { Alert, Badge, Button, Col, Dropdown, Form, Image, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { scrollToTop, sendToParent } from '../../../frame-messages';
import PullContentImage from '../../../images/undraw_mailbox_re_dvds.svg';
import PushContentImage from '../../../images/undraw_subscriber_re_om92.svg';
import ContentStageImage from '../../../images/undraw_typewriter_re_u9i2.svg';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { CollapsibleContainer } from '../../CollapsibleContainer';
import { EmbeddedModal } from '../../EmbeddedModal';
import { HelpLink } from '../../HelpLink';
import { IconWithCount } from '../../IconWithCount';
import { SelectEntityFromSite } from '../../SelectEntityFromSite';
import { StepCircle } from '../../StepCircle';
import { SwitchButton } from '../../SwitchButton';
import { ParamsComponent } from '../ParamsComponent';
import { machineNameToName, nameToMachineName } from '../../../Helpers';
import { EntityTypeIcon } from '../EntityTypeIcon';
import { Radios } from '../../Radios';

const HELP_POOLS = 'https://support.content-sync.io/support/solutions/articles/80000811215-pools-categorize-your-content';
const HELP_OVERRIDE_CONTENT = 'https://support.content-sync.io/support/solutions/articles/80000811238-override-content-locally';
const HELP_SUPPORTED_ENTITY_TYPES_AND_FIELDS =
  'https://support.content-sync.io/support/solutions/articles/80000811247-supported-entities-fields-and-contrib-modules';

const TYPE_PUSH = 'push';
const TYPE_PULL = 'pull';
const DEFAULT_NAME_PUSH = 'Push';
const DEFAULT_NAME_PULL = 'Pull';

const ASSIGN_ALL_POOLS = 'force';
const ASSIGN_POOLS_MANUALLY = 'allow';

const MODE_AUTOMATICALLY = 'automatically';
const MODE_MANUALLY = 'manually';
const MODE_DEFAULT = 'default';
const MODE_DEPENDENT = 'dependency';
const MODE_IGNORE = 'disabled';

const USER_REFERENCE_BY_NAME = 'name';
const USER_REFERENCE_BY_EMAIL = 'mail';
const USER_REFERENCE_ASSIGN_CONTENT_SYNC_USER = 'sync_user';

const POOL_WIDGET_CHECKBOXES = 'checkboxes';
const POOL_WIDGET_RADIO_BOXES = 'radios';
const POOL_WIDGET_SINGLE_SELECT = 'single_select';
const POOL_WIDGET_MULTI_SELECT = 'multi_select';

const PULL_UPDATE_NEVER = 'ignore';
const PULL_UPDATE_FORBID_EDITING = 'force_and_forbid_editing';
const PULL_UPDATE_UNLESS_OVERRIDDEN = 'allow_override';
const PULL_UPDATE_CREATE_UNPUBLISHED_REVISION = 'pull_update_unpublished';
const PULL_UPDATE_FORCE = 'force';

class EntityType {
  @UnformattedTextProperty(false, 200)
  machineName?: ExternalServiceId;
  @UnformattedTextProperty(false, 200)
  namespaceMachineName?: ExternalServiceId;
}

class ExistingEntityType {
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;
  @UnformattedTextProperty(true, 200)
  namespaceMachineName!: ExternalServiceId;
}

class BundleSettingsFilter {
  @UnformattedTextProperty(false, 200)
  type!: 'includes-reference' | 'menu';
}

class BundleSettingsFilterValue extends ExistingEntityType {
  @UnformattedTextProperty(true, 200)
  remoteUuid!: Uuid;
}
class BundleSettingsReferenceFilter extends BundleSettingsFilter {
  type!: 'includes-reference';
  @UnformattedTextProperty(false, 200)
  fieldMachineName!: ExternalServiceId;
  @NestedProperty(false, () => BundleSettingsFilterValue, { each: true })
  values!: BundleSettingsFilterValue[];
}

class BundleSettingsMenuFilter extends BundleSettingsFilter {
  type!: 'menu';
  @UnformattedTextProperty(true, 200, { each: true })
  values!: string[];
}

class BundleSettings {
  @UnformattedTextProperty(false, 200)
  mode!: typeof MODE_DEFAULT | typeof MODE_DEPENDENT | typeof MODE_IGNORE;

  @UnformattedTextProperty(false, 200)
  previewViewMode?: string;

  @NestedProperty(false, () => BundleSettingsFilter, { each: true })
  filters?: (BundleSettingsReferenceFilter | BundleSettingsMenuFilter)[];
}

class EntityTypeSettings {
  @NestedProperty(false, () => BundleSettings)
  allBundles?: BundleSettings;

  @AnyProperty(false)
  perBundle?: { [name in string]: BundleSettings };
}

class FormValues {
  // Base config information
  @UnformattedTextProperty(false, 200)
  name?: string;

  @UnformattedTextProperty(false, 200)
  machineName?: ExternalServiceId;

  // Base Flow settings
  @UnformattedTextProperty(false, 10)
  type?: typeof TYPE_PUSH | typeof TYPE_PULL;

  @UnformattedTextProperty(false, 200, { each: true })
  pools?: string[];

  @UnformattedTextProperty(false, 200)
  poolAssignment?: typeof ASSIGN_ALL_POOLS | typeof ASSIGN_POOLS_MANUALLY;

  @UnformattedTextProperty(false, 200)
  mode?: typeof MODE_AUTOMATICALLY | typeof MODE_MANUALLY;

  @BooleanProperty(false)
  deletions?: boolean;

  @BooleanProperty(false)
  embeddedDeletions?: boolean;

  @UnformattedTextProperty(false, 200)
  updateBehavior?:
    | typeof PULL_UPDATE_NEVER
    | typeof PULL_UPDATE_FORBID_EDITING
    | typeof PULL_UPDATE_UNLESS_OVERRIDDEN
    | typeof PULL_UPDATE_CREATE_UNPUBLISHED_REVISION
    | typeof PULL_UPDATE_FORCE;

  // Settings per entity type / bundle
  @AnyProperty(false)
  entityTypeSettings?: { [name in string]: EntityTypeSettings };

  // Advanced settings
  @BooleanProperty(false)
  ignoreUnpublishedChanges?: boolean;
  @BooleanProperty(false)
  allowExplicitUnpublishing?: boolean;
  @BooleanProperty(false)
  pushMenuItems?: boolean;
  @BooleanProperty(false)
  pushPreviews?: boolean;
  @BooleanProperty(false)
  mergeLocalChanges?: boolean;
  @BooleanProperty(false)
  allowLocalDeletion?: boolean;
  @BooleanProperty(false)
  allowCrossSync?: boolean;

  @UnformattedTextProperty(false, 200)
  resolveUserReferences?: typeof USER_REFERENCE_BY_NAME | typeof USER_REFERENCE_BY_EMAIL | typeof USER_REFERENCE_ASSIGN_CONTENT_SYNC_USER;

  @UnformattedTextProperty(false, 200)
  poolSelectionWidget?:
    | typeof POOL_WIDGET_CHECKBOXES
    | typeof POOL_WIDGET_RADIO_BOXES
    | typeof POOL_WIDGET_SINGLE_SELECT
    | typeof POOL_WIDGET_MULTI_SELECT;

  @UnformattedTextProperty(false, 200, { each: true })
  assignPoolsManuallyToDependencies?: string[];

  @UnformattedTextProperty(false, 200, { each: true })
  languages?: ExternalServiceId[];

  @BooleanProperty(false)
  simplifyEntityTypeSettings?: boolean;
}

class Pool {
  @UnformattedTextProperty(true, 200)
  name!: string;
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;
}

class ReferenceField {
  @UnformattedTextProperty(true, 200)
  name!: string;
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;
  @UnformattedTextProperty(true, 200)
  targetNamespaceMachineName!: ExternalServiceId;
  @UnformattedTextProperty(false, 200, { each: true })
  targetMachineNames?: ExternalServiceId[];
}

class EntityTypeBundle {
  @UnformattedTextProperty(true, 200)
  name!: string;
  @UnformattedTextProperty(true, 200)
  namespaceMachineName!: ExternalServiceId;
  @UnformattedTextProperty(true, 200)
  machineName!: ExternalServiceId;
  @BooleanProperty(true)
  supported!: boolean;
  @BooleanProperty(false)
  isConfiguration?: boolean;
  @AnyProperty(false)
  viewModes?: { [key: string]: string };
  @UnformattedTextProperty(true, 200, { each: true })
  unsupportedFields!: string[];
  @NestedProperty(true, () => ReferenceField, { each: true })
  referenceFields!: ReferenceField[];
  @AnyProperty(false)
  menus?: { [key: string]: string };
}

class LanguageDefinition {
  @UnformattedTextProperty(true, 200)
  id!: ExternalServiceId;
  @UnformattedTextProperty(true, 200)
  name!: string;
}

class FlowFormParams {
  @NestedProperty(true, () => FormValues)
  values?: FormValues;
  @NestedProperty(true, () => Pool, { each: true })
  pools!: Pool[];
  @NestedProperty(true, () => EntityTypeBundle, { each: true })
  bundles!: EntityTypeBundle[];
  @UnformattedTextProperty(true, 200, { each: true })
  reservedFlowMachineNames!: ExternalServiceId[];
  @NestedProperty(true, () => EntityType, { each: true })
  pushedEntityTypes!: EntityType[];
  @NestedProperty(true, () => EntityType, { each: true })
  pulledEntityTypes!: EntityType[];
  @UnformattedTextProperty(true, 200, { each: true })
  pushedPools!: string[];
  @UnformattedTextProperty(true, 200, { each: true })
  pulledPools!: string[];
  @NestedProperty(false, () => LanguageDefinition, { each: true })
  languages?: LanguageDefinition[];
}

interface IProps {
  params: FlowFormParams;
}

const STEP_GENERAL_SETTINGS = 0;
const STEP_ENTITY_TYPE_SETTINGS = 1;
const STEP_REVIEW = 2;
const ALL_STEPS = [STEP_GENERAL_SETTINGS, STEP_ENTITY_TYPE_SETTINGS, STEP_REVIEW];
interface IState extends ISyncCoreApiComponentState {
  values?: FormValues;
  showAdvanced?: boolean;
  showAllBundles?: string[];
  editMachineName?: boolean;
  showUnsupportedEntityTypes?: boolean;
  currentStep?: number;
  isDragging?: boolean;
  draggedItemId?: string;
  contractRevision?: ClientContractRevisionEntity;
  fixedType?: boolean;
  showSavingPopup?: boolean;
  saving?: boolean;
  addPool?: Pool;
  editPoolMachineName?: boolean;
  pools?: Pool[];
  allRemotePools?: ClientPoolEntity[];
  newBundles?: EntityType[];
  setReferenceFilter?: ExistingEntityType;
  setMenuFilter?: ExistingEntityType;
  site?: ClientSiteEntity;
  simpleSetup?: boolean;
}

const HEADER_COL_SIZE = 3;
const DEFAULT_MODE_INDEPENDENT_TYPES: { [key in SiteApplicationType]: ExternalServiceId[] } = {
  [SiteApplicationType.Drupal]: [
    'node' as ExternalServiceId,
    'group' as ExternalServiceId,
    'entity_queue' as ExternalServiceId,
    'entity_subqueue' as ExternalServiceId,
  ],
  [SiteApplicationType.Wordpress]: ['post' as ExternalServiceId],
};

const OPTIONS_YES_NO = {
  yes: <>Yes</>,
  no: <>No</>,
};
const OPTIONS_POOL_WIDGET = {
  [POOL_WIDGET_CHECKBOXES]: <>Checkboxes</>,
  [POOL_WIDGET_RADIO_BOXES]: <>Radio boxes</>,
  [POOL_WIDGET_SINGLE_SELECT]: <>Single select</>,
  [POOL_WIDGET_MULTI_SELECT]: <>Multi select</>,
};
const OPTIONS_USER_REFERENCES = {
  [USER_REFERENCE_BY_NAME]: <>By user name</>,
  [USER_REFERENCE_BY_EMAIL]: <>By email</>,
  [USER_REFERENCE_ASSIGN_CONTENT_SYNC_USER]: <>Assign Content Sync user</>,
};
const UPDATE_BEHAVIOR_DESCRIPTIONS = {
  [PULL_UPDATE_FORBID_EDITING]: (
    <>Users can't edit pulled content and pulled content will be updated immediately when other sites push a change.</>
  ),
  [PULL_UPDATE_UNLESS_OVERRIDDEN]: (
    <>Users can edit pulled content and pulled content will only be updated by other sites if users didn't override it.</>
  ),
  [PULL_UPDATE_NEVER]: <>Users can't edit pulled content and when other sites push a change it will be ignored.</>,
  [PULL_UPDATE_CREATE_UNPUBLISHED_REVISION]: (
    <>
      Users can't edit pulled content and when other sites push a change, an unpublished revision will be created on this site for review.
    </>
  ),
  [PULL_UPDATE_FORCE]: <>Users can change pulled content but the changes will be lost when an update from another site comes in.</>,
};
const UNEXPECTED_INDEPENDENT_TYPES = ['brick', 'crop', 'field_collection_item', 'file', 'paragraph'];

interface IUseCaseSelectorProps {
  product: Product;
  onSelected: (settings?: Partial<FormValues>) => void;
}
interface IUseCaseSelectorState extends ISyncCoreApiComponentState {
  step?: number;
  settings?: Partial<FormValues>;
}
export class UseCaseSelector extends SyncCoreApiComponent<IUseCaseSelectorProps, IUseCaseSelectorState> {
  async load() {
    return {
      step: 0,
      settings: {},
    };
  }

  render() {
    const { product, onSelected } = this.props;
    const { step, settings } = this.state;

    if (step === undefined || !settings) {
      return this.renderRequest();
    }

    return (
      <Modal show size="xl" onHide={() => onSelected()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {step === 1 ? 'Step 2: Select content types' : product === Product.Staging ? 'Step 1: Select site type' : 'Step 1: Select goal'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 ? (
            'select entity types'
          ) : (
            <>
              <Row>
                <Col xs={1} />
                <Col xs={4}>
                  <h2>
                    {product === Product.Staging ? (
                      <>
                        <FontAwesomeIcon icon={faKey} size={'sm'} className="me-2 text-muted" />
                        Content Stage
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faUpload} size={'sm'} className="me-2 text-muted" />
                        Publish Content
                      </>
                    )}
                  </h2>
                </Col>
                <Col xs={2} />
                <Col xs={4}>
                  <h2>
                    {product === Product.Staging ? (
                      <>
                        <FontAwesomeIcon icon={faGlobe} size={'sm'} className="me-2 text-muted" />
                        Production Site
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faDownload} size={'sm'} className="me-2 text-muted" />
                        Subscribe to Content
                      </>
                    )}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col xs={1} />
                <Col xs={4}>
                  <Image
                    src={product === Product.Staging ? ContentStageImage : PushContentImage}
                    style={{ width: '60%', margin: '10px 20%' }}
                  />
                </Col>
                {product === Product.Staging ? (
                  <Col xs={2} className="d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon icon={faArrowAltRight} className="text-light" size={'2x'} />
                  </Col>
                ) : (
                  <Col xs={2} />
                )}
                <Col xs={4}>
                  <Image src={PullContentImage} style={{ width: '60%', margin: '10px 20%' }} />
                </Col>
              </Row>
              <Row>
                <Col xs={1} />
                <Col xs={4}>
                  <div className="text-muted">
                    {product === Product.Staging
                      ? 'Prepare content on this site and then push it out to your production site with the click of a button.'
                      : 'Create content at this site, push it out to your content repository and then pull it into other sites.'}
                  </div>
                </Col>
                <Col xs={2} />
                <Col xs={4}>
                  <div className="text-muted">
                    {product === Product.Staging
                      ? 'Subscribe to new and updated content from your Content Staging site.'
                      : 'Pull content into this site that was published on another site.'}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={1} />
                <Col xs={4} className="text-center pt-4 pb-2">
                  <Button variant="primary" onClick={() => this.setState({ step: 1, settings: { type: TYPE_PUSH, mode: MODE_MANUALLY } })}>
                    Select
                  </Button>
                </Col>
                <Col xs={2} />
                <Col xs={4} className="text-center pt-4 pb-2">
                  <Button
                    variant="primary"
                    onClick={() => this.setState({ step: 1, settings: { type: TYPE_PULL, mode: MODE_AUTOMATICALLY } })}
                  >
                    Select
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {step === 0 ? (
            <Right>
              <span className="text-muted">or</span>{' '}
              <Button variant="link" onClick={() => onSelected()}>
                use advanced wizard
              </Button>
            </Right>
          ) : (
            <LeftRightContainer
              className="flex-grow-1"
              left={
                <Button variant="light" onClick={() => this.setState({ step: 0 })}>
                  Back
                </Button>
              }
              right={null}
            />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export class FlowFormWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const values: FormValues = {
      ...this.props.params.values,
    };

    const site = (await this.getCurrentSite()) as ClientSiteEntity;
    const contract = await site.contract?.get();

    const contractRevisions = contract ? await this.api.billing.contractRevisions.getMostRecentForContract(contract.id) : undefined;

    const newBundles: EntityType[] = [];

    this.props.params.bundles.sort((a, b) => {
      if (a.supported && !b.supported) {
        return -1;
      } else if (!a.supported && b.supported) {
        return 1;
      }
      if (a.namespaceMachineName === b.namespaceMachineName) {
        return a.name.localeCompare(b.name);
      }
      return a.namespaceMachineName.localeCompare(b.namespaceMachineName);
    });

    const fixedType =
      contractRevisions?.current?.product === Product.Staging &&
      site?.environmentType === SiteEnvironmentType.Production &&
      contractRevisions?.current?.packageType !== Package.Enterprise;
    if (!values.type) {
      values.type = fixedType ? TYPE_PULL : TYPE_PUSH;
    }
    if (!values.name) {
      values.name = values.type === 'pull' ? DEFAULT_NAME_PULL : DEFAULT_NAME_PUSH;
    }
    if (!values.machineName) {
      values.machineName = nameToMachineName(values.name!);
    }
    if (!values.poolAssignment) {
      values.poolAssignment = ASSIGN_ALL_POOLS;
    }
    if (!values.mode) {
      values.mode = MODE_AUTOMATICALLY;
    }
    if (values.deletions !== false) {
      values.deletions = true;
    }
    if (values.embeddedDeletions !== false && values.embeddedDeletions !== true) {
      values.embeddedDeletions = values.deletions;
    }
    if (!values.updateBehavior) {
      values.updateBehavior = PULL_UPDATE_FORBID_EDITING;
    }
    if (!values.entityTypeSettings) {
      values.entityTypeSettings = {};
    }
    const partiallySupportedEntityTypes = this.props.params.bundles.filter(
      (c) => c.supported && this.props.params.bundles.find((a) => a.namespaceMachineName === c.namespaceMachineName && !a.supported)
    );
    const defaultUnbundled = partiallySupportedEntityTypes
      .map((c) => c.namespaceMachineName)
      .filter((c, index) => partiallySupportedEntityTypes.findIndex((a) => a.namespaceMachineName === c) === index);
    DEFAULT_MODE_INDEPENDENT_TYPES[site.appType].forEach((c) => !defaultUnbundled.includes(c) && defaultUnbundled.push(c));

    // Add any missing entity types / bundles.
    this.props.params.bundles.forEach((bundle) => {
      const mode = bundle.supported
        ? DEFAULT_MODE_INDEPENDENT_TYPES[site.appType].includes(bundle.namespaceMachineName)
          ? MODE_DEFAULT
          : MODE_DEPENDENT
        : MODE_IGNORE;
      if (!values.entityTypeSettings![bundle.namespaceMachineName]) {
        newBundles.push({ namespaceMachineName: bundle.namespaceMachineName, machineName: bundle.machineName });
        if (defaultUnbundled.includes(bundle.namespaceMachineName) && !values.simplifyEntityTypeSettings) {
          values.entityTypeSettings![bundle.namespaceMachineName] = {
            perBundle: {
              [bundle.machineName]: {
                mode,
              },
            },
          };
        } else {
          values.entityTypeSettings![bundle.namespaceMachineName] = {
            allBundles: {
              mode,
            },
          };
        }
      }
      // A bundle that was not known before, so we add it to the config.
      else if (
        values.entityTypeSettings![bundle.namespaceMachineName].perBundle &&
        !values.entityTypeSettings![bundle.namespaceMachineName].perBundle![bundle.machineName]
      ) {
        newBundles.push({ namespaceMachineName: bundle.namespaceMachineName, machineName: bundle.machineName });
        values.entityTypeSettings![bundle.namespaceMachineName].perBundle![bundle.machineName] = {
          mode,
        };
      }
    });
    // Remove entity types / bundles that no longer exist.
    Object.keys(values.entityTypeSettings).forEach((namespaceMachineName) => {
      const typeSettings = values.entityTypeSettings![namespaceMachineName];
      if (typeSettings.allBundles) {
        const anyBundle = this.props.params.bundles.find((c) => c.namespaceMachineName === namespaceMachineName);
        if (!anyBundle) {
          delete values.entityTypeSettings![namespaceMachineName];
        }
      } else {
        Object.keys(typeSettings.perBundle!).forEach((machineName) => {
          const existingBundle = this.props.params.bundles.find(
            (c) => c.namespaceMachineName === namespaceMachineName && c.machineName === machineName
          );
          if (!existingBundle) {
            delete typeSettings.perBundle![machineName];
          } else if (!existingBundle.supported) {
            typeSettings.perBundle![machineName].mode = MODE_IGNORE;
          }
        });
        if (!Object.keys(typeSettings.perBundle!).length) {
          delete values.entityTypeSettings![namespaceMachineName];
        }
      }
    });
    if (values.ignoreUnpublishedChanges !== false) {
      values.ignoreUnpublishedChanges = true;
    }
    if (values.allowExplicitUnpublishing !== false) {
      values.allowExplicitUnpublishing = true;
    }
    if (values.pushMenuItems !== false) {
      values.pushMenuItems = true;
    }
    if (values.pushPreviews !== false) {
      values.pushPreviews = true;
    }
    if (!values.resolveUserReferences) {
      values.resolveUserReferences = USER_REFERENCE_BY_NAME;
    }
    if (values.mergeLocalChanges !== true || values.updateBehavior !== PULL_UPDATE_UNLESS_OVERRIDDEN) {
      values.mergeLocalChanges = false;
    }
    if (values.allowLocalDeletion !== false) {
      values.allowLocalDeletion = true;
    }
    if (
      values.allowCrossSync !== true ||
      (values.updateBehavior !== PULL_UPDATE_CREATE_UNPUBLISHED_REVISION && values.updateBehavior !== PULL_UPDATE_FORCE)
    ) {
      values.allowCrossSync = false;
    }
    if (!values.poolSelectionWidget) {
      values.poolSelectionWidget = POOL_WIDGET_CHECKBOXES;
    }

    const allRemotePools = await this.api.syndication.pools.list();

    return {
      contractRevision: contractRevisions?.current || undefined,
      values,
      fixedType,
      currentStep: STEP_GENERAL_SETTINGS,
      pools: this.props.params.pools.length
        ? [...this.props.params.pools]
        : allRemotePools.length === 1
        ? [...allRemotePools]
        : allRemotePools.length > 1
        ? []
        : [{ machineName: 'content' as ExternalServiceId, name: 'Content' }],
      allRemotePools,
      newBundles,
      site,
      simpleSetup: false, //!this.props.params.values?.machineName,
      showAllBundles: [],
    };
  }

  render() {
    const {
      values,
      showAdvanced,
      editMachineName,
      showUnsupportedEntityTypes,
      currentStep,
      isDragging,
      draggedItemId,
      contractRevision,
      simpleSetup,
      fixedType,
      showSavingPopup,
      saving,
      addPool,
      editPoolMachineName,
      pools,
      allRemotePools,
      newBundles,
      setReferenceFilter,
      setMenuFilter,
      site,
      showAllBundles,
    } = this.state;

    const { bundles } = this.props.params;

    if (!values || !pools || !allRemotePools || !site) {
      return this.renderRequest();
    }

    if (!contractRevision) {
      return <Alert variant="warning">Your contract has expired. Please renew your contract to continue.</Alert>;
    }

    const setStep = (newStep: number) => {
      let newEditMachineName: boolean = !!editMachineName;
      let newShowAdvanced: boolean = !!showAdvanced;
      if (newStep === STEP_REVIEW) {
        newEditMachineName =
          newEditMachineName || !values.machineName || this.props.params.reservedFlowMachineNames.includes(values.machineName);
        newShowAdvanced = newShowAdvanced || newEditMachineName;
      }
      this.setState({ currentStep: newStep, editMachineName: newEditMachineName, showAdvanced: newShowAdvanced });
    };

    const unusedRemotePools = allRemotePools.filter((c) => !pools.find((a) => a.machineName === c.machineName));

    const save = (migrateNext: boolean) => {
      this.setState({ saving: true });
      try {
        sendToParent({
          type: 'save-flow',
          data: {
            values: {
              ...values,
            },
            pools,
            migrateNext,
          },
        });
      } catch (e) {
        this.setState({ saving: false });
      }
    };

    const editExisting = !!this.props.params.values?.machineName;

    const setName = (name: string | undefined) => {
      values.name = name;
      if (!editExisting && !editMachineName) {
        values.machineName = nameToMachineName(values.name || '');
      }
      this.setState({ values });
    };

    const bundlesWithMode = bundles.map((c) => {
      const grouped = values.entityTypeSettings![c.namespaceMachineName].allBundles;
      const mode = grouped || values.entityTypeSettings![c.namespaceMachineName].perBundle![c.machineName];
      return {
        ...c,
        mode: mode.mode,
        previewViewMode: mode.previewViewMode,
        grouped: !!grouped,
      };
    });
    let displayDefaultTypes = bundlesWithMode.filter((c) => c.mode === MODE_DEFAULT);
    displayDefaultTypes = displayDefaultTypes.filter(
      (a, index) => !a.grouped || displayDefaultTypes.findIndex((b) => b.namespaceMachineName === a.namespaceMachineName) === index
    );
    let displayDependentTypes = bundlesWithMode.filter((c) => c.mode === MODE_DEPENDENT);
    displayDependentTypes = displayDependentTypes.filter(
      (a, index) => !a.grouped || displayDependentTypes.findIndex((b) => b.namespaceMachineName === a.namespaceMachineName) === index
    );
    let displayIgnoreTypes = bundlesWithMode.filter((c) => c.mode === MODE_IGNORE);
    displayIgnoreTypes = displayIgnoreTypes.filter(
      (a, index) => !a.grouped || displayIgnoreTypes.findIndex((b) => b.namespaceMachineName === a.namespaceMachineName) === index
    );
    let hiddenUnsupportedTypes: number;
    if (showUnsupportedEntityTypes) {
      hiddenUnsupportedTypes = 0;
    } else {
      hiddenUnsupportedTypes = displayIgnoreTypes.length;
      displayIgnoreTypes = displayIgnoreTypes.filter((c) => c.supported);
      hiddenUnsupportedTypes -= displayIgnoreTypes.length;
    }

    const showGroupedBundleNames = true;
    const dependentDisabled = values.simplifyEntityTypeSettings === true;
    const BundleOrTypeName = (settings: EntityTypeBundle & BundleSettings & { grouped: boolean }, isNew?: boolean) => {
      const bundlesForType = bundles.filter((c) => c.namespaceMachineName === settings.namespaceMachineName);

      return (
        <div className="d-flex">
          <div style={{ width: '34px' }} className="text-center">
            <EntityTypeIcon
              appType={site.appType}
              namespaceMachineName={settings.namespaceMachineName}
              machineName={settings.machineName}
              isConfiguration={settings.isConfiguration}
            />
          </div>
          <div className={`flex-grow-1`}>
            <div>
              {settings.grouped ? (
                <span>
                  <strong>{machineNameToName(settings.namespaceMachineName)}</strong>{' '}
                  {!showGroupedBundleNames && (
                    <Badge
                      bg="light"
                      title={bundles
                        .filter((c) => c.namespaceMachineName === settings.namespaceMachineName)
                        .map((c) => c.name)
                        .join('\n')}
                    >
                      {bundlesForType.length}
                    </Badge>
                  )}
                </span>
              ) : (
                <span>
                  {settings.name} <em>{machineNameToName(settings.namespaceMachineName)}</em>
                </span>
              )}
              {!settings.grouped &&
                (settings.previewViewMode ? (
                  <Badge
                    bg="light"
                    className="ms-1"
                    title="This view mode will be used to generate previews of the entity that other sites can search through to pull content manually."
                  >
                    {settings.viewModes?.[settings.previewViewMode] || settings.previewViewMode}
                  </Badge>
                ) : (
                  <Badge bg="light" className="ms-1" title="These entities will not generate a preview for the pull dashboard.">
                    <em>auto preview</em>
                  </Badge>
                ))}
              {isNew && (
                <>
                  {' '}
                  <Badge bg="danger">New</Badge>
                </>
              )}
            </div>
            {settings.grouped &&
              showGroupedBundleNames &&
              (bundlesForType.length > 1 || bundlesForType[0]?.name !== settings.name) &&
              bundlesForType.map((c, i) => {
                if (bundlesForType.length <= 4 || i < 2 || showAllBundles?.includes(c.namespaceMachineName)) {
                  return <div>{c.name}</div>;
                } else if (i === 2) {
                  return (
                    <div className="mt-2">
                      <em>+{bundlesForType.length - 2} more.</em>{' '}
                      <Button
                        variant="link"
                        onClick={() => this.setState({ showAllBundles: [...(showAllBundles ?? []), c.namespaceMachineName] })}
                      >
                        Show all
                      </Button>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      );
    };
    const BundleOrType = (
      provided: DraggableProvided,
      settings: EntityTypeBundle & BundleSettings & { grouped: boolean },
      mode: string,
      id: string
    ) => {
      const isUsed =
        mode === MODE_DEFAULT &&
        (values.type === TYPE_PUSH ? this.props.params.pushedEntityTypes : this.props.params.pulledEntityTypes).find((c) =>
          settings.grouped
            ? c.namespaceMachineName === settings.namespaceMachineName
            : c.namespaceMachineName === settings.namespaceMachineName && c.machineName === settings.machineName
        );
      const warnings: React.ReactNode[] = isUsed
        ? settings.grouped
          ? ['At least one of the bundles of this type is already configured in another Flow.']
          : ['This bundle is already configured in another Flow.']
        : [];
      if (mode === MODE_DEFAULT && UNEXPECTED_INDEPENDENT_TYPES.includes(settings.namespaceMachineName)) {
        warnings.push(
          "We don't recommend pushing this entity type independently. If you are doing this because (part of) your content is missing, please use the Serialize functionality when pushing content to see what's happening or reach out to our support to help you troubleshoot."
        );
      }
      if (settings.grouped) {
        const allUnsupportedFields = bundlesWithMode
          .filter((c) => c.namespaceMachineName === settings.namespaceMachineName)
          .map((c) => c.unsupportedFields.map((f) => `${c.name}: ${f}`))
          .flat();
        if (allUnsupportedFields.length) {
          warnings.push(
            <>
              Some fields are not supported. If you need support for these fields, please contact us. Unsupported fields are:{' '}
              <ul>
                {allUnsupportedFields.map((c) => (
                  <li key={c}>{c}</li>
                ))}
              </ul>
            </>
          );
        }
      } else if (settings.unsupportedFields.length) {
        warnings.push(
          <>
            Some fields are not supported. If you need support for these fields, please contact us. Unsupported fields are:{' '}
            <ul>
              {settings.unsupportedFields.map((c) => (
                <li key={c}>{c}</li>
              ))}
            </ul>
          </>
        );
      }
      const isNew =
        editExisting &&
        !!newBundles?.find((c) =>
          settings.grouped
            ? c.machineName === settings.machineName
            : c.namespaceMachineName === settings.namespaceMachineName && c.machineName === settings.machineName
        );
      const currentSettings = values.entityTypeSettings![settings.namespaceMachineName].perBundle
        ? values.entityTypeSettings![settings.namespaceMachineName].perBundle![settings.machineName]
        : values.entityTypeSettings![settings.namespaceMachineName].allBundles!;
      const currentFilters = currentSettings.filters;
      const filterFields = settings.referenceFields.filter((c) => c.targetNamespaceMachineName === 'taxonomy_term');
      return (
        <div
          className={`d-flex rounded shadow-sm pt-2 pe-2 pb-2 mb-2 ${draggedItemId === id ? 'flash-warning' : 'bg-white'}`}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <div className={`flex-grow-1 ${settings.supported ? '' : 'text-muted'}`} {...provided.dragHandleProps}>
            {BundleOrTypeName(settings, isNew)}
          </div>
          {settings.supported && (
            <div>
              {!!warnings.length && (
                <OverlayTrigger
                  placement="left"
                  overlay={(props) => (
                    <Tooltip
                      id={`warnings---${settings.namespaceMachineName}---${settings.machineName}`}
                      {...props}
                      className="tooltip-wide"
                    >
                      {warnings.length === 1 ? (
                        warnings[0]
                      ) : (
                        <ul>
                          {warnings.map((c, index) => (
                            <li key={index}>{c}</li>
                          ))}
                        </ul>
                      )}
                    </Tooltip>
                  )}
                >
                  {warnings.length > 1 ? (
                    <span className="fa-stack me-1" style={{ height: '1.2em' }}>
                      <FontAwesomeIcon className="text-warning fa-stack-1x p-0" icon={faExclamationTriangle} />
                      <div className="fa-stack-1x align-bottom text-end">
                        <small className="d-inline-block mt-1 ps-1 pe-1 align-bottom bg-warning text-white rounded-circle">
                          {warnings.length}
                        </small>
                      </div>
                    </span>
                  ) : (
                    <FontAwesomeIcon className="text-warning me-1" icon={faExclamationTriangle} />
                  )}
                </OverlayTrigger>
              )}
              {values.type === TYPE_PULL && !settings.grouped && mode !== MODE_IGNORE && filterFields.length > 0 && (
                <Button
                  variant="link"
                  className="ms-0 pt-0 pb-0"
                  title="Filter by taxonomy term."
                  onClick={() =>
                    this.setState({
                      setReferenceFilter: { namespaceMachineName: settings.namespaceMachineName, machineName: settings.machineName },
                    })
                  }
                >
                  <IconWithCount icon={faFilter} count={currentFilters?.map((c) => c.values).flat().length} />
                </Button>
              )}
              {settings.menus && mode !== MODE_IGNORE && (
                <Button
                  variant="link"
                  className="ms-0 pt-0 pb-0"
                  title="Filter by menu."
                  onClick={() =>
                    this.setState({
                      setMenuFilter: { namespaceMachineName: settings.namespaceMachineName, machineName: settings.machineName },
                    })
                  }
                >
                  <IconWithCount icon={faFilter} count={currentFilters?.map((c) => c.values).flat().length} />
                </Button>
              )}
              {settings.grouped ? (
                mode !== MODE_IGNORE && (
                  <Button
                    variant="link"
                    className="ms-0 pt-0 pb-0"
                    title="Configure each bundle separately."
                    onClick={() => {
                      values.entityTypeSettings![settings.namespaceMachineName].perBundle = {};
                      const bundleSettings = values.entityTypeSettings![settings.namespaceMachineName].allBundles!;
                      bundlesWithMode
                        .filter((c) => c.namespaceMachineName === settings.namespaceMachineName)
                        .forEach((c) => {
                          values.entityTypeSettings![settings.namespaceMachineName].perBundle![c.machineName] = { ...bundleSettings };
                          const bundleOptions = this.props.params.bundles.find(
                            (a) => c.namespaceMachineName === a.namespaceMachineName && c.machineName === a.machineName
                          );
                          if (!bundleOptions?.supported) {
                            values.entityTypeSettings![settings.namespaceMachineName].perBundle![c.machineName].mode = MODE_IGNORE;
                          }
                        });
                      delete values.entityTypeSettings![settings.namespaceMachineName].allBundles;
                      this.setState({ values });
                    }}
                  >
                    <FontAwesomeIcon icon={faObjectUngroup} />
                  </Button>
                )
              ) : (
                <>
                  {!settings.grouped && settings.viewModes && Object.keys(settings.viewModes).length ? (
                    <Dropdown className="d-inline-block">
                      <Dropdown.Toggle
                        variant="light"
                        id={`${settings.namespaceMachineName}---${settings.machineName}---more`}
                        className="pt-0 pb-0"
                      />
                      <Dropdown.Menu>
                        <Dropdown align={'end'}>
                          <Dropdown.Toggle
                            variant="none"
                            id={`${settings.namespaceMachineName}---${settings.machineName}---more`}
                            className="pt-0 pb-0 ps-4 text-start"
                            style={{ width: '100%' }}
                          >
                            Set preview
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              key={'---none---'}
                              onClick={() => {
                                delete values.entityTypeSettings![settings.namespaceMachineName].perBundle![settings.machineName]
                                  .previewViewMode;
                                this.setState({ values });
                              }}
                              className={!settings.previewViewMode ? 'text-primary' : undefined}
                            >
                              <em>Auto-generate</em>
                            </Dropdown.Item>
                            {Object.entries(settings.viewModes).map(([viewModeMachineName, viewModeDisplayName]) => (
                              <Dropdown.Item
                                key={viewModeMachineName}
                                onClick={() => {
                                  values.entityTypeSettings![settings.namespaceMachineName].perBundle![
                                    settings.machineName
                                  ].previewViewMode = settings.previewViewMode = viewModeMachineName;
                                  this.setState({ values });
                                }}
                                className={viewModeMachineName === settings.previewViewMode ? 'text-primary' : undefined}
                              >
                                {viewModeDisplayName}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : undefined}
                  <Button
                    variant="link"
                    className="ms-0 pt-0 pb-0"
                    title="Treat all bundles the same."
                    onClick={() => {
                      values.entityTypeSettings![settings.namespaceMachineName].allBundles =
                        values.entityTypeSettings![settings.namespaceMachineName].perBundle![settings.machineName];
                      delete values.entityTypeSettings![settings.namespaceMachineName].allBundles!.previewViewMode;
                      delete values.entityTypeSettings![settings.namespaceMachineName].perBundle;
                      this.setState({ values });
                    }}
                  >
                    <FontAwesomeIcon icon={faObjectGroup} />
                  </Button>
                </>
              )}
              {mode !== MODE_IGNORE && (
                <Button
                  variant="danger"
                  className="pt-0 pb-0 border-0"
                  onClick={() => {
                    if (settings.grouped) {
                      values.entityTypeSettings![settings.namespaceMachineName].allBundles!.mode = MODE_IGNORE;
                    } else {
                      values.entityTypeSettings![settings.namespaceMachineName].perBundle![settings.machineName].mode = MODE_IGNORE;
                    }
                    this.setState({ values, draggedItemId: id });
                  }}
                >
                  <FontAwesomeIcon className="text-danger" icon={faTimes} />
                </Button>
              )}
            </div>
          )}
        </div>
      );
    };

    const validationErrors: string[] = [];
    const validationErrorElements: { [key: string]: string } = {};
    if (currentStep === STEP_GENERAL_SETTINGS) {
      if (!values.name) {
        validationErrors.push((validationErrorElements.name = 'Please provide a name to continue.'));
      }
      if (!values.pools) {
        if (pools.length === 0) {
          validationErrors.push((validationErrorElements.pools = 'Please create one or more Pools to continue.'));
        }
      } else {
        if (!values.pools?.length) {
          validationErrors.push((validationErrorElements.pools = 'Please select one or more Pools to continue.'));
        }
      }
    } else if (currentStep === STEP_ENTITY_TYPE_SETTINGS) {
      if (!displayDefaultTypes.length) {
        validationErrors.push(
          (validationErrorElements.displayDefaultTypes = 'Please select one or more Independent entity types to continue.')
        );
      }
    } else if (currentStep === STEP_REVIEW) {
      if (!values.machineName) {
        validationErrors.push((validationErrorElements.machineName = 'Please provide a machine name to continue.'));
      } else if (this.props.params.reservedFlowMachineNames.includes(values.machineName)) {
        validationErrors.push((validationErrorElements.machineName = 'This machine name is already used by another Flow.'));
      }
    }

    let advancedSettingsBadges: React.ReactNode[] = [
      <Badge key={0} className="fw-normal" bg="dark">
        <strong>Machine name:</strong> {values.machineName}
      </Badge>,
    ];
    if (!values.deletions) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>{values.type === TYPE_PUSH ? 'Push deletions' : 'Delete automatically'}:</strong> No
        </Badge>
      );
    }
    if (!values.embeddedDeletions) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>{values.type === TYPE_PUSH ? 'Push deletions of dependent entities' : 'Delete dependent entities automatically'}:</strong>{' '}
          No
        </Badge>
      );
    }
    if (!values.allowLocalDeletion && values.type === TYPE_PULL) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Allow local deletion:</strong> No
        </Badge>
      );
    }
    if (values.allowCrossSync && values.type === TYPE_PULL) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Allow cross-sync:</strong> Yes
        </Badge>
      );
    }
    if (!values.ignoreUnpublishedChanges) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Ignore unpublished changes:</strong> No
        </Badge>
      );
    }
    if (!values.allowExplicitUnpublishing) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Allow unpublishing:</strong> No
        </Badge>
      );
    }
    if (!values.pushMenuItems && values.type === TYPE_PUSH) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Push menu items:</strong> No
        </Badge>
      );
    }
    if (!values.pushPreviews && values.type === TYPE_PUSH) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Push previews:</strong> No
        </Badge>
      );
    }
    if (values.mergeLocalChanges && values.type === TYPE_PULL) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Merge local changes:</strong> Yes
        </Badge>
      );
    }
    if (values.resolveUserReferences !== USER_REFERENCE_BY_NAME) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Resolve user references:</strong> {OPTIONS_USER_REFERENCES[values.resolveUserReferences!]}
        </Badge>
      );
    }
    if (values.poolSelectionWidget !== POOL_WIDGET_CHECKBOXES && values.type === TYPE_PUSH) {
      advancedSettingsBadges.push(
        <Badge key={advancedSettingsBadges.length} className="fw-normal" bg="dark">
          <strong>Resolve user references:</strong> {OPTIONS_POOL_WIDGET[values.poolSelectionWidget!]}
        </Badge>
      );
    }
    if (advancedSettingsBadges.length > 4) {
      advancedSettingsBadges = [
        advancedSettingsBadges[0],
        <Badge key={1} bg="dark">
          ...
        </Badge>,
      ];
    }

    const verb = values.type === TYPE_PULL ? 'pull' : 'push';

    return (
      <div>
        {contractRevision && simpleSetup ? (
          <UseCaseSelector
            product={contractRevision.product}
            onSelected={(settings) =>
              this.setState({ values: settings ? { ...values, ...settings } : values, simpleSetup: false, currentStep: settings ? 2 : 0 })
            }
          />
        ) : undefined}
        <LeftRightH1
          left={
            currentStep === STEP_GENERAL_SETTINGS
              ? 'General settings'
              : currentStep === STEP_ENTITY_TYPE_SETTINGS
              ? 'Entity type settings'
              : 'Review'
          }
          right={ALL_STEPS.map((group) => (
            <StepCircle
              key={group}
              index={group + 1}
              active={currentStep === group}
              enabled={!validationErrors.length}
              onSelect={() => setStep(group)}
            />
          ))}
          className="mb-4"
        />
        {currentStep === STEP_GENERAL_SETTINGS && (
          <>
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>Name</HeaderCol>
              <ContentCol>
                <Form.Control
                  type="text"
                  placeholder="Enter a name..."
                  value={values.name || ''}
                  className={validationErrorElements.name ? 'is-invalid' : 'is-valid'}
                  onChange={(e) => {
                    const name = e.target.value || undefined;
                    setName(name);
                  }}
                />
              </ContentCol>
            </Row>
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>Purpose</HeaderCol>
              <ContentCol>
                <SwitchButton
                  size="lg"
                  options={{
                    [TYPE_PUSH]: (
                      <>
                        <FontAwesomeIcon icon={faCloudUpload} className={'me-2'} /> Push content
                      </>
                    ),
                    [TYPE_PULL]: (
                      <>
                        <FontAwesomeIcon icon={faCloudDownload} className={'me-2'} /> Pull content
                      </>
                    ),
                  }}
                  selected={values.type || TYPE_PUSH}
                  disabled={editExisting || fixedType}
                  onSelect={(key) => {
                    values.type = key;
                    this.setState({ values });
                    if (values.name === (key === TYPE_PUSH ? DEFAULT_NAME_PULL : DEFAULT_NAME_PUSH)) {
                      setName(key === TYPE_PUSH ? DEFAULT_NAME_PUSH : DEFAULT_NAME_PULL);
                    }
                  }}
                />
                {fixedType && !editExisting && <span className="text-warning ms-2">Production sites can't push content.</span>}
              </ContentCol>
            </Row>
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>{values.type === TYPE_PUSH ? 'Restrict pools' : 'Pull from Pools'}</HeaderCol>
              <ContentCol>
                <div>
                  <div className="float-left">
                    <SwitchButton
                      size="lg"
                      options={{
                        all: values.type === TYPE_PUSH ? <>Allow all ({pools.length})</> : <>All ({pools.length})</>,
                        selected:
                          values.type === TYPE_PUSH ? (
                            <>Allow selected {values.pools ? `(${values.pools.length})` : undefined}</>
                          ) : (
                            'Selected'
                          ),
                      }}
                      selected={values.pools ? 'selected' : 'all'}
                      onSelect={(key) => {
                        values.pools = key === 'all' ? undefined : pools.map((c) => c.machineName);
                        this.setState({ values });
                      }}
                    />
                  </div>
                  <div className="float-left ms-3 mt-2">
                    <HelpLink url={HELP_POOLS}>What's a Pool?</HelpLink>
                  </div>
                  <div className="clearfix" />
                </div>
                <div className="mt-2">
                  {pools.map((c, index) => {
                    const isNew = !this.props.params.pools.find((a) => a.machineName === c.machineName);
                    const isIncluded = values.pools ? values.pools.includes(c.machineName) : true;
                    const isUsed =
                      isIncluded && values.type === TYPE_PUSH
                        ? this.props.params.pushedPools.includes(c.machineName)
                        : this.props.params.pulledPools.includes(c.machineName);
                    return (
                      <div key={c.machineName}>
                        {values.pools ? (
                          <Form.Check
                            className="cursor-pointer me-0"
                            inline
                            id={`pool-${c.machineName}`}
                            type="checkbox"
                            checked={values.pools!.includes(c.machineName)}
                            onChange={() => {
                              if (values.pools!.includes(c.machineName)) {
                                values.pools = values.pools!.filter((checked) => checked !== c.machineName);
                              } else {
                                values.pools!.push(c.machineName);
                              }
                              this.setState({ values });
                            }}
                            label={
                              <>
                                {c.name}
                                {isNew && (
                                  <>
                                    {' '}
                                    <Badge bg="danger">new</Badge>
                                  </>
                                )}
                              </>
                            }
                          />
                        ) : (
                          <>
                            <FontAwesomeIcon icon={faCheck} /> {c.name}
                            {isNew && (
                              <>
                                {' '}
                                <Badge bg="danger">new</Badge>
                              </>
                            )}
                          </>
                        )}
                        {isUsed && (
                          <FontAwesomeIcon
                            className="text-warning ms-2"
                            icon={faExclamationTriangle}
                            title="This pool is already used in another Flow."
                          />
                        )}
                        {isNew && (
                          <>
                            <Button
                              variant="danger"
                              className="ms-0 border-0"
                              onClick={() => {
                                pools.splice(index, 1);
                                if (values.pools) {
                                  const valuePoolIndex = values.pools.indexOf(c.machineName);
                                  values.pools.splice(valuePoolIndex, 1);
                                }
                                this.setState({ pools, values });
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          </>
                        )}
                      </div>
                    );
                  })}
                  <Button
                    className="mt-2"
                    variant={'primary'}
                    onClick={() =>
                      this.setState({
                        addPool: pools.length
                          ? { machineName: '' as ExternalServiceId, name: '' }
                          : { machineName: 'content' as ExternalServiceId, name: 'Content' },
                      })
                    }
                  >
                    Add Pool
                  </Button>
                  {validationErrorElements.pools && <div className="text-danger mt-1">{validationErrorElements.pools}</div>}
                </div>
              </ContentCol>
            </Row>
            {values.type === TYPE_PUSH && (
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Assign pools</HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={{
                      [ASSIGN_ALL_POOLS]: <>Assign all ({values.pools ? values.pools.length : pools.length})</>,
                      [ASSIGN_POOLS_MANUALLY]: <>Editor chooses</>,
                    }}
                    selected={values.poolAssignment || ASSIGN_ALL_POOLS}
                    onSelect={(key) => {
                      values.poolAssignment = key;
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
            )}
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>{values.type === TYPE_PUSH ? 'Push changes' : 'Pull new content'}</HeaderCol>
              <ContentCol>
                <SwitchButton
                  size="lg"
                  options={{
                    [MODE_AUTOMATICALLY]: (
                      <>
                        Immediately{' '}
                        <InfoIcon className={values.mode === MODE_AUTOMATICALLY ? 'text-white' : undefined}>
                          {values.type === TYPE_PUSH
                            ? 'Content is made available to other sites immediately whenever it is created, updated or deleted on this site.'
                            : 'New content is added to the site immediately whenever it is pushed by another site.'}
                        </InfoIcon>
                      </>
                    ),
                    [MODE_MANUALLY]: (
                      <>
                        Manually{' '}
                        <InfoIcon className={values.mode === MODE_MANUALLY ? 'text-white' : undefined}>
                          {values.type === TYPE_PUSH
                            ? 'Editors have an option to save content locally first and make it available to other sites by the push of a button.'
                            : 'Editors can pick and choose which piece of content to add to the site by searching through the available content.'}
                        </InfoIcon>
                      </>
                    ),
                  }}
                  selected={values.mode || MODE_AUTOMATICALLY}
                  onSelect={(key) => {
                    values.mode = key;
                    this.setState({ values });
                  }}
                />
              </ContentCol>
            </Row>
            {values.type === TYPE_PULL && (
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Update behavior</HeaderCol>
                <ContentCol>
                  <div className="d-flex">
                    <div className="pe-4 flex-shrink-0 flex-grow-0">
                      <Radios
                        options={{
                          [PULL_UPDATE_FORBID_EDITING]: <>Update and forbid editing</>,
                          [PULL_UPDATE_UNLESS_OVERRIDDEN]: <>Allow local changes</>,
                          [PULL_UPDATE_CREATE_UNPUBLISHED_REVISION]: <>Create unpublished revision</>,
                          [PULL_UPDATE_NEVER]: <>Ignore all updates</>,
                          [PULL_UPDATE_FORCE]: <>Unrestricted</>,
                        }}
                        selected={values.updateBehavior || PULL_UPDATE_FORBID_EDITING}
                        onSelect={(key) => {
                          values.updateBehavior = key;
                          this.setState({ values });
                        }}
                      />{' '}
                    </div>
                    <div className="ps-4 flex-shrink-1 flex-grow-1">
                      <InfoBox>
                        {
                          {
                            [PULL_UPDATE_FORBID_EDITING]: (
                              <>
                                <h3>Update and forbid editing</h3>
                                <p>
                                  <strong>Local changes:</strong> editors <strong>can not change</strong> pulled content.
                                </p>
                                <p>
                                  <strong>Remote changes:</strong> When another site pushes an update, content on this site{' '}
                                  <strong>is updated</strong> and the update is <strong>immediately published</strong>.
                                </p>
                                <p>
                                  <strong>Example use-cases:</strong> Content syndication where content is strictly controlled by the
                                  content entry site.
                                </p>
                              </>
                            ),
                            [PULL_UPDATE_UNLESS_OVERRIDDEN]: (
                              <>
                                <h3>Allow local changes</h3>
                                <p>
                                  <strong>Local changes:</strong> editors <strong>can change</strong> pulled content and also reset it to
                                  it's original content.
                                </p>
                                <p>
                                  <strong>Remote changes:</strong> if a content item was changed locally, it <strong>is not updated</strong>
                                  ; otherwise it <strong>is updated</strong> and the update is <strong>immediately published</strong>.
                                </p>
                                <p>
                                  <strong>Example use-cases:</strong> Content repositories where editors can optionally customize content
                                  for their local target audience.
                                </p>
                                <p>
                                  <HelpLink className="mt-2 ms-2" url={HELP_OVERRIDE_CONTENT}>
                                    Learn more
                                  </HelpLink>
                                </p>
                              </>
                            ),
                            [PULL_UPDATE_CREATE_UNPUBLISHED_REVISION]: (
                              <>
                                <h3>Create unpublished revision</h3>
                                <p>
                                  <strong>Creation:</strong> pulled content is created in an unpublished state and must be{' '}
                                  <strong>manually published</strong> by editors on this site.
                                </p>
                                <p>
                                  <strong>Local changes:</strong> editors <strong>can change</strong> pulled content.
                                </p>
                                <p>
                                  <strong>Remote changes:</strong> When another site pushes an update, content on this site{' '}
                                  <strong>is updated</strong>, but the update must be <strong>manually published</strong>.
                                </p>
                                <p>
                                  <strong>Example use-cases:</strong> Content syndication/repositories with a strict requirement to manually
                                  publish content per site.
                                </p>
                              </>
                            ),
                            [PULL_UPDATE_NEVER]: (
                              <>
                                <h3>Ignore all updates</h3>
                                <p>
                                  <strong>Local changes:</strong> editors <strong>can change</strong> pulled content.
                                </p>
                                <p>
                                  <strong>Remote changes:</strong> pulled content <strong>is not updated</strong> by other sites.
                                </p>
                                <p>
                                  <strong>Example use-cases:</strong> Default content that must be customized per site after the initial set
                                  up.
                                </p>
                              </>
                            ),
                            [PULL_UPDATE_FORCE]: (
                              <>
                                <h3>Unrestricted</h3>
                                <p>
                                  <strong>Local changes:</strong> editors <strong>can change</strong> pulled content.
                                </p>
                                <p>
                                  <strong>Remote changes:</strong> When another site pushes an update, content on this site{' '}
                                  <strong>is updated</strong> and the update is <strong>immediately published</strong>.
                                </p>
                                <p>
                                  <strong>Example use-cases:</strong> Content staging with multiple content entry sites and a custom locking
                                  mechanism in place.
                                </p>
                                <Alert variant="danger">
                                  <strong>
                                    Any local changes will be overwritten and lost when a content update is pushed from another site.
                                  </strong>{' '}
                                  Only use this if you have e.g. a custom cross-site content locking mechanism in place to avoid content
                                  being updated in multiple places simultaneously.
                                </Alert>
                              </>
                            ),
                          }[values.updateBehavior || PULL_UPDATE_FORBID_EDITING]
                        }
                      </InfoBox>
                    </div>
                  </div>
                </ContentCol>
              </Row>
            )}
          </>
        )}

        {currentStep === STEP_ENTITY_TYPE_SETTINGS && (
          <>
            <Row>
              <HeaderCol>Top-level entities</HeaderCol>
              <HeaderCol>
                Include automatically{' '}
                {values.simplifyEntityTypeSettings && (
                  <EditButton
                    onClick={() => {
                      values.simplifyEntityTypeSettings = false;
                      this.setState({ values });
                    }}
                  />
                )}
              </HeaderCol>
              <HeaderCol>Ignore</HeaderCol>
            </Row>
            <Row>
              <Col className="pt-0 pb-0">
                {values.mode === MODE_AUTOMATICALLY ? verb.replace(/^[a-z]/, (a) => a.toUpperCase()) : `Allow ${verb}ing`} these entities
                whenever they change.
              </Col>
              <Col className="pt-0 pb-0">
                Automatically {verb} these entities if they are referenced by another entity that is {verb}ed. Works with nested references.
              </Col>
              <Col className="pt-0 pb-0">Never {verb}.</Col>
            </Row>
            <Row>
              <Col>
                <span className="text-muted small">Usually nodes, sometimes taxonomies or menu items.</span>
              </Col>
              <Col>
                <span className="text-muted small">Usually paragraphs, bricks, media, files, ...</span>
              </Col>
              <Col>
                <span className="text-muted small">Usually used to exclude specific node bundles.</span>
              </Col>
            </Row>
            <DragDropContext
              onDragStart={() => this.setState({ isDragging: true, draggedItemId: undefined })}
              onDragEnd={({ destination, source, draggableId }) => {
                if (!destination) {
                  this.setState({ isDragging: false });
                  return;
                }
                if (source.droppableId === destination.droppableId) {
                  this.setState({ isDragging: false });
                  return;
                }
                const [namespaceMachineName, machineName] = draggableId.split('\n');
                const typeSettings = values.entityTypeSettings![namespaceMachineName];
                if (typeSettings.allBundles) {
                  typeSettings.allBundles.mode = destination.droppableId as typeof MODE_DEFAULT;
                } else {
                  typeSettings.perBundle![machineName].mode = destination.droppableId as typeof MODE_DEFAULT;
                }

                this.setState({ values, isDragging: false, draggedItemId: draggableId });
              }}
            >
              <Row>
                <ContentCol>
                  <Droppable droppableId={MODE_DEFAULT}>
                    {(provided) => (
                      <div
                        style={{ minHeight: '40px', height: '100%', ...(isDragging ? { boxShadow: 'inset 0 0 10px rgba(0,0,0,.2)' } : {}) }}
                        className={`p-2 ${isDragging ? 'bg-light' : ''} ${
                          validationErrorElements.displayDefaultTypes ? 'border border-danger rounded' : 'border border-white'
                        }`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {displayDefaultTypes.map((c, index) => {
                          const id = `${c.namespaceMachineName}\n${c.machineName}`;
                          return (
                            <Draggable
                              key={`${c.namespaceMachineName}\n${c.machineName}\n${c.grouped ? 'grouped' : ''}`}
                              draggableId={id}
                              index={index}
                            >
                              {(provided) => BundleOrType(provided, c, MODE_DEFAULT, id)}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {validationErrorElements.displayDefaultTypes && (
                          <div className="text-danger">{validationErrorElements.displayDefaultTypes}</div>
                        )}
                      </div>
                    )}
                  </Droppable>
                </ContentCol>
                <ContentCol>
                  <Droppable droppableId={MODE_DEPENDENT} isDropDisabled={dependentDisabled}>
                    {(provided) => (
                      <div
                        style={{
                          minHeight: '40px',
                          height: '100%',
                          ...(isDragging && !dependentDisabled ? { boxShadow: 'inset 0 0 10px rgba(0,0,0,.2)' } : {}),
                          ...(dependentDisabled ? { opacity: 0.5 } : {}),
                        }}
                        className={`p-2 ${isDragging && !dependentDisabled ? 'bg-light' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {displayDependentTypes.map((c, index) => {
                          const id = `${c.namespaceMachineName}\n${c.machineName}`;
                          return (
                            <Draggable
                              key={`${c.namespaceMachineName}\n${c.machineName}\n${c.grouped ? 'grouped' : ''}`}
                              isDragDisabled={dependentDisabled}
                              draggableId={id}
                              index={index}
                            >
                              {(provided) => BundleOrType(provided, c, dependentDisabled ? MODE_IGNORE : MODE_DEPENDENT, id)}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {!displayDependentTypes.length && <em className="text-muted">None.</em>}
                      </div>
                    )}
                  </Droppable>
                </ContentCol>
                <ContentCol>
                  <Droppable droppableId={MODE_IGNORE}>
                    {(provided) => (
                      <div
                        style={{ minHeight: '40px', height: '100%', ...(isDragging ? { boxShadow: 'inset 0 0 10px rgba(0,0,0,.2)' } : {}) }}
                        className={`p-2 ${isDragging ? 'bg-light' : ''}`}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {displayIgnoreTypes.map((c, index) => {
                          const id = `${c.namespaceMachineName}\n${c.machineName}`;
                          return (
                            <Draggable
                              key={`${c.namespaceMachineName}\n${c.machineName}\n${c.grouped ? 'grouped' : ''}`}
                              isDragDisabled={!c.supported}
                              draggableId={id}
                              index={index}
                            >
                              {(provided) => BundleOrType(provided, c, MODE_IGNORE, id)}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                        {hiddenUnsupportedTypes ? (
                          <div className={`text-muted ${displayIgnoreTypes.length ? 'mt-3' : ''}`}>
                            {hiddenUnsupportedTypes} bundles are not supported.{' '}
                            <Button variant="link" onClick={() => this.setState({ showUnsupportedEntityTypes: true })}>
                              Show
                            </Button>
                          </div>
                        ) : (
                          !displayIgnoreTypes.length && <em className="text-muted">None.</em>
                        )}
                        <div className="mt-3">
                          <HelpLink url={HELP_SUPPORTED_ENTITY_TYPES_AND_FIELDS}>What entity and field types are supported?</HelpLink>
                        </div>
                      </div>
                    )}
                  </Droppable>
                </ContentCol>
              </Row>
            </DragDropContext>
            {isDragging ? undefined : <div className="pt-5 pb-4" />}
          </>
        )}

        {currentStep === STEP_REVIEW && (
          <>
            <div className="mt-4 mb-5">
              <h2>Summary</h2>
              <ul>
                <li>
                  {values.mode === MODE_MANUALLY
                    ? values.type === TYPE_PUSH
                      ? 'Manually push content to'
                      : 'Manually pull new content from'
                    : values.type === TYPE_PUSH
                    ? 'Push content to'
                    : 'Pull content from'}{' '}
                  {values.pools ? (
                    values.pools.length === 1 ? (
                      <em>{pools.find((c) => c.machineName === values.pools![0])?.name}</em>
                    ) : (
                      `${values.pools.length} Pools`
                    )
                  ) : pools.length === 1 ? (
                    <em>{pools[0].name}</em>
                  ) : (
                    `${pools.length} Pools`
                  )}
                  {values.mode === MODE_AUTOMATICALLY
                    ? values.type === TYPE_PUSH
                      ? ' whenever it changes.'
                      : ' whenever new content is available.'
                    : '.'}
                </li>
                <li>
                  {values.type === TYPE_PUSH && (
                    <>
                      Users{' '}
                      {values.poolAssignment === ASSIGN_ALL_POOLS ? (
                        <>
                          can <strong>NOT</strong>
                        </>
                      ) : (
                        <strong>CAN</strong>
                      )}{' '}
                      select the Pools per content item.{' '}
                      {values.poolAssignment === ASSIGN_POOLS_MANUALLY && "If users select no Pool, the content won't be pushed."}
                      <br />
                    </>
                  )}
                  {values.type === TYPE_PULL && UPDATE_BEHAVIOR_DESCRIPTIONS[values.updateBehavior!]}
                </li>
                <li>
                  Entities of the following type / bundle will be included whenever they change:
                  <ul>
                    {displayDefaultTypes.map((c, index) => (
                      <li key={index}>{BundleOrTypeName(c)}</li>
                    ))}
                  </ul>
                </li>
              </ul>
            </div>
            <CollapsibleContainer
              level={1}
              defaultExpanded={showAdvanced || !!validationErrorElements.machineName}
              name={
                <>
                  Advanced settings{' '}
                  <small>
                    <small>{advancedSettingsBadges}</small>
                  </small>
                </>
              }
            >
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Machine name</HeaderCol>
                <ContentCol>
                  {editMachineName ? (
                    <Form.Control
                      type="text"
                      placeholder="Enter a machine name..."
                      value={values.machineName || ''}
                      onChange={(e) => {
                        values.machineName = nameToMachineName((e.target.value as string) || '');
                        this.setState({ values });
                      }}
                      className={validationErrorElements.machineName ? 'is-invalid' : 'is-valid'}
                    />
                  ) : (
                    <>
                      <span className="text-muted me-2">{values.machineName}</span>
                      {!editExisting && (
                        <Button variant="link" onClick={() => this.setState({ editMachineName: true })}>
                          Change
                        </Button>
                      )}
                    </>
                  )}
                  {validationErrorElements.machineName && <div className="text-danger mt-1">{validationErrorElements.machineName}</div>}
                </ContentCol>
              </Row>
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>{values.type === TYPE_PUSH ? 'Push deletions' : 'Delete automatically'}</HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={OPTIONS_YES_NO}
                    selected={values.deletions ? 'yes' : 'no'}
                    onSelect={(key) => {
                      values.deletions = key !== 'no';
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>
                  {values.type === TYPE_PUSH ? 'Push deletions of dependent entities' : 'Delete dependent entities automatically'}
                </HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={OPTIONS_YES_NO}
                    selected={values.embeddedDeletions ? 'yes' : 'no'}
                    onSelect={(key) => {
                      values.embeddedDeletions = key !== 'no';
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
              {values.type === TYPE_PULL && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Allow local deletion</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_YES_NO}
                      selected={values.allowLocalDeletion ? 'yes' : 'no'}
                      onSelect={(key) => {
                        values.allowLocalDeletion = key !== 'no';
                        this.setState({ values });
                      }}
                    />
                  </ContentCol>
                </Row>
              )}
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Ignore unpublished content</HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={OPTIONS_YES_NO}
                    selected={values.ignoreUnpublishedChanges ? 'yes' : 'no'}
                    onSelect={(key) => {
                      values.ignoreUnpublishedChanges = key !== 'no';
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Allow unpublishing content</HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={OPTIONS_YES_NO}
                    selected={values.allowExplicitUnpublishing ? 'yes' : 'no'}
                    onSelect={(key) => {
                      values.allowExplicitUnpublishing = key !== 'no';
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
              {this.props.params.languages && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Languages</HeaderCol>
                  <ContentCol>
                    <div style={{ maxWidth: '480px' }}>
                      <MultiSelect
                        hasSelectAll={false}
                        overrideStrings={{
                          selectSomeItems: 'No restriction.',
                        }}
                        options={this.props.params.languages.map((c) => ({ value: c.id, label: c.name }))}
                        value={
                          values.languages
                            ?.map((a) => this.props.params.languages!.find((b) => b.id === a))
                            .filter((c) => !!c)
                            .map((c) => ({ value: c!.id, label: c!.name })) || []
                        }
                        onChange={(selected: { value: ExternalServiceId }[]) => {
                          values.languages = selected.length ? selected.map((c) => c.value) : undefined;
                          this.setState({ values });
                        }}
                        labelledBy="Select"
                      />
                    </div>
                    <div className="text-muted">
                      Only entities / translations that use one of the selected languages will be pushed or pulled. If no language is
                      selected any language is allowed.
                    </div>
                    <div className="text-warning">
                      If the root language of a piece of content is not included here this content item will not be processed at all as the
                      root translation has to be identical across all sites.
                    </div>
                  </ContentCol>
                </Row>
              )}
              {values.type === TYPE_PUSH && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Push menu items</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_YES_NO}
                      selected={values.pushMenuItems ? 'yes' : 'no'}
                      onSelect={(key) => {
                        values.pushMenuItems = key !== 'no';
                        this.setState({ values });
                      }}
                    />
                  </ContentCol>
                </Row>
              )}
              {values.type === TYPE_PUSH && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Push previews</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_YES_NO}
                      selected={values.pushPreviews ? 'yes' : 'no'}
                      onSelect={(key) => {
                        values.pushPreviews = key !== 'no';
                        this.setState({ values });
                      }}
                    />
                  </ContentCol>
                </Row>
              )}
              {values.type === TYPE_PULL && values.updateBehavior === PULL_UPDATE_UNLESS_OVERRIDDEN && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Merge local changes</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_YES_NO}
                      selected={values.mergeLocalChanges ? 'yes' : 'no'}
                      onSelect={(key) => {
                        values.mergeLocalChanges = key !== 'no';
                        this.setState({ values });
                      }}
                    />
                    <InfoIcon>
                      This allows editors to make local changes to content and still receive new <strong>paragraphs</strong> from the source
                      site. This will disable some paragraph saving optimizations, so only enable this if you need it.
                    </InfoIcon>
                  </ContentCol>
                </Row>
              )}
              {values.type === TYPE_PULL &&
                ([PULL_UPDATE_CREATE_UNPUBLISHED_REVISION, PULL_UPDATE_FORCE] as (string | undefined)[]).includes(
                  values.updateBehavior
                ) && (
                  <Row>
                    <HeaderCol xs={HEADER_COL_SIZE}>Allow cross-sync</HeaderCol>
                    <ContentCol>
                      <SwitchButton
                        size="lg"
                        options={OPTIONS_YES_NO}
                        selected={values.allowCrossSync ? 'yes' : 'no'}
                        onSelect={(key) => {
                          values.allowCrossSync = key === 'yes';
                          this.setState({ values });
                        }}
                      />
                      <InfoIcon>
                        This allows editors to push content that was pulled from another site. By default content that has been pulled from
                        another site can't be pushed back.
                      </InfoIcon>
                    </ContentCol>
                  </Row>
                )}
              <Row>
                <HeaderCol xs={HEADER_COL_SIZE}>Resolve user references</HeaderCol>
                <ContentCol>
                  <SwitchButton
                    size="lg"
                    options={OPTIONS_USER_REFERENCES}
                    selected={values.resolveUserReferences || USER_REFERENCE_BY_NAME}
                    onSelect={(key) => {
                      values.resolveUserReferences = key;
                      this.setState({ values });
                    }}
                  />
                </ContentCol>
              </Row>
              {site.appType === SiteApplicationType.Drupal && values.type === TYPE_PUSH && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Manually assign Pools to paragraphs</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_YES_NO}
                      selected={values.assignPoolsManuallyToDependencies?.includes('paragraph') ? 'yes' : 'no'}
                      onSelect={(key) => {
                        if (key === 'yes') {
                          values.assignPoolsManuallyToDependencies = ['paragraph'];
                        } else {
                          delete values.assignPoolsManuallyToDependencies;
                        }
                        this.setState({ values });
                      }}
                    />
                  </ContentCol>
                </Row>
              )}
              {values.type === TYPE_PUSH && (
                <Row>
                  <HeaderCol xs={HEADER_COL_SIZE}>Pool selection widget</HeaderCol>
                  <ContentCol>
                    <SwitchButton
                      size="lg"
                      options={OPTIONS_POOL_WIDGET}
                      selected={values.poolSelectionWidget || POOL_WIDGET_CHECKBOXES}
                      onSelect={(key) => {
                        values.poolSelectionWidget = key;
                        this.setState({ values });
                      }}
                    />
                  </ContentCol>
                </Row>
              )}
            </CollapsibleContainer>
          </>
        )}

        <EmbeddedModal
          show={!!addPool}
          onHide={() => this.setState({ addPool: undefined, editPoolMachineName: false })}
          size="xl"
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Pool</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {unusedRemotePools.length > 0 && (
              <>
                <h2>Connect existing</h2>
                <ul>
                  {unusedRemotePools.map((c) => (
                    <li key={c.machineName}>
                      {c.name}{' '}
                      <Button
                        variant="link"
                        onClick={() => {
                          pools.push({
                            name: c.name,
                            machineName: c.machineName,
                          });
                          if (values.pools) {
                            values.pools.push(c.machineName);
                          }
                          this.setState({ pools, values, addPool: undefined, editPoolMachineName: false });
                        }}
                      >
                        Use
                      </Button>
                    </li>
                  ))}
                </ul>
                <div className="mt-4 text-light text-center">
                  <strong>-OR-</strong>
                </div>
                <h2 className="mt-4">Create new</h2>
              </>
            )}
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>Name</HeaderCol>
              <ContentCol>
                <Form.Control
                  type="text"
                  placeholder="Enter a name..."
                  value={addPool?.name || ''}
                  autoFocus={!!addPool}
                  onChange={(e) => {
                    const name = (e.target.value as string) || '';
                    this.setState({ addPool: { name, machineName: editPoolMachineName ? addPool!.machineName : nameToMachineName(name) } });
                  }}
                />
              </ContentCol>
            </Row>
            <Row>
              <HeaderCol xs={HEADER_COL_SIZE}>Machine name</HeaderCol>
              <ContentCol>
                {editPoolMachineName ? (
                  <Form.Control
                    type="text"
                    placeholder="Enter a machine name..."
                    value={addPool?.machineName || ''}
                    onChange={(e) => {
                      const machineName = (e.target.value as string) || '';
                      this.setState({ addPool: { ...addPool!, machineName: nameToMachineName(machineName) } });
                    }}
                  />
                ) : (
                  <>
                    <span className="text-muted me-2">{addPool?.machineName ? addPool?.machineName : <em>Please enter a name...</em>}</span>
                    <Button variant="link" onClick={() => this.setState({ editPoolMachineName: true })}>
                      Change
                    </Button>
                  </>
                )}
              </ContentCol>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button variant="light" onClick={() => this.setState({ addPool: undefined, editPoolMachineName: false })}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={!addPool?.name || !addPool?.machineName}
                onClick={() => {
                  pools.push(addPool!);
                  if (values.pools) {
                    values.pools.push(addPool!.machineName);
                  }
                  this.setState({ pools, values, addPool: undefined, editPoolMachineName: false });
                }}
              >
                Add
              </Button>
            </Right>
          </Modal.Footer>
        </EmbeddedModal>

        <EmbeddedModal show={saving || showSavingPopup} onHide={() => this.setState({ showSavingPopup: undefined })} size="xl" scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Do you want to {values.type === TYPE_PUSH ? 'push' : 'pull'} content next?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Is there existing content you want to {values.type === TYPE_PUSH ? 'push' : 'pull'} after saving this Flow?
            <br />
            <br />
            You will be redirected to the <em>Update</em> page to define what content exactly you want to{' '}
            {values.type === TYPE_PUSH ? 'push' : 'pull'}.
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button variant="light" disabled={saving} onClick={() => save(!!editExisting)}>
                {!editExisting ? 'Save' : <>Save and {values.type === TYPE_PUSH ? 'push' : 'pull'}</>}
              </Button>
              <Button variant="primary" disabled={saving} onClick={() => save(!editExisting)}>
                {editExisting ? 'Save' : <>Save and {values.type === TYPE_PUSH ? 'push' : 'pull'}</>}
              </Button>
            </Right>
          </Modal.Footer>
        </EmbeddedModal>

        <EmbeddedModal show={!!setMenuFilter} onHide={() => this.setState({ setMenuFilter: undefined })} size="xl" scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Filter by menu</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {setMenuFilter &&
              (() => {
                const entityType = this.props.params.bundles.find(
                  (c) => c.namespaceMachineName === setMenuFilter.namespaceMachineName && c.machineName === setMenuFilter.machineName
                );
                const menus = entityType!.menus!;
                const menuMachineNames = Object.keys(menus);
                const settings = values.entityTypeSettings![setMenuFilter.namespaceMachineName].perBundle
                  ? values.entityTypeSettings![setMenuFilter.namespaceMachineName].perBundle![setMenuFilter.machineName]
                  : values.entityTypeSettings![setMenuFilter.namespaceMachineName].allBundles!;
                const valueIndex = settings.filters?.findIndex((c) => c.type === 'menu');
                const value =
                  settings !== undefined && valueIndex! >= 0 ? (settings.filters![valueIndex!] as BundleSettingsMenuFilter) : undefined;
                return (
                  <>
                    {menuMachineNames.map((menuMachineName, index) => {
                      const checked = !!value?.values.includes(menuMachineName);
                      return (
                        <Form.Check
                          className="cursor-pointer me-0"
                          id={`menu-${menuMachineName}`}
                          type="checkbox"
                          checked={checked}
                          onChange={() => {
                            let newValue: BundleSettingsMenuFilter | undefined = value;
                            if (checked) {
                              newValue!.values.splice(newValue!.values.indexOf(menuMachineName), 1);
                              if (!newValue?.values.length) {
                                newValue = undefined;
                              }
                            } else {
                              if (newValue) {
                                newValue.values.push(menuMachineName);
                              } else {
                                newValue = {
                                  type: 'menu',
                                  values: [menuMachineName],
                                };
                              }
                            }
                            if (valueIndex !== undefined && valueIndex >= 0) {
                              if (newValue) {
                                settings.filters![valueIndex] = newValue;
                              } else {
                                settings.filters!.splice(valueIndex, 1);
                              }
                            } else if (newValue) {
                              if (!settings.filters) {
                                settings.filters = [];
                              }
                              settings.filters!.push(newValue);
                            }
                            this.setState({ values });
                          }}
                          label={menus[menuMachineName]}
                        />
                      );
                    })}
                  </>
                );
              })()}
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                variant="light"
                onClick={() => {
                  this.setState({ setMenuFilter: undefined });
                }}
              >
                Close
              </Button>
            </Right>
          </Modal.Footer>
        </EmbeddedModal>

        <EmbeddedModal show={!!setReferenceFilter} onHide={() => this.setState({ setReferenceFilter: undefined })} size="xl" scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Filter content</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingBottom: '300px' }}>
              {setReferenceFilter &&
                (() => {
                  const entityType = this.props.params.bundles.find(
                    (c) =>
                      c.namespaceMachineName === setReferenceFilter.namespaceMachineName && c.machineName === setReferenceFilter.machineName
                  );
                  const filterFields = entityType!.referenceFields.filter((c) => c.targetNamespaceMachineName === 'taxonomy_term');
                  return (
                    <>
                      {filterFields.map((field) => {
                        const valueIndex = values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                          setReferenceFilter.machineName
                        ].filters?.findIndex(
                          (c) =>
                            c.type === 'includes-reference' &&
                            (c as BundleSettingsFilter as BundleSettingsReferenceFilter).fieldMachineName === field.machineName
                        );
                        const value =
                          valueIndex !== undefined && valueIndex >= 0
                            ? values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![setReferenceFilter.machineName]
                                .filters![valueIndex]
                            : undefined;
                        return (
                          <FlowFormEntityTypeFilterByReference
                            entityTypes={bundles.filter(
                              (c) =>
                                c.namespaceMachineName === field.targetNamespaceMachineName &&
                                (!field.targetMachineNames || field.targetMachineNames.includes(c.machineName))
                            )}
                            key={field.machineName}
                            field={field}
                            value={value as BundleSettingsReferenceFilter}
                            onChange={(newValue) => {
                              if (valueIndex !== undefined && valueIndex >= 0) {
                                if (newValue) {
                                  values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                                    setReferenceFilter.machineName
                                  ].filters![valueIndex] = newValue;
                                } else {
                                  values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                                    setReferenceFilter.machineName
                                  ].filters!.splice(valueIndex, 1);
                                }
                              } else if (newValue) {
                                if (
                                  !values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                                    setReferenceFilter.machineName
                                  ].filters
                                ) {
                                  values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                                    setReferenceFilter.machineName
                                  ].filters = [];
                                }
                                values.entityTypeSettings![setReferenceFilter.namespaceMachineName].perBundle![
                                  setReferenceFilter.machineName
                                ].filters!.push(newValue);
                              }
                              this.setState({ values });
                            }}
                          />
                        );
                      })}
                    </>
                  );
                })()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Right>
              <Button
                variant="light"
                onClick={() => {
                  this.setState({ setReferenceFilter: undefined });
                }}
              >
                Close
              </Button>
            </Right>
          </Modal.Footer>
        </EmbeddedModal>

        <div style={{ boxShadow: '0 -0.125rem 0.25rem rgba(0, 0, 0, 8%)' }} className="mt-4 pt-1 pb-1">
          <LeftRightContainer
            left={
              <>
                <Button
                  variant="light"
                  disabled={!currentStep}
                  onClick={() => {
                    setStep(currentStep! - 1);
                    scrollToTop();
                  }}
                >
                  Back
                </Button>
              </>
            }
            right={
              <>
                <Button
                  disabled={!!validationErrors.length}
                  title={validationErrors[0]}
                  variant="primary"
                  onClick={() => {
                    if (currentStep === STEP_REVIEW) {
                      this.setState({ showSavingPopup: true });
                    } else {
                      setStep(currentStep! + 1);
                      scrollToTop();
                    }
                  }}
                >
                  {currentStep === STEP_REVIEW ? 'Save' : 'Continue'}
                </Button>
              </>
            }
          />
        </div>
      </div>
    );
  }
}

export const FlowForm = (props: Omit<IProps, 'params'>) => (
  <ParamsComponent<FlowFormParams> Params={FlowFormParams}>
    {(params: FlowFormParams) => {
      return <FlowFormWithParams params={params} {...props} />;
    }}
  </ParamsComponent>
);

class FlowFormEntityTypeFilterByReference extends React.Component<
  {
    entityTypes: {
      namespaceMachineName: ExternalServiceId;
      machineName: ExternalServiceId;
      name: string;
    }[];
    field: ReferenceField;
    value?: BundleSettingsReferenceFilter;
    onChange: (value?: BundleSettingsReferenceFilter) => void;
  },
  {}
> {
  render() {
    const { field, value, onChange, entityTypes } = this.props;

    return (
      <div className="mb-2">
        <h5>{field.name}</h5>
        <SelectEntityFromSite
          entityTypes={entityTypes}
          isClearable
          isMulti
          selected={value?.values.map((c) => ({
            namespaceMachineName: c.namespaceMachineName,
            machineName: c.machineName,
            remoteUuid: c.remoteUuid,
          }))}
          onSelect={(values) => {
            if (!values || !values.length) {
              onChange();
              return;
            }
            onChange({
              type: 'includes-reference',
              fieldMachineName: field.machineName,
              values: values.map((c) => ({
                namespaceMachineName: c.entityTypeNamespaceMachineName,
                machineName: c.entityTypeMachineName,
                remoteUuid: c.remoteUuid!,
              })),
            });
          }}
        />
      </div>
    );
  }
}
