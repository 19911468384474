import React from 'react';
import { AppParamsContext, IAppParams } from './AppParams';
import { WithSite } from './WithSite';

export type SiteFeature = 'count-entities' | 'request-per-translation' | 'request-polling' | 'site-settings-tab' | 'is-backend';

interface IProps {
  featureName: SiteFeature;
  children?: () => React.ReactNode;
  ifDisabled?: () => React.ReactNode;
}

interface IState {}

export class SiteFeatureFlagGate extends React.Component<IProps, IState> {
  render() {
    const { featureName, children, ifDisabled } = this.props;

    return (
      <AppParamsContext.Consumer>
        {(params: IAppParams) => {
          if (!Object.hasOwn(params.siteOptions.featureFlags, featureName) && params.siteOptions.featureFlags['is-backend']) {
            return (
              <WithSite entityId={'current'}>
                {(site) =>
                  site ? (site.featureFlags?.[featureName] ? (children ? children() : null) : ifDisabled ? ifDisabled() : null) : null
                }
              </WithSite>
            );
          }

          if (params.siteOptions.featureFlags[featureName]) {
            return children ? children() : null;
          }

          return ifDisabled ? ifDisabled() : null;
        }}
      </AppParamsContext.Consumer>
    );
  }
}

export function IsBackendGate(props: Omit<IProps, 'featureName'>) {
  return <SiteFeatureFlagGate featureName="is-backend" {...props}></SiteFeatureFlagGate>;
}
