import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { Button } from 'react-bootstrap';
import { Right } from '@edgebox/react-components';

interface IProps extends PropsWithChildren {
  variant: 'danger';
  headline?: React.ReactNode;
  icon?: IconProp;
  action?: {
    perform: () => void;
    label: React.ReactNode;
  };
}

export function BetterAlert({ variant, headline, children, icon, action }: IProps) {
  const alertIconType = faTimes;
  const alertIcon = (
    <span className="fa-stack fs-4">
      <FontAwesomeIcon icon={faCircle} className={`fa-stack-2x text-${variant}`} />
      <FontAwesomeIcon icon={alertIconType} className="text-white fa-stack-1x fs-3" />
    </span>
  );

  return (
    <div className="" style={{ maxWidth: '600px' }}>
      <div className={'d-flex flex-row mt-3 mb-3 align-items-center'}>
        <div className="flex-grow-0 flex-shrink-1">
          <div className={`shadow overflow-visible bg-white rounded w-100 mw-100 d-flex flex-row p-3 pe-5 align-items-center`}>
            <div className="flex-grow-0 flex-shrink-0 pe-3">{icon ? <FontAwesomeIcon icon={icon} size={'3x'} /> : alertIcon}</div>
            <div className="flex-grow-1 flex-shrink-1">
              {headline && <h3 className={`text-${variant} text-uppercase mb-2 fs-5`}>{headline}</h3>}

              <div>{children}</div>

              {action && (
                <Right className="mt-2">
                  <Button variant="danger" className={`bg-white text-${variant} py-2 px-3 shadow-sm`} onClick={action.perform}>
                    {action.label}
                  </Button>
                </Right>
              )}
            </div>
          </div>
        </div>

        {icon && (
          <div className="flex-grow-0 flex-shrink-0" style={{ marginLeft: '-30px', width: '60px', maxWidth: '60px', flexBasis: '60px' }}>
            <span className="align-middle">{alertIcon}</span>
          </div>
        )}
      </div>
    </div>
  );
}
