import { UnformattedTextProperty } from '@edgebox/data-definition-kit';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ParamsComponent } from '../ParamsComponent';

interface IFormatStatsNumberProps {
  count: number;
}

export class FormatStatsNumber extends React.Component<IFormatStatsNumberProps, {}> {
  render() {
    let { count } = this.props;

    let suffix: string | undefined = undefined;
    if (count > 1_000_000) {
      count /= 1_000_000;
      suffix = 'M';
    } else if (count > 1_000) {
      count /= 1_000;
      suffix = 'K';
    }

    return suffix ? `${count.toFixed(1)} ${suffix}` : count;
  }
}

class BoxParams {
  @UnformattedTextProperty(false, 500)
  className?: string;
}

interface IProps {}

interface IState extends ISyncCoreApiComponentState {
  count?: number;
}

export class ContentCountCustomer extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const response = await this.api.syndication.remoteEntityRevisions.list(
      {
        forCustomer: true,
      },
      {
        itemsPerPage: 0,
      }
    );

    return {
      count: response.totalNumberOfItems,
    };
  }

  render() {
    const { count } = this.state;

    if (typeof count !== 'number') {
      return this.renderRequest();
    }

    return (
      <ParamsComponent<BoxParams> Params={BoxParams}>
        {(params: BoxParams) => (
          <div className={params.className}>
            <FormatStatsNumber count={count} />
          </div>
        )}
      </ParamsComponent>
    );
  }
}
