import moment from 'moment';
import React from 'react';

const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
const yesterday = today.clone().subtract(1, 'day');
const last7Days = today.clone().subtract(7, 'days');
const last6Months = today.clone().subtract(6, 'months');

export function getDateName(time: moment.Moment) {
  if (time.isAfter(today)) {
    return 'Today';
  } else if (time.isAfter(yesterday)) {
    return 'Yesterday';
  } else if (time.isAfter(last7Days)) {
    return time.format('dddd');
  } else if (time.isAfter(last6Months)) {
    return time.format('MMMM DD');
  }
  return time.format('MMMM DD, YYYY');
}

export function FormatDate({ children, withTime }: { withTime?: boolean; children: moment.Moment }): React.ReactElement {
  if (withTime) {
    return (
      <>
        {getDateName(children)}, {children.format('LT')}
      </>
    );
  }

  return <>{getDateName(children)}</>;
}
