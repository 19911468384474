import { ExternalServiceId, Uuid } from '@edgebox/data-definition-kit';
import React from 'react';
import { Col, FormSelect, Row } from 'react-bootstrap';
import { AsyncPaginate } from 'react-select-async-paginate';
import { IListEntity, listEntities, retrieveEntity } from '../frame-messages';
import { getStyleColors } from '../Helpers';

interface IItem {
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  remoteUuid?: Uuid;
  remoteUniqueId?: ExternalServiceId;
}

interface IEntityType {
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  name: string;
}

interface IProps {
  isClearable?: boolean;
  isMulti: true;
  selected?: IItem[];
  entityTypes: IEntityType[];
  onSelect: (selected?: IListEntity[]) => void;
}
interface IState {
  selectedEntityType?: IEntityType;
  selectedItems?: IListEntity[];
}

export class SelectEntityFromSite extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { selected, entityTypes } = this.props;

    if (selected && selected.length) {
      Promise.all(
        selected.map((c) =>
          retrieveEntity({
            namespaceMachineName: c.namespaceMachineName,
            machineName: c.machineName,
            sharedEntityId: c.remoteUuid || c.remoteUniqueId!,
          })
        )
      ).then((existingItems) =>
        this.setState({
          selectedItems: existingItems.map((c) => ({
            entityTypeNamespaceMachineName: c.entityTypeByMachineName.namespaceMachineName,
            entityTypeMachineName: c.entityTypeByMachineName.machineName,
            name: c.name,
            remoteUuid: c.remoteUuid,
            remoteUniqueId: c.remoteUniqueId,
          })),
          selectedEntityType: entityTypes[0],
        })
      );
    } else {
      this.setState({
        selectedItems: [],
        selectedEntityType: entityTypes[0],
      });
    }
  }

  render() {
    const { onSelect, isClearable, isMulti, entityTypes } = this.props;
    const { selectedItems, selectedEntityType } = this.state;

    if (!selectedItems) {
      return 'Loading...';
    }

    const { primary, danger } = getStyleColors();

    const selectedEntityTypeIndex = entityTypes.findIndex(
      (c) => selectedEntityType!.namespaceMachineName === c.namespaceMachineName && selectedEntityType!.machineName === c.machineName
    );

    const select = (
      <AsyncPaginate
        key={selectedEntityTypeIndex}
        isClearable={isClearable}
        isMulti={isMulti}
        loadOptions={async (search, lastOptions, additional: any) => {
          if (!selectedEntityType) {
            throw new Error('Must provide entity types.');
          }
          const response = await listEntities({
            page: additional ? additional.page + 1 : 0,
            itemsPerPage: 8,
            namespaceMachineName: selectedEntityType.namespaceMachineName,
            machineName: selectedEntityType.machineName,
            mode: 'all',
            search: search || '',
          });

          return {
            options: response.items,
            hasMore: response.page < response.numberOfPages - 1,
            additional: { page: response.page },
          };
        }}
        placeholder="Search..."
        value={selectedItems}
        getOptionValue={(option) => option.remoteUuid || option.remoteUniqueId!}
        getOptionLabel={(option) => option.name}
        onChange={(options) => {
          this.setState({ selectedItems: [...options] });
          onSelect([...options] || undefined);
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary,
            danger,
          },
        })}
      />
    );

    if (entityTypes.length > 1) {
      return (
        <Row>
          <Col xs={4}>
            <FormSelect
              value={selectedEntityTypeIndex.toString()}
              onChange={(e) => this.setState({ selectedEntityType: entityTypes[parseInt(e.target.value)] })}
            >
              {entityTypes.map((c, index) => {
                return (
                  <option key={index} value={index.toString()}>
                    {c.name}
                  </option>
                );
              })}
            </FormSelect>
          </Col>
          <Col>{select}</Col>
        </Row>
      );
    }

    return select;
  }
}
