import { LeftRightH1, Right } from '@edgebox/react-components';
import { SyncCoreDataDefinitionsEnumTranslator, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import { ClientMigrationEntityWithSummary, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { faBrowser } from '@fortawesome/pro-light-svg-icons/faBrowser';
import { faListRadio } from '@fortawesome/pro-light-svg-icons/faListRadio';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { EmbeddedModal } from '../../EmbeddedModal';
import { WithEntityTypeVersion } from '../../EntityTypeVersionName';
import { PagedSyndicationList } from '../../PagedSyndicationList';
import { doneStatus, getSyndicationStatusColor, getSyndicationStatusIcon } from '../SyndicationStatusIcon';
import Select from 'react-select';
import { getStyleColors } from '../../../Helpers';
import { SyncCoreApiContext } from '../../../contexts/SyncCoreApiContext';
import { Nugget } from '../../Nugget';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { SiteName } from '../../SiteName';
import { getEntityTypeIcon } from '../EntityTypeIcon';
import { getDateName } from '../../FormatDate';
import { REFRESH_INTERVAL_RUNNING, STATUS_FILTERS, StatusFilter } from './SyndicationHelper';
import { Breadcrumb } from '../Breadcrumb';
import { HeadlineBackButton } from '../HeadlineBackButton';

export function MigrationDetails({
  migration,
  setMigration,
  onSelectSyndication,
  showSiteName,
  noMassUpdatesName,
  noMigrationName,
  noMassUpdates,
}: {
  migration: ClientMigrationEntityWithSummary;
  setMigration: (migration?: ClientMigrationEntityWithSummary) => void;
  onSelectSyndication?: (syndication: ClientSyndicationEntity) => void;
  showSiteName?: boolean;
  noMigrationName?: string;
  noMassUpdatesName?: string;
  noMassUpdates?: () => void;
}) {
  const [refreshSelectedMigration, setRefreshSelectedMigration] = useState(0);
  const [confirm, setConfirm] = useState('');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('all');
  const mounted = useRef(false);
  const api = useContext(SyncCoreApiContext);

  useEffect(() => {
    mounted.current = true;

    selectMigration(migration, true);

    return () => {
      mounted.current = false;
    };
  }, []);

  function selectMigration(selectedMigration?: ClientMigrationEntityWithSummary, forceUpdate?: boolean) {
    if (refreshSelectedMigration) {
      clearTimeout(refreshSelectedMigration);
      setRefreshSelectedMigration(0);
    }

    if (selectedMigration) {
      // Sometimes the summary isn't updated quickly enough, so we can't use the
      // migration status itself here.
      if (selectedMigration.summary.find((c) => !doneStatus.includes(c.status)) || forceUpdate) {
        const timeout = setTimeout(async () => {
          if (!mounted.current) {
            return;
          }

          const updatedMigration = await api?.api.syndication.migrations.itemWithSummary(selectedMigration.id, true);
          selectMigration(updatedMigration);
        }, REFRESH_INTERVAL_RUNNING);

        setRefreshSelectedMigration(timeout as any);
      }
    }

    if (migration !== selectedMigration) {
      setMigration(selectedMigration);
    }
  }

  const { primary, danger } = getStyleColors();

  return (
    <>
      <div className="">
        {noMigrationName || noMassUpdatesName ? (
          <Breadcrumb
            items={[
              ...(noMassUpdatesName && noMassUpdates ? [{ name: noMassUpdatesName, onClick: () => noMassUpdates() }] : []),
              ...(noMigrationName ? [{ name: noMigrationName, onClick: () => setMigration() }] : []),
              { name: SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', migration.type) },
            ]}
          />
        ) : null}
        <LeftRightH1
          left={
            <>
              <HeadlineBackButton onClick={() => setMigration()} />
              <span className="d-inline-block align-middle ms-2">
                {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('MigrationType', migration.type)}
              </span>
            </>
          }
          right={
            migration.isActive ? (
              <Button variant="danger" onClick={() => setConfirm('abort')}>
                Abort all
              </Button>
            ) : undefined
          }
        />

        {confirm && (
          <EmbeddedModal show onHide={() => setConfirm('')}>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="text-danger">
                <strong>This will stop all operations that haven't started yet.</strong>
              </div>
              <div className="mt-2 mb-2">
                As Content Sync works completely asynchronously, the status may still change if some requests to your site are already in
                progress. You may want to refresh in a couple of seconds to see if that's the case.
              </div>

              <Right className="pe-3">
                <Button
                  variant="danger"
                  disabled={confirm === 'abort-loading'}
                  className={confirm === 'abort-loading' ? 'text-muted' : undefined}
                  onClick={async () => {
                    setConfirm('abort-loading');

                    try {
                      await api?.api.syndication.migrations.abort(migration.id);
                      const updated = await api?.api.syndication.migrations.itemWithSummary(migration.id);
                      selectMigration(updated);
                    } finally {
                      setConfirm('');
                    }
                  }}
                >
                  Abort
                </Button>
              </Right>
            </Modal.Body>
          </EmbeddedModal>
        )}

        <div className="mt-3">
          <Nugget icon={faClock} dark>
            {getDateName(migration.createdAt)}, {migration.createdAt.format('LT')}
          </Nugget>
          {showSiteName && (
            <Nugget icon={faBrowser} dark>
              <SiteName id={migration.site.getId()} inline />
            </Nugget>
          )}
          {migration.entityTypeVersion ? (
            <WithEntityTypeVersion entityId={migration.entityTypeVersion.getId()!}>
              {(entityTypeVersion) => (
                <Nugget
                  dark
                  icon={
                    getEntityTypeIcon(entityTypeVersion.appType, entityTypeVersion.namespaceMachineName, entityTypeVersion.machineName).icon
                  }
                >
                  {entityTypeVersion.name}
                </Nugget>
              )}
            </WithEntityTypeVersion>
          ) : null}
          {migration.summary
            .sort((a, b) => (a.status < b.status ? -1 : 1))
            .map((summary, index) => (
              <Nugget
                icon={getSyndicationStatusIcon(summary.status)}
                iconSpin={summary.status === SyndicationStatus.Initializing}
                dark
                key={index}
                color={getSyndicationStatusColor(summary.status)}
              >
                <span
                  className="cursor-pointer text-decoration-underline"
                  onClick={() =>
                    setStatusFilter(
                      Object.entries(STATUS_FILTERS).find(([, statuses]) => statuses?.includes(summary.status))?.[0] as StatusFilter
                    )
                  }
                >
                  {summary.count}x
                </span>
              </Nugget>
            ))}

          {migration.runInOrder && (
            <Nugget icon={faListRadio} dark>
              Ordered
            </Nugget>
          )}
        </div>

        <div className="mt-3">
          <Select
            placeholder="Status"
            value={{ statusFilter }}
            getOptionValue={(option) => option.statusFilter || ''}
            getOptionLabel={(option) => option.statusFilter?.replace(/-/g, ' ') || ''}
            formatOptionLabel={(label) => <div className={`text-capitalize`}>{label?.statusFilter?.replace(/-/g, ' ') || ''}</div>}
            options={(Object.keys(STATUS_FILTERS) as StatusFilter[]).map((statusFilter: StatusFilter) => ({ statusFilter }))}
            onChange={(option) => option && setStatusFilter(option.statusFilter)}
            styles={{
              container: (base) => ({
                ...base,
                width: '200px',
                display: 'inline-block',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary,
                danger,
              },
            })}
          />
        </div>

        <PagedSyndicationList
          includeViewLink
          groupByDate
          isOnPageBackground
          migrationId={migration.id}
          statuses={statusFilter && statusFilter !== 'all' ? STATUS_FILTERS[statusFilter] : undefined}
          onSelect={onSelectSyndication}
        />
      </div>
    </>
  );
}
