import { ClientSiteEntity, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { useContext, useEffect, useState } from 'react';
import { UpdateStatusBoxWithParams } from './Customer/Box/UpdateStatusBox';
import React from 'react';
import { SyncCoreFeatureFlagGate } from './SyncCoreFeatureFlagGate';
import { FEATURE_ASYNC_SITE_CONFIG_AVAILABLE } from '../features';
import { Button } from 'react-bootstrap';
import { SyncCoreApiContext } from '../contexts/SyncCoreApiContext';
import { getCurrentSiteUuid } from '../services/SyncCoreApiComponent';

interface IProps {
  site?: ClientSiteEntity;
  variant?: string;
}

export function SyncConfigButton({ site, variant }: IProps) {
  const [exportingConfig, setExportingConfig] = useState<ClientSyndicationEntity | undefined>(undefined);
  const [useSite, setUseSite] = useState(site);
  const api = useContext(SyncCoreApiContext);

  useEffect(() => {
    const uuid = getCurrentSiteUuid(api!.jwt);
    if (uuid) {
      api!.api.billing.sites.itemByUuid(uuid).then((setSite) => setUseSite(setSite));
    }
  }, [site]);

  if (exportingConfig) {
    return <UpdateStatusBoxWithParams params={{ id: exportingConfig.id }} />;
  }

  if (!useSite) {
    return null;
  }

  if (!useSite.restUrls.siteConfig) {
    return null;
  }

  return (
    <SyncCoreFeatureFlagGate
      featureName={FEATURE_ASYNC_SITE_CONFIG_AVAILABLE}
      ifEnabled={() => (
        <Button
          variant={variant || 'light'}
          onClick={async () => {
            const exportingConfig = await api!.api.billing.sites.updateConfig('all');
            setExportingConfig(exportingConfig);
          }}
        >
          Sync config
        </Button>
      )}
    />
  );
}
