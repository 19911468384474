import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren {
  size?: '2x' | '1x' | 'sm';
  backgroundColor?: string;
  biggerBackground?: boolean;
}

export class IconWithBackground extends React.Component<IProps, {}> {
  render() {
    const { children, size, backgroundColor, biggerBackground } = this.props;

    return (
      <span className="fa-stack" style={size === 'sm' ? { height: '0.7em', width: '1.4em' } : undefined}>
        <FontAwesomeIcon
          icon={faCircle}
          className={`fa-stack-${(size === 'sm' ? '1x' : size) || '1x'} icon-background`}
          color={backgroundColor || 'white'}
          style={{
            ...(biggerBackground ? { zoom: '1.15' } : {}),
          }}
        />
        {children}
      </span>
    );
  }
}
