import { InternalId } from '@edgebox/data-definition-kit';
import { ClientSiteEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties } from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';
import { ExternalLink } from '@edgebox/react-components';

interface IProps {
  id: InternalId;
  light?: boolean;
  className?: string;
  linkIconClassName?: string;
  style?: CSSProperties;
  noIcon?: boolean;
  link?: string;
  inline?: boolean;
  middle?: boolean;
  withTitle?: boolean;
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
}

export class SiteName extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { id } = this.props;

    const site = await this.api.billing.sites.item(id);

    return {
      site,
    };
  }

  render() {
    const { light, className, style, link, linkIconClassName, noIcon, inline, middle, withTitle } = this.props;

    const { site } = this.state;
    if (site) {
      if (link) {
        return noIcon ? (
          <ExternalLink to={link} className={className}>
            {site.name}
          </ExternalLink>
        ) : (
          <ExternalLinkWithIcon to={link} className={className} iconClassName={linkIconClassName}>
            {site.name}
          </ExternalLinkWithIcon>
        );
      }
      if (inline) {
        return site.name || null;
      }
      return (
        <span
          className={`text-truncate d-inline-block ${className || ''} ${middle ? 'align-middle' : ''}`}
          style={style}
          title={withTitle ? site.name : undefined}
        >
          {site.name}
        </span>
      );
    }

    return <FontAwesomeIcon icon={faSpinner} spin className={`text-${light ? 'light' : 'dark'}`} style={{ fontSize: '1.4em' }} />;
  }
}
