import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { ExternalLink, Right } from '@edgebox/react-components';
import { FlowSyndicationMode, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import { ClientSyndicationUsageSummary } from '@edgebox/sync-core-rest-client';
import React from 'react';
import { Alert, Badge, Button, Form, Modal } from 'react-bootstrap';
import { sendToParent } from '../../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../../services/SyncCoreApiComponent';
import { CollapsibleContainer } from '../../../CollapsibleContainer';
import { EmbeddedModal } from '../../../EmbeddedModal';
import { SyndicationStatusIcon } from '../../SyndicationStatusIcon';
import { EntityStatusWithParams } from '../EntityStatus';
import { FlowEntityTypeMigrationStatus, FlowMigrationStatus, MigrateParams } from './MigrateParams';

export function isTypeFinished(type: FlowEntityTypeMigrationStatus) {
  if (type.pullMode && !type.pulledEntity) {
    return false;
  }

  if (type.pushMode && !type.pushedEntity) {
    return false;
  }

  return true;
}

export function isTypeMarkedDone(type: FlowEntityTypeMigrationStatus) {
  if (type.skipTest) {
    return true;
  }

  return isTypeFinished(type);
}

export function isTypeSkipped(type: FlowEntityTypeMigrationStatus) {
  if (isTypeFinished(type)) {
    return false;
  }

  return type.skipTest;
}

interface IProps {
  params: MigrateParams;
}

interface IState extends ISyncCoreApiComponentState {
  params?: MigrateParams;
  submitting?: boolean;
  wantsToSkip?: boolean;
}

interface ITestInstructionsProps {
  params: MigrateParams;
  isForPush: boolean;
  mode: FlowSyndicationMode;
}
class TestInstructions extends React.Component<ITestInstructionsProps, {}> {
  render() {
    const { isForPush, mode, params } = this.props;

    if (isForPush) {
      if (mode === FlowSyndicationMode.All || mode === FlowSyndicationMode.Manually) {
        return (
          <Alert variant="light">
            <strong>Instructions</strong>
            <ol>
              <li>
                Look for a content item that is appropriate to test with
                {mode === FlowSyndicationMode.Manually && <> and make a small change you can look out for at the target sites</>}.
              </li>
              <li>
                Open the entity status view <ExternalLink to={params.settings.pushToV2Url}>here</ExternalLink>.
              </li>
              <li>
                Search for the selected entity and use the <em>Test-push to v2</em> action.
              </li>
              <li>
                To test with new content, you need to push the content to the old Sync Core first and then continue as described above.
              </li>
              <li>Reload this page to verify the updated status and continue testing.</li>
            </ol>
          </Alert>
        );
      } else {
        if (mode === FlowSyndicationMode.Dependent) {
          return (
            <Alert variant="light">
              <strong>Instructions</strong>
              <ol>
                <li>
                  Look for a content item that is appropriate to test with and that <strong>includes an entity of this type</strong>.
                </li>
                <li>
                  Open the entity status view <ExternalLink to={params.settings.pushToV2Url}>here</ExternalLink>.
                </li>
                <li>
                  Search for the <strong>parent</strong> entity you want to test with and use the <em>Test-push to v2</em> action.
                </li>
                <li>
                  To test with new content, you need to push the content to the old Sync Core first and then continue as described above.
                </li>
                <li>Reload this page to verify the updated status and continue testing.</li>
              </ol>
            </Alert>
          );
        }
      }
    } else {
      return (
        <Alert variant="light">
          <strong>Instructions</strong>
          <ol>
            <li>Open this tab at another site that pushes this entity type.</li>
            <li>Follow the instructions on the source site to push an entity that is configured to be pulled into this site.</li>
            <li>Return to this page and view the syndication progress for any potential issues.</li>
            <li>If you don't see a status here, double check for any error log messages at the source site and at this site.</li>
            <li>
              View the pulled content on this site for correctness, then hit <em>Verify</em>.
            </li>
            {mode === FlowSyndicationMode.Manually && (
              <li>
                To test with new content, push the new item to the Sync Core v2 in the same way, but then click{' '}
                <ExternalLink to={params.settings.pullManuallyFromV2Url!}>here</ExternalLink> to open the new pull dashboard that is
                connected to your new Sync Core
              </li>
            )}
            <li>Continue testing.</li>
          </ol>
        </Alert>
      );
    }
  }
}

interface IFlowEntityTypeProps {
  params: MigrateParams;
  flowSkipped: boolean;
  type: FlowEntityTypeMigrationStatus;
  defaultExpanded?: boolean;
}
interface IFlowEntityTypeState extends ISyncCoreApiComponentState {
  recent?: ClientSyndicationUsageSummary[];
}
class MigrateTestFlowEntityType extends SyncCoreApiComponent<IFlowEntityTypeProps, IFlowEntityTypeState> {
  async load() {
    const { type } = this.props;

    let summary: IPagedListResponse<ClientSyndicationUsageSummary> | undefined = undefined;
    if (type.pulledEntity || type.pushedEntity) {
      const site = await this.getCurrentSite();
      const { remoteUniqueId, remoteUuid } = type.pushedEntity || type.pulledEntity!;
      summary = await this.api.syndication.syndications.usageSummaryForSite(
        site!.uuid,
        { namespaceMachineName: type.namespaceMachineName, machineName: type.machineName },
        remoteUniqueId ? { remoteUniqueId } : { remoteUuid: remoteUuid! }
      );
    }

    return {
      recent: summary?.items || [],
    };
  }

  render() {
    const { flowSkipped, type, defaultExpanded, params } = this.props;
    const { recent } = this.state;

    if (!recent) {
      return this.renderRequest();
    }

    let status: React.ReactNode = undefined;
    if (recent.length) {
      if (recent[0].thisSite.status === SyndicationStatus.Finished) {
        status = 'check';
      } else {
        status = (
          <SyndicationStatusIcon
            status={recent[0].thisSite.status}
            errorType={recent[0].thisSite.operations?.[0]?.errors?.[0]?.type}
            size="sm"
          />
        );
      }
    } else if (flowSkipped || type.skipTest) {
      status = <Badge bg="warning">Skipped</Badge>;
    } else {
      status = <Badge bg="primary">New</Badge>;
    }

    const entity = type.pushedEntity || type.pulledEntity;

    return (
      <CollapsibleContainer
        name={
          <>
            {type.namespaceMachineName}.{type.machineName}
          </>
        }
        status={status}
        level={1}
        defaultExpanded={defaultExpanded}
      >
        {recent.length ? (
          entity ? (
            <EntityStatusWithParams
              params={{
                configurationAccess: true,
                remoteUuid: entity!.remoteUuid,
                remoteUniqueId: entity!.remoteUniqueId,
              }}
              recent={recent}
            />
          ) : (
            <Alert variant="light">
              It looks like there's an entity available but the Sync Core didn't provide the entity yet. Please reload this page in a couple
              of seconds to try again.
            </Alert>
          )
        ) : entity ? (
          <Alert variant="light">
            It looks like there's an entity available but the Sync Core didn't provide a status update yet. Please reload this page in a
            couple of seconds to try again.
          </Alert>
        ) : (
          <TestInstructions isForPush={!!type.pushMode} mode={type.pushMode || type.pullMode!} params={params} />
        )}
      </CollapsibleContainer>
    );
  }
}

export function isFlowTestDone(flow: FlowMigrationStatus) {
  return flow.types.filter((c) => flow.skipTest || isTypeMarkedDone(c)).length === flow.types.length;
}

interface IFlowProps {
  params: MigrateParams;
  flow: FlowMigrationStatus;
}
interface IFlowState {
  expandDependent?: boolean;
  wantsToSkip?: boolean;
  submitting?: boolean;
}
class MigrateTestFlow extends React.Component<IFlowProps, IFlowState> {
  constructor(props: IFlowProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { flow, params } = this.props;
    const { expandDependent, submitting, wantsToSkip } = this.state;
    const skipped = flow.types.filter((c) => isTypeSkipped(c));
    const finished = flow.types.filter((c) => flow.skipTest || isTypeMarkedDone(c));
    const done = isFlowTestDone(flow);

    const next = flow.types.find(
      (c) =>
        (c.pullMode || c.pushMode) &&
        (expandDependent || (c.pullMode || c.pushMode) !== FlowSyndicationMode.Dependent) &&
        !c.pushedEntity &&
        (!c.pulledEntity || !c.pulledEntity.verified)
    );

    return (
      <CollapsibleContainer
        name={
          <>
            Test <em>{flow.name}</em>
          </>
        }
        status={
          done ? (
            'check'
          ) : (
            <>
              <Badge bg="light" className="text-dark">
                {finished.length} / {flow.types.length}
              </Badge>
              {skipped.length > 0 && <Badge bg="warning">{skipped.length} skipped</Badge>}
            </>
          )
        }
        defaultExpanded={finished.length < flow.types.length}
      >
        <div className="mb-5">
          <Right>
            <Form.Check
              type="checkbox"
              inline
              checked={!!expandDependent}
              label={'Show dependent'}
              id={`flow-${flow.machineName}-show-dependent`}
              onChange={() => this.setState({ expandDependent: !expandDependent })}
            />
          </Right>
        </div>

        {flow.types.map((type, index) => {
          if (type.pullMode === FlowSyndicationMode.Dependent || type.pushMode === FlowSyndicationMode.Dependent) {
            if (!expandDependent) {
              return null;
            }
          }

          return (
            <MigrateTestFlowEntityType
              key={`${type.namespaceMachineName}.${type.machineName}`}
              flowSkipped={flow.skipTest}
              type={type}
              defaultExpanded={next === type}
              params={params}
            />
          );
        })}

        <Right className="me-0 mt-4 mb-2">
          {wantsToSkip && (
            <EmbeddedModal show={true} onHide={() => this.setState({ wantsToSkip: undefined })} size="xl" scrollable>
              <Modal.Header closeButton>
                <Modal.Title>Are you sure?</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                This will mark all unfinished testing for this Flow as <em>skipped</em> so you can continue to the next migration step.
                <br />
                <br />
                You can still come back later and continue testing.
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="danger"
                  className="fw-bold"
                  disabled={submitting}
                  onClick={() => {
                    this.setState({
                      submitting: true,
                    });

                    sendToParent({
                      type: 'migration-skip-flows-test',
                      machineNames: [flow.machineName],
                    });
                  }}
                >
                  Confirm
                </Button>
              </Modal.Footer>
            </EmbeddedModal>
          )}
          {!done && (
            <Button
              variant="danger"
              className="fw-bold"
              onClick={() => {
                this.setState({
                  wantsToSkip: true,
                });
              }}
            >
              Skip Flow
            </Button>
          )}
        </Right>
      </CollapsibleContainer>
    );
  }
}

export class MigrateTest extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const params = this.props.params;
    params.flows.forEach((c) =>
      c.types.sort((a, b) => {
        const alphabetical =
          a.namespaceMachineName > b.namespaceMachineName
            ? 1
            : a.namespaceMachineName < b.namespaceMachineName
            ? -1
            : a.machineName > b.machineName
            ? 1
            : -1;
        if (a.pushMode === FlowSyndicationMode.All || a.pullMode === FlowSyndicationMode.All) {
          if (b.pushMode === FlowSyndicationMode.All || b.pullMode === FlowSyndicationMode.All) {
            return alphabetical;
          }
          return -1;
        }
        if (a.pushMode === FlowSyndicationMode.Manually || a.pullMode === FlowSyndicationMode.Manually) {
          if (b.pushMode === FlowSyndicationMode.Manually || b.pullMode === FlowSyndicationMode.Manually) {
            return alphabetical;
          }
          return -1;
        }
        return alphabetical;
      })
    );

    return {
      params,
    };
  }

  render() {
    const { params } = this.props;
    const { submitting, wantsToSkip } = this.state;

    if (false === undefined) {
      return this.renderRequest();
    }

    const allSkipped = !params.flows.find((c) => !c.skipTest);

    return (
      <>
        {params.settings.mustEnableHealthSubmodule && (
          <Alert variant="warning">To test pushing, please enable the cms_content_sync_health submodule.</Alert>
        )}
        {params.flows.map((flow) => (
          <MigrateTestFlow key={flow.machineName} flow={flow} params={params} />
        ))}
        {!allSkipped && (
          <Right className="m-0 mt-3">
            <Button variant="danger" onClick={() => this.setState({ wantsToSkip: true })} disabled={submitting || wantsToSkip}>
              Skip all Flows
            </Button>
          </Right>
        )}
        {wantsToSkip && (
          <EmbeddedModal show={true} onHide={() => this.setState({ wantsToSkip: undefined })} size="xl" scrollable>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              This will mark all Flows with all their entity types as <em>skipped</em> so you can continue to the next migration step.
              <br />
              <br />
              You can still come back later and continue testing.
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                className="fw-bold"
                disabled={submitting}
                onClick={() => {
                  this.setState({
                    submitting: true,
                  });

                  sendToParent({
                    type: 'migration-skip-flows-test',
                    machineNames: params.flows.map((c) => c.machineName),
                  });
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </EmbeddedModal>
        )}
      </>
    );
  }
}
