import { ExternalServiceId } from '@edgebox/data-definition-kit';
import { SiteApplicationType } from '@edgebox/sync-core-data-definitions';
import { faFileArrowDown } from '@fortawesome/pro-light-svg-icons/faFileArrowDown';
import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons/faFileArrowUp';
import { faFolderArrowDown } from '@fortawesome/pro-light-svg-icons/faFolderArrowDown';
import { faFolderArrowUp } from '@fortawesome/pro-light-svg-icons/faFolderArrowUp';
import { faGear } from '@fortawesome/pro-light-svg-icons/faGear';
import { darkStyles } from 'react-json-view-lite';

export const jsonStyle: typeof darkStyles = {
  ...darkStyles,
  label: `${darkStyles.label} fw-normal`,
  container: `${darkStyles.container} rounded pb-1 ps-2`,
  collapseIcon: `${darkStyles.collapseIcon} json-viewer-collapse-icon`,
  expandIcon: `${darkStyles.expandIcon} json-viewer-expand-icon`,
};

export function getStyleColors() {
  const bodyStyles = window.getComputedStyle(document.body);
  const primary = bodyStyles.getPropertyValue('--primary');
  const danger = bodyStyles.getPropertyValue('--danger');

  return {
    primary,
    danger,
  };
}

export function formatDuration(durationInMs: number) {
  if (durationInMs < 1_000) {
    return `${durationInMs}ms`;
  }
  if (durationInMs < 10) {
    return `${(durationInMs / 1_000).toFixed(1)}s`;
  }
  const durationInSeconds = Math.round(durationInMs / 1_000);
  if (durationInSeconds > 86_400) {
    const durationInDays = Math.round(durationInSeconds / 86_400);
    if (durationInDays === 1) {
      return '1 day';
    }
    return `${durationInDays} days`;
  }
  if (durationInSeconds > 3_600) {
    const durationInMinutes = Math.round(durationInSeconds / 60);
    return `${Math.floor(durationInMinutes / 60)}:${(durationInMinutes % 60).toString().padStart(2, '0')}h`;
  }
  if (durationInSeconds > 60) {
    return `${Math.floor(durationInSeconds / 60)}:${(durationInSeconds % 60).toString().padStart(2, '0')}m`;
  }
  return `${durationInSeconds}s`;
}

export function nameToMachineName(name: string) {
  return name.toLowerCase().replace(/[^a-z0-9_]+/g, '_') as ExternalServiceId;
}

export function machineNameToName(machineName: string) {
  const result = nameToMachineName(machineName).replace(/_/g, ' ');
  return result.charAt(0).toUpperCase() + result.substring(1);
}

export function typeMachineNameToName(appType: SiteApplicationType, machineName: string) {
  if (machineName === 'node') {
    return 'Content';
  }
  return machineNameToName(machineName);
}

export const OPERATION_DURATION_WARNING = 60_000;
export const OPERATION_DURATION_DANGER = 120_000;
export const DURATION_WARNING = 60_000;
export const DURATION_DANGER = 120_000;
export const FULL_DURATION_WARNING = 180_000;
export const FULL_DURATION_DANGER = 300_000;

export const ICON_PUSH = faFileArrowUp;
export const ICON_PULL = faFileArrowDown;
export const ICON_PUSH_MANY = faFolderArrowUp;
export const ICON_PULL_MANY = faFolderArrowDown;
export const ICON_EXPORT_CONFIG = faGear;
