import { IRuntimeReference } from '@edgebox/data-definition-kit';
import { ClientPoolEntity } from '@edgebox/sync-core-rest-client';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PoolName } from './PoolName';
import { Nugget } from './Nugget';

interface IProps {
  poolReferences: IRuntimeReference<ClientPoolEntity>[];
  wide?: boolean;
  className?: string;
  nugget?: boolean;
  placement?: 'left' | 'right';
}

export class PoolSummaryIcon extends React.Component<IProps, {}> {
  render() {
    const { poolReferences, wide, className, nugget, placement } = this.props;

    if (poolReferences.length === 1 && nugget) {
      return (
        <Nugget icon={faLayerGroup}>
          <PoolName id={poolReferences[0].getId()} inline />
        </Nugget>
      );
    }

    return (
      <OverlayTrigger
        placement={placement || 'left'}
        overlay={(props) => (
          <Tooltip id={`tooltip-pools`} {...props} className={wide ? 'tooltip-wide' : undefined}>
            {poolReferences.length === 1 ? (
              <PoolName id={poolReferences[0].getId()} />
            ) : (
              <ul>
                {poolReferences.map((c) => (
                  <li key={c.getId()}>
                    <PoolName id={c.getId()} />
                  </li>
                ))}
              </ul>
            )}
          </Tooltip>
        )}
      >
        {nugget ? (
          <Nugget icon={faLayerGroup}>{poolReferences.length}</Nugget>
        ) : (
          <span className={`me-3 ${className || ''}`}>
            <FontAwesomeIcon icon={faLayerGroup} /> {poolReferences.length}
          </span>
        )}
      </OverlayTrigger>
    );
  }
}
