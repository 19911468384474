import underscore from 'underscore';
import { InternalId } from '@edgebox/data-definition-kit';
import YAML from 'yaml';
import React, { useEffect, useState } from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { ClientFileEntity, ClientRemoteEntityRevisionEntity, ClientRemoteEntityTypeVersionEntity } from '@edgebox/sync-core-rest-client';
import { WithEntityTypeVersion } from './EntityTypeVersionName';
import { Nugget } from './Nugget';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { PoolName } from './PoolName';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faFingerprint } from '@fortawesome/pro-light-svg-icons/faFingerprint';
import { faLanguage } from '@fortawesome/pro-light-svg-icons/faLanguage';
import { getDateName } from './FormatDate';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { EntityTypeIcon, getEntityTypeIcon } from './Customer/EntityTypeIcon';
import { Alert, Badge, Button, Form, Table } from 'react-bootstrap';
import { instanceToPlain } from 'class-transformer';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { faBoxesStacked } from '@fortawesome/pro-light-svg-icons/faBoxesStacked';
import { faDatabase } from '@fortawesome/pro-light-svg-icons/faDatabase';
import { ExternalLink, Throbber } from '@edgebox/react-components';
import { JsonView } from 'react-json-view-lite';
import { jsonStyle, typeMachineNameToName } from '../Helpers';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import { RemoteEntityReference, instanceOfRemoteEntityReference } from '@edgebox/sync-core-rest-client/dist/services/api';
import { ExternalLinkWithIcon } from './ExternalLinkWithIcon';
import {
  IRemoteEntityProperty,
  IRemoteEntityRootEmbed,
  IRemoteEntityTypeProperty,
  RemoteEntityTypePropertyType,
} from '@edgebox/sync-core-data-definitions';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faEquals } from '@fortawesome/pro-light-svg-icons/faEquals';
import { faAsterisk } from '@fortawesome/pro-light-svg-icons/faAsterisk';
import { SyncCoreApiContext } from '../contexts/SyncCoreApiContext';
import { SyncCoreFeatureFlagGate } from './SyncCoreFeatureFlagGate';
import { FEATURE_TYPE_VERSION_BY_MACHINE_NAME_AVAILABLE } from '../features';
import diffableHtml from 'diffable-html';
import { SwitchButton } from './SwitchButton';

// TODO: Add button to toggle fullscreen.
// TODO: Refactor the standard props to be one "context" object instead.
// TODO: Split into multiple files in new folder.

interface IProps {
  id: InternalId;
  comparisonId?: InternalId;
}

interface IState extends ISyncCoreApiComponentState {
  entity?: ClientRemoteEntityRevisionEntity;
  comparisonEntity?: ClientRemoteEntityRevisionEntity;
  entityTypes?: ClientRemoteEntityTypeVersionEntity[];
}

interface IContainerProps {
  entity: ClientRemoteEntityRevisionEntity;
  comparisonEntity?: ClientRemoteEntityRevisionEntity;
  entityTypes: ClientRemoteEntityTypeVersionEntity[];
}

type Item =
  | ClientRemoteEntityRevisionEntity
  | (IRemoteEntityRootEmbed & Partial<Omit<ClientRemoteEntityRevisionEntity, 'entityTypeVersion'>>);

interface IComparisonTreeElementPropertyValue {
  type: ClientRemoteEntityTypeVersionEntity;
  property: IRemoteEntityTypeProperty;
  nestedProperty: IRemoteEntityTypeProperty;
  value: any;
  rawValue: any;
  references?: IComparisonTreeElementReference[];
}
type ChangeType = 'added' | 'removed' | 'changed' | 'nested-change' | 'unchanged';
const CHANGE_TYPE_NAME: { [key in ChangeType]: string } = {
  'nested-change': 'Nested change',
  added: 'Added',
  changed: 'Changed',
  removed: 'Removed',
  unchanged: 'Unchanged',
};
type IComparisonTreeElementProperty = {
  change: ChangeType;
  parent?: IComparisonTreeElementProperty;
} & (
  | {
      from: IComparisonTreeElementPropertyValue;
      to?: IComparisonTreeElementPropertyValue;
    }
  | {
      from?: IComparisonTreeElementPropertyValue;
      to: IComparisonTreeElementPropertyValue;
    }
);
interface IComparisonTreeElementReference {
  type: ClientRemoteEntityTypeVersionEntity;
  property: IRemoteEntityTypeProperty;
  element: IComparisonTreeElement;
}
type IComparisonTreeElement = {
  children: IComparisonTreeElementReference[];
  parents: IComparisonTreeElementReference[];
  implicitChildren: IComparisonTreeElement[];

  properties: IComparisonTreeElementProperty[];
  propertiesNested: IComparisonTreeElementProperty[];

  change: ChangeType;

  embed: boolean;
} & (
  | {
      fromType: ClientRemoteEntityTypeVersionEntity;
      from: Item;
      toType?: ClientRemoteEntityTypeVersionEntity;
      to?: Item;
    }
  | {
      fromType?: ClientRemoteEntityTypeVersionEntity;
      from?: Item;
      toType: ClientRemoteEntityTypeVersionEntity;
      to: Item;
    }
);
interface IComparisonTree {
  to: ClientRemoteEntityRevisionEntity;
  from?: ClientRemoteEntityRevisionEntity;
  children: IComparisonTreeElement[];
  root: IComparisonTreeElement[];
  shared: IComparisonTreeElement[];
}

function getElementId(element: IComparisonTreeElement) {
  const anyItem = element.to ?? element.from!;
  return anyItem.remoteUniqueId || anyItem.remoteUuid;
}

function castPropertyValue(value: any, propertyType: IRemoteEntityTypeProperty, parents?: IRemoteEntityTypeProperty[]): any {
  if (value === null || value === undefined) {
    return value;
  }

  if (Array.isArray(value)) {
    value = value.map((c) => castPropertyValue(c, propertyType, parents));

    // This will change order between updates which breaks the diff, so we order it to show changes reliably.
    if (
      propertyType.type === RemoteEntityTypePropertyType.Object &&
      propertyType.remoteTypeName === 'map' &&
      propertyType.machineName === 'metatag'
    ) {
      value.sort((a: any, b: any) => {
        if (!a || !b || typeof a.tag !== 'string' || typeof b.tag !== 'string' || !a.attributes || !b.attributes) {
          return 0;
        }

        const nameCompare = String(a.tag).localeCompare(String(b.tag));
        if (nameCompare) {
          return nameCompare;
        }

        const aProperty = a.attributes.name ?? a.attributes.property ?? a.attributes.rel;
        const bProperty = b.attributes.name ?? b.attributes.property ?? b.attributes.rel;
        if (!aProperty || !bProperty) {
          return 0;
        }

        return String(aProperty).localeCompare(String(bProperty));
      });
    }

    return value;
  }

  const type = propertyType.type;

  // Is processed later and set to the matching IComparisonTreeElement or null if it's not embedded.
  if (type === RemoteEntityTypePropertyType.Reference) {
    return undefined;
  }

  if (type === RemoteEntityTypePropertyType.Boolean) {
    return Boolean(value);
  }

  if (type === RemoteEntityTypePropertyType.Float) {
    return Number(value);
  }

  if (type === RemoteEntityTypePropertyType.Integer) {
    return Number(value);
  }

  if (type === RemoteEntityTypePropertyType.String) {
    if (parents?.length === 1 && parents[0].remoteTypeName === 'easychart') {
      if (propertyType.machineName === 'config' || propertyType.machineName === 'csv') {
        try {
          const parsed = JSON.parse(value);
          return YAML.stringify(parsed, { sortMapEntries: true });
        } catch (e) {}
      }
    }

    if (
      parents?.length === 1 &&
      ['text', 'text_long', 'text_with_summary'].includes(parents[0].remoteTypeName!) &&
      propertyType.machineName === 'value'
    ) {
      if (typeof value === 'string' && (value = value.trim()) && value.startsWith('<') && value.endsWith('>')) {
        const pretty = diffableHtml(value);
        return pretty;
      }
    }

    return String(value);
  }

  if (type === RemoteEntityTypePropertyType.Object) {
    if (typeof value === 'object' && propertyType.properties) {
      if (propertyType.properties.length === 1 && propertyType.properties[0].machineName === 'value') {
        return castPropertyValue(value.value, propertyType.properties[0], [...(parents ?? []), propertyType]);
      }

      const mapped: any = {};
      for (const childProperty of propertyType.properties) {
        if (value[childProperty.machineName] !== undefined) {
          mapped[childProperty.machineName] = castPropertyValue(value[childProperty.machineName], childProperty, [
            ...(parents ?? []),
            propertyType,
          ]);
        }
      }
      return mapped;
    }
  }

  return value;
}
function valuesEqual(value1: any, value2: any, propertyType?: IRemoteEntityTypeProperty, sameReference = false): boolean {
  if (value1 && value2 && typeof value1 === 'object' && typeof value2 === 'object') {
    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (value1.length !== value2.length) {
        return false;
      }
      return !value1.find((a, i) => !valuesEqual(a, value2[i], propertyType, sameReference));
    }

    if (propertyType?.type === RemoteEntityTypePropertyType.Reference && sameReference) {
      const value1Id = value1.remoteUniqueId || value1.remoteUuid;
      const value2Id = value2.remoteUniqueId || value2.remoteUuid;
      if (value1Id && value2Id) {
        return value1Id !== value2Id;
      }
    }

    return underscore.isEqual(value1, value2);
  }
  return value1 === value2;
}
function getComparisonTree(
  entity: ClientRemoteEntityRevisionEntity,
  entityTypes: ClientRemoteEntityTypeVersionEntity[],
  comparisonEntity?: ClientRemoteEntityRevisionEntity
): IComparisonTree {
  const allToItems: Item[] = [entity, ...(entity.embed ?? [])];
  const allFromItems: Item[] = [...(comparisonEntity ? [comparisonEntity] : []), ...(comparisonEntity?.embed ?? [])];

  const root: IComparisonTreeElement[] = [];
  const children: IComparisonTreeElement[] = [];

  function processProperties(
    element: IComparisonTreeElement,
    properties: IComparisonTreeElementProperty[],
    parents?: IComparisonTreeElement[]
  ) {
    function applyReferenceValue<Type extends 'from' | 'to'>(
      type: Type,
      property: IComparisonTreeElementProperty,
      values: IComparisonTreeElementReference[]
    ) {
      const simpleValues = values.map((c) => c.element);
      const applyAtProperty = property[type];
      if (applyAtProperty) {
        applyAtProperty.value = simpleValues;
        applyAtProperty.references = values;
      }
      if (property.change === 'unchanged' && values.find((c) => c.element.change !== 'unchanged')) {
        property.change = 'nested-change';
      }

      const applyAtParent = property.parent?.[type];
      if (applyAtProperty && applyAtParent) {
        applyAtParent.references = (applyAtParent.references ?? []).concat(values);

        if (Array.isArray(applyAtParent.value)) {
          applyAtParent.value[0][applyAtProperty.property.machineName] = simpleValues;
        } else {
          applyAtParent.value[applyAtProperty.property.machineName] = simpleValues;
        }
      }

      /*const parents: IComparisonTreeElementProperty[] = [];
      let childProperty = property;
      while (childProperty.parent) {
        parents.push(childProperty.parent);
        childProperty = childProperty.parent;
      }*/
    }

    for (const property of properties) {
      if (property.to?.property.type === RemoteEntityTypePropertyType.Reference && property.to.rawValue) {
        const sourceValues = Array.isArray(property.to.rawValue) ? property.to.rawValue : [property.to.rawValue];
        const values: IComparisonTreeElementReference[] = [];
        for (const value of sourceValues) {
          const referenceTo = allToItems.find(
            (toItem) => (toItem.remoteUniqueId || toItem.remoteUuid) === (value.remoteUniqueId || value.remoteUuid)
          );
          const referenceFrom = allFromItems.find(
            (fromItem) => (fromItem.remoteUniqueId || fromItem.remoteUuid) === (value.remoteUniqueId || value.remoteUuid)
          );
          if (!referenceTo) {
            property.to.value = null;
            continue;
          }

          // Avoid endless loops from cirular references.
          if (parents?.find((c) => c.to === referenceTo) || (referenceFrom && parents?.find((c) => c.from === referenceFrom))) {
            continue;
          }

          const childElement = process(
            {
              to: referenceTo,
              from: referenceFrom,
            },
            [...(parents ?? []), element]
          );

          const reference = {
            element: childElement,
            property: property.to.property,
            type: property.to.type,
          };

          if (!element.children.find((c) => c.element === childElement && c.property === property.to?.property)) {
            element.children.push(reference);

            childElement.parents.push({
              element: element,
              property: property.to.property,
              type: property.to.type,
            });
          }

          values.push(reference);
        }

        applyReferenceValue('to', property, values);
      }

      if (property.from?.property.type === RemoteEntityTypePropertyType.Reference && property.from.rawValue) {
        const sourceValues = Array.isArray(property.from.rawValue) ? property.from.rawValue : [property.from.rawValue];
        const values: IComparisonTreeElementReference[] = [];
        for (const value of sourceValues) {
          const referenceTo = allToItems.find(
            (toItem) => (toItem.remoteUniqueId || toItem.remoteUuid) === (value.remoteUniqueId || value.remoteUuid)
          );
          const referenceFrom = allFromItems.find(
            (fromItem) => (fromItem.remoteUniqueId || fromItem.remoteUuid) === (value.remoteUniqueId || value.remoteUuid)
          );
          if (!referenceFrom) {
            property.from.value = null;
            continue;
          }

          // Avoid endless loops from cirular references.
          if (parents?.find((c) => c.to === referenceFrom) || (referenceTo && parents?.find((c) => c.to === referenceTo))) {
            continue;
          }

          const childElement = process(
            {
              from: referenceFrom,
              to: referenceTo,
            },
            [...(parents ?? []), element]
          );

          const reference = {
            element: childElement,
            property: property.from.property,
            type: property.from.type,
          };

          if (!element.children.find((c) => c.element === childElement && c.property === property.from?.property)) {
            element.children.push(reference);

            childElement.parents.push({
              element: element,
              property: property.from.property,
              type: property.from.type,
            });
          }

          values.push(reference);
        }

        applyReferenceValue('from', property, values);
      }
    }
  }

  function process(
    items:
      | {
          from: Item;
          to?: Item;
        }
      | {
          from?: Item;
          to: Item;
        },
    parents?: IComparisonTreeElement[]
  ): IComparisonTreeElement {
    const { from: fromItem, to: toItem } = items;

    const existing = children.find((c) => (c.from ?? null) === (fromItem ?? null) && (c.to ?? null) === (toItem ?? null));
    if (existing) {
      return existing;
    }

    const findFromTypeByTyped = fromItem as ClientRemoteEntityRevisionEntity | IRemoteEntityRootEmbed | undefined;
    const fromType = findFromTypeByTyped
      ? findFromTypeByTyped instanceof ClientRemoteEntityRevisionEntity
        ? entityTypes.find((c) => c.id === findFromTypeByTyped.entityTypeVersion.getId())
        : entityTypes.find(
            (c) =>
              c.namespaceMachineName === findFromTypeByTyped.entityTypeNamespaceMachineName &&
              c.machineName === findFromTypeByTyped.entityTypeMachineName &&
              c.versionId === findFromTypeByTyped.entityTypeVersion
          )
      : undefined;
    if (fromItem && !fromType) {
      throw new Error(`Failed to get entity type.`);
    }

    const findToTypeByTyped = toItem as ClientRemoteEntityRevisionEntity | IRemoteEntityRootEmbed | undefined;
    const toType = findToTypeByTyped
      ? findToTypeByTyped instanceof ClientRemoteEntityRevisionEntity
        ? entityTypes.find((c) => c.id === findToTypeByTyped.entityTypeVersion.getId())
        : entityTypes.find(
            (c) =>
              c.namespaceMachineName === findToTypeByTyped.entityTypeNamespaceMachineName &&
              c.machineName === findToTypeByTyped.entityTypeMachineName &&
              c.versionId === findToTypeByTyped.entityTypeVersion
          )
      : undefined;
    if (toItem && !toType) {
      throw new Error(`Failed to get entity type.`);
    }

    const toProperties = [...(toItem?.properties ?? [])];
    toProperties.sort((a, b) => a.name.localeCompare(b.name));

    const fromProperties = [...(fromItem?.properties ?? [])];
    fromProperties.sort((a, b) => a.name.localeCompare(b.name));

    const properties: IComparisonTreeElementProperty[] = [];
    const propertiesNested: IComparisonTreeElementProperty[] = [];

    function addProperty(
      propertyType: IRemoteEntityTypeProperty,
      to?: IComparisonTreeElementPropertyValue,
      from?: IComparisonTreeElementPropertyValue,
      parent?: IComparisonTreeElementProperty
    ): IComparisonTreeElementProperty {
      const newProperty: IComparisonTreeElementProperty = {
        from,
        to: to!,
        change: from
          ? to
            ? valuesEqual(to?.value, from?.value, propertyType)
              ? 'unchanged'
              : from.rawValue
              ? 'changed'
              : 'added'
            : 'removed'
          : 'unchanged',
        parent,
      };

      if (parent) {
        propertiesNested.push(newProperty);
      } else {
        properties.push(newProperty);
      }

      if (propertyType.type === RemoteEntityTypePropertyType.Object && propertyType.properties) {
        for (const nestedProperty of propertyType.properties) {
          const nestedToValues = (
            (Array.isArray(to?.rawValue)
              ? to?.rawValue.map((c) => c?.[nestedProperty.machineName] ?? null)
              : [to?.rawValue?.[nestedProperty.machineName] ?? null]) ?? []
          ).filter((c) => c !== null);
          const nestedFromValues = (
            (Array.isArray(from?.rawValue)
              ? from?.rawValue.map((c) => c?.[nestedProperty.machineName] ?? null)
              : [from?.rawValue?.[nestedProperty.machineName] ?? null]) ?? []
          ).filter((c) => c !== null);
          //console.log("nested", nestedProperty.machineName, nestedProperty.type, nestedToValues, nestedFromValues, to?.rawValue, from?.rawValue, to?.value, from?.value);
          const allValues = nestedToValues
            .map((c, i) => [c, nestedFromValues[i] ?? null])
            .concat(nestedFromValues.slice(nestedToValues.length).map((c) => [null, c]));
          for (const [nestedToValue, nestedFromValue] of allValues) {
            if (nestedToValue === null && nestedFromValue === null) {
              continue;
            }

            const nestedTo: IComparisonTreeElementPropertyValue | undefined =
              to && nestedToValue !== null && nestedProperty
                ? {
                    property: nestedProperty,
                    nestedProperty:
                      nestedProperty.properties?.length === 1 && nestedProperty.properties[0].machineName === 'value'
                        ? nestedProperty.properties[0]
                        : nestedProperty!,
                    rawValue: nestedToValue,
                    value: castPropertyValue(nestedToValue, nestedProperty),
                    type: to.type,
                  }
                : undefined;

            const nestedFromPropertyType =
              from?.property.properties?.find((c) => c.machineName === nestedProperty.machineName) ?? nestedProperty;
            const nestedFrom: IComparisonTreeElementPropertyValue | undefined =
              from && nestedFromValue !== null
                ? {
                    property: nestedFromPropertyType,
                    nestedProperty:
                      nestedFromPropertyType.properties?.length === 1 && nestedFromPropertyType.properties[0].machineName === 'value'
                        ? nestedFromPropertyType.properties[0]
                        : nestedFromPropertyType,
                    rawValue: nestedFromValue,
                    value: castPropertyValue(nestedFromValue, nestedFromPropertyType),
                    type: from.type,
                  }
                : undefined;

            addProperty(nestedProperty, nestedTo, nestedFrom, newProperty);
          }
        }
      }

      return newProperty;
    }

    if (toItem) {
      for (const toPropertyValue of toProperties) {
        const toPropertyType = toType!.properties.find((c) => c.machineName === toPropertyValue.name);
        if (!toPropertyType) {
          continue;
        }

        const fromPropertyValue = fromProperties.find((c) => c.name === toPropertyValue.name);
        const fromPropertyType = fromPropertyValue ? fromType!.properties.find((c) => c.machineName === fromPropertyValue.name) : undefined;
        if (fromPropertyValue && !fromPropertyType) {
          continue;
        }

        const to = {
          rawValue: toPropertyValue.value,
          type: toType!,
          property: toPropertyType,
          nestedProperty:
            toPropertyType.properties?.length === 1 && toPropertyType.properties[0].machineName === 'value'
              ? toPropertyType.properties[0]
              : toPropertyType!,
          value: castPropertyValue(toPropertyValue.value, toPropertyType),
        };

        const from = fromPropertyValue
          ? {
              rawValue: fromPropertyValue.value,
              type: fromType!,
              property: fromPropertyType!,
              nestedProperty:
                fromPropertyType?.properties?.length === 1 && fromPropertyType.properties[0].machineName === 'value'
                  ? fromPropertyType.properties[0]
                  : fromPropertyType!,
              value: castPropertyValue(fromPropertyValue.value, fromPropertyType!),
            }
          : undefined;

        addProperty(toPropertyType, to, from);
      }
    }

    if (fromItem) {
      for (const fromProperty of fromProperties) {
        if (properties.find((c) => c.to?.property.machineName === fromProperty.name)) {
          continue;
        }

        const fromPropertyType = fromProperty ? fromType!.properties.find((c) => c.machineName === fromProperty.name) : undefined;
        if (!fromPropertyType) {
          continue;
        }

        const from = {
          rawValue: fromProperty.value,
          type: fromType!,
          property: fromPropertyType,
          nestedProperty:
            fromPropertyType?.properties?.length === 1 && fromPropertyType.properties[0].machineName === 'value'
              ? fromPropertyType.properties[0]
              : fromPropertyType!,
          value: castPropertyValue(fromProperty.value, fromPropertyType!),
        };

        addProperty(fromPropertyType, undefined, from);
      }
    }

    const anyType = toType ?? fromType!;

    const element: IComparisonTreeElement = {
      ...items,
      children: [],
      parents: [],
      implicitChildren: [],
      fromType: fromType!,
      toType: toType!,
      properties,
      propertiesNested,
      embed: anyType.namespaceMachineName === 'paragraph' || anyType.namespaceMachineName === 'brick',
      change: fromItem ? (toItem ? (properties.find((c) => c.change !== 'unchanged') ? 'changed' : 'unchanged') : 'removed') : 'added',
    };

    children.push(element);

    if (parents) {
      const existing = root.indexOf(parents[parents.length - 1]);
      if (existing > 0) {
        root.splice(existing, 1);
      }
    } else {
      root.push(element);
    }

    processProperties(element, properties, parents);
    processProperties(element, propertiesNested, parents);

    if (element.change === 'unchanged') {
      if (element.properties.find((c) => c.change !== 'unchanged')) {
        if (element.properties.find((c) => c.change !== 'nested-change')) {
          element.change = 'changed';
        } else {
          element.change = 'nested-change';
        }
      } else if (element.children.find((c) => c.element.change !== 'unchanged')) {
        if (element.children.find((c) => c.element.change !== 'nested-change')) {
          element.change = 'changed';
        } else {
          element.change = 'nested-change';
        }
      }
    }

    return element;
  }

  for (const toItem of allToItems) {
    if (children.find((c) => c.to === toItem)) {
      continue;
    }
    const fromItem = allFromItems.find(
      (fromItem) => (fromItem.remoteUniqueId || fromItem.remoteUuid) === (toItem.remoteUniqueId || toItem.remoteUuid)
    );
    process({ from: fromItem, to: toItem });
  }

  for (const fromItem of allFromItems) {
    if (children.find((c) => c.from === fromItem)) {
      continue;
    }
    const toItem = allFromItems.find(
      (toItem) => (fromItem.remoteUniqueId || fromItem.remoteUuid) === (toItem.remoteUniqueId || toItem.remoteUuid)
    );
    process({ from: fromItem, to: toItem });
  }

  const crops = children.filter((c) => (c.toType ?? c.fromType!).namespaceMachineName === 'crop');
  const files = children.filter((c) => (c.toType ?? c.fromType!).namespaceMachineName === 'file');
  for (const crop of crops) {
    const toFileUriProperty = crop.properties.find((c) => c.to?.property.machineName === 'uri');
    const toFileUri = toFileUriProperty?.to?.value?.[0];
    const fromFileUriProperty = crop.properties.find((c) => c.from?.property.machineName === 'uri');
    const fromFileUri = fromFileUriProperty?.from?.value?.[0];

    const toFile = toFileUri
      ? files.find((c) => c.to?.properties.find((c) => c.name === 'uri')?.value?.[0]?.value === toFileUri)
      : undefined;
    const fromFile = fromFileUri
      ? files.find((c) => c.from?.properties.find((c) => c.name === 'uri')?.value?.[0]?.value === fromFileUri)
      : undefined;

    if (toFile) {
      toFile.implicitChildren.push(crop);

      crop.parents.push({
        element: toFile,
        property: toFileUriProperty!.to!.property,
        type: crop.toType!,
      });

      if (root.includes(crop)) {
        root.splice(root.indexOf(crop), 1);
      }
    } else if (fromFile) {
      fromFile.implicitChildren.push(crop);

      crop.parents.push({
        element: fromFile,
        property: fromFileUriProperty!.from!.property,
        type: crop.fromType!,
      });

      if (root.includes(crop)) {
        root.splice(root.indexOf(crop), 1);
      }
    }
  }

  for (const element of children) {
    if (element.parents.length === 1) {
      element.embed = true;
    }
  }

  return {
    root,
    children,
    shared: children.filter((c) => c.parents.length > 1),
    to: entity,
    from: comparisonEntity,
  };
}

interface IInternalProps {
  tree: IComparisonTree;
  element: IComparisonTreeElement;
  setElement: (element: IComparisonTreeElement) => void;
  depth: number;
}

const byteSizeFormatter = Intl.NumberFormat(navigator.language, {
  notation: 'compact',
  style: 'unit',
  unit: 'byte',
  unitDisplay: 'narrow',
});
const dateFormatter = new Intl.DateTimeFormat(navigator.language, {
  dateStyle: 'medium',
  timeStyle: 'medium',
});

function FileValue({ id }: { id: InternalId }) {
  const [file, setFile] = useState<ClientFileEntity | undefined>(undefined);

  if (file && file.id === id) {
    if (file.downloadUrl) {
      if (file.mimeType.startsWith('image/')) {
        return <img src={file.downloadUrl} style={{ maxHeight: '200px', maxWidth: '100%' }} />;
      }
      return <ExternalLinkWithIcon to={file.downloadUrl}>Download</ExternalLinkWithIcon>;
    }

    return <em>Can't display file right now.</em>;
  }

  return (
    <SyncCoreApiContext.Consumer>
      {(api) => {
        if (!api) {
          return <Throbber />;
        }

        api.api.syndication.files
          .item(id)
          .then((file) => setFile(file))
          .catch((e) => {});
      }}
    </SyncCoreApiContext.Consumer>
  );
}

function PropertyValue({
  property,
  tree,
  setElement,
  depth,
}: {
  property: IComparisonTreeElementPropertyValue;
  tree: IComparisonTree;
  setElement: (element: IComparisonTreeElement) => void;
  depth: number;
}) {
  const type = property.nestedProperty.type;
  const value = property.value;

  if ((value === null || value === null) && property.rawValue !== null && property.rawValue !== undefined) {
    const serialized = YAML.stringify(property.rawValue, { sortMapEntries: true });
    return <SyntaxHighlighter language="yaml">{serialized}</SyntaxHighlighter>;
  }

  if (value === null) {
    return (
      <span style={{ color: 'gray' }}>
        <em>none</em>
      </span>
    );
  }

  if (value === undefined) {
    return (
      <span style={{ color: 'gray' }}>
        <em>missing</em>
      </span>
    );
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((value, i) => (
          <PropertyValue
            key={i}
            property={{
              type: property.type,
              property: property.property,
              nestedProperty: property.nestedProperty,
              rawValue: property.rawValue[i],
              value,
            }}
            setElement={setElement}
            tree={tree}
            depth={depth}
          />
        ))}
      </>
    );
  }

  if (type === RemoteEntityTypePropertyType.Boolean) {
    return <span style={{ color: value ? 'ForestGreen' : 'IndianRed', fontWeight: 'bold' }}>{value ? 'Yes' : 'No'}</span>;
  }
  if (type === RemoteEntityTypePropertyType.Integer || type === RemoteEntityTypePropertyType.Float) {
    if (property.nestedProperty.remoteTypeName === 'timestamp') {
      return <pre style={{ color: /*'SandyBrown'*/ 'DarkOrchid' }}>{dateFormatter.format(new Date(value * 1_000))}</pre>;
    }
    return <span style={{ color: 'RoyalBlue' }}>{value.toString()}</span>;
  }
  if (type === RemoteEntityTypePropertyType.String) {
    return <pre style={{ color: /*'SandyBrown'*/ 'gray' }}>{value}</pre>;
  }
  if (type === RemoteEntityTypePropertyType.File) {
    if (value.id) {
      return <FileValue id={value.id} />;
    }
    return <span style={{ color: 'gray' }}>{value.toString()}</span>;
  }

  if (type === RemoteEntityTypePropertyType.Reference) {
    const element: IComparisonTreeElement = value;
    if (element.embed) {
      return <EntityDetails element={element} tree={tree} setElement={setElement} depth={depth + 1} key={getElementId(element)} />;
    } else {
      const anyValue = element.to ?? element.from!;
      const anyType = element.toType ?? element.fromType!;
      // typed.viewUrl ? <ExternalLinkWithIcon to={typed.viewUrl}>view</ExternalLinkWithIcon>
      return (
        <>
          <Nugget icon={getEntityTypeIcon(anyType.appType, anyType.namespaceMachineName, anyType.machineName).icon}>{anyType.name}</Nugget>{' '}
          <Button variant="link" onClick={() => setElement(element)}>
            {anyValue.name || <em>(unnamed)</em>}
          </Button>
        </>
      );
    }
  }

  return <span style={{ color: 'gray' }}>{value.toString()}</span>;
}

function ObjectProperty({
  property,
  display,
  tree,
  setElement,
  depth,
}: {
  property: IComparisonTreeElementProperty;
  display: { isComparison: boolean; showUnchanged: boolean };
  tree: IComparisonTree;
  setElement: (element: IComparisonTreeElement) => void;
  depth: number;
}) {
  const anyProperties = property.to?.nestedProperty.properties ?? property.from?.nestedProperty.properties;

  if (anyProperties?.length) {
    let properties = [...anyProperties];
    for (const addProperty of (anyProperties === property.to?.nestedProperty.properties ? property.from : property.to)?.nestedProperty
      .properties ?? []) {
      if (properties.find((c) => c.machineName === addProperty.machineName)) {
        continue;
      }
      properties.push(addProperty);
    }

    properties = properties.filter(
      (c) =>
        ((property.from?.value?.[c.machineName] !== null && property.from?.value?.[c.machineName] !== undefined) ||
          (property.to?.value?.[c.machineName] !== null && property.to?.value?.[c.machineName] !== undefined)) &&
        (display.showUnchanged || !valuesEqual(property.from?.value?.[c.machineName], property.to?.value?.[c.machineName]))
    );

    return (
      <Table>
        <tbody>
          {properties.map((childProperty) => {
            const nestedFrom: IComparisonTreeElementPropertyValue | undefined = property.from
              ? {
                  ...property.from,
                  property:
                    property.from.nestedProperty.properties?.find((c) => c.machineName === childProperty.machineName) ?? childProperty,
                  nestedProperty:
                    property.from.nestedProperty.properties?.find((c) => c.machineName === childProperty.machineName) ?? childProperty,
                  value: property.from.value?.[childProperty.machineName],
                }
              : undefined;

            const nestedTo: IComparisonTreeElementPropertyValue | undefined = property.to
              ? {
                  ...property.to,
                  property: childProperty,
                  nestedProperty: childProperty,
                  value: property.to!.value?.[childProperty.machineName],
                }
              : undefined;

            if (!nestedFrom && !nestedTo) {
              return null;
            }

            return (
              <tr key={childProperty.machineName}>
                <th style={{ width: '200px' }}>{childProperty.name}</th>

                <td>
                  <Property
                    property={{
                      ...property,
                      change: !nestedFrom || !nestedTo ? 'unchanged' : property.change,
                      from: nestedFrom,
                      to: nestedTo!,
                    }}
                    display={display}
                    tree={tree}
                    setElement={setElement}
                    depth={depth}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  } else if (!display.isComparison || !property.from || property.change === 'unchanged') {
    const serialized = YAML.stringify((property.to ?? property.from!).value, { sortMapEntries: true });
    return <SyntaxHighlighter language="yaml">{serialized}</SyntaxHighlighter>;
  } else if (!property.to) {
    const serialized = YAML.stringify(property.from!.value, { sortMapEntries: true });
    return <SyntaxHighlighter language="yaml">{serialized}</SyntaxHighlighter>;
  } else {
    const oldValue = YAML.stringify(property.from?.value ?? '', { sortMapEntries: true });
    const newValue = YAML.stringify(property.to?.value ?? '', { sortMapEntries: true });
    const hideMarker = true;
    const neutralBackground = true;

    return (
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        hideLineNumbers
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
        styles={{
          marker: hideMarker
            ? {
                display: 'none',
              }
            : {
                padding: '0 4px 0 4px !important',
                fontWeight: 'bold',
              },
          diffRemoved: neutralBackground
            ? {
                background: 'none',
              }
            : {},
          diffAdded: neutralBackground
            ? {
                background: 'none',
              }
            : {},
        }}
      />
    );
  }
}

function Property({
  property,
  display,
  tree,
  setElement,
  depth,
}: {
  property: IComparisonTreeElementProperty;
  display: { isComparison: boolean; showUnchanged: boolean };
  tree: IComparisonTree;
  setElement: (element: IComparisonTreeElement) => void;
  depth: number;
}) {
  const anyProperty = property.to ?? property.from!;

  if (!display.isComparison || property.change === 'unchanged') {
    if (Array.isArray(anyProperty.value)) {
      return (
        <>
          {anyProperty.value.map((c, index) => (
            <Property
              key={index}
              property={{ ...property, [anyProperty === property.to ? 'to' : 'from']: { ...anyProperty, value: c } }}
              display={display}
              tree={tree}
              setElement={setElement}
              depth={depth}
            />
          ))}
        </>
      );
    }

    if (anyProperty.nestedProperty.type === RemoteEntityTypePropertyType.Object) {
      return <ObjectProperty property={property} display={display} tree={tree} setElement={setElement} depth={depth} />;
    }

    return <PropertyValue property={anyProperty} tree={tree} setElement={setElement} depth={depth} />;
  }

  const type = anyProperty.nestedProperty.type;

  const noFrom = !property.from || property.from.value === null || property.from.value === undefined || property.change === 'added';
  const noTo = !property.to || property.to.value === null || property.to.value === undefined || property.change === 'removed';

  if (Array.isArray(property.from?.value) || Array.isArray(property.to?.value)) {
    const lines: [any, any, ChangeType][] = [
      ...(property.from
        ? property.from.value.map((c: any) => [c, null, 'removed'])
        : property.to!.value.map((c: any) => [null, c, 'added'])),
    ];
    let diffLine = 0;
    for (const line of property.from ? property.to?.value ?? [] : []) {
      const nextMatch = lines
        .slice(diffLine)
        .findIndex((c) => c[0] !== null && c[1] === null && valuesEqual(c[0], line, anyProperty.property, true));
      if (nextMatch < 0) {
        if (lines.length === 1 && property.to?.value.length === 1 && type !== RemoteEntityTypePropertyType.Reference) {
          lines[0][1] = line;
          lines[0][2] = 'changed';
        } else {
          lines.splice(diffLine, 0, [null, line, 'added']);
          diffLine++;
        }
      } else {
        lines[nextMatch][1] = line;
        lines[nextMatch][2] = valuesEqual(lines[nextMatch][0], lines[nextMatch][1], anyProperty.property, false) ? 'unchanged' : 'changed';
        diffLine = nextMatch + 1;
      }
    }

    if (lines.length === 1) {
      const [from, to, change] = lines[0];
      return (
        <Property
          display={display}
          property={{
            change,
            from:
              property.from && !noFrom
                ? {
                    ...property.from,
                    value: from,
                  }
                : undefined,
            to: (property.to && !noTo
              ? {
                  ...property.to,
                  value: to,
                }
              : undefined)!,
          }}
          tree={tree}
          setElement={setElement}
          depth={depth}
        />
      );
    }

    if (
      lines.length === 2 &&
      ((lines[0][2] === 'added' && lines[1][2] === 'removed') ||
        (lines[0][2] === 'removed' && lines[1][2] === 'added') ||
        type === RemoteEntityTypePropertyType.Reference)
    ) {
      return (
        <React.Fragment>
          {lines.map(([from, to, change], index) => (
            <React.Fragment key={index}>
              <Property
                display={display}
                property={{
                  change,
                  from:
                    property.from && !noFrom
                      ? {
                          ...property.from,
                          value: from,
                        }
                      : undefined,
                  to: (property.to && !noTo
                    ? {
                        ...property.to,
                        value: to,
                      }
                    : undefined)!,
                }}
                tree={tree}
                setElement={setElement}
                depth={depth}
              />
            </React.Fragment>
          ))}
        </React.Fragment>
      );
    }

    return (
      <ul>
        {lines.map(([from, to, change], index) => (
          <li key={index}>
            <Property
              display={display}
              property={{
                change,
                from: {
                  ...property.from!,
                  value: from,
                },
                to: {
                  ...property.to!,
                  value: to,
                },
              }}
              tree={tree}
              setElement={setElement}
              depth={depth}
            />
          </li>
        ))}
      </ul>
    );
  }

  if (noFrom || !property.from) {
    if (property.to?.nestedProperty.type === RemoteEntityTypePropertyType.Object) {
      return (
        <>
          {(property.from || property.change === 'added') && type !== RemoteEntityTypePropertyType.Reference ? (
            <div>
              <Badge bg="success">{CHANGE_TYPE_NAME.added}</Badge>
            </div>
          ) : null}
          <div>
            <ObjectProperty property={property} display={display} tree={tree} setElement={setElement} depth={depth} />
          </div>
        </>
      );
    }

    return (
      <>
        {(property.from || property.change === 'added') && type !== RemoteEntityTypePropertyType.Reference ? (
          <div>
            <Badge bg="success">{CHANGE_TYPE_NAME.added}</Badge>
          </div>
        ) : null}
        <div>
          <PropertyValue property={property.to!} tree={tree} setElement={setElement} depth={depth} />
        </div>
      </>
    );
  }

  if (noTo || !property.to) {
    if (type === RemoteEntityTypePropertyType.Object) {
      return (
        <>
          {property.to || property.change === 'removed' ? (
            <div>
              <Badge bg="danger">{CHANGE_TYPE_NAME.removed}</Badge>
            </div>
          ) : null}
          <div>
            <ObjectProperty property={property} display={display} tree={tree} setElement={setElement} depth={depth} />
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          {(property.to || property.change === 'removed') && type !== RemoteEntityTypePropertyType.Reference ? (
            <Badge bg="danger">{CHANGE_TYPE_NAME.removed}</Badge>
          ) : null}{' '}
          <PropertyValue property={property.from!} tree={tree} setElement={setElement} depth={depth} />
        </div>
      </>
    );
  }

  const remoteType = property.to.nestedProperty.remoteTypeName;
  if (remoteType === 'timestamp') {
    return (
      <ReactDiffViewer
        oldValue={property.from.value ? dateFormatter.format(new Date(property.from.value * 1_000)) : ''}
        newValue={property.to.value ? dateFormatter.format(new Date(property.to.value * 1_000)) : ''}
        hideLineNumbers
        compareMethod={DiffMethod.CHARS}
        styles={{
          marker: {
            display: 'none',
          },
          diffRemoved: {
            background: 'none',
          },
          diffAdded: {
            background: 'none',
          },
        }}
      />
    );
  } else if (
    type === RemoteEntityTypePropertyType.String ||
    type === RemoteEntityTypePropertyType.Float ||
    type === RemoteEntityTypePropertyType.Integer
  ) {
    const oldValue = property.from.value.toString();
    const newValue = property.to.value.toString();
    const isMultiline = oldValue.includes('\n') || newValue.includes('\n');
    const hideMarker = !isMultiline;
    const neutralBackground = !isMultiline;
    return (
      <ReactDiffViewer
        oldValue={oldValue}
        newValue={newValue}
        hideLineNumbers
        compareMethod={DiffMethod.WORDS_WITH_SPACE}
        styles={{
          marker: hideMarker
            ? {
                display: 'none',
              }
            : {
                padding: '0 4px 0 4px !important',
                fontWeight: 'bold',
              },
          diffRemoved: neutralBackground
            ? {
                background: 'none',
              }
            : {},
          diffAdded: neutralBackground
            ? {
                background: 'none',
              }
            : {},
        }}
      />
    );
  } else if (type === RemoteEntityTypePropertyType.Object) {
    return <ObjectProperty property={property} display={display} tree={tree} setElement={setElement} depth={depth} />;
  }

  if (type === RemoteEntityTypePropertyType.Reference) {
    if (property.change === 'nested-change') {
      return (
        <>
          <PropertyValue property={property.to!} tree={tree} setElement={setElement} depth={depth} />
        </>
      );
    }

    return (
      <>
        <PropertyValue property={property.from!} tree={tree} setElement={setElement} depth={depth} />
        <PropertyValue property={property.to!} tree={tree} setElement={setElement} depth={depth} />
      </>
    );
  }

  return (
    <>
      <div>
        <Badge bg="danger">{CHANGE_TYPE_NAME.removed}</Badge>{' '}
        <PropertyValue property={property.from!} tree={tree} setElement={setElement} depth={depth} />
      </div>
      <div>
        <Badge bg="success">{CHANGE_TYPE_NAME.added}</Badge>{' '}
        <PropertyValue property={property.to!} tree={tree} setElement={setElement} depth={depth} />
      </div>
    </>
  );
}

function PropertyValueRaw(props: { entity: ClientRemoteEntityRevisionEntity; value: any; setItem: (item: Item) => void }) {
  const { entity, value, setItem } = props;
  if (typeof value === 'boolean' || value === null) {
    return <span style={{ color: 'violet' }}>{value === null ? 'null' : value.toString()}</span>;
  }
  if (typeof value === 'number') {
    return <span style={{ color: 'blue' }}>{value}</span>;
  }
  if (typeof value === 'string') {
    return <span style={{ color: 'red' }}>{JSON.stringify(value)}</span>;
  }
  if (value && typeof value === 'object') {
    if (Array.isArray(value)) {
      const allObjects = !value.find((c) => typeof c !== 'object');
      if (allObjects) {
        const allOnlyValueProperty = !value.find((c) => {
          const keys = Object.keys(c);
          return keys.length !== 1 || keys[0] !== 'value';
        });
        if (allOnlyValueProperty) {
          return (
            <>
              {value.map((c, i) => (
                <div key={i}>
                  <span className="text-muted">{'{ value:'} </span>
                  <PropertyValueRaw {...props} value={c.value} />
                  <span className="text-muted"> {'}'}</span>
                </div>
              ))}
            </>
          );
        }

        const oneLineValues = !value.find((c) => {
          const values = Object.values(c);
          return values.find((c) => c && (typeof c === 'object' || (typeof c === 'string' && c.includes('\n'))));
        });
        if (oneLineValues) {
          return (
            <>
              {value.map((c, i) => {
                const entries = Object.entries(c);
                if (entries.length === 2 && c.name && c.mail) {
                  return (
                    <a key={i} href={`mailto:${c.mail}`} className="btn btn-link">
                      {c.name} &lt;{c.mail}&gt;
                    </a>
                  );
                }
                return (
                  <div key={i}>
                    <span className="text-muted">{'{'} </span>
                    {entries.map(([name, value], index) => (
                      <span key={name}>
                        <span>
                          {index > 0 ? ', ' : null}
                          {name}:{' '}
                        </span>
                        <PropertyValueRaw {...props} value={value} />
                      </span>
                    ))}
                    <span className="text-muted"> {'}'}</span>
                  </div>
                );
              })}
            </>
          );
        }
      }

      return (
        <>
          {value.map((c, i) => (
            <div key={i}>
              <PropertyValueRaw {...props} value={c} />
            </div>
          ))}
        </>
      );
    } else if (instanceOfRemoteEntityReference(value)) {
      const typed: RemoteEntityReference = value;
      const exists = entity.embed?.find(
        (c) =>
          c.entityTypeNamespaceMachineName === typed.entityTypeNamespaceMachineName &&
          c.entityTypeMachineName === typed.entityTypeMachineName &&
          (c.remoteUniqueId || c.remoteUuid) === (typed.remoteUniqueId || typed.remoteUuid)
      );
      const name = typed.name || <em>unnamed</em>;

      return (
        <>
          <Nugget icon={getEntityTypeIcon(entity.appType, typed.entityTypeNamespaceMachineName, typed.entityTypeMachineName).icon}>
            {typed.entityTypeMachineName}
          </Nugget>{' '}
          {exists ? (
            <Button variant="link" onClick={() => setItem(exists)}>
              {name}
            </Button>
          ) : (
            name
          )}{' '}
          {!exists && typed.viewUrl ? <ExternalLinkWithIcon to={typed.viewUrl}>view</ExternalLinkWithIcon> : null}
        </>
      );
    }
  }
  return <JsonView data={value} style={jsonStyle} />;
}

function NavigationItemGroup({ label, children, badge }: { label: React.ReactNode; children: React.ReactNode; badge?: React.ReactNode }) {
  return (
    <React.Fragment>
      <div className="fw-bold px-1 py-1">
        {label}{' '}
        {badge ? (
          <Badge bg={'light'} className="opacity-75">
            {badge}
          </Badge>
        ) : null}
      </div>
      <div className="ps-4">{children}</div>
    </React.Fragment>
  );
}

function NavigationItem({
  onClick,
  element,
  active,
  isComparison,
  showEntityTypeIcon,
}: {
  onClick?: () => void;
  element: IComparisonTreeElement;
  active?: boolean;
  isComparison: boolean;
  showEntityTypeIcon?: boolean;
}) {
  const anyItem = element.to ?? element.from!;

  let icon: React.ReactNode = null;
  if (isComparison) {
    if (element.change === 'added') {
      icon = <FontAwesomeIcon icon={faPlus} className="text-success" />;
    } else if (element.change === 'removed') {
      icon = <FontAwesomeIcon icon={faMinus} className="text-danger" />;
    } else if (element.change === 'unchanged') {
      icon = <FontAwesomeIcon icon={faEquals} className="text-neutral" />;
    } else {
      icon = <FontAwesomeIcon icon={faAsterisk} className="text-warning" />;
    }
    icon = <>{icon} </>;
  }

  if (showEntityTypeIcon) {
    const entityType = element.toType ?? element.fromType!;
    icon = icon ? (
      <>
        {icon}
        <EntityTypeIcon
          appType={entityType.appType}
          namespaceMachineName={entityType.namespaceMachineName}
          machineName={entityType.machineName}
          useTitle={entityType.name}
        />{' '}
      </>
    ) : (
      <>
        <EntityTypeIcon
          appType={entityType.appType}
          namespaceMachineName={entityType.namespaceMachineName}
          machineName={entityType.machineName}
          useTitle={entityType.name}
        />{' '}
      </>
    );
  }

  return (
    <div className={`${active ? 'bg-primary rounded text-white' : 'cursor-pointer'} px-1 py-1`} onClick={onClick}>
      {icon}
      {anyItem.name || <em>(unnamed)</em>}
    </div>
  );
}

function EntityDetailsContainer({ entity, comparisonEntity, entityTypes }: IContainerProps) {
  const [activeElement, setActiveElement] = useState<IComparisonTreeElement | undefined>(undefined);
  const [tree, setTree] = useState<IComparisonTree | undefined>(undefined);
  const [groupByType, setGroupByType] = useState<boolean>(false);
  const [showUnchanged, setShowUnchanged] = useState<boolean>(false);

  useEffect(() => {
    const tree = getComparisonTree(entity, entityTypes, comparisonEntity);
    console.log(tree);
    setTree(tree);
    setActiveElement(tree.root[0]);
  }, [entity, comparisonEntity]);

  if (!tree || !activeElement) {
    return null;
  }

  const isComparison = !!tree.from;

  const navigation = (
    groupByType
      ? () => {
          const children = tree.children.filter((c) => showUnchanged || c.change !== 'unchanged');

          let types = children.map((c) => {
            const type = c.toType ?? c.fromType!;
            return type.namespaceMachineName;
          });
          types = types.filter((a, i) => types.indexOf(a) === i);
          types.sort((a, b) => {
            return typeMachineNameToName(entityTypes[0].appType, a).localeCompare(typeMachineNameToName(entityTypes[0].appType, b));
          });

          return (
            <>
              {types.map((typeName) => {
                const typeChildren = children.filter((c) => {
                  const type = c.toType ?? c.fromType!;
                  return type.namespaceMachineName === typeName;
                });
                let bundles = typeChildren.map((c) => {
                  const type = c.toType ?? c.fromType!;
                  return type.machineName;
                });
                bundles = bundles.filter((a, i) => bundles.indexOf(a) === i);
                bundles.sort((a, b) => {
                  const entityTypeA = entityTypes.find((c) => c.namespaceMachineName === typeName && c.machineName === a)!;
                  const entityTypeB = entityTypes.find((c) => c.namespaceMachineName === typeName && c.machineName === b)!;
                  return entityTypeA.name.localeCompare(entityTypeB.name);
                });

                return (
                  <NavigationItemGroup
                    key={typeName}
                    label={typeMachineNameToName(entityTypes[0].appType, typeName)}
                    badge={`${typeChildren.length}`}
                  >
                    {bundles.map((bundleName) => {
                      const elements = children.filter((c) => {
                        const type = c.toType ?? c.fromType!;
                        return type.namespaceMachineName === typeName && type.machineName === bundleName;
                      });
                      const entityType = entityTypes.find((c) => c.namespaceMachineName === typeName && c.machineName === bundleName)!;

                      return (
                        <NavigationItemGroup
                          key={bundleName}
                          label={
                            <>
                              <EntityTypeIcon
                                appType={entityType.appType}
                                namespaceMachineName={entityType.namespaceMachineName}
                                machineName={entityType.machineName}
                                useTitle=""
                              />{' '}
                              {entityType.name}
                            </>
                          }
                          badge={`${elements.length}`}
                        >
                          {elements.map((element, i) => {
                            return (
                              <NavigationItem
                                key={i}
                                active={activeElement === element}
                                element={element}
                                onClick={() => setActiveElement(element)}
                                isComparison={isComparison}
                              />
                            );
                          })}
                        </NavigationItemGroup>
                      );
                    })}
                  </NavigationItemGroup>
                );
              })}
            </>
          );
        }
      : () => {
          let root = tree.root.filter((c) => showUnchanged || c.change !== 'unchanged');
          if (root.length === 0) {
            root = tree.root;
          }
          const shared = tree.shared.filter((c) => showUnchanged || c.change !== 'unchanged');

          return (
            <div>
              <NavigationItemGroup label="Root">
                {root.map((element, i) => {
                  return (
                    <NavigationItem
                      key={i}
                      active={activeElement === element}
                      element={element}
                      onClick={() => setActiveElement(element)}
                      isComparison={isComparison}
                      showEntityTypeIcon
                    />
                  );
                })}
              </NavigationItemGroup>

              {shared.length > 0 ? (
                <NavigationItemGroup label="Shared">
                  {shared.map((element, i) => {
                    return (
                      <NavigationItem
                        key={i}
                        active={activeElement === element}
                        element={element}
                        onClick={() => setActiveElement(element)}
                        isComparison={isComparison}
                        showEntityTypeIcon
                      />
                    );
                  })}
                </NavigationItemGroup>
              ) : null}
            </div>
          );
        }
  )();

  return (
    <div className="d-flex">
      <div style={{ flexBasis: '300px' }} className="flex-shrink-0 flex-grow-0 bg-lighter rounded p-1 me-3">
        <div className="d-flex flex-column">
          <div className="flex-shrink-0 flex-grow-0 rounded bg-light py-2 px-3">
            <div>
              <Form.Check
                className="cursor-pointer"
                id="group-by-type"
                type="checkbox"
                checked={!!groupByType}
                onChange={() => setGroupByType(!groupByType)}
                label="Show nested"
              />
            </div>
            {isComparison ? (
              <div>
                <SwitchButton
                  options={{ '0': 'Changed', '1': 'All' }}
                  onSelect={(key) => setShowUnchanged(key === '1')}
                  selected={showUnchanged ? '1' : '0'}
                  size="m"
                />
              </div>
            ) : null}
          </div>
          <div className="flex-shrink-1 flex-grow-1">{navigation}</div>
        </div>
      </div>
      <div className="flex-shrink-1 flex-grow-1">
        <EntityDetails element={activeElement} setElement={setActiveElement} tree={tree} depth={1} key={getElementId(activeElement)} />
      </div>
    </div>
  );
}

const HIDE_PROPERTIES: { [key in string]?: string[] } = {
  node: ['menu_items', 'langcode', 'default_langcode', 'content_translation_source', 'status', 'title'],
  menu_link_content: ['menu_items', 'langcode', 'default_langcode', 'enabled', 'title', 'draggableviews'],
  brick: ['menu_items', 'langcode', 'default_langcode', 'title', 'draggableviews'],
  paragraph: [
    'menu_items',
    'langcode',
    'default_langcode',
    'content_translation_source',
    'status',
    'parent_type',
    'parent_field_name',
    'draggableviews',
  ],
  block: ['menu_items', 'uuid', 'langcode', 'default_langcode', 'status'],
  media: ['langcode', 'default_langcode', 'content_translation_source'],
  file: ['langcode', 'default_langcode', 'draggableviews', 'status', 'filename'],
  crop: ['menu_items', 'langcode', 'default_langcode', 'entity_id', 'entity_type', 'draggableviews'],
};

function EntityDetails({ element, setElement, tree, depth }: IInternalProps) {
  const item = element.to ?? element.from!;
  const entity = tree.to;
  const serialized = instanceToPlain(item);
  const size = item instanceof ClientRemoteEntityRevisionEntity ? JSON.stringify(serialized).length : undefined;

  // Missing: entity.appType, entity.translationRoot.

  const showUnchangedPropertiesDefault = element.change === 'added';
  const [showUnchangedPropertiesSetting, setShowUnchangedProperties] = useState(false);

  const isComparison = !!tree.from;
  const showUnchanged = showUnchangedPropertiesDefault || showUnchangedPropertiesSetting;

  const allProperties = element.properties.filter(
    (c) => !HIDE_PROPERTIES[(c.to?.type ?? c.from!.type).namespaceMachineName]?.includes((c.to ?? c.from!).property.machineName)
  );
  const showProperties = allProperties.filter(
    (c) =>
      (!isComparison && c.to?.rawValue !== null && c.to?.rawValue !== undefined) ||
      (showUnchanged &&
        ((c.to?.rawValue !== null && c.to?.rawValue !== undefined) || (c.from?.rawValue !== null && c.from?.rawValue !== undefined))) ||
      c.change !== 'unchanged'
  );
  const hideProperties = allProperties.filter((c) => !showProperties.includes(c));

  const headline = (
    <>
      {isComparison ? (
        <Badge
          bg={
            element.change === 'added'
              ? 'success'
              : element.change === 'removed'
              ? 'danger'
              : element.change === 'changed' || element.change === 'nested-change'
              ? 'warning'
              : 'light'
          }
          className="me-2"
        >
          {CHANGE_TYPE_NAME[element.change]}
        </Badge>
      ) : null}
      {item.name || <em>Unnamed entity</em>} {item.published !== false ? null : <Badge bg="danger">Draft</Badge>}{' '}
      {item.incomplete ? <Badge bg="danger">INCOMPLETE</Badge> : null}
    </>
  );

  const isTranslationRoot = item.isTranslationRoot || item.properties.find((c) => c.name === 'default_langcode')?.value;
  const translationSource = item.properties.find((c) => c.name === 'content_translation_source')?.value;

  return (
    <div className="mb-4 border-start-2 border-lighter ps-3">
      {depth > 4 ? <h4>{headline}</h4> : depth >= 3 ? <h3>{headline}</h3> : depth >= 2 ? <h2>{headline}</h2> : <h1>{headline}</h1>}
      <div>
        {typeof item.entityTypeVersion === 'object' ? (
          <WithEntityTypeVersion entityId={item.entityTypeVersion.getId()}>
            {(entityTypeVersion) => (
              <Nugget
                icon={
                  getEntityTypeIcon(entityTypeVersion.appType, entityTypeVersion.namespaceMachineName, entityTypeVersion.machineName).icon
                }
              >
                {entityTypeVersion.name}
              </Nugget>
            )}
          </WithEntityTypeVersion>
        ) : (
          <Nugget
            icon={
              getEntityTypeIcon(
                entity.appType,
                (item as IRemoteEntityRootEmbed).entityTypeNamespaceMachineName,
                (item as IRemoteEntityRootEmbed).entityTypeMachineName
              ).icon
            }
          >
            {(item as IRemoteEntityRootEmbed).entityTypeMachineName}
          </Nugget>
        )}
        <Nugget icon={faLanguage}>
          {item.language}
          {isTranslationRoot ? (
            <em> (root)</em>
          ) : translationSource ? (
            <>
              {' '}
              (from <em>{translationSource}</em>)
            </>
          ) : null}
        </Nugget>
        <Nugget icon={faLayerGroup}>
          {entity.pools.length > 3 ? (
            <>{entity.pools.length > 1 ? ` +${entity.pools.length - 1}` : null}</>
          ) : (
            entity.pools.map((poolReference) => <PoolName key={poolReference.getId()} id={poolReference.getId()} inline />)
          )}
        </Nugget>
        {item.createdAt && (
          <Nugget icon={faClock}>
            {getDateName(item.createdAt)}, {item.createdAt.format('LT')}
          </Nugget>
        )}
        {item.deleted ? (
          <Nugget icon={faTrashCan}>
            {item.deletedAt ? (
              <>
                {getDateName(item.deletedAt)}, {item.deletedAt.format('LT')}
              </>
            ) : (
              'Yes'
            )}
          </Nugget>
        ) : null}
        <Nugget icon={faFingerprint}>
          <span title={item.id}>{item.remoteUniqueId || item.remoteUuid}</span>
        </Nugget>
        {item.viewUrl && (
          <Nugget icon={faArrowUpRightFromSquare}>
            <ExternalLink to={item.viewUrl} className="text-dark">
              view
            </ExternalLink>
          </Nugget>
        )}
        {item.embed && <Nugget icon={faBoxesStacked}>{item.embed?.length}</Nugget>}
        {size && <Nugget icon={faDatabase}>{byteSizeFormatter.format(size)}</Nugget>}
      </div>

      <Table>
        <tbody>
          {showProperties.map((property) => {
            const anyProperty = property.to ?? property.from!;
            return (
              <tr key={anyProperty.property.machineName}>
                <th style={{ width: '200px' }}>{anyProperty.property.name}</th>

                <td>
                  <Property
                    property={property}
                    display={{ isComparison, showUnchanged }}
                    tree={tree}
                    setElement={setElement}
                    depth={depth}
                  />
                </td>
              </tr>
            );
          })}
          {element.implicitChildren.map((element, index) => {
            const anyType = element.toType ?? element.fromType!;
            return (
              <tr key={index}>
                <th style={{ width: '200px' }}>{typeMachineNameToName(anyType.appType, anyType.namespaceMachineName)}</th>

                <td>
                  <EntityDetails element={element} setElement={setElement} tree={tree} depth={depth + 1} key={getElementId(element)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {hideProperties.length ? (
        <div className="mt-2">
          <em>
            +{hideProperties.length} unchanged properties.{' '}
            <Button variant="link" onClick={() => setShowUnchangedProperties(true)}>
              Show
            </Button>
          </em>
        </div>
      ) : null}
    </div>
  );
}

export class DisplayEntityRevision extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { id, comparisonId } = this.props;
    const entity = await this.api.syndication.remoteEntityRevisions.item(id);
    const comparisonEntity = comparisonId ? await this.api.syndication.remoteEntityRevisions.item(comparisonId) : undefined;

    const typeReferences = [entity.entityTypeVersion];
    const allEmbed = [...(entity.embed ?? []), ...(comparisonEntity?.embed ?? [])];

    const typeReferencesByMachineName: {
      namespaceMachineName: string;
      machineName: string;
      versionId: string;
    }[] = [];
    for (const embed of allEmbed) {
      if (
        typeReferencesByMachineName.find(
          (c) =>
            c.namespaceMachineName === embed.entityTypeNamespaceMachineName &&
            c.machineName === embed.entityTypeMachineName &&
            c.versionId === embed.entityTypeVersion
        )
      ) {
        continue;
      }

      typeReferencesByMachineName.push({
        namespaceMachineName: embed.entityTypeNamespaceMachineName,
        machineName: embed.entityTypeMachineName,
        versionId: embed.entityTypeVersion,
      });
    }

    if (comparisonEntity) {
      if (!typeReferences.find((c) => c.getId() === comparisonEntity.entityTypeVersion.getId())) {
        typeReferences.push(comparisonEntity.entityTypeVersion);
      }
    }

    const entityTypes = await Promise.all([
      ...typeReferences.map((c) => c.get()),
      ...typeReferencesByMachineName.map((c) =>
        this.api.syndication.remoteEntityTypeVersions.itemByMachineName(c.namespaceMachineName, c.machineName, c.versionId)
      ),
    ]);

    return {
      entity,
      comparisonEntity,
      entityTypes,
    };
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.props.id !== prevProps.id || this.props.comparisonId !== prevProps.comparisonId) {
      this.setState({
        entity: undefined,
        comparisonEntity: undefined,
      });
      this.load();
    }
  }

  render() {
    const { entity, comparisonEntity, entityTypes } = this.state;

    if (!entity || !entityTypes) {
      return this.renderRequest();
    }

    return (
      <SyncCoreFeatureFlagGate
        featureName={FEATURE_TYPE_VERSION_BY_MACHINE_NAME_AVAILABLE}
        ifEnabled={() => <EntityDetailsContainer entity={entity} comparisonEntity={comparisonEntity} entityTypes={entityTypes} />}
        ifDisabled={() => (
          <Alert variant="warning">
            The Sync Core you are connected to doesn't support this feature yet.
            <br />
            <br />
            Please update your Sync Core to enable this feature if you want to use it.
          </Alert>
        )}
      />
    );
  }
}
