import { IPagedListResponse } from '@edgebox/data-definition-kit';
import { Pager } from '@edgebox/react-components';
import Mark from 'mark.js';
import React from 'react';
import { Row } from 'react-bootstrap';
import Alert from 'react-bootstrap/cjs/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { hotkeys } from 'react-keyboard-shortcuts';
import { scrollToTop } from '../frame-messages';
import { RequestLoadingAnimation } from '../services/Request';
import { ISyncCoreApiComponentState, RequestReason, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import Select from 'react-select';
import { getStyleColors } from '../Helpers';

type SetIndividualFilterArgs<Filter extends object> = [keyof Filter, any, boolean?];
type SetMultipleFilterArgs<Filter extends object> = [Partial<Filter>, boolean?];
export type OnChangeCallback<Filter extends object> = (...args: SetIndividualFilterArgs<Filter> | SetMultipleFilterArgs<Filter>) => void;

export type RenderFiltersCallback<Filter extends object> = (onChange: OnChangeCallback<Filter>) => React.ReactNode;

export interface ITextSearchFilter {
  search?: string;
}

interface IProps<Type, Filter extends object = ITextSearchFilter> {
  initialFilters?: Filter;
  searchable?: boolean;
  renderFilters?: RenderFiltersCallback<Filter>;
  renderListHeader?: () => React.ReactNode;
  renderItem: (item: Type, index: number, meta: { setFilter: OnChangeCallback<Filter> }, highlight?: boolean) => React.ReactNode;
  renderList?: (items: React.ReactNode) => React.ReactNode;
  renderGroup?: (name: string) => React.ReactNode;
  groupBy?: (item: Type) => string;
  getItemId?: (item: Type) => string;
  request: (page: number, filter?: Filter, numberOfItemsPerPage?: number) => Promise<IPagedListResponse<Type>>;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
  numberOfResultsLabel?: React.ReactNode;
  loadingAnimation?: RequestLoadingAnimation;
  showLoadingAnimationImmediately?: boolean;
  highlightSearch?: string;
  numberOfItemsPerPageOptions?: number[];
  defaultNumberOfItemsPerPage?: number;
  style?: React.CSSProperties;
  className?: string;
  showSpeed?: boolean;
  noPagerIfNotNeeded?: boolean;
  isOnPageBackground?: boolean;
  watch?: boolean;
}

interface IState<Type, Filter extends object = ITextSearchFilter> extends ISyncCoreApiComponentState {
  filter: Filter;
  filterUpdate?: Partial<Filter>;
  page?: number;
  result?: IPagedListResponse<Type>;
  numberOfItemsPerPage?: number;
  speed?: number;
  highlightFirst?: number;
}

const UPDATE_INTERVAL = 5_000;

export class PagedListClass<Type, Filter extends object = ITextSearchFilter> extends SyncCoreApiComponent<
  IProps<Type, Filter>,
  IState<Type, Filter>
> {
  constructor(props: IProps<Type, Filter>) {
    super(props, {
      filter: props.initialFilters || ({} as Filter),
      numberOfItemsPerPage: props.defaultNumberOfItemsPerPage,
    });
  }

  hot_keys = {
    left: {
      priority: 1,
      handler: (event: any) => this.previous(event.target.tagName),
    },
    right: {
      priority: 1,
      handler: (event: any) => this.next(event.target.tagName),
    },
  };
  previous(targetTag: string) {
    if (targetTag === 'INPUT' || targetTag === 'TEXTAREA') {
      return;
    }
    const { result } = this.state;
    if (!result || !result.page) {
      return;
    }
    this.load(result.page - 1);
  }
  next(targetTag: string) {
    if (targetTag === 'INPUT' || targetTag === 'TEXTAREA') {
      return;
    }
    const { result } = this.state;
    if (!result || result.page === result.numberOfPages - 1) {
      return;
    }
    this.load(result.page + 1);
  }

  initialLoad = true;
  startSearch: any = undefined;

  mark: Mark | undefined = undefined;
  async load(page?: number, numberOfItemsPerPage?: number, fromWatch?: boolean): Promise<Partial<IState<Type, Filter>>> {
    if (this.autoUpdate) {
      clearTimeout(this.autoUpdate);
      this.autoUpdate = null;
    }

    const { request } = this.props;
    const { filterUpdate } = this.state;
    const numberOfItemsPerPageBefore = this.state.numberOfItemsPerPage;

    const previousFilterId = JSON.stringify(this.state.filter);
    const newFilter = filterUpdate ? { ...this.state.filter, ...filterUpdate } : this.state.filter;
    const newFilterId = JSON.stringify(newFilter);

    if (page === undefined) {
      // Prevent unnecessary searches
      if ((!filterUpdate || previousFilterId === newFilterId) && !this.initialLoad && !fromWatch) {
        return {};
      }

      this.initialLoad = false;

      // New query => always start at first page.
      page = 0;
    }
    if (numberOfItemsPerPage === undefined) {
      numberOfItemsPerPage = numberOfItemsPerPageBefore;
    }

    if (!fromWatch) {
      scrollToTop();

      this.setState({
        filter: newFilter,
        page,
        numberOfItemsPerPage,
        result: undefined,
      });
    }

    const start = Date.now();
    const result = await request(page, newFilter, numberOfItemsPerPage);
    const speed = Date.now() - start;

    const filterIdAfterRequest = JSON.stringify(this.state.filter);

    // User changed search while this request was running. Skip.
    if (filterIdAfterRequest !== newFilterId || this.state.page !== page) {
      return {};
    }

    let highlightFirst: number | undefined = undefined;

    // Watching, but no new update. Skip.
    if (fromWatch && this.props.getItemId && this.state.result) {
      const previousFirst = this.props.getItemId(this.state.result.items[0]);
      highlightFirst = result.items.findIndex((c) => this.props.getItemId?.(c) === previousFirst);
      if (previousFirst && highlightFirst === 0) {
        return {};
      }
      if (highlightFirst < 0) {
        highlightFirst = result.items.length;
      }
    }

    const { highlightSearch } = this.props;
    const search = (newFilter as any).search as string;
    if (highlightSearch && search) {
      setTimeout(() => {
        //const terms = search.replace(/\s+/g, " ").split(" ");
        this.mark = new Mark(highlightSearch);
        this.mark.mark(search);
        //terms.forEach(term => this.mark!.mark(term));
      }, 1);
    }

    if (!this.autoUpdate) {
      this.registerAutoUpdate();
    }

    return {
      speed,
      result,
      highlightFirst,
    };
  }

  autoUpdate: any = null;

  registerAutoUpdate() {
    if (!this.__isMounted) {
      return;
    }

    if (this.autoUpdate) {
      clearTimeout(this.autoUpdate);
      this.autoUpdate = null;
    }

    this.autoUpdate = setTimeout(async () => {
      if (this.props.watch && (document.hidden === false || (document.hidden !== true && document.hasFocus()))) {
        this.setState((await this.load(this.state.page, this.state.numberOfItemsPerPage, true)) as IState<Type, Filter>);
      }

      this.registerAutoUpdate();
    }, UPDATE_INTERVAL);
  }

  componentWillUnmount(): void {
    if (this.autoUpdate) {
      clearTimeout(this.autoUpdate);
      this.autoUpdate = null;
    }

    super.componentWillUnmount();
  }

  componentDidUpdate(prevProps: Readonly<IProps<Type, Filter>>, prevState: Readonly<IState<Type, Filter>>, snapshot?: any) {
    if (this.props.initialFilters && JSON.stringify(this.props.initialFilters) !== JSON.stringify(prevProps.initialFilters)) {
      //this.setFilter(this.props.initialFilters);
    }
  }

  setFilter: OnChangeCallback<Filter> = (...args: SetIndividualFilterArgs<Filter> | SetMultipleFilterArgs<Filter>) => {
    let newFilterValue: Partial<Filter>;
    let setImmediately: boolean = false;
    if (typeof args[0] === 'string') {
      newFilterValue = {
        [args[0]]: args[1],
      } as Partial<Filter>;
      setImmediately = args[2] as boolean;
    } else {
      newFilterValue = args[0] as Partial<Filter>;
      setImmediately = args[1] as boolean;
    }

    this.setState({
      filterUpdate: this.state.filterUpdate ? { ...this.state.filterUpdate, ...newFilterValue } : newFilterValue,
    });

    if (this.startSearch) {
      clearTimeout(this.startSearch);
    }

    this.startSearch = setTimeout(() => this.load(), setImmediately ? 1 : 300);
  };

  render() {
    const {
      searchable,
      emptyMessage,
      renderItem,
      renderList,
      renderListHeader,
      numberOfResultsLabel,
      renderFilters,
      emptyMessageWithNoFilters,
      numberOfItemsPerPageOptions,
      style,
      className,
      showSpeed,
      renderGroup,
      groupBy,
      noPagerIfNotNeeded,
      isOnPageBackground,
    } = this.props;
    const { result, filter, numberOfItemsPerPage, speed, highlightFirst } = this.state;

    let search: React.ReactNode;
    if (searchable) {
      const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement> | React.KeyboardEvent<any>) => {
        const value = (e.target as HTMLInputElement).value;
        this.setFilter('search' as keyof Filter, value);
      };

      const filters = renderFilters ? (
        renderFilters(this.setFilter.bind(this))
      ) : (
        <Row>
          <Col>
            <Form.Control placeholder={'Enter search term...'} onKeyPress={onChange} onChange={onChange} />
          </Col>
        </Row>
      );

      search = (
        <>
          <Form className={'mb-3'}>{filters}</Form>
        </>
      );
    } else {
      search = undefined;
    }

    let items: React.ReactNode;
    if (!result) {
      items = this.renderRequest(
        RequestReason.LoadComponent,
        this.props.loadingAnimation || undefined,
        this.props.showLoadingAnimationImmediately
      );
    } else {
      if (!result.items.length) {
        items = emptyMessage ? (
          emptyMessage
        ) : (
          <Alert className={'no-items'} variant={'light'}>
            {searchable ? 'No matches.' : 'No items.'}
          </Alert>
        );
      } else {
        let lastGroup: string | undefined = undefined;
        items = result.items.map((item, index) => {
          const group = groupBy ? groupBy(item) : undefined;
          const groupChanged = group !== lastGroup;
          lastGroup = group;
          return (
            <React.Fragment key={index}>
              {groupChanged ? renderGroup ? renderGroup(group || '') : <h3 className="mt-3 mb-0">{group}</h3> : null}

              {renderItem(item, index, { setFilter: this.setFilter.bind(this) }, highlightFirst !== undefined && index < highlightFirst)}
            </React.Fragment>
          );
        });

        items = (
          <>
            {renderListHeader && renderListHeader()}

            <div className={'items'}>{renderList ? renderList(items) : items}</div>
          </>
        );
      }
    }

    let pager: React.ReactNode;
    if (result?.totalNumberOfItems && (!noPagerIfNotNeeded || result.numberOfPages > 1)) {
      pager = (
        <Pager
          buttonClassName={isOnPageBackground ? 'shadow-sm' : ''}
          speed={showSpeed ? speed : undefined}
          numberOfItemsPerPageOptions={numberOfItemsPerPageOptions}
          numberOfItemsPerPage={numberOfItemsPerPage}
          numberOfResultsLabel={numberOfResultsLabel}
          className={'mt-4'}
          searchable={searchable}
          page={result.page}
          numberOfPages={result.numberOfPages}
          totalNumberOfItems={result.totalNumberOfItems}
          onChange={(page: number, numberOfItemsPerPage?: number) => this.load(page, numberOfItemsPerPage)}
          renderNumberOfItemsPerPage={
            numberOfItemsPerPageOptions
              ? ({ onChange, numberOfItemsPerPage }) => {
                  if (!numberOfItemsPerPageOptions?.length) {
                    return null;
                  }

                  const { primary, danger } = getStyleColors();

                  return (
                    <Select
                      placeholder="Page size..."
                      value={numberOfItemsPerPage ? { numberOfItemsPerPage } : undefined}
                      getOptionValue={(option) => option.numberOfItemsPerPage.toString()}
                      getOptionLabel={(option) => `${option.numberOfItemsPerPage.toString()} per page`}
                      options={numberOfItemsPerPageOptions.map((numberOfItemsPerPage) => ({ numberOfItemsPerPage }))}
                      onChange={(option) => option && onChange(option.numberOfItemsPerPage)}
                      styles={{
                        container: (base) => ({
                          ...base,
                          width: '150px',
                        }),
                      }}
                      menuPlacement={'top'}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary,
                          danger,
                        },
                      })}
                    />
                  );
                }
              : undefined
          }
        />
      );
    } else {
      pager = undefined;
    }

    const hasAnyFilters = !!Object.values(filter).find((c) => !!c);

    return (
      <div className={`mt-3 mb-3 paged-list ${className || ''}`} style={style}>
        {!hasAnyFilters && result && !result.items.length && emptyMessageWithNoFilters ? (
          emptyMessageWithNoFilters
        ) : (
          <>
            {search}

            {items}

            {pager}
          </>
        )}
      </div>
    );
  }
}

export const PagedList: typeof PagedListClass = hotkeys(PagedListClass);
