import {
  SyncCoreDataDefinitionsEnumTranslator,
  SyndicationErrorType,
  SyndicationStatus,
  SyndicationType,
} from '@edgebox/sync-core-data-definitions';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faLongArrowLeft } from '@fortawesome/pro-light-svg-icons/faLongArrowLeft';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faHistory } from '@fortawesome/pro-light-svg-icons/faHistory';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faCircleMinus } from '@fortawesome/pro-light-svg-icons/faCircleMinus';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { IconWithBackground } from './IconWithBackground';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';

export const neutralStatus = [SyndicationStatus.Aborted];
export const dangerStatus = [SyndicationStatus.Failed, SyndicationStatus.LimitExceeded];
export const doneStatus = [...dangerStatus, ...neutralStatus, SyndicationStatus.Finished];
export const warningStatus = [SyndicationStatus.Retrying];
export const issueStatus = [SyndicationStatus.Retrying, SyndicationStatus.Failed];

interface IProps {
  size?: '2x' | 'sm';
  status: SyndicationStatus;
  errorType?: SyndicationErrorType;
  background?: 'primary' | 'none';
  title?: string;
  type?: SyndicationType;
  foregroundClassName?: string;
}

function groupSyndicationStatus(status: SyndicationStatus, type?: SyndicationType) {
  const done = doneStatus.includes(status);
  const success = status === SyndicationStatus.Finished;
  const aborted = status === SyndicationStatus.Aborted;
  const deleted =
    type &&
    [
      SyndicationType.DeleteEntity,
      SyndicationType.DeleteEmbeddedEntity,
      SyndicationType.RetrieveAndDeleteEmbeddedEntity,
      SyndicationType.RetrieveAndDeleteEntity,
    ].includes(type);

  return {
    done,
    success,
    aborted,
    deleted,
  };
}

export function getSyndicationStatusIcon(status: SyndicationStatus, type?: SyndicationType) {
  const { done, success, aborted, deleted } = groupSyndicationStatus(status, type);
  if (done) {
    return aborted ? faCircleMinus : success ? (deleted ? faTrashCan : faCheckCircle) : faTimesCircle;
  }
  if (status === SyndicationStatus.Initializing) {
    return faSpinnerThird;
  }
  if (status === SyndicationStatus.Running) {
    return faArrowsRotate;
  }
  return faHistory;
}

export function getSyndicationStatusColor(status: SyndicationStatus, type?: SyndicationType) {
  const { done, success, aborted, deleted } = groupSyndicationStatus(status, type);
  if (done) {
    return aborted ? 'dark' : success ? (deleted ? 'danger' : 'success') : 'danger';
  }
  if (status === SyndicationStatus.Initializing) {
    return 'light';
  }
  if (status === SyndicationStatus.Running) {
    return 'light';
  }
  return 'warning';
}

export function getSyndicationStatusTitle(status: SyndicationStatus) {}

export class SyndicationStatusIcon extends React.Component<IProps, {}> {
  render() {
    const { status, size, errorType, background, type, foregroundClassName } = this.props;

    const swapColors = background === 'primary';
    const foregroundColor = swapColors ? 'white' : 'var(--primary)';
    const backgroundColor = swapColors ? 'var(--primary)' : 'white';

    const noBackground = background === 'none';

    const done = doneStatus.includes(status);

    const statusName = SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SyndicationStatus', status);
    let title = this.props.title ? `${statusName}: ${this.props.title}` : statusName;

    let icon: React.ReactNode;

    const stack = noBackground ? '' : 'fa-stack-1x';

    if (done) {
      icon = (
        <FontAwesomeIcon
          title={title}
          icon={getSyndicationStatusIcon(status, type)}
          className={`${stack} text-${getSyndicationStatusColor(status, type)} ${foregroundClassName || ''}`}
          style={{ fontSize: size === '2x' ? '1.6em' : '1em' }}
        />
      );
    } else {
      const mustPoll = errorType === SyndicationErrorType.SiteMustPoll;
      if (mustPoll) {
        title = 'Site must poll';
      }

      icon =
        status === SyndicationStatus.Initializing ? (
          <FontAwesomeIcon
            title={title}
            icon={faSpinnerThird}
            spin
            className={`${stack} ${foregroundClassName || ''}`}
            style={{ fontSize: size === '2x' ? '1.4em' : '1em', '--fa-primary-color': foregroundColor } as any}
          />
        ) : status === SyndicationStatus.Running ? (
          <>
            <FontAwesomeIcon
              title={title}
              icon={faLongArrowRight}
              transform="up-4"
              className={`${stack} faa-horizontal animated faa-slow ${foregroundClassName || ''}`}
              style={{ fontSize: size === '2x' ? '1.4em' : '1em', '--fa-primary-color': foregroundColor } as any}
            />
            <FontAwesomeIcon
              title={title}
              icon={faLongArrowLeft}
              transform="down-4"
              className={`${stack} faa-horizontal faa-reverse animated faa-slow ${foregroundClassName || ''}`}
              style={{ fontSize: size === '2x' ? '1.4em' : '1em', '--fa-primary-color': foregroundColor } as any}
            />
          </>
        ) : (
          <FontAwesomeIcon
            title={title}
            icon={faHistory}
            flip={'horizontal'}
            className={`${stack} ${mustPoll ? 'text-dark' : 'text-warning'} ${foregroundClassName || ''}`}
            style={{ fontSize: size === '2x' ? '1.4em' : '1em' }}
          />
        );
    }

    if (noBackground) {
      return icon;
    }

    return (
      <>
        <IconWithBackground
          size={size}
          backgroundColor={status === SyndicationStatus.Retrying ? 'white' : backgroundColor}
          biggerBackground={status === SyndicationStatus.Retrying}
        >
          {icon}
        </IconWithBackground>
      </>
    );
  }
}
