import { InternalId } from '@edgebox/data-definition-kit';
import { ClientProjectEntity } from '@edgebox/sync-core-rest-client';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../services/SyncCoreApiComponent';

interface IProps {
  entityId: InternalId;
}

interface IState extends ISyncCoreApiComponentState {
  entity?: ClientProjectEntity;
}

export class ProjectName extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const entity = await this.api.syndication.projects.item(this.props.entityId);

    return {
      entity,
    };
  }

  render() {
    const { entity } = this.state;

    if (!entity) {
      return this.renderRequest();
    }

    return entity.name;
  }
}
