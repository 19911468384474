import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import React from 'react';
import { ExternalLink } from '@edgebox/react-components';

export function ExternalLinkWithIcon(props: {
  to: string;
  sameWindow?: boolean;
  className?: string;
  iconClassName?: string;
  children: React.ReactNode;
}) {
  return (
    <ExternalLink {...props}>
      {props.children}
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} className={props.iconClassName || 'ms-2'} />
    </ExternalLink>
  );
}
