import { ExternalServiceId, Uuid } from '@edgebox/data-definition-kit';

export interface IRegisterSite {
  type: 'register-site';
}

export interface ISaveFlow {
  type: 'save-flow';
  data: any;
}

export interface IMigrate {
  type: 'migrate';
}

export interface IMigrationExportPools {
  type: 'migration-export-pools';
  machineNames: ExternalServiceId[];
}

export interface IMigrationExportFlows {
  type: 'migration-export-flows';
  machineNames: ExternalServiceId[];
}

export interface IMigrationSkipFlowsTest {
  type: 'migration-skip-flows-test';
  machineNames: ExternalServiceId[];
}

export interface IMigrationSkipFlowsPush {
  type: 'migration-skip-flows-push';
  machineNames: ExternalServiceId[];
}

export interface IMigrationSkipFlowsPull {
  type: 'migration-skip-flows-pull';
  machineNames: ExternalServiceId[];
}

export interface IMigrationSwitch {
  type: 'migration-switch';
}

export interface IUpdateQuery {
  type: 'update-query';
  query: any;
}

export interface IListEntity {
  remoteUuid?: Uuid;
  remoteUniqueId?: ExternalServiceId;
  name: string;
  entityTypeNamespaceMachineName: ExternalServiceId;
  entityTypeMachineName: ExternalServiceId;
}
export interface IListEntityResponse {
  items: IListEntity[];
  page: number;
  numberOfPages: number;
  totalNumberOfItems: number;
}
export const siteRequests: { [key: string]: (data: any) => void } = {};
export type ListRequestMode = 'all' | 'pushed' | 'push-failed' | 'pulled';
export interface IListEntities {
  type: 'list-entities';
  page: number;
  itemsPerPage: number;
  mode: ListRequestMode;
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  flowMachineName?: ExternalServiceId;
  search?: string;
  callbackId?: string;
  callback: (result?: IListEntityResponse) => void;
}
export interface ICountEntities {
  type: 'count-entities';
  mode: ListRequestMode;
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  flowMachineName?: ExternalServiceId;
  search?: string;
  callbackId?: string;
  callback: (result?: IListEntityResponse) => void;
}
export interface IEntityDetails {
  remoteUuid?: Uuid;
  remoteUniqueId?: ExternalServiceId;
  name: string;
  entityTypeByMachineName: {
    namespaceMachineName: ExternalServiceId;
    machineName: ExternalServiceId;
  };
}
export interface IRetrieveEntity {
  type: 'retrieve-entity';
  namespaceMachineName: ExternalServiceId;
  machineName: ExternalServiceId;
  sharedEntityId: Uuid | ExternalServiceId;
  flowMachineName?: ExternalServiceId;
  callbackId?: string;
  callback: (result?: IEntityDetails) => void;
}

export interface IScrollToTop {
  type: 'scroll-to-top';
}

export interface IModalOpen {
  type: 'modal-open';
}

export interface IModalClose {
  type: 'modal-close';
}

export type AnyFrameMessage =
  | IModalOpen
  | IModalClose
  | IScrollToTop
  | IRegisterSite
  | ISaveFlow
  | IListEntities
  | ICountEntities
  | IRetrieveEntity
  | IUpdateQuery
  | IMigrate
  | IMigrationExportPools
  | IMigrationExportFlows
  | IMigrationSkipFlowsTest
  | IMigrationSkipFlowsPush
  | IMigrationSkipFlowsPull
  | IMigrationSwitch;

export function sendToParent(message: AnyFrameMessage) {
  if (message.type === 'list-entities' || message.type === 'count-entities' || message.type === 'retrieve-entity') {
    if (!message.callbackId) {
      message.callbackId = `${message.type}-${Date.now()}-${Math.random()}`;
    }
    siteRequests[message.callbackId!] = message.callback;
    delete (message as any).callback;
  }
  (window as any).parentIFrame.sendMessage(message);
}
export function scrollToTop() {
  sendToParent({
    type: 'scroll-to-top',
  });
}
export function modalOpen() {
  sendToParent({
    type: 'modal-open',
  });
}
export function modalClose() {
  sendToParent({
    type: 'modal-close',
  });
}
export function listEntities(message: Omit<IListEntities, 'type' | 'callback' | 'callbackId'>) {
  return new Promise<IListEntityResponse>((resolve, reject) => {
    sendToParent({
      type: 'list-entities',
      ...message,
      callback: (result) => {
        if (result) {
          resolve(result);
        } else {
          reject(new Error('Request failed.'));
        }
      },
    });
  });
}
export function countEntities(message: Omit<ICountEntities, 'type' | 'callback' | 'callbackId'>) {
  return new Promise<number>((resolve, reject) => {
    sendToParent({
      type: 'count-entities',
      ...message,
      callback: (result) => {
        if (result) {
          resolve(result.totalNumberOfItems);
        } else {
          reject(new Error('Request failed.'));
        }
      },
    });
  });
}
export function retrieveEntity(message: Omit<IRetrieveEntity, 'type' | 'callback' | 'callbackId'>) {
  return new Promise<IEntityDetails>((resolve, reject) => {
    sendToParent({
      type: 'retrieve-entity',
      ...message,
      callback: (result) => {
        if (result) {
          resolve(result);
        } else {
          reject(new Error('Request failed.'));
        }
      },
    });
  });
}
