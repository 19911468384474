import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  icon: IconProp;
  count?: number;
}

export class IconWithCount extends React.Component<IProps, {}> {
  render() {
    const { icon, count } = this.props;
    return (
      <div style={{ position: 'relative', width: '1.5em', height: '1em' }} className="text-start">
        <div style={{ position: 'absolute' }}>
          <FontAwesomeIcon icon={icon} />
        </div>
        {!!count && (
          <div
            style={{ position: 'absolute', bottom: '-8px', right: '0', width: '1em', height: '1em', lineHeight: '1em' }}
            className="rounded-circle bg-danger text-white text-center small"
          >
            {count}
          </div>
        )}
      </div>
    );
  }
}
