import { findIconDefinition, icon, library } from '@fortawesome/fontawesome-svg-core';
import {
  BooleanProperty,
  ExternalServiceId,
  InternalId,
  NestedProperty,
  UnformattedTextProperty,
  Uuid,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import { getStyleColors } from '../../../Helpers';
import { ButtonLink, LeftRightSpan, Right } from '@edgebox/react-components';
import { EntityRemoteStatus, SyndicationStatus } from '@edgebox/sync-core-data-definitions';
import {
  ClientPreviewItem,
  ClientSiteEntity,
  ClientSyndicationEntity,
  ClientSyndicationUsageSummary,
  IPreviewServiceFilters,
} from '@edgebox/sync-core-rest-client';
import { PullDashboardConfiguration } from '@edgebox/sync-core-rest-client';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faFilter } from '@fortawesome/pro-light-svg-icons/faFilter';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons/faSpinner';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Badge, Button, ButtonGroup, Collapse, Dropdown, Form, Modal, Overlay } from 'react-bootstrap';
import { SingleDatePicker } from 'react-dates';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FEATURE_PREVIEWS } from '../../../features';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { ContractWarnings } from '../../ContractWarnings';
import { EmbeddedModal } from '../../EmbeddedModal';
import { PagedList } from '../../PagedList';
import { PoolSummaryIcon } from '../../PoolSummaryIcon';
import { SelectSite } from '../../SelectSite';
import { SerializedEntityRevision } from '../../SerializedEntityRevision';
import { SiteName } from '../../SiteName';
import { SyncCoreFeatureFlagGate } from '../../SyncCoreFeatureFlagGate';
import { UserHtml } from '../../UserHtml';
import { ParamsComponent, transformBool, transformDate } from '../ParamsComponent';
import { doneStatus, SyndicationStatusIcon } from '../SyndicationStatusIcon';
import { SwitchButton } from '../../SwitchButton';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { Transform, TransformFnParams, TransformationType, instanceToPlain } from 'class-transformer';
import { modalClose, modalOpen, sendToParent } from '../../../frame-messages';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faTrashCan } from '@fortawesome/pro-light-svg-icons/faTrashCan';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import { Nugget, makeNugget } from '../../Nugget';
import { getEntityTypeIcon } from '../EntityTypeIcon';
import { faTag } from '@fortawesome/pro-light-svg-icons/faTag';
import { FormatDate } from '../../FormatDate';
import { SyncCoreApiContext } from '../../../contexts/SyncCoreApiContext';
import { ExternalLinkWithIcon } from '../../ExternalLinkWithIcon';
import { ICON_PULL, ICON_PUSH } from '../../../Helpers';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { CustomOption, EntityStatus, EntityStatusWithParams } from './EntityStatus';
import { DisplayEntityRevision } from '../../DisplayEntityRevision';
import { WithSite } from '../../WithSite';

library.add(faTag);
library.add(faSpinner);

let hasMultiplePoolsLoading: Promise<boolean> | undefined = undefined;
function HasMultiplePools(props: { children: React.ReactElement }) {
  const api = useContext(SyncCoreApiContext);

  const [hasMultiplePools, setHasMultiplePools] = useState<boolean | undefined>();

  useEffect(() => {
    if (hasMultiplePoolsLoading) {
      hasMultiplePoolsLoading.then((has) => setHasMultiplePools(has));
    } else if (api) {
      hasMultiplePoolsLoading = (async () => {
        const page = await api.api.syndication.pools.list();
        const has = page.length > 1;
        setHasMultiplePools(has);
        return has;
      })();
    }
  }, [api]);

  if (hasMultiplePools === undefined || hasMultiplePools === false) {
    return <></>;
  }

  return props.children;
}

const DISPLAY_TERMS_LIMIT = 5;

function ItemPreview({
  item,
  addTextFilter,
  imagePreviewJwt,
}: {
  item: ClientPreviewItem;
  addTextFilter?: (text: string) => void;
  imagePreviewJwt?: string;
}) {
  const api = useContext(SyncCoreApiContext);

  return item.previewHtml ? (
    <div className={`p-3 preview-html has-preview`}>
      <UserHtml
        dangerousHtml={item.previewHtml}
        renderer={{
          a: (element) => {
            element.setAttribute('target', '_blank');
          },
          // Move tags to our own tags section beneath the headline.
          '.pi-tags': (element) => {
            let parent = element.parentElement;
            while (parent && !parent.classList.contains('preview-item') && parent.parentElement && parent.parentElement !== document.body) {
              parent = parent.parentElement;
            }
            if (parent && parent.classList.contains('preview-item')) {
              const nuggets = parent.querySelector('.nuggets');
              if (nuggets) {
                element.classList.add('d-none');

                const tags = element.querySelectorAll('.pi-tag');
                tags.forEach((term, index) => {
                  const clb = addTextFilter;
                  const text = term.textContent;
                  makeNugget(
                    term,
                    'tag',
                    clb && text
                      ? () => {
                          clb(text);
                        }
                      : undefined
                  );

                  term.classList.add('preview-tag');

                  nuggets.appendChild(term);

                  if (index >= DISPLAY_TERMS_LIMIT) {
                    term.classList.add('d-none');
                  }

                  if (index === DISPLAY_TERMS_LIMIT) {
                    const more = document.createElement('span');
                    more.setAttribute('class', 'pi-tag');
                    more.innerText = '+' + (tags.length - DISPLAY_TERMS_LIMIT);
                    makeNugget(more, 'tag', () => {
                      nuggets.querySelectorAll('.d-none').forEach((c) => c.classList.remove('d-none'));
                      more.classList.add('d-none');
                    });

                    nuggets.appendChild(more);
                  }
                });
              }
            }
          },
          // Replace file references with actual HTML images.
          '.pi-image': (element) => {
            const fileId = element.getAttribute('data-file-id') as InternalId;
            let uri = element.getAttribute('data-file-uri');
            let alt = element.getAttribute('data-file-name');
            if (!fileId && !uri) {
              return;
            }

            const throbber = document.createElement('span');

            const iconDefinition = findIconDefinition({ prefix: 'fas', iconName: 'spinner' });
            const iconInstance = icon(iconDefinition);
            if (iconInstance) {
              Array.from(iconInstance.node).map((n) => {
                n.classList.add('fa-spin');
                throbber.appendChild(n);
              });
            }

            const container = document.createElement('div');
            if (element.parentElement?.classList.contains('pi-variant-image-text')) {
              container.setAttribute('class', 'float-start pe-3 pb-3');
              element.parentElement.classList.add('clearfix');
            }
            container.appendChild(throbber);

            function setImage() {
              if (uri) {
                container.classList.add('mw-25');

                // Max width, rounded up to the nearest 100s, to make it cacheable.
                const MAX_WIDTH = Math.ceil(((window.innerWidth || 1_000) * (window.devicePixelRatio || 1) * 0.25) / 100) * 100;
                // Design doesn't allow more than 300 CSS pixels, so we take that and round it to the nearest 100s, to make it cacheable.
                const MAX_HEIGHT = Math.ceil((300 * window.devicePixelRatio) / 100) * 100;

                const image = document.createElement('img');
                image.setAttribute(
                  'src',
                  uri
                    .replace(/(\[|%5B)JWT(\]|%5D)/g, encodeURIComponent(imagePreviewJwt || ''))
                    .replace(/(\[|%5B)MAX-WIDTH(\]|%5D)/g, MAX_WIDTH.toString())
                    .replace(/(\[|%5B)MAX-HEIGHT(\]|%5D)/g, MAX_HEIGHT.toString())
                );
                image.setAttribute('alt', alt || uri);
                image.setAttribute('loading', 'lazy');
                throbber.replaceWith(image);
              } else {
                throbber.replaceWith('');
              }
            }

            if (uri) {
              setImage();
            } else if (fileId) {
              // TODO: Load optimized, smaller images.
              // TODO: Only load images at all when in the viewport.
              function loadFile() {
                if (api) {
                  api.api.syndication.files.item(fileId!).then((file) => {
                    if (file.downloadUrl) {
                      uri = file.downloadUrl;
                      alt = file.fileName;
                    }
                    setImage();
                  });
                } else {
                  setTimeout(loadFile, 500);
                }
              }
              loadFile();
            } else {
              setImage();
            }

            element.replaceWith(container);
          },
        }}
      />
    </div>
  ) : null;
}

interface IItemProps {
  item: ClientPreviewItem;
  pullWithFlowMachineName?: ExternalServiceId;
  configurationAccess?: boolean;
  imagePreviewJwt?: string;
  addTextFilter?: (text: string) => void;
}
interface IItemState extends ISyncCoreApiComponentState {
  refreshCount?: number;
  item?: ClientPreviewItem;
  syndication?: ClientSyndicationEntity;
  pullingEntity?: boolean;
  serialize?: boolean;
  showUpdate?: boolean;
  serializeMode?: 'table' | 'yaml-pretty' | 'yaml-raw';
  selectedComparison?: number;
  selectedComparisonId?: InternalId;
}
function WithMostRecentSyndications({
  children,
  siteUuid,
  entity,
  type,
}: {
  children: (recent: ClientSyndicationUsageSummary[]) => React.ReactElement;
  siteUuid: Uuid;
  type: { namespaceMachineName: ExternalServiceId; machineName: ExternalServiceId };
  entity: { remoteUniqueId?: ExternalServiceId; remoteUuid?: Uuid };
}) {
  const [recent, setRecent] = useState<ClientSyndicationUsageSummary[] | undefined>(undefined);

  if (recent) {
    return children(recent);
  }

  return (
    <SyncCoreApiContext.Consumer>
      {(api) => {
        if (!api) {
          return null;
        }

        api.api.syndication.syndications
          .usageSummaryForSite(
            siteUuid,
            type,
            entity.remoteUniqueId ? { remoteUniqueId: entity.remoteUniqueId } : { remoteUuid: entity.remoteUuid! },
            { includingMigrations: true, isRegularSyndication: true, separateUntil: 3 }
          )
          .then((response) => setRecent(response.items))
          .catch((e) => {});
      }}
    </SyncCoreApiContext.Consumer>
  );
}
// Start with refreshing every second.
const REFRESH_INTERVAL_RUNNING = 1_000;
// Double the refresh interval every 4 times.
const REFRESH_INTERVAL_DOUBLE_TIME = 4;
const REFRESH_INTERVAL_ISSUE = 20_000;
export class PullDashboardItem extends SyncCoreApiComponent<IItemProps, IItemState> {
  async load(stateUpdate?: Pick<IItemState, 'pullingEntity' | 'syndication'>) {
    const state = { ...this.state, ...(stateUpdate || {}) };
    const item = state.item || this.props.item;
    let syndication = state.syndication || item.lastPull;
    const status = syndication?.status;
    const { refreshCount } = state;

    if (refreshCount) {
      syndication = await this.api.syndication.syndications.item(syndication!.id, true, { includeUsage: true });
    }

    let interval: number;
    if (!status || doneStatus.includes(status)) {
      interval = 0;
    } else if (status === SyndicationStatus.Initializing || status === SyndicationStatus.Running) {
      interval = REFRESH_INTERVAL_RUNNING;
      const doubleTimes = Math.floor(refreshCount || 0 / REFRESH_INTERVAL_DOUBLE_TIME);
      interval *= Math.pow(2, doubleTimes);
    } else {
      interval = REFRESH_INTERVAL_ISSUE;
    }

    if (interval > 0) {
      setTimeout(() => {
        if (!this.__isMounted) {
          return;
        }
        this.load();
      }, interval);
    }

    return {
      ...(stateUpdate || {}),
      refreshCount: refreshCount !== undefined ? refreshCount + 1 : 0,
      syndication,
    };
  }

  render() {
    const item = this.state.item || this.props.item;
    const syndication = this.state.syndication || item.lastPull;
    const { pullWithFlowMachineName, imagePreviewJwt } = this.props;
    const { pullingEntity, showUpdate, serialize, serializeMode, selectedComparison, selectedComparisonId } = this.state;

    const wasDeleted = item.localUsage && item.localUsage.status !== EntityRemoteStatus.Exists;
    const deleted = wasDeleted && (!this.state.syndication || this.state.syndication === this.props.item.lastPull);
    const sourceDeleted = item.sourceUsage.status !== EntityRemoteStatus.Exists;
    let viewUrl: string | undefined = undefined;
    if (!pullingEntity) {
      if (syndication) {
        if (!wasDeleted || syndication.status === SyndicationStatus.Finished) {
          viewUrl = syndication.usage?.viewUrl || item.localUsage?.viewUrl;
        }
      } else if (!wasDeleted) {
        viewUrl = item.localUsage?.viewUrl;
      }
    }

    const canPull = !!pullWithFlowMachineName;
    const exists = viewUrl && !deleted;
    const showPull = !syndication || doneStatus.includes(syndication.status);
    const actionDropDown = true;

    const viewSource = (secondary: boolean, embedded = true) =>
      item.sourceUsage.status === EntityRemoteStatus.Exists ? (
        <ExternalLinkWithIcon
          to={item.sourceUsage.viewUrl}
          className={
            embedded ? `${secondary ? `p-2 ${viewUrl ? 'text-muted' : ''}` : `px-1`}` : 'text-decoration-none fs-5 d-block px-3 py-2'
          }
        >
          View source
        </ExternalLinkWithIcon>
      ) : embedded ? (
        <Badge bg="warning" className="ms-1">
          Source deleted
        </Badge>
      ) : null;

    let actions: React.ReactNode[] = [];
    if (!exists || item.sourceUsage.viewUrl != item.localUsage?.viewUrl) {
      actions.push(viewSource(true, !actionDropDown));
    }

    if (this.props.configurationAccess) {
      actions.push(
        <Button
          variant="link"
          className={`text-muted ${actionDropDown ? 'px-3 py-2 d-block w-100 text-start text-decoration-none fs-5' : 'px-2'}`}
          onClick={() => {
            this.setState({ serialize: true, selectedComparisonId: undefined });
            modalOpen();
          }}
        >
          Serialize
        </Button>
      );
      actions.push(
        <Button
          variant="link"
          className={`text-muted ${actionDropDown ? 'px-3 py-2 d-block w-100 text-start text-decoration-none fs-5' : 'px-2'}`}
          onClick={() => this.setState({ showUpdate: true })}
        >
          Show Updates
        </Button>
      );
    }

    const menuItems = (
      <Dropdown.Menu style={{ width: '200px' }} className="shadow-sm">
        {actions
          .filter((c) => !!c)
          .map((c, index) => (
            <Dropdown.Item className="p-0" key={index} as={'div'}>
              {c}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    );

    if (exists) {
      actions = [
        <>
          <Dropdown as={ButtonGroup} className="rounded bg-white-selectable shadow-sm">
            <ButtonLink
              to={viewUrl || ''}
              variant="light"
              className="pull-submit ps-3 pe-3 pt-2 pb-2 fs-5 fw-bold bg-none color-dark"
              disabled={!viewUrl}
              target="_blank"
              style={{ width: '172px' }}
            >
              {canPull ? 'View here' : 'View'} <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </ButtonLink>

            <Dropdown.Toggle split variant="light" id="dropdown-split-basic" className="border-start bg-none color-dark" />

            {menuItems}
          </Dropdown>
        </>,
      ];
    } else if (canPull) {
      actions = [
        <>
          <Dropdown as={ButtonGroup} className="rounded bg-primary-selectable shadow-sm">
            <Button
              variant="primary"
              className="pull-submit px-3 pt-2 pb-2 fs-5 fw-bold bg-none"
              disabled={!!pullingEntity || !showPull}
              style={{ width: '172px' }}
              onClick={(e) => {
                e.preventDefault();

                this.setState({ pullingEntity: true });

                (async () => {
                  try {
                    const syndication = await this.api.syndication.syndications.pull({
                      flowMachineName: pullWithFlowMachineName,
                      entityTypeMachineName: item.entityTypeVersion.machineName,
                      entityTypeNamespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                      manually: true,
                      remoteUuid: item.entity.remoteUuid,
                      remoteUniqueId: item.entity.remoteUniqueId,
                    });

                    const stateUpdate = {
                      pullingEntity: undefined,
                      syndication,
                    };

                    await this.load(stateUpdate);
                  } catch (e) {
                    this.setState({
                      pullingEntity: undefined,
                    });

                    if ((e as Error).message) toast((e as Error).message, { type: 'error' });
                  }
                })();

                return false;
              }}
            >
              {pullingEntity ? <FontAwesomeIcon icon={faSpinner} spin /> : undefined}
              {deleted ? (
                'Restore'
              ) : (
                <>
                  {syndication ? (
                    <SyndicationStatusIcon
                      status={syndication.status}
                      errorType={syndication.operations?.[0]?.errors?.[0]?.type}
                      size="sm"
                      background="primary"
                    />
                  ) : pullingEntity ? null : (
                    <FontAwesomeIcon icon={ICON_PULL} className="me-2" />
                  )}{' '}
                  Pull
                </>
              )}
            </Button>

            <Dropdown.Toggle split variant="primary" id="dropdown-split-basic" className="border-start bg-none" />

            {menuItems}
          </Dropdown>
        </>,
      ];
    }

    const { primary, danger } = getStyleColors();

    return (
      /* border border-lighter */
      <div key={item.entity.id} className="preview-item rounded shadow-sm my-4 bg-white">
        <h2 className="mb-0 d-inline-flex justify-content-start ps-3 pt-2">
          <div className="preview-name text-truncate mw-100 flex-grow-1 flex-shrink-1 align-self-center" title={item.entity.name}>
            {pullWithFlowMachineName ? (
              deleted ? (
                <FontAwesomeIcon icon={faTrashCan} className="text-danger me-2 fs-4" />
              ) : viewUrl ? (
                <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2 fs-4" />
              ) : undefined
            ) : undefined}
            {item.entity.name || <em>Unnamed</em>}{' '}
          </div>
          {(!pullWithFlowMachineName || !deleted) && item.sourceUsage.createdAt.isAfter(moment().subtract(1, 'week')) && (
            <div className="flex-grow-0 align-self-center flex-shrink-0 ms-3 fs-4">
              <Badge bg="danger">New</Badge>
            </div>
          )}
        </h2>
        <div className="ps-3 pt-3 fs-7 mb-3 nuggets">
          <Nugget icon={ICON_PULL}>
            <SiteName
              id={item.sourceUsage.site.getId()}
              link={sourceDeleted ? undefined : item.sourceUsage.viewUrl}
              linkIconClassName="ms-1 mt-1"
              className="text-muted"
              inline
            />
          </Nugget>
          <HasMultiplePools>
            {item.entity.pools?.length ? <PoolSummaryIcon poolReferences={item.entity.pools} wide className="text-muted" nugget /> : <></>}
          </HasMultiplePools>
          <Nugget
            icon={
              getEntityTypeIcon(item.entity.appType, item.entityTypeVersion.namespaceMachineName, item.entityTypeVersion.machineName).icon
            }
          >
            {item.entityTypeVersion.name}
          </Nugget>
          <Nugget icon={faClock}>
            <FormatDate withTime>{item.entity.createdAt}</FormatDate>
          </Nugget>
        </div>
        <ItemPreview item={item} addTextFilter={this.props.addTextFilter} imagePreviewJwt={imagePreviewJwt} />
        <div>
          <div className="text-end pt-2 pb-3 pe-3">
            {actions.map((c, i) => (
              <React.Fragment key={i}>{c}</React.Fragment>
            ))}
          </div>
        </div>

        {serialize && (
          <EmbeddedModal
            scrollable
            size="lg"
            fullscreen
            show
            onHide={() => {
              this.setState({ serialize: false });
              modalClose();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title className="w-100" title={item.entity.name}>
                <LeftRightSpan
                  className="w-100"
                  left={<span className="text-truncate mw-100">{item.entity.name || <em>Unnamed</em>}</span>}
                  right={
                    <div className="d-flex">
                      <div>
                        <SwitchButton
                          className="me-2"
                          selected={serializeMode ?? 'table'}
                          options={{ table: 'Table', 'yaml-pretty': 'Technical', 'yaml-raw': 'Raw' }}
                          size="m"
                          onSelect={async (newValue) => {
                            this.setState({ serializeMode: newValue });
                          }}
                        />
                      </div>
                      <div>
                        <WithSite entityId={item.sourceUsage.site.getId()}>
                          {(site) =>
                            site ? (
                              <WithMostRecentSyndications
                                siteUuid={site.uuid}
                                type={{
                                  namespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                                  machineName: item.entityTypeVersion.machineName,
                                }}
                                entity={item.entity}
                              >
                                {(recent) => (
                                  <Select
                                    isClearable
                                    value={selectedComparison === undefined ? undefined : recent[selectedComparison]}
                                    getOptionValue={(option) => (option.sourceSite ?? option.thisSite ?? option.thisSite).id}
                                    getOptionLabel={(option) => option.startedAt.fromNow()}
                                    isOptionDisabled={(option) =>
                                      !(option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() ||
                                      (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId() === item.entity.id
                                    }
                                    placeholder="Compare to ..."
                                    options={recent}
                                    onChange={(option) =>
                                      this.setState({
                                        selectedComparison: !option ? undefined : recent.indexOf(option),
                                        selectedComparisonId: !option
                                          ? undefined
                                          : (option.sourceSite ?? option.thisSite ?? option.targetSite)?.rootEntity?.getId(),
                                      })
                                    }
                                    components={{ Option: CustomOption }}
                                    theme={(theme) => ({
                                      ...theme,
                                      colors: {
                                        ...theme.colors,
                                        primary,
                                        danger,
                                      },
                                    })}
                                  />
                                )}
                              </WithMostRecentSyndications>
                            ) : null
                          }
                        </WithSite>
                      </div>
                    </div>
                  }
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!serializeMode || serializeMode === 'table' ? (
                <DisplayEntityRevision
                  id={item.entity.id}
                  comparisonId={selectedComparisonId}
                  key={item.entity.id + '-' + selectedComparisonId}
                />
              ) : (
                <SerializedEntityRevision
                  id={item.entity.id}
                  comparisonId={selectedComparisonId}
                  structure={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
                  values={serializeMode === 'yaml-raw' ? 'plain' : 'pretty'}
                />
              )}
            </Modal.Body>
          </EmbeddedModal>
        )}

        {showUpdate && (
          <EmbeddedModal scrollable size="xl" show onHide={() => this.setState({ showUpdate: false })}>
            <Modal.Header closeButton>
              <Modal.Title className="w-100" title={item.entity.name}>
                <span className="text-truncate mw-100">{item.entity.name || <em>Unnamed</em>}</span>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EntityStatusWithParams
                embedded
                params={{
                  configurationAccess: true,
                  namespaceMachineName: item.entityTypeVersion.namespaceMachineName,
                  machineName: item.entityTypeVersion.machineName,
                  remoteUniqueId: item.entity.remoteUniqueId,
                  remoteUuid: item.entity.remoteUuid,
                }}
              />
            </Modal.Body>
          </EmbeddedModal>
        )}
      </div>
    );
  }
}

class Query {
  @UnformattedTextProperty(false, 100)
  flow?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  entityTypeNamespaceMachineName?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  entityTypeMachineName?: ExternalServiceId;

  @UnformattedTextProperty(false, 100)
  pool?: ExternalServiceId;

  @UuidProperty(false)
  sourceSite?: Uuid;

  @Transform(transformDate)
  publishedAfter?: moment.Moment;

  @Transform(transformDate)
  publishedBefore?: moment.Moment;

  @UnformattedTextProperty(false, 100)
  search?: string;

  //@UnformattedTextProperty(false, 5)
  //@Matches(/^(true|false|)$/)

  @BooleanProperty(false)
  @Transform(transformBool)
  deleted?: boolean;

  @BooleanProperty(false)
  @Transform(transformBool)
  deletedLocally?: boolean;

  @BooleanProperty(false)
  @Transform(transformBool)
  existsLocally?: boolean;
}

class Params {
  @BooleanProperty(false)
  viewAll?: boolean;

  @BooleanProperty(false)
  viewForSite?: boolean;

  @BooleanProperty(false)
  configurationAccess?: boolean;

  @UnformattedTextProperty(false, 1_000)
  imagePreviewJwt?: string;

  @NestedProperty(false, () => Query)
  query?: Query;
}

interface IEntityTypeOption {
  label: string;
  value: {
    name: string;
    namespaceMachineName: ExternalServiceId;
    machineName: ExternalServiceId;
  };
}
interface IEntityTypeGroupOption {
  label: string;
  options: IEntityTypeOption[];
}

interface IPoolOption {
  label: string;
  value: ExternalServiceId;
}
type IFlowOption = IPoolOption;

interface IProps {
  params: Params;
}

interface IStateFilters {
  selectedFlow?: IFlowOption;
  selectedEntityType?: IEntityTypeOption;
  selectedPool?: IPoolOption;
  selectedSourceSite?: ClientSiteEntity;

  publishedAfter?: moment.Moment;
  publishedBefore?: moment.Moment;

  search?: string;

  deleted?: boolean;
  deletedLocally?: boolean;
  existsLocally?: boolean;
}
interface IState extends ISyncCoreApiComponentState {
  configuration?: PullDashboardConfiguration;
  allEntityTypeOptions?: IEntityTypeOption[];
  allPoolOptions?: IPoolOption[];

  showAdvancedFilters?: boolean;

  publishedAfterFocused?: boolean;
  publishedBeforeFocused?: boolean;

  flowOptions?: IFlowOption[];

  entityTypeOptions?: IEntityTypeOption[] | IEntityTypeGroupOption[];

  poolOptions?: IPoolOption[];

  defaultFilters?: IStateFilters;
  filters?: IStateFilters;

  statusFiltersOpen?: boolean;

  pullingEntityId?: InternalId;

  reset?: number;

  query?: Query;
}

const NUMBER_OF_ITEMS_PER_PAGE_OPTIONS = [1, 5, 10, 25];
const DEFAULT_NUMBER_OF_ITEMS_PER_PAGE = NUMBER_OF_ITEMS_PER_PAGE_OPTIONS[1];

const FIXED_NAMESPACE_NAMES: { [key: string]: string } = {
  node: 'Content',
};

export class PullDashboardWithParams extends SyncCoreApiComponent<IProps, IState> {
  private statusFiltersButton = React.createRef<any>();

  get isBackendEmbed(): boolean {
    return !!(this.props.params.viewAll || this.props.params.viewForSite);
  }

  get defaultStatusFilterName(): string {
    if (this.props.params.viewAll) {
      return 'All';
    }
    if (this.props.params.viewForSite) {
      return 'Local';
    }
    return 'New';
  }

  async load(): Promise<Partial<IState>> {
    let configuration: PullDashboardConfiguration;
    if (this.isBackendEmbed) {
      configuration = {
        flows: [],
      };
    } else {
      configuration = await this.api.utility.configuration.pullDashboard();
    }

    const flowOptions = configuration?.flows.map((c) => ({ label: c.name, value: c.machineName }));
    const selectedFlow = this.isBackendEmbed
      ? undefined
      : this.props.params.query?.flow
      ? flowOptions!.find((c) => c.value === this.props.params.query?.flow) || flowOptions![0]
      : flowOptions![0];

    const flow = selectedFlow ? configuration!.flows.find((c) => c.machineName === selectedFlow.value) : undefined;
    const allEntityTypeOptions: IEntityTypeOption[] = flow
      ? flow.entityTypes.map((value) => ({ label: value.name, value }))
      : (await this.api.syndication.remoteEntityTypes.list()).map((value) => ({ label: value.name, value }));
    const allPoolOptions: IPoolOption[] = flow
      ? flow.pools.map((c) => ({ label: c.name, value: c.machineName }))
      : (await this.api.syndication.pools.list()).map((c) => ({ label: c.name, value: c.machineName }));

    // For pulling: By default we only show content that doesn't exist locally yet and that has not been deleted yet.
    // For "view all": Ignore "exists locally" but also hide deleted content by default.
    const defaultFilters = {
      existsLocally: this.props.params.viewAll ? undefined : this.props.params.viewForSite ? true : false,
      deleted: false,
      deletedLocally: false,
    };

    const stateUpdate: Partial<IState> = {
      configuration,
      flowOptions,
      defaultFilters,
      filters: { ...defaultFilters },
      allEntityTypeOptions,
      allPoolOptions,
      query: this.props.params.query || {},
    };

    this.setState(stateUpdate as any);

    if (Object.keys(stateUpdate.query!).length) {
      const { filters } = await this.getFiltersFromQuery(stateUpdate.query!, stateUpdate);
      stateUpdate.filters = filters;
    }

    this.updateFilters(selectedFlow, stateUpdate as any);

    return {};
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any): void {
    const query = this.props.params.query;
    if (this.state.filters && query && Object.keys(query).length) {
      if (!prevProps.params.query || JSON.stringify(query) !== JSON.stringify(prevProps.params.query)) {
        this.updateFiltersFromQuery(query, this.state);
      }
    }
  }

  async getFiltersFromQuery(query: Query, useState: Partial<IState>) {
    let changed = false;

    const filters = useState.filters!;

    if (query.deleted !== filters.deleted) {
      changed = true;
      filters.deleted = query.deleted;
    }
    if (query.deletedLocally !== filters.deletedLocally) {
      changed = true;
      filters.deletedLocally = query.deletedLocally;
    }
    if (query.existsLocally !== filters.existsLocally) {
      changed = true;
      filters.existsLocally = query.existsLocally;
    }
    if (query.search !== filters.search) {
      changed = true;
      filters.search = query.search;
    }
    if (query?.publishedAfter !== undefined && query.publishedAfter.valueOf() !== filters.publishedAfter?.valueOf()) {
      changed = true;
      filters.publishedAfter = query.publishedAfter;
    }
    if (query?.publishedBefore !== undefined && query.publishedBefore.valueOf() !== filters.publishedBefore?.valueOf()) {
      changed = true;
      filters.publishedBefore = query.publishedBefore;
    }

    if (
      query.entityTypeNamespaceMachineName !== filters.selectedEntityType?.value.namespaceMachineName ||
      query.entityTypeMachineName !== filters.selectedEntityType?.value.machineName
    ) {
      changed = true;
      const entityType =
        query?.entityTypeNamespaceMachineName && query?.entityTypeMachineName
          ? useState.allEntityTypeOptions?.find(
              (c) =>
                c.value.namespaceMachineName === query.entityTypeNamespaceMachineName && c.value.machineName === query.entityTypeMachineName
            )
          : undefined;
      filters.selectedEntityType = entityType;
    }
    if (query.flow !== filters.selectedFlow?.value) {
      changed = true;
      const flow = query?.flow ? useState.flowOptions?.find((c) => c.value === query.flow) : undefined;
      filters.selectedFlow = flow;
    }
    if (query.pool !== filters.selectedPool?.value) {
      changed = true;
      const pool = query?.pool ? useState.allPoolOptions?.find((c) => c.value === query.pool) : undefined;
      filters.selectedPool = pool;
    }
    if (query.sourceSite !== filters.selectedSourceSite?.uuid) {
      changed = true;
      const site = query?.sourceSite ? await this.api.billing.sites.itemByUuid(query.sourceSite) : undefined;
      filters.selectedSourceSite = site;
    }

    return {
      filters,
      changed,
    };
  }

  async updateFiltersFromQuery(query: Query, useState: Partial<IState>) {
    const { filters, changed } = await this.getFiltersFromQuery(query, useState);

    if (changed) {
      this.setState({ filters, query });
      this.updateFilters();
    }
  }

  getQueryFromFilters(previousQuery: Query, filters: IStateFilters) {
    let changed = false;
    const query = { ...previousQuery };

    if (query.deleted !== filters.deleted) {
      changed = true;
      query.deleted = filters.deleted;
    }
    if (query.deletedLocally !== filters.deletedLocally) {
      changed = true;
      query.deletedLocally = filters.deletedLocally;
    }
    if (query.existsLocally !== filters.existsLocally) {
      changed = true;
      query.existsLocally = filters.existsLocally;
    }
    if (query.search !== filters.search) {
      changed = true;
      query.search = filters.search;
    }
    if (query.publishedAfter?.valueOf() !== filters.publishedAfter?.valueOf()) {
      changed = true;
      query.publishedAfter = filters.publishedAfter;
    }
    if (query.publishedBefore?.valueOf() !== filters.publishedBefore?.valueOf()) {
      changed = true;
      query.publishedBefore = filters.publishedBefore;
    }

    if (
      query.entityTypeNamespaceMachineName !== filters.selectedEntityType?.value.namespaceMachineName ||
      query.entityTypeMachineName !== filters.selectedEntityType?.value.machineName
    ) {
      changed = true;
      query.entityTypeNamespaceMachineName = filters.selectedEntityType?.value.namespaceMachineName;
      query.entityTypeMachineName = filters.selectedEntityType?.value.machineName;
    }
    if (query.flow !== filters.selectedFlow?.value) {
      changed = true;
      query.flow = filters.selectedFlow?.value;
    }
    if (query.pool !== filters.selectedPool?.value) {
      changed = true;
      query.pool = filters.selectedPool?.value;
    }
    if (query.sourceSite !== filters.selectedSourceSite?.uuid) {
      changed = true;
      query.sourceSite = filters.selectedSourceSite?.uuid;
    }

    return {
      query: Object.assign(new Query(), query),
      changed,
    };
  }

  updateFilters<K extends keyof IState>(selectedFlow?: IFlowOption, stateUpdate?: Pick<IState, K>) {
    const state = { ...this.state, ...(stateUpdate || {}) };

    const configuration = state.configuration!;

    let filters = state.filters!;
    let { selectedEntityType, selectedPool } = filters;

    let allEntityTypeOptions = state.allEntityTypeOptions!;

    const flow = selectedFlow ? configuration!.flows.find((c) => c.machineName === selectedFlow.value) : undefined;
    if (flow) {
      allEntityTypeOptions = flow.entityTypes.map((value) => ({ label: value.name, value }));
    }

    let entityTypeOptions: IEntityTypeOption[] | IEntityTypeGroupOption[];
    let namespaces = allEntityTypeOptions.map((c) => c.value.namespaceMachineName);
    namespaces = namespaces.filter((a, index) => namespaces.indexOf(a) === index);

    // Group by namespace
    if (namespaces.length > 1) {
      entityTypeOptions = namespaces.map(
        (namespace) =>
          ({
            label:
              FIXED_NAMESPACE_NAMES[namespace] ||
              namespace.replace(/(^|[^A-Za-z0-9]+)([a-z0-9])/g, (m, $1, $2) => ($1 ? ' ' : '') + $2.toUpperCase()),
            options: allEntityTypeOptions.filter((c) => c.value.namespaceMachineName === namespace),
          } as IEntityTypeGroupOption)
      ) as IEntityTypeGroupOption[];
    } else {
      entityTypeOptions = allEntityTypeOptions;
    }

    let poolOptions: IPoolOption[] = state.allPoolOptions!;
    if (flow) {
      poolOptions = flow.pools.map((c) => ({ label: c.name, value: c.machineName }));
    }
    poolOptions = poolOptions.filter((a, index) => poolOptions.findIndex((b) => a.value === b.value) === index);

    // Unset the selected options if they are no longer available, but keep them if they are.
    filters = {
      ...filters,
      selectedFlow,
      selectedEntityType:
        selectedEntityType &&
        allEntityTypeOptions.find(
          (c) =>
            c.value.namespaceMachineName === selectedEntityType!.value.namespaceMachineName &&
            c.value.machineName === selectedEntityType!.value.machineName
        ),
      selectedPool: selectedPool && poolOptions.find((c) => c.value === selectedPool!.value),
    };

    this.setState({
      ...(stateUpdate || {}),
      entityTypeOptions,
      poolOptions,
      filters,
    });
  }

  render() {
    const { configuration, showAdvancedFilters, defaultFilters, filters, entityTypeOptions, poolOptions, statusFiltersOpen, reset, query } =
      this.state;

    if (!configuration || !filters || !defaultFilters || !query) {
      return this.renderRequest();
    }

    const {
      selectedFlow,

      publishedAfter,
      publishedBefore,

      selectedEntityType,

      selectedPool,

      deleted,
      deletedLocally,
      existsLocally,

      selectedSourceSite,

      search,
    } = filters;

    if (!selectedFlow && !this.props.params.viewAll && !this.props.params.viewForSite) {
      return <Alert variant="light">This site is not configured to receive any content manually.</Alert>;
    }

    if (!entityTypeOptions?.length) {
      return <Alert variant="light">This Flow is not configured to receive any content.</Alert>;
    }

    if (!poolOptions?.length) {
      return <Alert variant="light">This site is not configured to receive content manually from any Pool.</Alert>;
    }

    // TODO: Add filter by source site.
    // TODO: When using the pager, scroll up. Drupal must provide a message for this that uses $('body').scrollTop(0).

    const bodyStyles = window.getComputedStyle(document.body);
    const primary = bodyStyles.getPropertyValue('--primary');
    const danger = bodyStyles.getPropertyValue('--danger');

    const filterCount =
      (selectedSourceSite ? 1 : 0) +
      (selectedEntityType ? 1 : 0) +
      (selectedPool ? 1 : 0) +
      (publishedAfter ? 1 : 0) +
      (publishedBefore ? 1 : 0);
    const defaultStatusFilter =
      deleted === defaultFilters.deleted &&
      deletedLocally === defaultFilters.deletedLocally &&
      existsLocally === defaultFilters.existsLocally;
    const restrictiveStatusFilter = deleted === true || deletedLocally === true || existsLocally === true;
    const hasAnyCustomFilters = filterCount > 0 || restrictiveStatusFilter || !!search;
    const hasAnyFilters = hasAnyCustomFilters || deleted !== undefined || deletedLocally !== undefined || existsLocally !== undefined;

    const isAppEmbed = !!this.props.params.viewAll;

    return (
      <div style={{ minHeight: '400px' }} className={isAppEmbed ? 'p-1' : 'pe-3 ps-3 pt-1'}>
        <ContractWarnings />

        <PagedList<ClientPreviewItem, IPreviewServiceFilters>
          numberOfItemsPerPageOptions={NUMBER_OF_ITEMS_PER_PAGE_OPTIONS}
          defaultNumberOfItemsPerPage={DEFAULT_NUMBER_OF_ITEMS_PER_PAGE}
          highlightSearch=".paged-list .preview-html.has-preview, .paged-list .preview-name, .paged-list .preview-tag"
          searchable
          showLoadingAnimationImmediately
          isOnPageBackground
          key={reset?.toString()}
          showSpeed
          loadingAnimation={() => (
            <div className="m-3 mt-5 pt-5 d-flex align-items-center justify-content-center">
              <FontAwesomeIcon icon={faSpinner} spin size="8x" className="text-light" />
            </div>
          )}
          initialFilters={{
            flowMachineName: selectedFlow?.value,
            entityTypeNamespaceMachineNames: selectedEntityType?.value.namespaceMachineName
              ? [selectedEntityType?.value.namespaceMachineName]
              : [],
            entityTypeMachineNames: selectedEntityType?.value.machineName ? [selectedEntityType?.value.machineName] : [],
            poolMachineNames: selectedPool?.value ? [selectedPool?.value] : [],
            publishedEarliest: publishedAfter,
            publishedLatest: publishedBefore,
            search,
            sourceSiteId: selectedSourceSite?.id,
            deleted,
            deletedLocally,
            existsLocally,
          }}
          request={(page, filter, itemsPerPage) => {
            setTimeout(() => {
              const { changed, query } = this.getQueryFromFilters(this.state.query!, this.state.filters!);
              if (changed) {
                sendToParent({
                  type: 'update-query',
                  query: instanceToPlain(query),
                });
              }
            }, 1);

            return this.api.syndication.previews.list(
              {
                flowMachineName: selectedFlow?.value,
                deleted,
                deletedLocally,
                existsLocally,
                ...filter,
              },
              { page, itemsPerPage: itemsPerPage || DEFAULT_NUMBER_OF_ITEMS_PER_PAGE }
            );
          }}
          renderFilters={(onChange) => {
            const flowFilter =
              configuration.flows.length > 1 && !this.isBackendEmbed ? (
                <Select
                  value={selectedFlow}
                  options={configuration.flows.map((c) => ({ label: c.name, value: c.machineName }))}
                  onChange={(selectedFlow) => {
                    if (selectedFlow && selectedFlow.value !== this.state.filters!.selectedFlow!.value) {
                      this.updateFilters(selectedFlow);
                      onChange('flowMachineName', selectedFlow.value, true);
                    }
                  }}
                  id="flow-filter"
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '250px',
                    }),
                  }}
                  placeholder="Select Flow..."
                />
              ) : undefined;
            const typeFilter =
              entityTypeOptions.length > 0 ? (
                <Select<IEntityTypeOption>
                  value={selectedEntityType}
                  options={entityTypeOptions as IEntityTypeOption[]}
                  onChange={(selectedEntityType) => {
                    this.setState({ filters: { ...filters, selectedEntityType: selectedEntityType || undefined } });
                    onChange(
                      {
                        entityTypeNamespaceMachineNames: selectedEntityType ? [selectedEntityType.value.namespaceMachineName] : undefined,
                        entityTypeMachineNames: selectedEntityType ? [selectedEntityType.value.machineName] : undefined,
                      },
                      true
                    );
                  }}
                  //components={{ Option: CustomOption }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '200px',
                    }),
                  }}
                  isClearable
                  placeholder="Select type..."
                />
              ) : undefined;
            const poolFilter =
              poolOptions.length > 0 ? (
                <Select
                  value={selectedPool}
                  options={poolOptions}
                  onChange={(selectedPool) => {
                    this.setState({ filters: { ...filters, selectedPool: selectedPool || undefined } });
                    onChange('poolMachineNames', selectedPool ? [selectedPool.value] : undefined, true);
                  }}
                  //components={{ Option: CustomOption }}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary,
                      danger,
                    },
                  })}
                  styles={{
                    control: (base) => ({
                      ...base,
                      minWidth: '200px',
                    }),
                  }}
                  isClearable
                  placeholder="Select Pool..."
                />
              ) : undefined;

            const now = moment();
            const isToday = (date: moment.Moment) => now.isSame(date, 'date');

            const statusFilter = (name: 'existsLocally' | 'deleted' | 'deletedLocally', label: string) => (
              <div className="mb-3">
                <div>
                  <Form.Label>{label}</Form.Label>
                </div>
                <div>
                  <ButtonGroup className="shadow-border">
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === true ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: true } } as any);
                        onChange(name, true, true);
                      }}
                      className={`${name + '-yes'} border-primary border-end`}
                    >
                      Yes
                    </Button>
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === false ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: false } } as any);
                        onChange(name, false, true);
                      }}
                      className={`${name + '-no'} border-primary border-end border-start`}
                    >
                      No
                    </Button>
                    <Button
                      style={{ width: '80px' }}
                      variant={filters[name] === undefined ? 'primary' : 'light'}
                      onClick={() => {
                        this.setState({ filters: { ...filters, [name]: undefined } } as any);
                        onChange(name, undefined, true);
                      }}
                      className={`${name + '-both'} border-primary border-start`}
                    >
                      Both
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            );

            return (
              <>
                <div className="d-flex">
                  {flowFilter ? <div className="pe-1">{flowFilter}</div> : undefined}
                  <div className="px-1">
                    <Button
                      ref={this.statusFiltersButton}
                      variant={statusFiltersOpen ? 'light' : 'light'}
                      onClick={() => this.setState({ statusFiltersOpen: !statusFiltersOpen })}
                      id="status-filter"
                      className="shadow-sm"
                    >
                      Status: {defaultStatusFilter ? this.defaultStatusFilterName : <Badge bg="danger">custom</Badge>}{' '}
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                    <Overlay target={this.statusFiltersButton.current} show={statusFiltersOpen} placement="bottom">
                      {({ placement, arrowProps, show: _show, popper, ...props }) => (
                        <div
                          {...props}
                          style={{
                            ...props.style,
                          }}
                          className={`bg-white rounded p-2 shadow ${props.className || ''}`}
                        >
                          {statusFilter('existsLocally', 'Exists here')}
                          {statusFilter('deleted', 'Source deleted')}
                          {statusFilter('deletedLocally', 'Deleted here')}

                          <Right>
                            <Button
                              className="me-3 py-1 px-2"
                              variant={defaultStatusFilter ? 'dark' : 'link'}
                              onClick={() => {
                                const setTo = { deleted: false, deletedLocally: false, existsLocally: false };
                                this.setState({ filters: { ...filters, ...setTo } });
                                onChange(setTo, true);
                              }}
                              id="status-filter-reset"
                            >
                              Reset
                            </Button>
                          </Right>
                        </div>
                      )}
                    </Overlay>
                  </div>
                  <div className={`${flowFilter ? 'px-1' : 'pe-1'} flex-grow-1 align-self-stretch position-relative`}>
                    <Form.Control
                      autoFocus
                      id="searchbar"
                      type="text"
                      placeholder="Search..."
                      value={search || ''}
                      onChange={(e) => {
                        const search = e.target.value || undefined;
                        this.setState({ filters: { ...filters, search } });
                        onChange('search', search);
                      }}
                    />
                    <div
                      className={`${search ? 'd-block' : 'd-none'} cursor-pointer px-2 op-hover`}
                      style={{ top: '5px', bottom: '5px', right: '10px', position: 'absolute' }}
                      onClick={() => {
                        this.setState({ filters: { ...filters, search: '' } });
                        onChange('search', '', true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} className="align-middle text-dark" />
                    </div>
                  </div>
                  <div className="ps-1">
                    <Button
                      variant={'light'}
                      onClick={() => this.setState({ showAdvancedFilters: !showAdvancedFilters })}
                      id="advanced-filter"
                      className="bg-none"
                    >
                      <FontAwesomeIcon icon={faFilter} /> Advanced
                      {filterCount ? (
                        <>
                          {' '}
                          <Badge bg={'danger'}>{filterCount}</Badge>
                        </>
                      ) : null}{' '}
                      <FontAwesomeIcon icon={showAdvancedFilters ? faChevronUp : faChevronDown} />
                    </Button>
                  </div>
                </div>
                <Collapse in={!!showAdvancedFilters}>
                  <div>
                    <div className="d-flex mt-3">
                      <div className="pe-1" style={{ width: '200px' }}>
                        <div>
                          <Form.Label>Source</Form.Label>
                        </div>
                        <SelectSite
                          selectedSite={selectedSourceSite}
                          onSelect={(selectedSourceSite) => {
                            onChange('sourceSiteId', selectedSourceSite?.id, true);
                            this.setState({ filters: { ...filters, selectedSourceSite } });
                          }}
                          isClearable
                        />
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Type</Form.Label>
                        </div>
                        {typeFilter}
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Pool</Form.Label>
                        </div>
                        {poolFilter}
                      </div>
                      <div className="px-1">
                        <div>
                          <Form.Label>Published after</Form.Label>
                        </div>
                        <SingleDatePicker
                          date={publishedAfter || null}
                          onDateChange={(publishedAfter) => {
                            const value = publishedAfter?.startOf('day');
                            this.setState({ filters: { ...filters, publishedAfter: value || undefined } });
                            onChange('publishedEarliest', value?.valueOf() || undefined, true);
                          }}
                          isOutsideRange={() => false}
                          isDayHighlighted={isToday}
                          focused={this.state.publishedAfterFocused || false}
                          onFocusChange={({ focused: publishedAfterFocused }) => this.setState({ publishedAfterFocused })}
                          id="publishedAfter"
                          placeholder="Select date..."
                          showClearDate
                        />
                      </div>
                      <div className="ps-1">
                        <div>
                          <Form.Label>Published before</Form.Label>
                        </div>
                        <SingleDatePicker
                          date={publishedBefore || null}
                          onDateChange={(publishedBefore) => {
                            const value = publishedBefore?.endOf('day');
                            this.setState({ filters: { ...filters, publishedBefore: value || undefined } });
                            onChange('publishedLatest', value?.valueOf() || undefined, true);
                          }}
                          isOutsideRange={() => false}
                          isDayHighlighted={isToday}
                          focused={this.state.publishedBeforeFocused || false}
                          onFocusChange={({ focused: publishedBeforeFocused }) => this.setState({ publishedBeforeFocused })}
                          id="publishedBefore"
                          placeholder="Select date..."
                          showClearDate
                        />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </>
            );
          }}
          emptyMessage={
            <Alert variant={'light'}>
              No content matches {hasAnyCustomFilters ? 'your' : 'the default'} filters.&nbsp;
              {hasAnyCustomFilters && (
                <Button
                  variant="link"
                  onClick={() =>
                    this.setState({
                      reset: Date.now(),
                      filters: {
                        selectedFlow,
                        ...defaultFilters,
                      },
                    })
                  }
                >
                  Reset
                </Button>
              )}
              {hasAnyFilters && (
                <>
                  {hasAnyCustomFilters && ' or '}
                  <Button
                    variant="link"
                    onClick={() =>
                      this.setState({
                        reset: Date.now(),
                        filters: {
                          selectedFlow,
                        },
                      })
                    }
                  >
                    Show everything
                  </Button>
                </>
              )}
            </Alert>
          }
          renderItem={(item, index, { setFilter }) => {
            return (
              <PullDashboardItem
                key={item.entity.id}
                item={item}
                pullWithFlowMachineName={selectedFlow?.value}
                configurationAccess={this.props.params.configurationAccess}
                imagePreviewJwt={this.props.params.imagePreviewJwt}
                addTextFilter={(search) => {
                  this.setState({ filters: { ...filters, search } });
                  setFilter('search', search, true);
                }}
              />
            );
          }}
        />
      </div>
    );
  }
}

export const PullDashboard = () => (
  <SyncCoreFeatureFlagGate
    featureName={FEATURE_PREVIEWS}
    ifEnabled={() => (
      <ParamsComponent<Params> Params={Params}>
        {(params: Params) => {
          return <PullDashboardWithParams params={params} />;
        }}
      </ParamsComponent>
    )}
    ifDisabled={() => (
      <Alert variant="warning">
        The Sync Core you are connected to doesn't support previews so the pull dashboard can't be used.
        <br />
        <br />
        Please contact your Sync Core owner to enable this feature if you want to use it.
      </Alert>
    )}
  />
);
