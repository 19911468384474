import { IAppConfigurationProp } from '@edgebox/react-components';
import { ISyncCoreApi, SyncCoreApi } from '@edgebox/sync-core-rest-client';
import React, { PropsWithChildren } from 'react';
import { IDataComponentConfiguration } from '../services/SyncCoreApiComponent';

export interface ISyncCoreApiContextValue {
  api: ISyncCoreApi;
  jwt: string;
  baseUrl: string;
}

export const SyncCoreApiContext: React.Context<ISyncCoreApiContextValue | undefined> = React.createContext<
  ISyncCoreApiContextValue | undefined
>(undefined);

interface IProps extends IAppConfigurationProp<IDataComponentConfiguration>, PropsWithChildren {}

export class SyncCoreApiProvider extends React.Component<IProps, {}> {
  protected api: SyncCoreApi;

  constructor(props: IProps) {
    super(props);

    this.api = SyncCoreApi.getInstance({
      services: {
        syncCore: {
          baseDomain: this.props.appConfiguration.syncCoreDomain,
        },
      },
      jwt: this.props.appConfiguration.jwt,
    });
  }

  public render() {
    const { children } = this.props;

    return (
      <SyncCoreApiContext.Provider
        value={{
          api: this.api,
          jwt: this.props.appConfiguration.jwt,
          baseUrl: this.props.appConfiguration.baseUrl,
        }}
      >
        {children}
      </SyncCoreApiContext.Provider>
    );
  }
}
