import { ExternalServiceId, InternalId } from '@edgebox/data-definition-kit';
import { ClientRemoteEntityTypeVersionEntity } from '@edgebox/sync-core-rest-client';
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ListRequestMode } from '../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../services/SyncCoreApiComponent';
import { CountSiteEntities } from './CountSiteEntities';
import { CountSyncCoreEntities } from './CountSyncCoreEntities';
import { SiteFeatureFlagGate } from './SiteFeatureFlagGate';
import { EntityTypeIcon } from './Customer/EntityTypeIcon';
import { SyncCoreApiContext } from '../contexts/SyncCoreApiContext';

interface IPropsBase {
  count?: {
    fromSite: boolean;
    mode: ListRequestMode;
    poolMachineNames?: ExternalServiceId[];
    poolIds?: InternalId[];
    flowMachineName?: ExternalServiceId;
  };
  loading?: () => React.ReactElement;
  light?: boolean;
  withNamespace?: boolean;
  asIcon?: boolean;
  withIcon?: boolean;
  id?: string;
  isLeft?: boolean;
}
type IProps = IPropsBase &
  (
    | {
        entityId: InternalId;
        entity?: undefined;
      }
    | {
        entityId?: undefined;
        entity: ClientRemoteEntityTypeVersionEntity;
      }
  );

interface IState extends ISyncCoreApiComponentState {
  entityType?: ClientRemoteEntityTypeVersionEntity;
}

export function WithEntityTypeVersion<Type extends React.ReactNode>(props: {
  entityId: InternalId;
  children: (entityTypeVersion: ClientRemoteEntityTypeVersionEntity) => Type;
  loading?: () => React.ReactElement;
}) {
  const api = useContext(SyncCoreApiContext);

  const [entityTypeVersion, setEntityTypeVersion] = useState<ClientRemoteEntityTypeVersionEntity | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const entity = await api?.api.syndication.remoteEntityTypeVersions.item(props.entityId);
      setEntityTypeVersion(entity);
    })();
  }, [api, props.entityId]);

  if (entityTypeVersion) {
    return props.children(entityTypeVersion);
  }

  return props.loading ? props.loading() : null;
}

export class EntityTypeVersionName extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const { entityId, entity } = this.props;

    return {
      entityType: entity ?? (await this.api.syndication.remoteEntityTypeVersions.item(entityId)),
    };
  }

  render() {
    const { light, withNamespace, count, asIcon, id, withIcon, loading, isLeft } = this.props;

    const { entityType } = this.state;
    if (entityType) {
      function renderIcon() {
        return (
          <EntityTypeIcon
            useTitle=""
            appType={entityType!.appType}
            namespaceMachineName={entityType!.namespaceMachineName}
            machineName={entityType!.machineName}
            className="align-middle"
          />
        );
      }

      let content = (
        <>
          {withIcon ? <>{renderIcon()} </> : null}
          {withNamespace ? (
            <span className="text-truncate d-inline-block mw-100 align-middle">{entityType.name}</span>
          ) : (
            <span
              title={asIcon ? undefined : `${entityType.namespaceMachineName}.${entityType.machineName}`}
              className="text-truncate d-inline-block mw-100 align-middle"
            >
              {entityType.name}
            </span>
          )}{' '}
          {withNamespace && <em>({entityType.namespaceMachineName})</em>}
          {count &&
            (count.fromSite ? (
              <SiteFeatureFlagGate featureName="count-entities">
                {() => (
                  <Badge bg="danger" className="ms-1">
                    <CountSiteEntities
                      flowMachineName={count.flowMachineName}
                      mode={count.mode}
                      namespaceMachineName={entityType.namespaceMachineName}
                      machineName={entityType.machineName}
                    />
                  </Badge>
                )}
              </SiteFeatureFlagGate>
            ) : (
              <Badge bg="danger" className="ms-1">
                <CountSyncCoreEntities
                  namespaceMachineName={entityType.namespaceMachineName}
                  machineName={entityType.machineName}
                  poolMachineNames={count.poolMachineNames}
                  poolIds={count.poolIds}
                />
              </Badge>
            ))}
        </>
      );

      if (asIcon) {
        return (
          <OverlayTrigger
            placement={isLeft ? 'right' : 'left'}
            overlay={(props) => (
              <Tooltip id={`tooltip-${id || Date.now()}-entity-type-${this.props.entityId}`} {...props} className="tooltip-wide">
                {content}
              </Tooltip>
            )}
          >
            <span>{renderIcon()}</span>
          </OverlayTrigger>
        );
      }

      return content;
    }

    if (loading) {
      return loading();
    }

    return <FontAwesomeIcon icon={faSpinner} spin className={`text-${light ? 'light' : 'dark'}`} style={{ fontSize: '1.4em' }} />;
  }
}
