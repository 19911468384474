import { LeftRightH3 } from '@edgebox/react-components';
import React, { useState } from 'react';
import Select from 'react-select';
import { PagedSyndicationList } from '../../PagedSyndicationList';
import { STATUS_FILTERS, StatusFilter } from './SyndicationHelper';
import { InternalId } from '@edgebox/data-definition-kit';
import { getStyleColors } from '../../../Helpers';
import { ClientSiteEntity, ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { SelectSite } from '../../SelectSite';

export function SyndicationTargets({
  sourceSyndicationId,
  setSyndication,
  setSyndicationAndErrorDetails,
}: {
  sourceSyndicationId: InternalId;
  setSyndication: (selectedSyndication?: ClientSyndicationEntity) => void;
  setSyndicationAndErrorDetails: (
    selectedSyndication: ClientSyndicationEntity,
    errorDetails: { operationIndex: number; errorIndex: number }
  ) => void;
}) {
  const [statusFilter, setStatusFilter] = useState<StatusFilter | undefined>();
  const [selectedSite, setSelectedSite] = useState<ClientSiteEntity | undefined>();

  const selectSite = (
    <SelectSite selectedSite={selectedSite} onSelect={(selectedSite) => setSelectedSite(selectedSite)} isClearable width="200px" />
  );

  const { primary, danger } = getStyleColors();

  return (
    <div style={{ minHeight: '300px' }}>
      <LeftRightH3
        className="mt-5 mb-0"
        left={'Targets'}
        right={
          <div className="fs-6 fw-normal">
            <Select
              placeholder="Status"
              value={{ statusFilter: statusFilter || 'all' }}
              getOptionValue={(option) => option.statusFilter || 'all'}
              getOptionLabel={(option) => option.statusFilter?.replace(/-/g, ' ') || 'All'}
              formatOptionLabel={(label) => <div className={`text-capitalize`}>{label?.statusFilter?.replace(/-/g, ' ') || ''}</div>}
              options={(Object.keys(STATUS_FILTERS) as StatusFilter[]).map((statusFilter: StatusFilter) => ({
                statusFilter,
              }))}
              onChange={(option) => option && setStatusFilter(option.statusFilter)}
              styles={{
                container: (base) => ({
                  ...base,
                  width: '200px',
                  display: 'inline-block',
                  marginRight: '0.5em',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary,
                  danger,
                },
              })}
            />
            {selectSite}
          </div>
        }
      />
      <PagedSyndicationList
        isOnPageBackground
        statuses={statusFilter && statusFilter !== 'all' ? STATUS_FILTERS[statusFilter] : undefined}
        sourceSyndicationId={sourceSyndicationId}
        siteIds={selectedSite?.id ? [selectedSite.id] : undefined}
        includeViewLink
        groupBySite
        showSiteName
        onSelect={setSyndication}
        onErrorDetails={setSyndicationAndErrorDetails}
      />
    </div>
  );
}
