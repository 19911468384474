import { BooleanProperty, ExternalServiceId, NestedProperty, UnformattedTextProperty, UrlProperty } from '@edgebox/data-definition-kit';
import { ButtonLink, ContentCol, HeaderCol } from '@edgebox/react-components';
import { SiteEnvironmentType, SiteStatus, SyncCoreDataDefinitionsEnumTranslator } from '@edgebox/sync-core-data-definitions';
import {
  ClientContractEntity,
  ClientContractRevisionEntity,
  ClientCustomerEntity,
  ClientSiteEntity,
  ClientSyndicationEntity,
} from '@edgebox/sync-core-rest-client';
import { ContractConfiguration } from '@edgebox/sync-core-rest-client/dist/services/api';
import React from 'react';
import { Alert, Badge, Button, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { FEATURE_DOMAINS } from '../../../features';
import { sendToParent } from '../../../frame-messages';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { AppParamsContext, IAppParams } from '../../AppParams';
import { ContractWarnings } from '../../ContractWarnings';
import { CountSyncCoreEntities } from '../../CountSyncCoreEntities';
import { SiteFeatureFlagGate } from '../../SiteFeatureFlagGate';
import { SyncCoreFeatureFlagGate } from '../../SyncCoreFeatureFlagGate';
import { ParamsComponent } from '../ParamsComponent';
import { ProjectName } from '../ProjectName';
import { SiteSettings } from './SiteSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons/faArrowUpRightFromSquare';
import { ExternalLinkWithIcon } from '../../ExternalLinkWithIcon';
import { SyncConfigButton } from '../../SyncConfigButton';

class FlowItem {
  @UnformattedTextProperty(true, 100)
  machineName!: ExternalServiceId;
  @UnformattedTextProperty(true, 1000)
  name!: string;
  @UrlProperty(true, false, { allowLocalhost: true })
  exportUrl!: string;
}

class MigratedParams {
  @BooleanProperty(false)
  migrated?: boolean;

  @NestedProperty(false, () => FlowItem, { each: true })
  flows?: FlowItem[];

  @UrlProperty(false, false, { allowLocalhost: true })
  createFlowUrl?: string;
}

class Params {
  @BooleanProperty(false)
  viewOnly?: boolean;
}

interface IInternalProps {
  embedded?: boolean;
}

interface IProps extends IInternalProps {
  params: Params;
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
  contract?: ClientContractEntity;
  contractConfiguration?: ContractConfiguration;
  contractRevision?: ClientContractRevisionEntity | null;
  customer?: ClientCustomerEntity;
  loadedSite?: boolean;
  refreshingContract?: boolean;
  exportingConfig?: ClientSyndicationEntity;
}

export class RegisteredSiteWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    let site: ClientSiteEntity | undefined = undefined;
    try {
      site = await this.getCurrentSite();

      this.setState({
        site,
        loadedSite: true,
      });

      if (!site) {
        return {};
      }
    } catch (e) {
      return {
        loadedSite: true,
      };
    }

    const contract = await site?.contract.get();
    const customer = await site?.customer.get();

    // TODO: Create interface that provides customer and contract revision for a given site.
    const contractRevisions = contract ? await this.api.billing.contractRevisions.getMostRecentForContract(contract.id) : undefined;
    const contractConfiguration = await this.api.utility.configuration.contract();

    return {
      contract,
      contractRevision: contractRevisions?.current || null,
      contractConfiguration,
      customer,
    };
  }

  render() {
    const { site, loadedSite, customer, contractRevision, contractConfiguration, refreshingContract, contract } = this.state;

    const { params } = this.props;

    const isBackend = !!params.viewOnly;

    const registerAgain = (light?: boolean) =>
      params.viewOnly ? null : (
        <Button
          variant={light ? 'light' : 'primary'}
          onClick={() =>
            sendToParent({
              type: 'register-site',
            })
          }
        >
          Re-register
        </Button>
      );

    if (!site) {
      if (loadedSite) {
        return (
          <Alert variant={'danger'}>
            Failed to load site information. Please reload the page. If this issue persists please contact our support.
            <br />
            <br />
            {registerAgain()}
          </Alert>
        );
      }
      return this.renderRequest();
    }

    const didBaseUrlChange = this.context.baseUrl && this.context.baseUrl !== site.baseUrl;

    return (
      <div>
        <ContractWarnings />

        {didBaseUrlChange ? (
          <Alert variant={'danger'}>
            The base URL of this site changed. Please re-register this site below.
            <br />
          </Alert>
        ) : site.status !== SiteStatus.Active ? (
          <Alert variant={'danger'}>
            This site is not active and can't push or pull content. Please use{' '}
            <ExternalLinkWithIcon to="https://app.content-sync.io/">the Content Sync app</ExternalLinkWithIcon> to reactivate the site.
          </Alert>
        ) : (
          <Alert variant={'success'}>This site was successfully registered to the Sync Core.</Alert>
        )}
        {!isBackend && (
          <ButtonLink className="fw-bold rounded pt-2 pb-2 ps-4 pe-4 mt-2 mb-2" target="_blank" to={'https://app.content-sync.io/'}>
            <span className="d-inline-block pb-1">
              Open Content Sync App <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </span>
          </ButtonLink>
        )}
        <Row>
          <HeaderCol xs={2}>Name</HeaderCol>
          <ContentCol>
            {site.name}{' '}
            <Badge
              bg={
                site.environmentType === SiteEnvironmentType.Testing || site.environmentType === SiteEnvironmentType.Local
                  ? 'warning'
                  : 'primary'
              }
            >
              {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteEnvironmentType', site.environmentType)}
            </Badge>{' '}
            <Badge bg={site.status === SiteStatus.Inactive ? 'danger' : 'light'}>
              {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('SiteStatus', site.status)}
            </Badge>{' '}
            {registerAgain(true)}
            <SyncConfigButton site={site} />
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Contract</HeaderCol>
          <ContentCol>
            <div>
              {contractRevision ? (
                <>
                  {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Product', contractRevision.product)}{' '}
                  {SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue('Package', contractRevision.packageType)}
                </>
              ) : contractRevision === null ? (
                <span className={'text-danger'}>Expired</span>
              ) : (
                'Loading...'
              )}{' '}
              {(contractRevision || contractRevision === null) && contract ? (
                <Button
                  variant="light"
                  disabled={refreshingContract}
                  onClick={async () => {
                    this.setState({ refreshingContract: true });
                    await this.api.utility.backend.refreshContract(contract!.id);
                    const updatedContract = await this.api.billing.contracts.item(contract!.id, true);
                    const updatedRevisions = await this.api.billing.contractRevisions.getMostRecentForContract(updatedContract.id);
                    const updatedContractConfiguration = await this.api.utility.configuration.contract();
                    this.setState({
                      refreshingContract: false,
                      contract: updatedContract,
                      contractRevision: updatedRevisions?.current || null,
                      contractConfiguration: updatedContractConfiguration,
                    });
                  }}
                >
                  Refresh
                </Button>
              ) : undefined}
            </div>
            <div className="text-truncate mw-100">{customer ? customer.name : undefined}</div>
            {contractConfiguration && (
              <div>
                {contractConfiguration.remainingUpdates === null || contractConfiguration.remainingUpdates === undefined ? (
                  <em>Unlimited</em>
                ) : (
                  <strong>
                    {contractConfiguration.remainingUpdates} updates remaining{' '}
                    {contractRevision ? `until ${contractRevision.endDate.format('LL')}` : undefined}
                  </strong>
                )}
              </div>
            )}
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Project</HeaderCol>
          <ContentCol>
            <ProjectName entityId={site.project.getId()} />{' '}
            <Badge bg="light">
              <CountSyncCoreEntities /> independent entities
            </Badge>
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>{didBaseUrlChange ? 'Expected site URL' : 'Site URL'}</HeaderCol>
          <ContentCol>
            <div className={didBaseUrlChange ? 'text-danger fw-bold' : ''}>{site.baseUrl}</div>
            {didBaseUrlChange && (
              <div>
                <span className={'fw-bold'}>{this.context.baseUrl}</span> <Badge bg="light">new</Badge>
              </div>
            )}
            {didBaseUrlChange && registerAgain()}
            <SyncCoreFeatureFlagGate
              featureName={FEATURE_DOMAINS}
              ifEnabled={() =>
                site.domains &&
                site.domains.length > 1 &&
                site.environmentType !== SiteEnvironmentType.Testing &&
                site.environmentType !== SiteEnvironmentType.Local ? (
                  <span className="text-warning">
                    You will be invoiced for{' '}
                    <OverlayTrigger
                      placement="left"
                      overlay={(props) => (
                        <Tooltip id={`tooltip-domains`} {...props} className="tooltip-wide">
                          <ul>
                            {site.domains!.map((domain, index) => (
                              <li key={index}>{domain}</li>
                            ))}
                          </ul>
                        </Tooltip>
                      )}
                    >
                      <span className="border-warning" style={{ cursor: 'help', borderBottom: '1px dashed #000' }}>
                        {site.domains!.length} domains
                      </span>
                    </OverlayTrigger>{' '}
                    on this site.
                  </span>
                ) : null
              }
            />
          </ContentCol>
        </Row>
        <Row>
          <HeaderCol xs={2}>Sync Core</HeaderCol>
          <ContentCol>
            <AppParamsContext.Consumer>{(params: IAppParams) => params.siteOptions.syncCoreDomain}</AppParamsContext.Consumer>
          </ContentCol>
        </Row>

        {!params.viewOnly && (
          <SiteFeatureFlagGate featureName="site-settings-tab" ifDisabled={() => <SiteSettings embedded />}>
            {() => <></>}
          </SiteFeatureFlagGate>
        )}

        <ParamsComponent<MigratedParams> Params={MigratedParams}>
          {(params: MigratedParams) => {
            return params.migrated ? (
              <Row>
                <HeaderCol xs={2}>Migration</HeaderCol>
                <ContentCol>
                  Finished{' '}
                  <Button
                    variant="light"
                    onClick={() => sendToParent({ type: 'migrate' })}
                    className="py-0"
                    style={{ verticalAlign: 'baseline' }}
                  >
                    view
                  </Button>
                </ContentCol>
              </Row>
            ) : null;
          }}
        </ParamsComponent>
      </div>
    );
  }
}

export const RegisteredSite = (defaults: IInternalProps) => (
  <ParamsComponent<Params> Params={Params}>
    {(params: Params) => {
      return <RegisteredSiteWithParams params={params} {...defaults} />;
    }}
  </ParamsComponent>
);
