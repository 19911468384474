import { ContentCol, FormField, FormRow, formWithValidation, HeaderCol, InfoIcon, Right } from '@edgebox/react-components';
import { GetThrottlingDto, SetThrottlingDto } from '@edgebox/sync-core-rest-client';
import { instanceToPlain, plainToInstance } from 'class-transformer';
import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { EmbeddedModal } from '../../EmbeddedModal';

interface IProps {}

interface IState extends ISyncCoreApiComponentState {
  values: GetThrottlingDto;
  edit?: boolean;
}

const ThrottlingForm = formWithValidation<SetThrottlingDto, SetThrottlingDto>(
  (values) => plainToInstance(SetThrottlingDto, values),
  (entity) => instanceToPlain<SetThrottlingDto>(entity)
);

export class ThrottleSettings extends SyncCoreApiComponent<IProps, IState> {
  async load() {
    const values = await this.api.billing.sites.getThrottling();

    return {
      values,
    };
  }

  render() {
    const { values, edit } = this.state;

    if (!values) {
      return this.renderRequest();
    }

    return (
      <div style={{ maxWidth: '300px' }}>
        <Row>
          <HeaderCol>
            Max parallel requests{' '}
            <InfoIcon>
              All requests that can't be processed immediately are queued and then run every minute. This is the number of requests that
              will be made in parallel to this site when that cron job runs.
            </InfoIcon>
          </HeaderCol>
          <ContentCol xs={2}>{values.appliedSiteParallel}</ContentCol>
        </Row>
        <Row>
          <HeaderCol>
            Max requests per minute{' '}
            <InfoIcon>This site will not receive more than this number of requests from the Sync Core per minute.</InfoIcon>
          </HeaderCol>
          <ContentCol xs={2}>{values.appliedSitePerMinute}</ContentCol>
        </Row>
        <Button className="px-0 edit-throttling" variant="light" onClick={() => this.setState({ edit: true })}>
          Edit throttling
        </Button>

        <EmbeddedModal show={edit} onHide={() => this.setState({ edit: undefined })} size="xl" scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Edit throttling</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ThrottlingForm
              initialValues={values}
              onSubmit={async (values: SetThrottlingDto) => {
                this.setState({
                  values: await this.api.billing.sites.setThrottling(values),
                  edit: undefined,
                });
              }}
            >
              {() => (
                <>
                  <Row>
                    <Col xs={12} lg={4}>
                      <h4>This site</h4>
                      <FormRow name={'siteParallel'} label={'Parallel'} labelPlacement={'above'}>
                        <FormField name={'siteParallel'} label={'(use default)'} type={'number'} noRow />
                      </FormRow>
                      <FormRow name={'sitePerMinute'} label={'Per minute'} labelPlacement={'above'}>
                        <FormField name={'sitePerMinute'} label={'(use default)'} type={'number'} noRow />
                      </FormRow>
                    </Col>

                    {/* Requires work in the Sync Core */
                    /*<Col xs={12} lg={4}>
                        <h4>Across all sites</h4>
                        <FormRow name={'projectParallel'} label={'Parallel'} labelPlacement={'above'}>
                          <FormField name={'projectParallel'} label={'no limit'} type={'number'} noRow />
                        </FormRow>
                        <FormRow name={'projectPerMinute'} label={'Per minute'} labelPlacement={'above'}>
                          <FormField name={'projectPerMinute'} label={'no limit'} type={'number'} noRow />
                        </FormRow>
                      </Col>*/}

                    <Col xs={12} lg={4}>
                      <h4>Defaults for all sites</h4>
                      <FormRow
                        name={'projectDefaultPerSiteParallel'}
                        label={'Default parallel per site (unless overridden)'}
                        labelPlacement={'above'}
                      >
                        <FormField
                          name={'projectDefaultPerSiteParallel'}
                          label={`Use Sync Core default, currently ${values.defaultPerSiteParallel}`}
                          type={'number'}
                          noRow
                        />
                      </FormRow>
                      <FormRow
                        name={'projectDefaultPerSitePerMinute'}
                        label={'Default per minute per site (unless overridden)'}
                        labelPlacement={'above'}
                      >
                        <FormField
                          name={'projectDefaultPerSitePerMinute'}
                          label={`Use Sync Core default, currently ${values.defaultPerSitePerMinute}`}
                          type={'number'}
                          noRow
                        />
                      </FormRow>
                    </Col>
                  </Row>

                  <Right className="pe-3">
                    <Button variant="primary" type="submit">
                      Save
                    </Button>
                  </Right>
                </>
              )}
            </ThrottlingForm>
          </Modal.Body>
        </EmbeddedModal>
      </div>
    );
  }
}
