import * as Sentry from '@sentry/react';
import React from 'react';

export class NotFoundError extends Error {
  constructor() {
    super(NotFoundError.MESSAGE);

    Object.setPrototypeOf(this, NotFoundError.prototype);
  }

  static MESSAGE = 'Page not found';
}

export class NotFound extends React.Component<{}, {}> {
  constructor(props: {}) {
    super(props);

    Sentry.captureException(new NotFoundError());
  }

  render() {
    return (
      <>
        <p>We are sorry, the page you requested was not found. Please try the following:</p>
        <ul>
          <li>Reload the page.</li>
          <li>If you are an admin, double check that the module and the Sync Core are up-to-date.</li>
          <li>If you are not an admin, ask for support and provide the exact URL to this page.</li>
          <li>Come back again later.</li>
        </ul>
      </>
    );
  }
}
