import { AppConfiguration, IAppConfiguration } from '@edgebox/react-components';
import React from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { AppParams, AppParamsContext, IAppParams } from './components/AppParams';
import { RoutingContainer } from './components/Customer/RoutingContainer';
import { SyncCoreApiProvider } from './contexts/SyncCoreApiContext';
import { IDataComponentConfiguration } from './services/SyncCoreApiComponent';
import './styles.scss';

interface IProps {}

type IAppConfig = IAppConfiguration<IDataComponentConfiguration>;

const AppConfigurationProvider = AppConfiguration.Provider as React.Provider<IAppConfig>;

export function App(props: IProps) {
  return (
    <>
      <AppParams>
        <AppParamsContext.Consumer>
          {(params: IAppParams) => {
            const appConfiguration = {
              jwt: params.siteOptions.jwt,
              syncCoreDomain: params.siteOptions.syncCoreDomain,
              baseUrl: params.siteOptions.baseUrl,
            };
            return (
              <AppConfigurationProvider value={appConfiguration}>
                <SyncCoreApiProvider appConfiguration={appConfiguration}>
                  <RoutingContainer />
                </SyncCoreApiProvider>
              </AppConfigurationProvider>
            );
          }}
        </AppParamsContext.Consumer>
      </AppParams>

      <ToastContainer />
    </>
  );
}
