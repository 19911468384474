import { CopyToClipboardButton, LeftRightContainer, LeftRightH1, LoadingBar } from '@edgebox/react-components';
import { SiteApplicationType, SyncCoreDataDefinitionsEnumTranslator, SyndicationErrorType } from '@edgebox/sync-core-data-definitions';
import { ClientSyndicationEntity } from '@edgebox/sync-core-rest-client';
import { SiteRequestDetails } from '@edgebox/sync-core-rest-client/dist/services/api';
import { faPlay } from '@fortawesome/pro-light-svg-icons/faPlay';
import { faStop } from '@fortawesome/pro-light-svg-icons/faStop';
import { faStopwatch } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faHeartPulse } from '@fortawesome/pro-light-svg-icons/faHeartPulse';
import { faArrowsRotate } from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Form, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { SyndicationStatusIcon } from '../SyndicationStatusIcon';
import { formatDuration, jsonStyle } from '../../../Helpers';
import { SyncCoreApiContext } from '../../../contexts/SyncCoreApiContext';
import { Nugget } from '../../Nugget';
import { getDateName } from '../../FormatDate';
import { getSyndicationTypeName } from '../../SyndicationName';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { UserHtml } from '../../UserHtml';
import moment from 'moment';
import ReactShadowRoot from 'react-shadow-root';
import { Breadcrumb } from '../Breadcrumb';
import { HeadlineBackButton } from '../HeadlineBackButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { EntityTypeIcon } from '../EntityTypeIcon';
import { faSitemap } from '@fortawesome/pro-light-svg-icons/faSitemap';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { JsonView, collapseAllNested } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import { getOperationIndexAndName } from '../../OperationName';
import { SyncConfigButton } from '../../SyncConfigButton';

type DrupalLogLevel = 'emergency' | 'alert' | 'critical' | 'error' | 'warning' | 'notice' | 'info' | 'debug' | 'log';
const DRUPAL_LOG_LEVELS_ERROR: DrupalLogLevel[] = ['emergency', 'alert', 'critical', 'error'];
const DRUPAL_LOG_LEVELS_WARNING: DrupalLogLevel[] = ['warning'];
const DRUPAL_LOG_LEVELS_DEBUG: DrupalLogLevel[] = ['debug'];

interface IDrupalError {
  message: string;
  stack: string;
  timestamp?: number;
  code?: string;
  parent?: IDrupalError;
}
interface IDrupalLogMessage {
  level: DrupalLogLevel;
  message: string;
  context: any;
  timestamp: number;
}

interface IErrorStackLineParsed {
  no: number;
  fileName?: string;
  filePath?: string;
  line?: number;
  className?: string;
  classPath?: string;
  functionName: string;
  args?: string;
  callType?: 'static';
}
interface IErrorStackLine {
  raw: string;
  parsed?: IErrorStackLineParsed;
}
interface IDrupalErrorProcessed {
  message: string;
  stack: IErrorStackLine[];
  timestamp?: moment.Moment;
  code?: string;
}
interface IDrupalErrorContextElement {
  name: string;
  type: string;
  value: string;
}
interface IDrupalLogMessageProcessed {
  level: DrupalLogLevel;
  message: React.ReactNode;
  messageRaw: string;
  messageIcons: {
    icon: IconProp;
    label: React.ReactNode;
  }[];
  context: IDrupalErrorContextElement[];
  timestamp: moment.Moment;
  ignoredEntity: boolean;
}
interface IDrupalErrorResponse extends IDrupalError {
  log?: IDrupalLogMessage[];
}
function isDrupalErrorResponse(input: unknown): input is IDrupalErrorResponse {
  return !!input && typeof input === 'object' && typeof (input as any).message === 'string' && typeof (input as any).stack === 'string';
}
function processDrupalError(input: IDrupalError): IDrupalErrorProcessed[] {
  const stackLines = input.stack.split('\n').map((c) => c.trim());
  const stack: IErrorStackLine[] = stackLines.map((raw) => {
    const regularMethodWithLine = raw.match(/^#([0-9]+) (.*)\/([^\/]+)\(([0-9]+)\): (.*)\\([^\\]+)(->|::)([^\(]+)\((.*)\)$/);
    if (regularMethodWithLine) {
      const [, no, filePath, fileName, line, classPath, className, callType, functionName, args] = regularMethodWithLine;
      return {
        raw,
        parsed: {
          no: parseInt(no),
          filePath,
          fileName,
          line: parseInt(line),
          classPath,
          className,
          callType: callType === '::' ? 'static' : undefined,
          functionName,
          args,
        },
      };
    }

    const internalMethod = raw.match(/^#([0-9]+) (\[internal function\]): (.*)\\([^\\]+)(->|::)([^\(]+)\((.*)\)$/);
    if (internalMethod) {
      const [, no, fileName, classPath, className, callType, functionName, args] = internalMethod;
      return {
        raw,
        parsed: {
          no: parseInt(no),
          fileName,
          classPath,
          className,
          callType: callType === '::' ? 'static' : undefined,
          functionName,
          args,
        },
      };
    }

    const regularFunctionWithLine = raw.match(/^#([0-9]+) (.*)\/([^\/]+)\(([0-9]+)\): ([^\(]+)\((.*)\)$/);
    if (regularFunctionWithLine) {
      const [, no, filePath, fileName, line, functionName, args] = regularFunctionWithLine;
      return {
        raw,
        parsed: {
          no: parseInt(no),
          filePath,
          fileName,
          line: parseInt(line),
          functionName,
          args,
        },
      };
    }

    const internalFunction = raw.match(/^#([0-9]+) (\[internal function\]): ([^\(]+)\((.*)\)$/);
    if (internalFunction) {
      const [, no, fileName, functionName, args] = internalFunction;
      return {
        raw,
        parsed: {
          no: parseInt(no),
          fileName,
          functionName,
          args,
        },
      };
    }

    const main = raw.match(/^#([0-9]+) (\{main\})$/);
    if (main) {
      const [, no, functionName] = main;
      return {
        raw,
        parsed: {
          no: parseInt(no),
          functionName,
        },
      };
    }

    return {
      raw,
    };
  });
  const self = {
    message: input.message,
    stack,
    code: input.code,
    timestamp: input.timestamp ? moment(input.timestamp) : undefined,
  };
  if (input.parent) {
    return [...processDrupalError(input.parent), self];
  }
  return [self];
}
function processDrupalLog(input: IDrupalLogMessage[]): IDrupalLogMessageProcessed[] {
  return input.map((message) => {
    const messageRaw = message.message
      .replace(/(@[a-zA-Z-_]+)/g, (_, name) => {
        const replacement = message.context.hasOwnProperty(name) ? message.context[name] : name;
        if (replacement.length > 550) {
          return replacement.substring(0, 500) + '...';
        }
        return replacement;
      })
      .replace(/<br\s*\/>/g, '<br>');
    const context = Array.isArray(message.context)
      ? []
      : Object.entries(message.context).map(([name, value]) => ({
          name: name.replace(/^@/, ''),
          value: typeof value === 'object' ? (value ? JSON.stringify(value) : 'null') : value + '',
          type: typeof value,
        }));

    const messageIcons: {
      icon: IconProp;
      label: React.ReactNode;
    }[] = [];
    // Always using the same Flow, so this info is redundant (already included in the URL).
    /*if (message.context['@flow_id']) {
      messageIcons.push({
        icon: faSitemap,
        label: message.context['@flow_id'],
      });
    }*/
    if (message.context['@pool_id']) {
      messageIcons.push({
        icon: faLayerGroup,
        label: message.context['@pool_id'],
      });
    }
    if (message.context['@duration']) {
      const timers = [
        `Duration: ${message.context['@duration']}`,
        ...(message.context['@timers'] && message.context['@timers'] !== '-' ? message.context['@timers'].split(', ') : []),
      ];
      messageIcons.push({
        icon: faStopwatch,
        label:
          timers.length > 1 ? (
            <ul>
              {timers.map((c, i) => (
                <li key={i}>{c}</li>
              ))}
            </ul>
          ) : (
            timers[0]
          ),
      });
    }

    const typeInfo =
      message.context['@entity_type'] && message.context['@bundle'] ? (
        <>
          <EntityTypeIcon
            namespaceMachineName={message.context['@entity_type']}
            machineName={message.context['@bundle']}
            appType={SiteApplicationType.Drupal}
          />{' '}
          {message.context['@bundle']}
        </>
      ) : (
        `${message.context['@entity_type'] || '?'}.${message.context['@bundle'] || '?'}`
      );

    if (message.message.startsWith('@not @embed PUSH ')) {
      const ignoredEntity = message.context['@not'] === 'NO';
      return {
        context,
        timestamp: moment(message.timestamp),
        level: message.level,
        ignoredEntity,
        messageRaw,
        message: ignoredEntity ? (
          <>
            <strong>NOT</strong> pushing {typeInfo} <span className="text-muted">{message.context['@uuid']?.substring(0, 14)}...</span>:{' '}
            {message.context['@message']}
          </>
        ) : (
          <>
            Pushing {typeInfo} <span className="text-muted">{message.context['@uuid']?.substring(0, 14)}...</span>
          </>
        ),
        messageIcons,
      };
    }

    if (message.message.startsWith('@not PULL ')) {
      const ignoredEntity = message.context['@not'] === 'NO';
      return {
        context,
        timestamp: moment(message.timestamp),
        level: message.level,
        ignoredEntity,
        messageRaw,
        message: ignoredEntity ? (
          <>
            <strong>NOT</strong> pulling {typeInfo} <span className="text-muted">{message.context['@uuid']?.substring(0, 14)}...</span>:{' '}
            {message.context['@message']}
          </>
        ) : (
          <>
            Pulling {typeInfo} <span className="text-muted">{message.context['@uuid']?.substring(0, 14)}...</span>
          </>
        ),
        messageIcons,
      };
    }

    return {
      context,
      timestamp: moment(message.timestamp),
      level: message.level,
      messageRaw,
      message: messageRaw,
      messageIcons: [],
      ignoredEntity: false,
    };
  });
}
function DrupalErrors({ errors }: { errors: IDrupalErrorProcessed[] }) {
  const [expanded, setExpanded] = useState<number[]>([]);

  return (
    <>
      {errors.map((error, index) => {
        const isExpanded = expanded.includes(index);
        return (
          <React.Fragment key={index}>
            <Row
              className={`cursor-pointer mb-2 ${index > 0 ? 'text-muted' : ''}`}
              onClick={() => setExpanded(isExpanded ? expanded.filter((c) => c !== index) : [...expanded, index])}
            >
              <Col xs={1}>
                <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
              </Col>
              <Col xs={2}>{error.timestamp ? error.timestamp.format('HH:mm:ss.SSS') : null}</Col>
              <Col>{error.message}</Col>
            </Row>
            {isExpanded
              ? error.stack.map((line, callIndex) => {
                  return line.parsed ? (
                    <Row key={callIndex} title={line.raw}>
                      <Col xs={1}></Col>
                      <Col xs={2} className="text-end text-light">
                        #{line.parsed.no}
                      </Col>
                      <Col xs={4} className="text-truncate text-end">
                        <span className="text-muted">{line.parsed.fileName || ''}</span>
                        {typeof line.parsed.line === 'number' ? `:${line.parsed.line}` : ''}
                      </Col>
                      <Col xs={5} className="text-truncate">
                        {line.parsed.className ? `${line.parsed.className}${line.parsed.callType === 'static' ? '::' : '->'}` : ''}
                        {line.parsed.functionName}()
                      </Col>
                    </Row>
                  ) : (
                    <Row key={callIndex}>
                      <Col xs={3}></Col>
                      <Col>{line.raw}</Col>
                    </Row>
                  );
                })
              : null}
          </React.Fragment>
        );
      })}
    </>
  );
}
function DrupalLogMessage({ message }: { message: IDrupalLogMessageProcessed }) {
  const [isExpanded, setExpanded] = useState<boolean>(false);

  return (
    <>
      <Row className="cursor-pointer mb-2" onClick={() => setExpanded(!isExpanded)}>
        <Col xs={1}>
          <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />
        </Col>
        <Col
          xs={2}
          title={message.level}
          className={`${
            DRUPAL_LOG_LEVELS_ERROR.includes(message.level)
              ? 'text-error'
              : DRUPAL_LOG_LEVELS_WARNING.includes(message.level)
              ? 'text-warning'
              : DRUPAL_LOG_LEVELS_DEBUG.includes(message.level)
              ? 'text-muted'
              : ''
          }`}
        >
          {message.timestamp.format('HH:mm:ss.SSS')}
        </Col>
        {message.messageIcons.length ? (
          <Col xs={1} className="pe-0">
            <div>
              {message.messageIcons.map((icon, index) => (
                <OverlayTrigger
                  placement="left"
                  overlay={(props) => (
                    <Tooltip id={`tooltip-message-${message.timestamp}-${index}-icon`} {...props} className="tooltip-wide">
                      {icon.label}
                    </Tooltip>
                  )}
                >
                  <span className="d-inline-block me-1">
                    <FontAwesomeIcon icon={icon.icon} className={`${icon.icon === faSitemap ? 'fa-rotate-90' : ''}`} />
                  </span>
                </OverlayTrigger>
              ))}
            </div>
          </Col>
        ) : null}
        <Col title={message.messageRaw === message.message ? undefined : message.messageRaw}>
          {typeof message.message === 'string'
            ? message.message.split('<br>').map((line, lineIndex) => <div key={lineIndex}>{line}</div>)
            : message.message}
        </Col>
      </Row>
      {isExpanded
        ? message.context.map((item) => {
            if (item.name === 'not' && ['', 'NOT'].includes(item.value)) {
              return <React.Fragment key={item.name}></React.Fragment>;
            }

            return (
              <Row key={item.name}>
                <Col xs={3}></Col>
                <Col xs={3} className="text-truncate" title={item.name}>
                  {item.name}
                </Col>
                {/*<Col xs={1}>{item.type}</Col>*/ null}
                <Col>{item.value}</Col>
              </Row>
            );
          })
        : null}
    </>
  );
}

export const ERROR_INDEX_TRACE = -1;
const REQUEST_DETAILS_UNAVAILABLE: 1 = 1;
const REQUEST_DETAILS_FAILED_TO_LOAD: 2 = 2;
export function OperationErrorDetails({
  syndication,
  noSyndication,
  showSiteName,
  noMassUpdates,
  noMassUpdatesName,
  noMigration,
  noMigrationName,
  migrationName,
  setErrorDetails,
  operationIndex,
  errorIndex,
}: {
  syndication: ClientSyndicationEntity;
  noSyndication: () => void;
  showSiteName?: boolean;
  noMassUpdates?: () => void;
  noMassUpdatesName?: string;
  noMigration?: () => void;
  noMigrationName?: string;
  migrationName?: string;
  setErrorDetails?: (errorDetails?: { operationIndex: number; errorIndex: number }) => void;
  operationIndex: number;
  errorIndex: number;
}) {
  const mounted = useRef(false);
  const api = useContext(SyncCoreApiContext);

  const [requestDetails, setRequestDetails] = useState<
    SiteRequestDetails | typeof REQUEST_DETAILS_UNAVAILABLE | typeof REQUEST_DETAILS_FAILED_TO_LOAD | undefined
  >(undefined);
  const [errors, setErrors] = useState<IDrupalErrorProcessed[] | undefined>(undefined);
  const [logMessages, setLogMessages] = useState<IDrupalLogMessageProcessed[] | undefined>(undefined);

  const [showRaw, setShowRaw] = useState(false);
  const [showRequestBody, setShowRequestBody] = useState(false);
  const [showResponseBody, setShowResponseBody] = useState(false);
  const [showIgnoredEntities, setShowIgnoredEntities] = useState(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const isTrace = errorIndex === ERROR_INDEX_TRACE;

  const operation = syndication.operations?.[operationIndex];
  const error = operation?.errors?.[errorIndex];
  const requestDetailsReference = isTrace ? operation?.traceRequestDetails : error?.requestDetails;

  useEffect(() => {
    if (!requestDetailsReference) {
      setRequestDetails(REQUEST_DETAILS_UNAVAILABLE);
      return;
    }

    requestDetailsReference
      .get()
      .then((requestDetailsFile) => {
        if (!requestDetailsFile?.downloadUrl) {
          setRequestDetails(REQUEST_DETAILS_UNAVAILABLE);
          return;
        }

        fetch(requestDetailsFile.downloadUrl)
          .then((requestDetailsResponse) => {
            if (!requestDetailsResponse) {
              setRequestDetails(REQUEST_DETAILS_FAILED_TO_LOAD);
              return;
            }

            requestDetailsResponse
              .json()
              .then((newRequestDetails: SiteRequestDetails) => {
                setRequestDetails(newRequestDetails);
                if (newRequestDetails.responseBody) {
                  try {
                    const bodyContent = JSON.parse(newRequestDetails.responseBody);
                    // If it's JSON, we can show the response body immediately
                    // without overloading the browser.
                    setShowResponseBody(true);
                    if (isDrupalErrorResponse(bodyContent)) {
                      setErrors(processDrupalError(bodyContent));
                    }

                    if (bodyContent.log?.length || bodyContent.responseBody?.log?.length || isDrupalErrorResponse(bodyContent)) {
                      setLogMessages(
                        bodyContent.log
                          ? processDrupalLog(bodyContent.log)
                          : bodyContent.responseBody?.log
                          ? processDrupalLog(bodyContent.responseBody.log)
                          : []
                      );
                    }
                  } catch (e) {}
                }
              })
              .catch((e) => setRequestDetails(REQUEST_DETAILS_FAILED_TO_LOAD));
          })
          .catch((e) => setRequestDetails(REQUEST_DETAILS_FAILED_TO_LOAD));
      })
      .catch((e) => setRequestDetails(REQUEST_DETAILS_FAILED_TO_LOAD));
  }, [api, error]);

  let content: React.ReactNode;
  if (requestDetails === undefined) {
    content = <LoadingBar />;
  } else if (requestDetails === REQUEST_DETAILS_UNAVAILABLE) {
    if (error?.type === SyndicationErrorType.Timeout) {
      content = (
        <Alert variant="warning" className="mt-3">
          This request timed out. Please check out the Advanced settings for different strategies to avoid them.
        </Alert>
      );
    } else if (error?.type === SyndicationErrorType.SiteMustPoll) {
      content = (
        <Alert variant="warning" className="mt-3">
          This site is configured to poll for requests. Please use drush to process this request asynchronously.
        </Alert>
      );
    } else if (error?.type === SyndicationErrorType.SiteFailedToPoll) {
      content = (
        <Alert variant="warning" className="mt-3">
          This site is configured to poll for requests, but failed to do so in time. Please retry if you're site is ready to poll now.
        </Alert>
      );
    } else if (error?.type === SyndicationErrorType.BadRequestBody) {
      content = (
        <Alert variant="danger" className="mt-3">
          <h3>This site expects a different request</h3>
          <p className="mt-3">
            {error?.errorMessage?.startsWith('Http ') ? (
              <strong>{error.errorMessage.substring('Http '.length)}</strong>
            ) : (
              error?.errorMessage ?? <em>Missing details.</em>
            )}
          </p>
          <p className="mt-3">
            This can happen when the source site doesn't send a property that's required at the target site or when both sites use different
            field types for the same field.
            <br />
            <em>
              If both sites are using the same entity type definition, this could be an older content item that needs to be pushed again to
              update all properties to the expected format.
            </em>
          </p>
        </Alert>
      );
    } else {
      content = (
        <Alert variant="warning">This request doesn't have any details. This usually happens if the request is older than two weeks.</Alert>
      );
    }
  } else if (requestDetails === REQUEST_DETAILS_FAILED_TO_LOAD) {
    content = (
      <Alert variant="danger">
        We were not able to load the request details, please try again. If this happens regularly, please reach out to our support.
      </Alert>
    );
  } else {
    content = undefined;

    const filteredLogMessages = logMessages?.filter((c) => showIgnoredEntities || !c.ignoredEntity);

    if (requestDetails.responseBody) {
      try {
        let json = JSON.parse(requestDetails.responseBody);
        if (json.responseBody) {
          json = json.responseBody;
        }
        delete json.log;

        const errorMessage = operation?.errors?.[0]?.errorMessage;
        const unknownProperty = errorMessage?.includes('Unknown property')
          ? errorMessage?.replace(/^.*Http Validation Exception \(([a-zA-Z0-9_\.\-]+)\).*$/, '$1')
          : undefined;
        const invalidProperty = errorMessage?.includes('Invalid property')
          ? errorMessage?.replace(/^.*Invalid property\s*([a-zA-Z0-9_\.\-]+).*$/, '$1')
          : undefined;
        const showResponseBodyByDefault = unknownProperty || invalidProperty;

        let open: string[] | undefined = unknownProperty?.split('.') || invalidProperty?.split('.');

        function getNestedValue(end: number) {
          if (!open) {
            return null;
          }
          const parts = open.slice(0, end);
          let value: any = json;
          for (const property of parts) {
            if (Array.isArray(value)) {
              value = value[parseInt(property)];
            } else {
              value = value[property];
            }
            if (!value) {
              break;
            }
          }
          return value;
        }
        function shouldExpandNode(level: number, value: any, field?: string) {
          const defaultOpen = level === 0;
          if (!open || defaultOpen) {
            return defaultOpen;
          }

          const expected = open[level - 1];
          if (!expected) {
            return defaultOpen;
          }

          if (field) {
            return typeof field === 'number' ? parseInt(expected) === field : expected === field;
          }

          const parentValue = getNestedValue(level - 1);
          if (Array.isArray(parentValue)) {
            const index = parentValue.indexOf(value);
            return index === parseInt(expected);
          }

          return defaultOpen;
        }

        content = (
          <>
            {operation?.errors?.length && operation.errors[0].type === SyndicationErrorType.BadResponseBody ? (
              <Alert variant="danger">
                {errorMessage}
                {errorMessage?.includes('Unknown property') ? (
                  <>
                    <br />
                    <br />
                    <strong>
                      It looks like you added a new field to one of your content types. Please export your Flow configuration to the Sync
                      Core before pushing content.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      To avoid this issue in the future, please always run <em>drush cse</em> after every deployment.
                    </strong>
                    <br />
                    <br />
                    <SyncConfigButton variant="primary" />
                  </>
                ) : null}
                {errorMessage?.includes('Missing required property') ? (
                  <>
                    <br />
                    <br />
                    <strong>
                      It looks like you added a new field to one of your content types that is required. If you add required fields, you
                      need to make sure that all content items have a value set or they can't be pushed.
                    </strong>
                    <br />
                    <br />
                    <strong>
                      Please either update this content to include the new field or make the field optional to pass validation.
                    </strong>
                  </>
                ) : null}
              </Alert>
            ) : null}

            <div>
              {showResponseBody || showResponseBodyByDefault ? (
                errors ? (
                  <>
                    <h4 className="mt-4">Error</h4>
                    <DrupalErrors errors={errors} />
                    {filteredLogMessages && (
                      <>
                        <h4 className="mt-4">Log messages</h4>
                        {filteredLogMessages.length ? (
                          filteredLogMessages.map((c, i) => <DrupalLogMessage message={c} key={i} />)
                        ) : (
                          <em>None.</em>
                        )}

                        {!showIgnoredEntities && logMessages && logMessages.length > filteredLogMessages.length && (
                          <div>
                            +{logMessages.length - filteredLogMessages.length} ignored entities.{' '}
                            <Button variant="link" onClick={() => setShowIgnoredEntities(true)}>
                              Show
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  </>
                ) : filteredLogMessages ? (
                  <>
                    <h4 className="mt-4">Log messages</h4>
                    {filteredLogMessages.length ? (
                      filteredLogMessages.map((c, i) => <DrupalLogMessage message={c} key={i} />)
                    ) : (
                      <em>None.</em>
                    )}

                    {!showIgnoredEntities && logMessages && logMessages.length > filteredLogMessages.length && (
                      <div>
                        +{logMessages.length - filteredLogMessages.length} ignored entities.{' '}
                        <Button variant="link" onClick={() => setShowIgnoredEntities(true)}>
                          Show
                        </Button>
                      </div>
                    )}

                    <h4 className="mt-4">Response body</h4>
                    <CopyToClipboardButton name="request-body" text={requestDetails.responseBody} buttonOnly buttonText=" Copy" />
                    <JsonView data={json} style={jsonStyle} shouldExpandNode={shouldExpandNode} />
                  </>
                ) : (
                  <>
                    <div className="fw-bold">Response body</div>
                    <CopyToClipboardButton name="request-body" text={requestDetails.responseBody} buttonOnly buttonText=" Copy" />
                    <JsonView data={json} style={jsonStyle} shouldExpandNode={shouldExpandNode} />
                  </>
                )
              ) : (
                <>
                  <div className="fw-bold">Response body</div>
                  <CopyToClipboardButton name="request-body" text={requestDetails.responseBody} buttonOnly buttonText=" Copy" />
                  <Button variant="link" onClick={() => setShowResponseBody(true)}>
                    Show
                  </Button>
                </>
              )}
            </div>
          </>
        );
      } catch (e) {
        content = (
          <>
            <LeftRightContainer
              left={<strong>Response body</strong>}
              right={
                <Form.Check
                  className="cursor-pointer"
                  inline
                  id="show-raw"
                  type="switch"
                  checked={!!showRaw}
                  onChange={() => setShowRaw(!showRaw)}
                  label="Raw"
                />
              }
            />
            <CopyToClipboardButton name="request-body" text={requestDetails.responseBody} buttonOnly buttonText=" Copy" />
            {/* There is no plain "html", so we use "vbscript-html" instead */}
            {showResponseBody ? (
              showRaw ? (
                <SyntaxHighlighter language="vbscript-html">{requestDetails.responseBody}</SyntaxHighlighter>
              ) : (
                <div>
                  <ReactShadowRoot>
                    <UserHtml dangerousHtml={requestDetails.responseBody} />
                  </ReactShadowRoot>
                </div>
              )
            ) : (
              <>
                <Button variant="link" className="mt-1" onClick={() => setShowResponseBody(true)}>
                  Show
                </Button>
              </>
            )}
          </>
        );
      }
    } else {
      content = (
        <>
          <div>
            <strong>Response body</strong>
          </div>
          <em>None.</em>
        </>
      );
    }

    content = (
      <Row>
        <Col xs={12} xl={6}>
          <div className="mx-1 my-2 p-3 bg-white rounded">
            <h2>Request</h2>
            <div className="mt-2 mb-3">
              <Nugget icon={faPlay}>
                {requestDetails.requestStart ? (
                  <>
                    {getDateName(moment(requestDetails.requestStart))}, {moment(requestDetails.requestStart).format('LT')}
                  </>
                ) : (
                  <em>Not provided</em>
                )}
              </Nugget>
              <Nugget icon={faStop}>
                {requestDetails.requestEnd ? (
                  <>
                    {getDateName(moment(requestDetails.requestEnd))}, {moment(requestDetails.requestEnd).format('LT')}
                  </>
                ) : (
                  <em>Not provided</em>
                )}
              </Nugget>
              {requestDetails.requestEnd && requestDetails.requestStart ? (
                <Nugget icon={faStopwatch}>{<>{formatDuration(requestDetails.requestEnd - requestDetails.requestStart)}</>}</Nugget>
              ) : null}
            </div>
            {requestDetails.requestUrl && (
              <div className="text-break">
                <strong>{requestDetails.requestMethod ? requestDetails.requestMethod : undefined}</strong> {requestDetails.requestUrl}
              </div>
            )}
            <Row className="mt-2">
              <Col>
                <div className="fw-bold">Settings</div>
                <div>Max follow: {requestDetails.requestMaxFollow || <em>Not provided.</em>}</div>
                <div>Max size: {requestDetails.requestMaxSize || <em>Not provided.</em>}</div>
                <div>Timeout: {requestDetails.requestTimeout || <em>Not provided.</em>}</div>
              </Col>
            </Row>
            <div className="fw-bold mt-2">Request headers</div>
            {requestDetails.requestHeaders ? (
              Object.keys(requestDetails.requestHeaders).map((name) => (
                <Row key={name}>
                  <Col>{name}</Col>
                  <Col>
                    {requestDetails.requestHeaders[name].map((value: string, index: number) => (
                      <div key={index}>{value}</div>
                    ))}
                  </Col>
                </Row>
              ))
            ) : (
              <em>Not provided.</em>
            )}
            <div className="fw-bold mt-2">Request body</div>
            <div>
              {requestDetails.requestBody ? (
                <>
                  <CopyToClipboardButton name="request-body" text={requestDetails.requestBody} buttonOnly buttonText=" Copy" />
                  {showRequestBody ? (
                    <JsonView data={JSON.parse(requestDetails.requestBody)} style={jsonStyle} shouldExpandNode={collapseAllNested} />
                  ) : (
                    <Button variant="link" onClick={() => setShowRequestBody(true)}>
                      Show
                    </Button>
                  )}
                </>
              ) : (
                <em>None.</em>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} xl={6}>
          <div className="mx-1 my-2 p-3 bg-white rounded">
            <h2>Response</h2>
            <div className="mt-2 mb-3">
              <Nugget
                icon={faHeartPulse}
                color={requestDetails.responseStatusCode && requestDetails.responseStatusCode >= 400 ? 'danger' : undefined}
              >
                {requestDetails.responseStatusCode || requestDetails.responseStatusText ? (
                  <>
                    {requestDetails.responseStatusCode || <em>(no code provided)</em>}{' '}
                    {requestDetails.responseStatusText || <em>(no text provided)</em>}
                  </>
                ) : (
                  <em>None</em>
                )}
              </Nugget>
            </div>
            <div>
              {requestDetails.responseStatusCode === 500 && !logMessages && (
                <>
                  <Alert variant="warning">Please check your site logs for more details.</Alert>
                </>
              )}
            </div>
            <div className="fw-bold mt-2">Response headers</div>
            {requestDetails.responseHeaders && Object.keys(requestDetails.responseHeaders).length ? (
              Object.keys(requestDetails.responseHeaders).map((name) => (
                <Row key={name}>
                  <Col>{name}</Col>
                  <Col>
                    {requestDetails.responseHeaders[name].map((value: string, index: number) => (
                      <div key={index}>{value}</div>
                    ))}
                  </Col>
                </Row>
              ))
            ) : (
              <em>Not provided.</em>
            )}
            <div className="mt-2">{content}</div>
          </div>
        </Col>
      </Row>
    );
  }

  return (
    <>
      <div className="">
        {noMigrationName || noMassUpdatesName || migrationName ? (
          <Breadcrumb
            items={[
              ...(noMassUpdatesName && noMassUpdates ? [{ name: noMassUpdatesName, onClick: () => noMassUpdates() }] : []),
              ...(noMigrationName && noMigration ? [{ name: noMigrationName, onClick: () => noMigration() }] : []),
              ...(migrationName && noSyndication ? [{ name: migrationName, onClick: () => noSyndication() }] : []),
              ...(syndication && setErrorDetails
                ? [
                    {
                      name: getSyndicationTypeName(syndication.type, syndication.rootEntityReference?.name),
                      onClick: () => setErrorDetails(),
                    },
                  ]
                : []),
              ...(operation
                ? [
                    {
                      name: getOperationIndexAndName(operationIndex, operation),
                    },
                  ]
                : []),
              {
                name: isTrace
                  ? 'Trace'
                  : SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
                      'SyndicationErrorType',
                      error?.type || SyndicationErrorType.Unexpected
                    ),
              },
            ]}
          />
        ) : null}
        <LeftRightH1
          left={
            <>
              <HeadlineBackButton onClick={() => setErrorDetails?.()} />
              <span className="d-inline-block align-middle fs-2 ms-2 me-2">
                <SyndicationStatusIcon status={operation?.status || syndication.status} type={syndication.type} background="none" />
              </span>
              <span className="d-inline-block align-middle">
                {isTrace
                  ? 'Trace'
                  : SyncCoreDataDefinitionsEnumTranslator.transLateEnumValue(
                      'SyndicationErrorType',
                      error?.type || SyndicationErrorType.Unexpected
                    )}
              </span>
            </>
          }
          right={null}
        />
      </div>

      <div className="mt-2 mb-3">
        {(operation?.updateCount ?? null) !== null || (operation?.ignoreCount ?? null) !== null ? (
          <Nugget icon={faArrowsRotate} dark>
            <>
              {operation?.updateCount ?? 0}/{(operation?.updateCount ?? 0) + (operation?.ignoreCount ?? 0)}
            </>
          </Nugget>
        ) : null}
      </div>

      {content}
    </>
  );
}
